import { createContext, useContext, useState } from "react";
import { createPostContext } from "./createPostContext";

// background context
export const backgroundContext = createContext();

const PostBackgroundContextProvider = ({children}) => {
    const {post,setPost} = useContext(createPostContext);
    const [background, setBackground] = useState(post.theme || null);
    return (
        <backgroundContext.Provider value={{background,setBackground}}>
            {children}
        </backgroundContext.Provider>
    )
}

export default PostBackgroundContextProvider;