import { useInfiniteQuery, useMutation, useQuery } from "react-query"
import { createNews, getHallNews, getNewsTypes, getRelatedNews, getSingleNews, postNewsPic } from "../api/news"
import { toast } from "react-toastify";

const useGetHallNews = (id,type) => {
    return useInfiniteQuery(["hall-news",type],({pageParam})=>getHallNews(pageParam || `/buzz/v1/buzz/?hall=${id}&type=${type}`),{
        getNextPageParam: (lastPage) => lastPage.data.next,
        // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
        // onSuccess:(data)=>console.log(data),
        onError:(error)=>{
            toast.error("Failed to fetch news",{position:"top-center",hideProgressBar:true,autoClose:2000});
        },
        enabled:!!type
      });
}
const useGetNewsTypes = () => {
    return useQuery("news-types",getNewsTypes,{
        // onSuccess:data=>console.log(data),
        // onError:error=>{
        //     toast.error("Failed to fetch news categories",{position:"top-center",hideProgressBar:true,autoClose:2000});
        // }
    })
}

const useCreateNews = () => {
    return useMutation(createNews,{
        mutationKey:"create-news"
    })
}

const usePostNewsPic = () => {
    return useMutation((values)=>postNewsPic(values.values,values.uploadProgress),{
        mutationKey:"post-news-pic",
        // onError:(error)=>console.log(error),
        // onSuccess:(data)=>console.log(data),
    })
}

const useGetSingleNews = (newsId) => {
    return useQuery(["single-news",newsId],()=>getSingleNews(newsId),{
        // onSuccess:(data)=>{
        //     console.log(data);
        // },
        onError:(error)=>{
            toast.error("Failed to fetch news",{position:"top-center",hideProgressBar:true,autoClose:2000});
        }
    })
}

const useGetRelatedNews = (newsId) => {
    return useQuery(["related-news",newsId],()=>getRelatedNews(newsId),{
        // onSuccess:data=>{
        //     console.log(data)
        // },
        onError:error=>{
            toast.error("Failed to fetch related news",{position:"top-center",hideProgressBar:true,autoClose:2000});
        }
    })
}

export {
    useGetNewsTypes,
    useCreateNews,
    usePostNewsPic,
    useGetHallNews,
    useGetSingleNews,
    useGetRelatedNews
}