import { useContext, useEffect, useState } from "react";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { themeContext } from "../../contexts/themeContext";
const OptionSelector = ({options,onChange,name}) => {
  const {theme} = useContext(themeContext);
    const [selectedOption,setSelectedOption] = useState("");
    const [showOptions,setShowOptions] = useState(false);
    const [rotate,setRotate] = useState(false);
    const onDropDown = () => {
        setShowOptions(prev=>!prev);
        setRotate(prev=>!prev);
    }
    const onSelect = (value) => {
        setSelectedOption(value.type);
        onChange(name,value.id)
        setRotate(false);
        setShowOptions(false);
    }
    useEffect(()=>{
        if(options){
            setSelectedOption(options[0].type);
            onChange("type",options[0].id)
        }
    },[options])
  return (
    <div className="w-40 relative rounded-md" style={{border:theme?.value == "light"?"1px solid #D9D9D9":"1px solid #6c6c6c"}}>
      <div className="h-10 w-full text-primaryTextLight dark:text-primaryTextDark flex items-center justify-between px-2 pl-3 rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDown}>
        <span className="font-light">{selectedOption}</span>
        <div className="flex items-center gap-2">
          {theme?.value == "light"?
          <SelectIcon width="12" height="12" onClick={onDropDown} className={
            "cursor-pointer " +
            (rotate ? "rotate-180 delay-200" : "delay-200")
          }/>:<SelectIconDark width="12" height="12" onClick={onDropDown} className={
            "cursor-pointer " +
            (rotate ? "rotate-180 delay-200" : "delay-200")
          }/>}
        </div>
      </div>
      {showOptions && 
          <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md mt-[1px] absolute top-10 w-full z-10 max-h-28 overflow-auto">
            {options.map((option,index)=>{
                return (
                    <div className="text-primaryTextLight dark:text-primaryTextDark flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={option.id} onClick={()=>onSelect(option)}>
                        <span>{option.type}</span>
                    </div>
                    )
                })}
            </div>
      }
    </div>
  );
};

export default OptionSelector;