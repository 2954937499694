import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import AvatarEditor from "react-avatar-editor";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera-icon-white.svg";

import Slider from "react-input-slider";
import { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../contexts/userContext";
import { hallContext } from "../../contexts/hallContext";
import { useUpdateProfilePicture } from "../../hooks/hallHooks";
import { hallActions } from "../../reducers/actions";
import { BeatLoader } from "react-spinners";
import { momentMenuContext } from "../../contexts/momentMenuContext";
import { toast } from "react-toastify";

const SetAsCoverPhotoModal = () => {
  const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
  const [state, setState] = useState({ x: 0, y: 0 });
  const { hall, setHall } = useContext(hallContext);
  const editor = useRef(null);

  const { mutate: updateCover, isLoading } = useUpdateProfilePicture(
    hall?.hallInfo.id
  );

  const onUpdate = async () => {
    if (editor) {
      const croppedImageUrl = editor.current
        .getImageScaledToCanvas()
        .toDataURL();
      const result = await fetch(croppedImageUrl);
      const blob = await result.blob();
      const croppedImage = new File(
        [blob],
        `${hall?.hallInfo?.name}-profile-picture.${blob.type.split("/")[1]}`,
        { type: blob.type }
      );
      const formData = new FormData();
      formData.append("cover_image", croppedImage);
      updateCover(formData, {
        onSuccess: (data) => {
          setHall(prev=>{return {...prev,hallInfo:data?.data}});
          setMomentMenu(prev=>{ return {...prev,setAsCoverPhoto:false,coverPhoto:""}});
        toast.success("Cover photo updated",{autoClose:2000,position:"top-center",hideProgressBar:true})
        },
        onError: (error) => {
          toast.error("Failed to set as cover photo",{autoClose:2000,position:"top-center",hideProgressBar:true})
        },
      });
    }
  };

  const onCancel = () => {
    setMomentMenu(prev=> { return {...prev,setAsCoverPhoto:false,coverPhoto:""}})
  }

  return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:200}}>
      <section className="h-[80%] w-[45%] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
          <CloseIcon
            width="25"
            height="25"
            onClick={onCancel}
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
          />
        <div className="w-full px-4 py-4 flex items-center justify-between">
          <span className="text-primaryTextLight dark:text-primaryTextDark text-xl font-semibold">Cover Picture</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="w-full text-center pt-6 mb-10">
          <span className="border-2 dark:border-dark-gray rounded-md px-4 py-2 text-primaryTextLight dark:text-primaryTextDark font-normal text-xl">
            Position Picture
          </span>
        </div>
        <div className="w-full h-60 flex justify-center relative items-center">
            <div className="h-60 w-[60%] overflow-y-scroll scrollbar-hide">
              <AvatarEditor
                image={momentMenu.coverPhoto}
                width={400}
                height={150}
                border={[20, 60]}
                borderRadius={10}
                color={[128, 128, 128, 0.8]} // RGBA
                scale={1 + state.x / 25}
                rotate={0}
                className="bg-backgroundLight dark:bg-backgroundDark w-full h-full"
                ref={editor}
                crossOrigin="anonymous"
              />
            </div>
        </div>
        <div className="w-full flex gap-20 mt-5 ml-8 items-center mb-2">
          <span className="text-primaryTextLight dark:text-primaryTextDark">Zoom</span>
          <Slider
            axis="x"
            x={state.x}
            onChange={({ x }) => setState((state) => ({ ...state, x }))}
            styles={{
              track: {
                backgroundColor: "rgba(246, 64, 73, 0.29);",
                height: "5px",
                width: "300px",
              },
              active: {
                backgroundColor: "red",
              },
              thumb: {
                width: 20,
                height: 20,
              },
              disabled: {
                opacity: 0.5,
              },
            }}
          />
        </div>
        <div className="w-full flex items-center justify-center">
          {isLoading ? (
            <BeatLoader color="#F63E49" size={10} />
          ) : (
            <span
              className="underline text-center font-medium text-xl text-[#F63E49] cursor-pointer"
              onClick={onUpdate}
            >
              Update
            </span>
          )}
        </div>
      </section>
    </div>
  );
};

export default SetAsCoverPhotoModal;
