import { useContext, useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import { useCheckAboutChangeRequestExists } from "../../hooks/hallHooks";
import EditButton from "./EditButton";

const AboutHall = ({ onEdit,onSeeMore,onAboutRequest,setAboutRequestData,isChangeRequested,setIsChangeRequested}) => {
  const {hall} = useContext(hallContext);
  // const onCheckIsChangeRequested = (data) => {
  //     console.log(data)
  //     if(data?.data.requested === false){
  //       setIsAboutChangeRequested(false);
  //     }else{
  //       setIsAboutChangeRequested(true);
  //       setAboutRequestData(data?.data.change_request);
  //     }
  //   }
  const {data} =  useCheckAboutChangeRequestExists(hall?.hallInfo.id);
  
  
  return (
    <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark w-full h-72 rounded-md p-2 pl-4 pr-4 flex flex-col gap-5 mb-5">
      <div className="flex w-full justify-between h-8 items-center">
        <span className="text-primaryTextLight dark:text-primaryTextDark font-medium text-lg">About Hall</span>
        <EditButton onClick={!data?.data.requested ? onEdit : onAboutRequest} />
      </div>
      <div>
        <p className="text-primaryTextLight dark:text-primaryTextDark text-md inline line-clamp-8">{hall?.hallInfo?.about}</p>
        <span className="text-sm text-[#F63E49] cursor-pointer" onClick={onSeeMore}>See more</span>
      </div>
    </div>
  );
};

export default AboutHall;
