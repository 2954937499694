import {ReactComponent as SearchIcon} from "../../assets/icons/search-icon.svg";
import {ReactComponent as SearchIconDark} from "../../assets/icons/search-icon-dark.svg";
import {ReactComponent as FilterIcon} from "../../assets/icons/filter-icon.svg";
import ExecutiveCard from "./ExecutiveCard";
import { useGetCurrentManagement, useSearchManagement } from "../../hooks/leadersHooks";
import { ClipLoader } from "react-spinners";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg"; 
import React, { useContext, useEffect, useState } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { BehaviorSubject,debounceTime,map,distinctUntilChanged,filter,switchMap,merge,of } from 'rxjs';
import { BarLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { themeContext } from "../../contexts/themeContext";
import moment from "moment";

const CurrentManagementList = ({setSearching,searching,showFilteredManagement,filteredManagement}) => {
  const {setLeadershipStates} = useContext(leadershipContext);
  const [subject,setSubject] = useState(null);
  const [loading,setLoading] = useState(false);
  const [searchTerm,setSearchTerm] = useState("");
  const {hall} = useContext(hallContext);
  const {data:currentManagement,isLoading} = useGetCurrentManagement(hall?.hallInfo?.id);
  const {data:searchResults,refetch,hasNextPage,fetchNextPage} = useSearchManagement(searchTerm,hall?.hallInfo.id,(data)=>{setLoading(false);})
  const {theme} = useContext(themeContext);

  const onChange = async (event) => {
    setSearchTerm(event.target.value)
    if(subject){
    setLoading(true);
    setSearching(true);
    return subject.next(event.target.value)
    }
}

useEffect(()=>{
  if(subject === null){
      const sub = new BehaviorSubject("");
      setSubject(sub);
  }else{
      const observable = subject.pipe(
          map(s => s.trim()),
          distinctUntilChanged(),
          debounceTime(200),
          )
          .subscribe(()=>{
            refetch();
          });
          return () => {
              observable.unsubscribe()
              subject.unsubscribe()}
  }
},[subject])

useEffect(()=>{
  if(searchTerm === ""){
      setSearching(false);
      setLoading(false);
  }
},[searchTerm])

  return (
    <div className="flex flex-col w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md px-4 pt-4 pb-10">
      <div className="flex justify-between items-center text-primaryTextLight dark:text-primaryTextDark text-sm">
        <span className="text-primaryTextLight dark:text-primaryTextDark font-semibold">
          {(!searching && !showFilteredManagement) ? "Current Management":"Management Leaders"}
        </span>
        <div className="flex gap-6">
          <div className="rounded-md border dark:border-dark-gray h-8 flex flex-col items-center justify-between">
          <div className="flex w-52 bg-cardBackgroundLight dark:bg-cardBackgroundDark items-center gap-2 h-8 px-2 rounded-md">
            {
              theme?.value == "light" ?
              <SearchIcon width="15" height="15" />:
              <SearchIconDark width="15" height="15" />
            }
            <input
              type={"search"}
              placeholder="Search for Management"
              className="text-sm font-light h-full focus:outline-none w-full rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
              onChange={onChange}
              value={searchTerm}
            />
          </div>
          {
            (loading) && 
            <BarLoader color="#F63E49" height={2} width="95%" className="w-full"/>
            }
          </div>
          <div className="flex h-full items-center w-28 py-1 bg-filter-grad px-2 gap-5 rounded-md cursor-pointer" onClick={()=>setLeadershipStates(prev=>{return {...prev,filterManagement:true}})}>
            <FilterIcon />
            <span className="text-primaryTextLight">{!showFilteredManagement ? "Filter": "Filtered"}</span>
          </div>
          <button className="px-8 py-1 flex items-center bg-linear-grad text-white rounded-sm" onClick={()=>setLeadershipStates(prev=>{return {...prev,addManagement:true,previous:"add-management"}})}>
            Add New
          </button>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 mt-8 w-full px-[10%] bg-cardBackgroundLight dark:bg-cardBackgroundDark">
        {
          !searching && !showFilteredManagement ?
          <React.Fragment>
        {isLoading && 
        <div className="w-full h-36 flex items-center justify-center bg-cardBackgroundLight dark:bg-cardBackgroundDark">
        <ClipLoader size={80} color="#F63E49"/>
      </div>
        }
        {
          !isLoading && currentManagement?.data?.results?.length === 0 &&
          <div className="flex items-center justify-center w-full h-full">
            <NoDataIcon height="400" width="400"/>
          </div>
        }
        {currentManagement?.data?.results?.map(executive=>{
          return (
            <ExecutiveCard
              name={executive.name}
              picture={executive.picture}
              position={executive.portofolio.title}
              key={executive.id}
              leader={executive}
              leaderCategory={"Management"}
              linked_account={executive.useraccount}
              showMenu={true}
              isCurrentExecutive={true}
              />          )
            })}
      </React.Fragment> :
      <React.Fragment>
        {
          searching &&
      <React.Fragment>
        {searchResults?.pages[0]?.data?.results?.length === 0 ? 
        <div className="w-full flex items-center justify-center"> <NoDataIcon height={400} width={400}/></div>:
        <InfiniteScroll
        dataLength={searchResults?.pages?.length * 64 || 64}
        next={fetchNextPage}
        // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextPage}
        loader={<div className="flex w-full justify-center mt-5">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}
        scrollableTarget={"page-container"}
        className="scrollbar-hide flex flex-wrap gap-4 w-full"
        >
            {searchResults?.pages?.map((group,i)=>{
                return group?.data?.results?.map(executive=>{
                 return <ExecutiveCard
              name={executive.name}
              picture={executive.picture}
              position={executive.portofolio.title}
              key={executive.id}
              leader={executive}
              leaderCategory={"Management"}
              showTenure={true}
              start_date={executive.start_date}
              end_date={executive.end_date}
              linked_account={executive.useraccount}
              isCurrentExecutive={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}
              showMenu={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}              />          })
                })}
        
        </InfiniteScroll>
        }
      </React.Fragment>
      }
      {
        showFilteredManagement && 
        <React.Fragment>
        {filteredManagement?.pages[0]?.data?.results?.length === 0 ? 
        <div className="w-full flex items-center justify-center"> <NoDataIcon height={400} width={400}/></div>:
        <InfiniteScroll
        dataLength={searchResults?.pages?.length * 64 || 64}
        next={fetchNextPage}
        // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextPage}
        loader={<div className="flex w-full justify-center mt-5">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}
        scrollableTarget={"page-container"}
        className="scrollbar-hide flex flex-wrap gap-4 w-full"
        >
            {filteredManagement?.pages?.map((group,i)=>{
                return group?.data?.results?.map(executive=>{
                 return <ExecutiveCard
              name={executive.name}
              picture={executive.picture}
              position={executive.portofolio.title}
              key={executive.id}
              leader={executive}
              leaderCategory={"Management"}
              showTenure={true}
              start_date={executive.start_date}
              end_date={executive.end_date}
              linked_account={executive.useraccount}
              isCurrentExecutive={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}
              showMenu={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}              />          })
                })}
        
        </InfiniteScroll>
        }
      </React.Fragment>
      }
      </React.Fragment>
          }
      </div>
    </div>
  );
};

export default CurrentManagementList;
