import { useContext, useState } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useCreateTenure } from "../../hooks/leadersHooks";
import { hallContext } from "../../contexts/hallContext";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const AddTenure = ({addExecutive}) => {
    const {hall} = useContext(hallContext);
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {mutate:createTenure,isLoading} = useCreateTenure();
    const [tenure,setTenure] = useState({start_date:"",end_date:"",hall:hall?.hallInfo?.id});
    const handleDateChange = (name,date) => {
        if(name ==="start_date" || name === "end_date"){
            const today = new Date();
            const day = today.getDate();
            setTenure(prev=> {return {...prev,[name]:date}})
    }

    }

    const onCreateTenure = () => {
        if(tenure.start_date === "" || tenure.end_date === "" ){
            toast.error("Start or End Date cannot be empty",{position:"top-center",autoClose:2000,hideProgressBar:true})
        }else if(moment(tenure.start_date).isSameOrAfter(moment(tenure.end_date),'year')){
            toast.error("End Date cannot be same as or before start date",{position:"top-center",autoClose:2000,hideProgressBar:true})
        }else if(!moment(tenure.start_date).isSame(moment(new Date),'year')){
            toast.error("Cannot create past tenure",{position:"top-center",autoClose:2000,hideProgressBar:true})
        }   
        else{
            createTenure({...tenure,start_date:moment(tenure.start_date).format("YYYY-MM-DD"),end_date:moment(tenure.end_date).format("YYYY-MM-DD")},{
                onSuccess:(data)=>{
                    toast.success("Tenure created successfully",{position:"top-center",autoClose:2000,hideProgressBar:true});
                    setLeadershipStates(prev=>{
                        return {...prev,addTenure:false,selectedTenure:data?.data};
                    });
                    addExecutive();
                },
                onError:(error) => {
                    toast.error("Failed to create tenure",{position:"top-center",autoClose:2000,hideProgressBar:true})
                }
            })
        }
    }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:"9999"}}>
            <div className="w-[40%] max-w-[400px] pb-5 rounded-md bg-backgroundLight dark:to-backgroundDark relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]"
                onClick={()=>{setLeadershipStates(prev=>{return {...prev,addTenure:false}})}}
                />
                <div className="flex w-full justify-between px-5 py-3">
                    <span className="text-primaryTextLight dark:text-primaryTextDark font-semibold text-lg">New Tenure</span>
                </div>
                <hr className="border-primaryTextLight opacity-[20%] dark:border-dark-gray"/>
                <div className="p-5 flex justify-center w-full gap-10">
                    <span className="inline-flex w-[10%] text-primaryTextLight dark:text-primaryTextDark">Tenure</span>
                    <div className="flex flex-col gap-5 w-[70%]">
                        <div className="w-full">
                            <ReactDatePicker showYearPicker dateFormat="yyyy" showMonthYearPicker selected={tenure.start_date} placeholderText="Starting (YYYY)" className="w-full placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" name="start_date" onChange={(date)=>handleDateChange("start_date",date)} maxDate={new Date()}/>
                        </div>
                        <div className="w-full">
                            <ReactDatePicker showYearPicker dateFormat="yyyy" showMonthYearPicker selected={tenure.end_date} placeholderText="Ending (YYYY)" className="w-full placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" name="end_date" onChange={(date)=>handleDateChange("end_date",date)} minDate={new Date()}/>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center">
                <button className="bg-linear-grad text-white px-4 rounded-md mt-5 text-xl" onClick={onCreateTenure}>{!isLoading ? "Done" : <BeatLoader size={10} color="white"/>}</button>
                </div>
            </div>
        </div>
    )
}

export default AddTenure;