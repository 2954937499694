import React from "react";
import { useState } from "react";
import { useGetPosts } from "../../hooks/postHooks";
import ImagesPostContent from "./ImagesPostContent";
import Post from "./Post";
import PostWithPoll from "./PostWithPoll";
import TextPostContent from "./TextPostContent";
import TextWithBgPostContent from "./TextWithBgPostContent";
import TextWithImagesPostContent from "./TextWithImagesPostContent";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";
import Trend from "./Trend";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import VideoPostContent from "./VideoPostContent";
import TextPostWithVideo from "./TextPostWithVideo";

const SearchedPosts = ({posts,trending,trends,setViewImages,setImages,searchedPostsDetails,searchedTrendsDetails,parentId}) => {
    if(!trending && posts?.pages && posts?.pages[0]?.data?.results?.length === 0){
        return (
        <div className="w-full flex items-center justify-center mt-10">
            <NoDataIcon height="200" width="200"/>
        </div>
        )
    }
    if(trending && trends?.pages && trends?.pages[0]?.data?.results?.length === 0){
        return (
            <div className="w-full flex items-center justify-center mt-10">
                <NoDataIcon height="200" width="200"/>
            </div>
            )
    }
    return (
        !trending ?( 
          <InfiniteScroll
          dataLength={posts?.pages?.length * 64 || 64}
          next={searchedPostsDetails.fetchNextPage}
          style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
          hasMore={searchedPostsDetails.hasNextPage}
          loader={<div className="flex w-full justify-center mt-5">
          <ClipLoader size={30} color="#F63E49"/>
      </div>}
          scrollableTarget={parentId}
          className="scrollbar-hide"
          >
          {/* <React.Fragment> */}
              {posts?.pages?.map((group,i)=>{
                  return group?.data?.results?.map(post=>{
                  if(post.shared_from === null){
                      if(post.polls.length !== 0){
                          return (
                              <Post key={post.id} post={post}>
                                  <PostWithPoll polls={post.polls[0]} text={post.text}/>
                              </Post>
                          )
                      }
                      else if(post.videos.length !== 0 && !post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <VideoPostContent video={post.videos[0]}/>
                            </Post>
                        )
                    }else if(post.videos.length !== 0 && post.text){
                        return (
                        <Post key={post.id} post={post}>
                            <TextPostWithVideo text={post.text} video={post.videos[0]}/>
                        </Post>
                        )
                    }
                      else if(post.theme){
                          return (
                              <Post key={post.id} post={post}>
                                  <TextWithBgPostContent text={post.text} theme={post.theme}/>
                              </Post>
                          )
                      }
                      else if(post.pictures && !post.text){
                          return (
                              <Post key={post.id} post={post}>
                                  <ImagesPostContent pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                              </Post>
                          )
                      }
                      else if(post.pictures.length !== 0 && post.text){
                          return (
                              <Post key={post.id} post={post}>
                                  <TextWithImagesPostContent text={post.text} pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                              </Post>
                          )
                      }
                      else if (post.text && post.pictures.length === 0 && !post.theme){
                          return (
                              <Post key={post.id} post={post}>
                                  <TextPostContent text={post.text}/>
                              </Post>
                          )
                      }else{
                          return "";
                      }
                      }else{
                          if(post.shared_from.polls.length !== 0){
                              return (
                                  <Post key={post.id} post={post.shared_from}>
                                      <PostWithPoll polls={post.shared_from.polls[0]} text={post.shared_from.text}/>
                                  </Post>
                              )
                          }
                          else if(post.shared_from.videos.length !== 0 && !post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <VideoPostContent video={post.shared_from.videos[0]}/>
                                </Post>
                            )
                        }else if(post.shared_from.videos.length !== 0 && post.shared_from.text){
                            return (
                            <Post key={post.id} post={post.shared_from}>
                                <TextPostWithVideo text={post.shared_from.text} video={post.shared_from.videos[0]}/>
                            </Post>
                            )
                        }
                          else if(post.shared_from.theme){
                              return (
                                  <Post key={post.id} post={post.shared_from}>
                                      <TextWithBgPostContent text={post.shared_from.text} theme={post.shared_from.theme}/>
                                  </Post>
                              )
                          }
                          else if(post.shared_from.pictures && !post.shared_from.text){
                              return (
                                  <Post key={post.id} post={post.shared_from}>
                                      <ImagesPostContent pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                  </Post>
                              )
                          }
                          else if(post.shared_from.pictures.length !== 0 && post.shared_from.text){
                              return (
                                  <Post key={post.id} post={post.shared_from}>
                                      <TextWithImagesPostContent text={post.shared_from.text} pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                  </Post>
                              )
                          }
                          else if (post.shared_from.text && post.shared_from.pictures.length === 0 && !post.shared_from.theme){
                              return (
                                  <Post key={post.id} post={post.shared_from}>
                                      <TextPostContent text={post.shared_from.text}/>
                                  </Post>
                              )
                          }else{
                              return "";
                          }
                      }
                  })
                  
              })}
          {/* </React.Fragment> */}
          </InfiniteScroll>): (
        <InfiniteScroll
        dataLength={trends?.pages?.length * 64 || 64}
        next={searchedTrendsDetails.fetchNextPage}
        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={searchedTrendsDetails.hasNextPage}
        loader={<div className="flex w-full justify-center mt-5">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}        scrollableTarget={parentId}
        className="scrollbar-hide w-full flex justify-center"
        > 
{trends?.pages?.map((group,i)=>{
                return group?.data?.results?.map(trend=>{
                    if (trend.text && !trend.theme && trend.pictures.length === 0) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextPostContent text={trend.text} />
            </Trend>
          );
        } else if (trend.text && trend.pictures.length !== 0) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextWithImagesPostContent
                text={trend.text}
                pictures={trend.pictures}
                setViewImages={setViewImages}
                setImages={setImages}
              />
            </Trend>
          );
        } else if (trend.text && trend.theme) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextWithBgPostContent text={trend.text} theme={trend.theme} />
            </Trend>
          );
        } else {
          return "";
        }
      })})}
    </InfiniteScroll>)
    )
}

export default SearchedPosts;