import HallInfo from "./HallInfo";
import Notification from "./Notifications";
import SearchInput from "./SearchInput";
import User from "./User";

const Banner = ({ onEditProfile, show, onEditProfilePicture }) => {
  return (
    <div className="flex flex-col w-full justify-between h-full relative">
      {show && (
        <div className="absolute h-full w-full bg-black opacity-[20%] rounded-md"></div>
      )}
      <div
        className={
          "h-full w-full z-10 flex flex-col justify-between" +
          (show ? " p-2" : "")
        }
      >
        <div className="flex items-center gap-2 ml-auto mr-2 h-8">
          {/* {show && <SearchInput />} */}
          {/* <Notification /> */}
          <User />
        </div>
        {show && (
          <HallInfo
            onEdit={onEditProfile}
            editProfilePicture={onEditProfilePicture}
          />
        )}
      </div>
    </div>
  );
};

export default Banner;
