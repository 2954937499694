import { toast } from "react-toastify";

export function openAppOrStore(code) {
    //invite code Note this code can vary
    // const code = "SxRhJflxV3SmZt0ZvpqBQ9AwGCvoyJ-nePjXtpU_0xU";

    // Replace "myapp://" with your custom URI scheme
    const customScheme = `${process.env.REACT_APP_CUSTOM_SCHEME}/invite/${code}`;

    // Replace "your.app.package" with your Android app's package name
    const androidAppPackage = process.env.REACT_APP_ANDROID_PACKAGE;
    const appleAppID = process.env.REACT_APP_APPLE_ID;

    // Replace the iOS app store link with the actual link for your app
    const appStoreLink = `https://apps.apple.com/us/app/${appleAppID}`;

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
    if (userAgent.match(/iPad|iPhone|iPod/i)) {
        // iOS device
        window.location.href = customScheme;
        setTimeout(() => {
            window.location.href = appStoreLink;
        }, 500);
    } else if (userAgent.match(/Android/i)) {
        // Android device
        window.location.href = customScheme;
        setTimeout(() => {
            window.location.href = `https://play.google.com/store/apps/details?id=${androidAppPackage}`;
        }, 500);
    } else {
        // Not an iOS or Android device, handle as desired (e.g., show an error message)
        //TODO::
        toast.info("Device is neither an iPhone nor an Android. Please open from an iPhone or Android device",{autoClose:2000,position:"top-center",hideProgressBar:true});
    }
}
