import React, { useContext, useEffect, useRef, useState } from "react"
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji-icon.svg";
import {ReactComponent as EmojiIconDark} from "../../assets/icons/emoji-icon-dark.svg";
import EmojiPicker from "../EmojiPicker";
import { themeContext } from "../../contexts/themeContext";
import { ReactComponent as ChangeBackgroundIcon } from "../../assets/icons/change-background-icon.svg";
import { postBackgroundContext } from "../../contexts/backgroundContext";
import { backgroundContext } from "../../contexts/postBackgroundContext";

const PostInput = ({post,setPost, trending, setTrend, trend,setChangeBackground,postOption}) => {
    const [textareaheight, setTextareaheight] = useState(1);
    const {theme} = useContext(themeContext);
    const [text,setText] = useState("");
    const {postBackgrounds} = useContext(postBackgroundContext);
    const { setBackground } = useContext(backgroundContext);

    const [showEmoji,setShowEmoji] = useState(false)
    const onEmojiClick = (emoji) => {
        setPost(prev=>{
            if(prev.text)
                return {...prev,text:prev.text + emoji.char};
            else
                return {...prev,text:emoji.char}
            })
        setTrend(prev=>{
            if(prev.text)
                return {...prev,text:prev.text + emoji.char};
            else
                return {...prev,text:emoji.char}
        })
        if(text){
            setText(text+emoji.char)
        }else{
            setText(emoji.char)
        }
    };

    const ref = useRef();
    const handleMouseDown = (event) => {
        if (!ref.current?.contains(event.target) && showEmoji === true) {
        setShowEmoji(false);
        } 
    };

    function autoSizeTextarea() {
        const textarea = document.getElementById('post-input-field');
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });

    const handleChange = (event) => {
        // if (event.target.value.length === 0) {
        //     setTextareaheight(1);
        //   }
        //   const height = event.target.scrollHeight;
        //   const rows = event.target.rows;
        //   const rowHeight = 20;
        //   const trows = Math.ceil(height / rowHeight) - 1;
        //   if (trows > rows) {
        //     setTextareaheight(trows);
        //   }
          handleInputChange(event);
          setText(event.target.value);
    }

    const handleInputChange = (event) => {
        setPost(prev=>{
            return {...prev,[event.target.name]:event.target.value}
        })
        setTrend(prev=>{
            return {...prev,[event.target.name]:event.target.value}
        })
    } 
    useEffect(()=>{
        setText(post.text);
    },[post.text])
    useEffect(() => {
        const textarea = document.querySelector("#post-input-field");
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = textarea.scrollHeight + "px";
        }
      }, [text]);
      
    return (
        <div className="w-full relative min-h-[3.5rem]">
        <div className="flex px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark py-2 rounded-xl min-h-[3rem] border-2 w-full h-full items-center dark:border-dark-gray" id="post-input">
            <textarea
          className={
            "w-full max-h-[100px] h-auto resize-none focus:outline-none scrollbar-hide text-base text-primaryTextLight dark:text-primaryTextDark placeholder:text-base bg-cardBackgroundLight dark:bg-cardBackgroundDark dark:border-dark-gray"
          }
          id="post-input-field"
          placeholder="What's up?"
          rows={1}
          onInput={autoSizeTextarea}
          onChange={handleChange}
          value={text}
          name="text"
          style={{ maxHeight: "100px", height: "auto" }}
        />
        {
            theme?.value == "light"?
            <EmojiIcon height="20" width="20" onClick={()=>{setShowEmoji(prev=>!prev)}} className="cursor-pointer mt-auto mb-auto" name="text"/>
            :
            <EmojiIconDark height="20" width="20" onClick={()=>{setShowEmoji(prev=>!prev)}} className="cursor-pointer mt-auto mb-auto" name="text"/>

        }
        </div>
        {
            showEmoji && 
            <div className="z-[100] absolute top-[100%] w-full bg-cardBackgroundLight dark:bg-backgroundDark rounded-md" ref={ref}>
                 <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"10rem"}/>
            </div>
        }
        </div>
    )
}

export default PostInput