import authClient from "./authAxiosInstance";

const getHallExecutives = (id, type, in_service) => {
  return authClient.get(
    `/user/user-portofolio?hall=${id}&leadership_type=${type}&in_service=${in_service}`
  );
};

const getCurrentExecutives = (hall) => {
  return authClient.get(`/user/hall/executive/?hall=${hall}`);
};

const getPastExecutives = (hall) => {
  return authClient.get(`/user/hall/executive/past/?hall=${hall}`);
}

const getPastYearExecutives = () => {
  return authClient.get("/user/hall/executive/b/");
};

const getPastTwoYearExecutives = () => {
  return authClient.get("/user/hall/executive/c/");
};

const getPastThreeYearExecutives = () => {
  return authClient.get("/user/hall/executive/d/");
};

const getCurrentManagement = (hall) => {
  return authClient.get(`/user/hall/management/?in_service=true&hall=${hall}`);
};

const getPastManagement = (hall) => {
  return authClient.get(`/user/hall/management/past/?hall=${hall}`);
};

const searchLeaders = (pageParam) => {
  return authClient.get(`${pageParam}`);
}

const getLeadershipPortfolios = ({pageParam="/user/portfolio/",queryKey}) => {
  return authClient.get(`${pageParam}?hall=${queryKey[1]}`);
}

const getLeadershipPortfoliosByType = ({pageParam="/user/portfolio",queryKey},leadership_type) => {
  return authClient.get(`${pageParam}?hall=${queryKey[1]}&leadership_type=${leadership_type}`);
}

const addLeadershipPortfolios = (values) => {
  return authClient.post(`/user/portfolio/`,values);
}

const updateLeadershipPortfolio = (values,id) => {
  return authClient.put(`/user/portfolio/${id}/`,values);
}

const linkAccount = (values,id) => {
  return authClient.patch(`/user/user-portfolio/${id}/`,values,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  })
}

const updateUserPortfolio = (values,id) => {
  return authClient.patch(`/user/user-portfolio/${id}/`,values,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  })
}

const addLeaderPortfolio = (values) => {
  return authClient.post(`/user/user-portfolio/`,values,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  })
}

const filterExecutives = (pageParam) => {
  return authClient.get(`${pageParam}`);
}

const filterManagement = (pageParam) => {
  return authClient.get(`${pageParam}`);
}

const registerLeadershipChangeRequest = (values) => {
  return authClient.post('/user/leadership-change-request/',values,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  });
}

const declineLeadershipChangeRequest = (id) => {
  return authClient.get(`/user/leadership-change-request/${id}/cancel/`);
}

const getLeadershipChangeRequest = (id) => {
  return authClient.get(`/user/leadership-change-request/${id}/`)
}

const createTenure = (values) => {
  return authClient.post(`/user/portfolio_tenure/`,values);
}

const getCurrentTenure = (hall) => {
  return authClient.get(`/user/portfolio_tenure/current/?hall=${hall}`);
}

const deleteLeaderPortfolio = (id) => {
  return authClient.delete(`/user/user-portfolio/${id}/`);
}

const unlinkAccount = (id) => {
  return authClient.get(`/user/user-portfolio/${id}/unlink-account/`)
}

const getHallTenures = (id) => {
  return authClient.get(`/user/portfolio_tenure/?hall=${id}`);
}

export {
  getHallExecutives,
  getCurrentExecutives,
  getPastThreeYearExecutives,
  getPastTwoYearExecutives,
  getPastYearExecutives,
  getCurrentManagement,
  getPastManagement,
  getLeadershipPortfolios,
  getLeadershipPortfoliosByType,
  addLeadershipPortfolios,
  updateLeadershipPortfolio,
  addLeaderPortfolio,
  linkAccount,
  updateUserPortfolio,
  searchLeaders,
  filterExecutives,
  filterManagement,
  registerLeadershipChangeRequest,
  declineLeadershipChangeRequest,
  getLeadershipChangeRequest,
  getPastExecutives,
  createTenure,
  getCurrentTenure,
  deleteLeaderPortfolio,
  unlinkAccount,
  getHallTenures
};
