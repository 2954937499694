import ForgotPasswordPage from "./pages/auth/ForgotPassword.page";
import LoginPage from "./pages/auth/Login.page";
import ResetPasswordPage from "./pages/auth/ResetPassword.page";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import UserContextProvider from "./contexts/userContext";
import { ToastContainer } from "react-toastify";
import { CookiesProvider } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
import "rc-time-picker/assets/index.css";
import "react-image-crop/dist/ReactCrop.css";
import "yet-another-react-lightbox/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import HallContext from "./contexts/hallContext";
import HallPage from "./pages/hallpage/Hall.page";
import PostsTrendingPage from "./pages/posts-trendingpage/Posts-Trending.page";
import useIsAuthenticated from "./hooks/isAuthenticated";
import MembersPage from "./pages/members/Members.page";
import LeadershipPage from "./pages/leadership/Leadership.page";
import CreatePostContext from "./contexts/createPostContext";
import CreateTrendContext from "./contexts/createTrendsContext";
import MomentsPage from "./pages/moments/Moments.page";
import AdsMonetizationPage from "./pages/ads-monetization/Ads-Monetization.page";
import LogoutContextProvider from "./contexts/logoutContext";
import PostTrendMenuContextProvider from "./contexts/postTrendMenuContext";
import PostBackgroundContextProvider from "./contexts/postBackgroundContext";
import { ReactQueryDevtools } from "react-query/devtools";
import MemberContextProvider from "./contexts/memberContext";
import MomentMenuContextProvider from "./contexts/momentMenuContext";
import LeadershipContextProvider from "./contexts/leadershipContext";
import CreateMomentProvider from "./contexts/createMomentContext";
import EmojiPicker from "./components/EmojiPicker";
import { ReactComponent as ReloadIcon } from "./assets/icons/reload-icon.svg";
import LeadershipChangeRequested from "./components/leadership/LeadershipChangeRequested";
import MomentsDetailPage from "./pages/moments/MomentDetail.page";
import ThemeContextProvider from "./contexts/themeContext";
import ConfirmOTPPage from "./pages/auth/ConfirmOTP.page";
import { LoadingPageProvider } from "./contexts/loadingContext";
import {
  PostBackgroundProvider,
  TrendBackgroundProvider,
} from "./contexts/backgroundContext";
import 'react-circular-progressbar/dist/styles.css';
import { QueryClient, QueryClientProvider } from "react-query";
import NewsPage from "./pages/news/News.page";
import NewsDetailPage from "./pages/news/NewsDetail.page";
import AddNewsPage from "./pages/news/AddNews.page";
import AddNewsContextProvider from "./contexts/addNewsContext";
import NotFoundPage from "./pages/404/NotFound.page";
import AddExecutivesPage from "./pages/leadership/AddExecutives.page";
import 'video-react/dist/video-react.css';
import ChatInvitePage from "./pages/invite/ChatInvite.page";
import TermsAndConditions from "./pages/terms-and-conditions/Terms-And-Conditions.page";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return children;
};

// application-routes
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PostBackgroundProvider>
        <TrendBackgroundProvider>
          <LoginPage />
        </TrendBackgroundProvider>
      </PostBackgroundProvider>
    ),
  },
  {
    path:"/invite/:inviteId",
    element:(
      <PostBackgroundProvider>
      <TrendBackgroundProvider>
        <ChatInvitePage/>
      </TrendBackgroundProvider>
    </PostBackgroundProvider>
    ),  
  },
  {
    path:"/terms-and-conditions",
    element:(
      <TermsAndConditions/>
    ),  
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  // {
  //   path:"confirm-otp",
  //   element:<ConfirmOTPPage/>
  // },
  {
    path: "hallpage",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <HallPage />
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "posts",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <PostsTrendingPage />
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "members",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <MemberContextProvider>
                <MembersPage />
              </MemberContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  // {
  //   path: "messages",
  //   element: (
  //     <PrivateRoute>
  //       <PostBackgroundProvider>
  //         <TrendBackgroundProvider>
  //           <LoadingPageProvider>
  //             <ChatRoomOptionContextProvider>
  //               <ChatRoomProvider>
  //                 <MessagesPage />
  //               </ChatRoomProvider>
  //             </ChatRoomOptionContextProvider>
  //           </LoadingPageProvider>
  //         </TrendBackgroundProvider>
  //       </PostBackgroundProvider>
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "leadership",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <LeadershipContextProvider>
                <LeadershipPage />
              </LeadershipContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "leadership/add-executives",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <LeadershipContextProvider>
                <AddExecutivesPage />
              </LeadershipContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "moments",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <MomentMenuContextProvider>
                <CreateMomentProvider>
                  <MomentsPage />
                </CreateMomentProvider>
              </MomentMenuContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "moments/:momentId",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            {" "}
            <LoadingPageProvider>
              <MomentMenuContextProvider>
                <CreateMomentProvider>
                  <MomentsDetailPage />
                </CreateMomentProvider>
              </MomentMenuContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "news",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
                <NewsPage />
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "news/:newsId",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <NewsDetailPage/>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path: "news/add",
    element: (
      <PrivateRoute>
        <PostBackgroundProvider>
          <TrendBackgroundProvider>
            <LoadingPageProvider>
              <AddNewsContextProvider>
                <AddNewsPage/>
              </AddNewsContextProvider>
            </LoadingPageProvider>
          </TrendBackgroundProvider>
        </PostBackgroundProvider>
      </PrivateRoute>
    ),
  },
  {
    path:"*",
    element:(
      <NotFoundPage/>
    )
  },
  // {
  //   path: "ads-monetize",
  //   element: (
  //     <PrivateRoute>
  //       <PostBackgroundProvider>
  //         <TrendBackgroundProvider>
  //           <LoadingPageProvider>
  //             <AdsMonetizationPage />
  //           </LoadingPageProvider>
  //         </TrendBackgroundProvider>
  //       </PostBackgroundProvider>
  //     </PrivateRoute>
  //   ),
  // },
  // route used for viewing components while creating
  // {
  //   path: "component",
  //   element: <AddTenure/>
  // },
]);

// query client for react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
// });

// persistQueryClient({
//   queryClient,
//   persistor: localStoragePersistor,
//   maxAge: 1000 * 60 * 60 * 24,
//   // hydrateOptions: {},
//   // dehydrateOptions: {
//   //   shouldDehydrateQuery: ({ queryKey }) => {
//   //     return !doNotPersistQueries.includes(queryKey)
//   //   },
//   // },
// })

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <CookiesProvider>
          <LogoutContextProvider>
            <UserContextProvider>
              <PostTrendMenuContextProvider>
                <HallContext>
                  <CreatePostContext>
                    <CreateTrendContext>
                      <PostBackgroundContextProvider>
                        <RouterProvider router={router} />
                        <ToastContainer />
                      </PostBackgroundContextProvider>
                    </CreateTrendContext>
                  </CreatePostContext>
                </HallContext>
              </PostTrendMenuContextProvider>
            </UserContextProvider>
          </LogoutContextProvider>
        </CookiesProvider>
      </ThemeContextProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}
