import pick from "lodash.pick";
import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { leadershipContext } from "../../contexts/leadershipContext";
import compareDate from "../../helpers/compareDate";
import {
  useAddLeader,
  useGetCurrentTenure,
  useGetLeadershipPortfolios,
} from "../../hooks/leadersHooks";
import NewLeaderAccount from "./NewLeaderAccount";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { hallContext } from "../../contexts/hallContext";

const AddExecutiveList = () => {
  const { leadershipStates, setLeadershipStates } =
    useContext(leadershipContext);
  const { hall } = useContext(hallContext);
  const {
    data: leadershipPortfolios,
    isLoading: isFetchingPortfolios,
    fetchNextPage,
    hasNextPage,
  } = useGetLeadershipPortfolios(hall?.hallInfo.id);
  const { data: currentTenure, isLoading: isFetchingCurrentTenure } = useGetCurrentTenure(hall?.hallInfo.id);

  useEffect(()=>{
    if(hasNextPage){
        fetchNextPage();
    }
  },[hasNextPage])

  return (
    <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark shadow-5xl w-full min-h-[35rem] rounded-md">
      <div className="flex justify-between pl-2 pr-4 h-20 pt-3">
        <span className="text-xl font-semibold text-primaryTextLight dark:text-primaryTextDark">
          Hall Executives
        </span>
        {/* <span className="bg-linear-grad bg-clip-text text-transparent underline cursor-pointer" onClick={onCancel}>Cancel</span> */}
        <div className="flex gap-6 mr-5">
          <span className="text-primaryTextLight dark:text-primaryTextDark font-semibold">
            Tenure
          </span>
          <div className="flex gap-2">
            <div className="flex">
              <span className="text-primaryTextLight">Starting{" "}</span>
              <span className="text-primaryTextLight">{" ("}</span>
              <ReactDatePicker
                dateFormat="yyyy"
                showMonthYearPicker
                readOnly
                selected={
                  currentTenure?.data
                    ? new Date(currentTenure?.data?.start_date)
                    : null
                }
                className=" font-light rounded-sm focus:outline-none w-[2.5rem] text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark placeholder:font-thin"
                name="start_date"                
              />
              <span className="text-primaryTextLight">{")"}</span>
            </div>
            {/* <span className="text-lg">-</span> */}
            <div className="flex">
              <span className="text-primaryTextLight">Ending{" "}</span>
              <span className="text-primaryTextLight">{" ("}</span>{" "}
              <ReactDatePicker
                dateFormat="yyyy"
                showMonthYearPicker
                placeholderText="Ending (MM/yyyy)"
                readOnly
                selected={
                  currentTenure?.data
                    ? new Date(currentTenure?.data?.end_date)
                    : null
                }
                className=" font-light rounded-sm focus:outline-none w-[2.5rem] text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark placeholder:font-thin"
                name="end_date"                
              />
              <span className="text-primaryTextLight">{")"}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex pl-2 pr-4 justify-end mb-2">
        {/* <button className="bg-filter-grad px-2 rounded-sm" onClick={()=>setLeadershipStates(prev=>{return {...prev,addExecutive:true}})}><span className="text-white text-xl">+</span> <span className="text-primaryTextLight text-sm">Add Executive</span></button> */}

        {/* <button className="bg-linear-grad text-white px-6 rounded-sm" onClick={onAddExecutivesList}>{!isLoading ? "Save" : <BeatLoader color="white" size={10}/>}</button> */}
      </div>
      <div className="pl-2 pr-4 flex flex-col gap-2 pb-2">
        {isFetchingPortfolios ? (
          <div className="flex items-center justify-center w-full">
            <ClipLoader color="#F63E49" size={50} />
          </div>
        ) : (
          <>
            {leadershipPortfolios.pages.map((group, index) => {
              return group.data?.results.map((portfolio) => {
                if (
                  portfolio.leadership_type === "Executive" &&
                  portfolio.is_active
                ) {
                  return (
                    <NewLeaderAccount
                      key={portfolio.id}
                      portfolio={portfolio}
                      selectedTenure={currentTenure?.data}
                    />
                  );
                } else {
                  return null;
                }
              });
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default AddExecutiveList;
