import React, { useEffect, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import PageContainer from "../../components/PageContainer";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { otpSchema } from "../../schema/authSchema";
import { useNavigate } from "react-router-dom";
import { useConfirmPasswordResetOTP, useForgotPassword } from "../../hooks/authHooks";
import pick from "lodash.pick";
import { toast } from "react-toastify";


const ConfirmOTPPage = ({email,username}) => {
    const nav = useNavigate();
    const {mutate:confirmPasswordOTP,isLoading} = useConfirmPasswordResetOTP();
    const onSubmit = (values) => {
        if(email !== ""){
          values.email = email;
        }else{
          values.username = username
        }
        confirmPasswordOTP(values,{
            onSuccess:(data)=>{
              nav("/reset-password",{state:{email:email,username:username}})
            },
            onError:(error)=>{
              if(error.response.data.errorCode === 111)
                toast.error('Invalid OTP',{position:"top-center",hideProgressBar:true,autoClose:2000})
              else if(error.response.data.errorCode === 112){
                toast.error('OTP has expired',{position:"top-center",hideProgressBar:true,autoClose:2000})
              }else{
                toast.error('Failed to send otp',{position:"top-center",hideProgressBar:true,autoClose:2000})
              }
            }
        })
    }

    useEffect(()=>{
      document.title = "LinkLounge | Forgot Password"
    },[])

    return (
    <PageContainer customstyle="flex flex-col h-full">
      <main className="flex h-19/20 justify-between">
      <Formik
          initialValues={{otp:''}}
          validationSchema={otpSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange,handleSubmit,errors,touched,handleBlur }) => (
                <section className="flex flex-col w-full items-center justify-center sm:w-3/5">
                <form className="w-80 p-4 flex flex-col gap-5" onSubmit={handleSubmit}>
                    <h2 className="mb-2 font-bold text-2xl bg-linear-grad bg-clip-text text-transparent">Confirm OTP</h2>
                    <p className="text-primaryTextLight dark:text-primaryTextDark mb-5 text-sm">Enter the otp sent to your email</p>
                    <div className="mb-3">
                        <Input
                        label="OTP"
                        placeholder="******"
                        name="otp"
                        type="text"
                        customstyle="flex flex-col w-full gap-2 text-primaryTextLight dark:text-primaryTextDark text-xs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {
                        errors.otp && touched.otp ?
                        <ErrorMessage name="otp" component="span" className="text-xs text-[#F63E49] font-light leading-3" /> : null
                        }
                    </div>
                    <Button text="CONFIRM" type="submit" customstyle="w-full h-8 drop-shadow-md mb-5" loadingText="" isLoading={isLoading}/>
                    <p className="text-sm text-primaryTextLight dark:text-primaryTextDark ml-auto mr-auto">Remember your password? <span className="bg-linear-grad bg-clip-text text-transparent hover:cursor-pointer" onClick={()=>nav('/')}>Sign In</span></p>
                </form>
                </section>
          )}
        </Formik>
        <section className="w-2/5 h-full hidden sm:block">
          <BackgroundImage customstyle="h-full w-full" />
        </section>
      </main>
      <Footer/>
    </PageContainer>
  );
};

export default ConfirmOTPPage;
