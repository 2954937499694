import { useContext } from "react";
import { createContext, useState } from "react"
import { userContext } from "./userContext";

export const createPostContext = createContext({});
const CreatePostContext = ({children}) => {
    const {user_state} = useContext(userContext);
    const [post,setPost] = useState({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[],videos:[],previewVideos:[]});

    return (
        <createPostContext.Provider value={{post,setPost}}>
            {children}
        </createPostContext.Provider>
    )
}

export default CreatePostContext;