import { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";

const MemberListFooter = ({page,pageSize,setPage,setPageSize,count}) => {
    const [numbers,setNumbers] = useState([]);
    const [sliceStart,setSliceStart] = useState(0);

    const incrementPage = () => {
        if(page < Math.ceil(count/pageSize)){
            setPage(prevPage=>prevPage+1)
        }
    }

    const decrementPage = () => {
        if(page > 1){
            setPage(prevPage=>prevPage - 1)
        }
    }

    // const onDoubleIncrement = () => {
    //     if(sliceStart+10 < Math.ceil(count/pageSize)){
    //         setSliceStart(prev=>prev+5)
    //     }else{
    //         setSliceStart(prev=> Math.ceil(count/pageSize) - 5 - prev);
    //     }
    // }

    // const onDoubleDecrement = () => {

    // }

    const onSelectPage = (page) => {
        setPage(page+1)
    }

    const PaginatedNumbers = () => {
        return (
            <>
            {
                numbers?.slice(0,5).map(number=>{
                    if((number + 1) === page){
                        return (
                            <span className="underline text-[#F63E49] cursor-pointer" key={number + 1} onClick={()=>onSelectPage(number+1)}>{number + 1}</span>
                        )
                    }
                    return (
                        <span className="underline cursor-pointer" key={number + 1} onClick={()=>onSelectPage(number+1)}>{number + 1}</span>
                    )
                })
            }
            </>
        )
    }

    return (
        <div className="w-full mt-4 flex justify-between pr-6 text-[rgba(54, 76, 102, 0.84)] dark:text-primaryTextDark" style={{color:"rgba(54, 76, 102, 0.84)"}}>
            <div className="w-[20%] flex gap-2">
                <span className="text-sm font-normal text-primaryTextLight dark:text-primaryTextDark">
                    Showing
                </span>
                <select className="w-12 outline-none border border-[rgba(54, 76, 102, 0.84)] dark:bg-cardBackgroundDark dark:text-primaryTextDark rounded-md text-sm" value={pageSize} onChange={(event)=>{setPageSize(event.target.value);setPage(1)}}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
                <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">rows</span>
            </div>
            <div className="flex justify-end px-6">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={(event)=>onSelectPage(event.selected)}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(count/pageSize)}
                    previousLabel="<"
                    // renderOnZeroPageCount={null}
                    className="flex text-sm gap-2 text-primaryTextLight dark:text-primaryTextDark"
                    activeClassName="text-[#F63E49] dark:text-[#F63E49]"
                    pageClassName="text-primaryTextLight dark:text-primaryTextDark underline"
                    nextClassName="text-primaryTextLight dark:text-primaryTextDark"
                    previousClassName="text-primaryTextLight dark:text-primaryTextDark"
                    forcePage={page - 1}
                  />                  
                </div>
            {/* <div className="flex gap-2 text-sm">
                <div className="flex gap-2">
                    <span className="cursor-pointer">{"<<"}</span>
                    <span className="cursor-pointer" onClick={decrementPage}>{"<"}</span>
                </div>
                <div className="flex gap-2">
                        <PaginatedNumbers/>
                </div>
                <div className="flex gap-2">
                    <span className="cursor-pointer" onClick={incrementPage}>{">"}</span>
                    <span className="cursor-pointer">{">>"}</span>
                </div>
            </div> */}
        </div>
    )
}

export default MemberListFooter;