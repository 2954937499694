import { useState } from "react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import { useEditHallInfo, useRequestChange } from "../../hooks/hallHooks";
import  {BeatLoader} from 'react-spinners'
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
import { hallActions } from "../../reducers/actions";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import * as Diff from "diff";

const EditAboutModal = ({onClose}) => {
    const {hall} = useContext(hallContext);
    const [aboutHall,setAboutHall] = useState(hall?.hallInfo?.about);
    const queryClient = useQueryClient()
    const {mutate:editAbout,isLoading} = useRequestChange();
    const onChange = (event) => {
        setAboutHall(prev=>event.target.value);
    }
    const onUpdate = () => {
        editAbout({hall:hall?.hallInfo.id,change_type:"About",change_tables:[{new_value:aboutHall,old_value:hall?.hallInfo?.about,item_to_change:"About"}],},{
            onSuccess: async (data)=>{
                queryClient.invalidateQueries("check-about-request");
                toast.success("Change requested",{position:"top-center",hideProgressBar:true,autoClose:1000});
                onClose();
            },onError:(error)=>{
                if(error.response.data.change_tables[0].new_value)
                    toast.error("About not updated. Failed to request change",{position:"top-center",hideProgressBar:true,autoClose:1000});
                else{
                    toast.error("Failed to request change",{position:"top-center",hideProgressBar:true,autoClose:1000})
                }
            }
        });
    }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:100}}>
        <section className="w-[40%] h-[450px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark relative">
            <CloseIcon width="25" height="25" onClick={onClose} className="cursor-pointer absolute top-[-5px] right-[-5px]"/>
            <div className="flex justify-between items-center px-4 py-3">
                <span className="font-semibold text-lg">Edit About Hall</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full px-4 py-4 h-[75%]">
                <textarea className="w-full h-full resize-none p-2 focus:outline-none rounded-md text-md bg-cardBackgroundLight dark:bg-cardBackgroundDark" defaultValue={hall?.hallInfo?.about} onChange={onChange}/>
            </div>
            <div className="w-full text-center">
                <button className="bg-linear-grad text-white w-[50%] py-2 rounded-sm disabled:opacity-[30%]" onClick={onUpdate}>{!isLoading ? "Update Request" : <> <BeatLoader color="#fff" size={10}/></>}</button>
            </div>
        </section>
        </div>
    )
}

export default EditAboutModal;