import { useContext } from "react";
import { createContext, useState } from "react";
import { hallContext } from "./hallContext";
import { userContext } from "./userContext";

export const createTrendContext = createContext();

const CreateTrendContext = ({children}) => {
    const {user_state} = useContext(userContext)
    const {hall} = useContext(hallContext);
    const [trend,setTrend] = useState({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false,videos:[],previewVideos:[]});
    return (
        <createTrendContext.Provider value={{trend,setTrend}}>
            {children}
        </createTrendContext.Provider>
    )
}

export default CreateTrendContext;