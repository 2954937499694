import authClient from "./authAxiosInstance";

const getHallMembers = (hall_id, offset=0, limit=10) => {
  return authClient.get(
    `/user/user_accounts/?user__hall=${hall_id}&limit=${limit}&offset=${offset}`
  );
};

const getHallAccounts = ({pageParam="/user/user_accounts/",queryKey}) => {
  return authClient.get(`${pageParam}?user__hall=${queryKey[1]}`);
}

const getNewHallMembers = () => {
  return authClient.get("/user/user_accounts/new-users/");
};

const getBlockedHallMembers = (id,page,pageSize) => {
  return authClient.get(`/user/v1/hall-blacklist/?id=${id}&page=${page}&page_size=${pageSize}`);
};

const blockHallMember = (values) => {
  return authClient.post("/user/v1/hall-blacklist/", values);
};

const reportHallMember = (values) => {
  return authClient.post(`/user/abuse-report/`, values);
};

const getReportMemberReasons = () => {
    return authClient.get(`/user/abuse-report-reason/`);
}

const unblockHallMember = async (id) => {
  const response = await authClient.get(
    `/user/user_accounts/${id}/hall-unblock/`
  );
  if (response.status === 200) {
    return response?.data;
  }
  return { error: "Could not unblock user" };
};

const getMemberBlacklistReasons = () => {
  return authClient.get(`/user/v1/hall-blacklist-reason/`);
};

const searchMembers = async (search,hall_id,page,pageSize) => {
  const response = await authClient.get(
    `/user/user_accounts/?user__hall=${hall_id}&page=${page}&page_size=${pageSize}&search=${search}`
  );
  return response.data;
}

const searchNewMembers = async (search,hall_id,page,pageSize) => {
  const response = await authClient.get(
    `/user/user_accounts/new-users/?user__hall=${hall_id}&page=${page}&pageSize=${pageSize}&search=${search}`
  )
  return response.data;
};

const searchAccounts = ({pageParam='/user/user_accounts/',queryKey},search) => {
  return authClient.get(`${pageParam}?user__hall=${queryKey[1]}&search=${search}`);
}

export {
  getHallMembers,
  getHallAccounts,
  getNewHallMembers,
  getBlockedHallMembers,
  blockHallMember,
  unblockHallMember,
  reportHallMember,
  getMemberBlacklistReasons,
  getReportMemberReasons,
  searchMembers,
  searchNewMembers,
  searchAccounts
};
