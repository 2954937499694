import moment from "moment";
import { useContext, useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { cancelVote, votePoll } from "../../api/posts";
import { generateRandomId } from "../../helpers/generateRandomId";
import formatTime from "../../helpers/timeFormatter";
import { useVotePoll } from "../../hooks/postHooks";
import { themeContext } from "../../contexts/themeContext";

const PostWithPoll = ({ polls,text }) => {
  const {theme} = useContext(themeContext);
  const currentDate = new Date()
  const [poll,setPoll] = useState({voted:polls.voted,voteCount:polls.total_votes});
  const [pollOptions,setPollOptions] = useState(polls.options);
  const [isPollEnded,setIsPollEnded] = useState(currentDate.toISOString() >= new Date((polls.end_date) + "T" + (polls.end_time)).toISOString());
  const [votedOption,setVotedOption] = useState(polls.options.find(option=> option.voted === true));
  const classId = generateRandomId();
  const [cancelling,setCancelling] = useState(false);
  const [voting,setVoting] = useState(null);

  const onVote = async (option) => {
    if(!isPollEnded && !option.voted){
     setVoting(option.id) 
     const results = await votePoll(option.id);
     if(results.error){
      toast.error("Failed to vote",{autoClose:2000,hideProgressBar:true,position:"top-center"});
     }else{
      setPollOptions(results);
      setVotedOption(results.find(option => option.voted === true));
      setPoll(prevPoll=>{
          return {...prevPoll,voted:true,voteCount:results.reduce((prev,curr)=> prev + curr.votes_count,0)}
        })
     }
     setVoting(null)
    }
  };

  const onCancelVote = async ( ) => {
    setCancelling(true);
    const response = await cancelVote(votedOption.id);
    setPoll({voted:response.voted,voteCount:response.total_votes});
    setPollOptions(response.options);
    setVotedOption(undefined)
    setCancelling(false);
  };
  return (
    <div className="w-full flex flex-col gap-6 mt-5">
      <p className="w-[90%] text-xl text-primaryTextLight dark:text-primaryTextDark">
        {text}
      </p>
      <div className="w-full flex flex-col gap-4">
      {
        pollOptions?.map((option)=>{
        return (
            <div className="w-full flex gap-6 items-center" key={option.id}>
          <div
            className={"w-[80%] h-12 rounded-lg text-xl flex items-center relative cursor-default"}
            id={"option"+(option.id)}
            style={{border: theme?.value=="light" ? "2px solid rgba(54, 76, 102, 0.56)":"2px solid #6c6c6c"}}
          >
            <div
              className={"absolute top-0 h-full rounded-tl-md rounded-bl-md " + ("option" + classId) + (option.votes_count / poll.voteCount * 100 === 100 ? " rounded-tr-md rounded-br-md" : "")}
              style={{width: `${option.votes_count / poll.voteCount * 100}%`,backgroundColor:theme?.value == "light" ? "rgba(54, 76, 102, 0.21)":"#565656"}}
            ></div>
            <div className="w-full flex px-4 z-10 opacity-[81%] text-primaryTextLight dark:text-primaryTextDark items-center">
              <div>{option.option_text}</div>
              {
                ((voting !== null && voting === option.id)) && 
                <ClipLoader size={15} color="#F63E49" className="ml-auto"/>
              }
              {poll.voted && (
                <div
                  className="ml-auto text-sm mt-auto mb-auto"
                  style={{ color:theme?.value === "light"? "rgba(54, 76, 102, 0.56)":"#e4e4e4"}}
                >
                  {option.votes_count}{"vote(s)"}
                </div>
              )}
            </div>
          </div>
          {poll.voted || isPollEnded ? option.voted ? (
            <div
              className="text-xl font-medium text-[rgba(246, 64, 73, 0.65)] dark:text-primaryTextDark"
            >
              {Math.round((option.votes_count / poll.voteCount) * 100)}%
            </div>
          ) : <div
          className="text-xl font-medium text-[rgba(54, 76, 102, 0.21)] dark:text-primaryTextDark"
        >
          {Math.round((option.votes_count / poll.voteCount) * 100) || 0}%
        </div>: null}
        </div>
        )
        })
        }
      </div>
      <div className="mb-4 flex items-center w-full justify-between">
        <span>
          {!isPollEnded ? "Poll ends on ": "Poll ended on "}
          <span className="font-semibold">{moment(polls.end_date).format("Do MMM YYYY")}, {formatTime(polls.end_time)}</span>
        </span>
        {/* {poll.voted && !isPollEnded && (
          <>
          {
            !cancelling ? 
          <span
            className="text-sm text-[#F63E49] mr-5 cursor-pointer"
            onClick={() => onCancelVote()}
          >
            Unvote
          </span> :
          <ClipLoader size={15} color="#F63E49" className="mr-10"/>
          }
          </>
        )} */}
      </div>
    </div>
  );
};

export default PostWithPoll;
