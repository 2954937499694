import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';

const CircularProgressTracker = ({percentage,width,height}) => {
    return (
        <div style={{width:width,height:height}}>
            <CircularProgressbar value={percentage} text={`${percentage}%`} styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: 'butt',
                        textSize: '1.5rem',
                        pathTransitionDuration: 0.5,
                        pathColor: `#F63E49`,
                        textColor: '#f88',
                        trailColor: '#d6d6d6',
                        // backgroundColor: '#3e98c7',
                    })}/>
        </div>
    )
}

export default CircularProgressTracker