import { useContext, useState } from "react";
import RowMember from "./RowMember";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";

const Memberslist = ({setShowMember,setMember,members,setBlockMember,setBlock}) => {
    if(members?.length === 0){
        return (
            <div className="w-full flex items-center justify-center mt-10">
                <NoDataIcon height="200" width="200"/>
            </div>
            )    
    }
    return (
        <>
        {members?.map(member=>{
            return (

                <RowMember member={member} key={member.id} setShowMember={setShowMember} setMember={setMember} setBlockMember={setBlockMember} setBlock={setBlockMember}/>
            )
        })}

        </>
    )
}

export default Memberslist;