import { createContext, useState } from "react";

export const addNewsContext = createContext();

const AddNewsContextProvider = ({children}) => {
    const [news,setNews] = useState({title:"",body:"",source:"",type:"",hall:"",is_hall_buzz:"",pictures:[],previewPictures:[],created_by:"",date_created:"",preview:false})
    return (
        <addNewsContext.Provider value={{news,setNews}}>
            {children}
        </addNewsContext.Provider>
    )
}

export default AddNewsContextProvider;