import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
import EditButton from "./EditButton";
import Messages from "./Messages";
import Profile from "./Profile";
import millify from "millify";


const HallInfo = ({onEdit,editProfilePicture}) => {
    const {hall}=useContext(hallContext);
    return (
        <div className="h-20 w-full rounded-md flex dark:bg-cardBackgroundDark bg-cardBackgroundLight opacity-[80%] text-primaryTextLight dark:text-primaryTextDark relative">
            <div className="w-36 h-36 flex justify-center top-[-70px] relative rounded-full ml-8 bg-transparent items-center opacity-100">
                <Profile editProfilePicture={editProfilePicture}/>
            </div>
            <div className="flex flex-col justify-center ml-5">
                <div className="flex gap-5 h-1/2 items-end">
                <span className="text-xl font-medium">{hall?.hallInfo?.name}</span>
                <span className="text-sm italic mb-1">{hall?.hallInfo?.nickname ? `(${hall?.hallInfo?.nickname})`:"(Not Set)"}</span>
                </div>
                <div className="flex gap-5 h-1/2 items-start">
                    <span className="text-sm">{hall?.hallInfo?.collective_name || "Hallers"}: {hall?.hallInfo?.hallers_count ? millify(hall?.hallInfo?.hallers_count) : 0}</span>
                    <span className="text-sm">Followers: {hall?.hallInfo?.followers_count ? millify(hall?.hallInfo?.followers_count) : 0}</span>
                </div>
            </div>
            <div className="flex ml-auto mr-4 mt-auto mb-auto gap-4">
                    {/* <Messages /> */}
                    <EditButton onClick={onEdit}/>
                </div>
        </div>
    )
}

export default HallInfo;