import { useContext } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import { leadershipContext } from "../../contexts/leadershipContext";
import { useGetLeadershipPortfolios } from "../../hooks/leadersHooks";
import Portofolio from "./Portofolio";
import InfiniteScroll from 'react-infinite-scroll-component'
import { ClipLoader } from "react-spinners";


const LeadershipPortofolios = () => {
    const {setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    const {data:leadershipPortfolios,isLoading,fetchNextPage,hasNextPage} = useGetLeadershipPortfolios(hall?.hallInfo.id);
    return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
      style={{ zIndex: 200 }}
    >
      <div className="w-[40%] max-h-[80%] min-h-[20%] bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md relative">
        <div>
          <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>{setLeadershipStates(prev=>{return {...prev,showPortofolios:false}})}}/>
        </div>
        <div className="h-12 flex items-end py-2 px-6">
          <span className="w-[40%] text-xl font-semibold text-primaryTextLight dark:text-primaryTextDark">Leadership</span>
          <span className="w-[30%] text-xl text-primaryTextLight dark:text-primaryTextDark opacity-[50%]">Portofolios</span>
          <span className="w-[15%] text-xl text-primaryTextLight dark:text-primaryTextDark opacity-[50%]"></span>
          <span className="bg-linear-grad bg-clip-text ml-auto text-transparent underline cursor-pointer" onClick={()=>{setLeadershipStates(prev=>{return {...prev,addPortofolio:true}})}}>Add New</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="h-[85%] overflow-y-auto" id="leader-portfolios">
          {
            isLoading ? <div className="flex w-full h-36 justify-center mt-10">
            <ClipLoader size={40} color="#F63E49"/>
        </div> : 
        <InfiniteScroll
        dataLength={leadershipPortfolios?.pages?.length * 64 || 64}
        next={fetchNextPage}
        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextPage}
        loader={<div className="flex w-full justify-center mt-5">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}        scrollableTarget={"leader-portfolios"}
        className="scrollbar-hide"
        >
          {leadershipPortfolios?.pages?.map((group,i)=>{
                return group?.data?.results?.map(portfolio=>{
                  return (
                    <Portofolio portfolio={portfolio} key={portfolio.id}/>
                  )
                })
          })}
        </InfiniteScroll>
          }
        
        </div>
      </div>
    </div>
  );
};

export default LeadershipPortofolios;
