const TrendingCardWithImage = ({tags,text,bgImage,likes,comments}) => {
    return (
      <div className=" relative w-52 h-52 rounded-md mr-4 flex flex-col shrink-0" style={{backgroundImage:`url(${bgImage})`,backgroundSize:"cover"}}>
        <div className="absolute bg-black opacity-[50%] top-0 w-full h-full text-white rounded-md">
        </div>
        <div className="z-10 text-white flex flex-col h-full w-full px-4 pb-2 pt-4">
        <p className="mt-auto text-center text-xl flex line-clamp-5">
          {text}
        </p>
        <div className="flex flex-col gap-2 mt-auto">
          <div className="text-sm flex gap-2 text-[#0085FF]">
            {tags.length === 0 ? <span>General</span> :tags.map(tag=>{
              return (
                <span key={tag.id}>{tag.short_hand}</span>
              )
            })}
          </div>
          <div className="text-sm flex justify-between">
              <span>Likes: <span>{likes}</span></span>
              <span>Comments: <span>{comments}</span></span>
          </div>
        </div>
        </div>
       
      </div>
    );
  };
  
  export default TrendingCardWithImage;
  