import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useAddLeader, useGetExecutiveLeadershipPortfolios } from "../../hooks/leadersHooks";
import InfiniteScroll from "react-infinite-scroll-component";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import { toast } from "react-toastify";
import { generateRandomId } from "../../helpers/generateRandomId";
import validatePhoneNumber from "../../helpers/validatePhoneNumber";
import { themeContext } from "../../contexts/themeContext";
import { useQueryClient } from "react-query";

const NewExecutive = () => {
  const {theme} = useContext(themeContext);
  const { setLeadershipStates,leadershipStates } = useContext(leadershipContext);
  const [preview, setPreview] = useState("");
  const [profile, setProfile] = useState("");
  const {hall} = useContext(hallContext);
  const [leader,setLeader] = useState({name:"",user:"",portofolio:leadershipStates?.selectedPortfolio?.id,hall:hall?.hallInfo.id,contact:"",tenure:leadershipStates.selectedTenure.id,picture:""})
  const {mutate:createExecutive,isLoading:isCreatingExecutive} = useAddLeader()
  const queryClient = useQueryClient();
  const onSelectFile = () => {
    document.getElementById("leader-image-input").click();
  };
  const handleChange = (event) => {
      setLeader(prev => {return {...prev,[event.target.name]:event.target.value}})
    }
  
    const onAddExecutives = () => {
      if(leader.name === "" || leader.contact === "" || leader.portofolio === ""){
        toast.error("Input fields cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(!validatePhoneNumber(leader.contact)){
        toast.error("Invalid contact",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }
      else{
        const formdata = new FormData();
        for (let data in leader) {
          formdata.append(data,leader[data]);
        }
      createExecutive(formdata,{
        onSuccess:(data)=>{
          toast.success("Leader created successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          queryClient.invalidateQueries(["leadership-portfolios",hall?.hallInfo.id]);
          queryClient.invalidateQueries("current-hall-executives");
          setLeadershipStates((prev) => {
            return { ...prev, addExecutive: false };
          })
        },onError:(error)=>{
          if(error.response.data.contact[0] == "user portofolio with this contact already exists."){
            toast.error("Leader with this contact already exists",{autoClose:2000,hideProgressBar:true,position:"top-center",style:{width:"25rem"}});
          }else{
            toast.error("Failed to create new executive",{autoClose:2000,position:"top-center",hideProgressBar:true});
          }
        }
      })
      }
    }
  useEffect(() => {
    if (!profile) {
      setPreview(null);
      return;
    }

    const objectUrl = URL.createObjectURL(profile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [profile]);
  return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
      style={{ zIndex: 200 }}
    >
      <div className="w-[30%] h-[32rem] bg-backgroundLight dark:bg-cardBackgroundDark rounded-md relative">
          <CloseIcon
            width="25"
            height="25"
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
            onClick={() =>
              setLeadershipStates((prev) => {
                return { ...prev, addExecutive: false };
              })
            }
          />
        <div className="h-10 flex items-center justify-between px-4">
          <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">
            New Leader
          </span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="w-[80%] h-40 flex justify-center mt-5">
          <div className="flex w-[14.5rem] justify-end">
            <div className="w-32 h-32 relative">
              <img
                src={preview || defaultProfile}
                alt="Leader Profile"
                width="100%"
                height="100%"
                className="w-36 h-full object-cover rounded-md"
              />
              <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark absolute bottom-[0px] right-[0px]">
                <CameraIcon
                  height="20"
                  width="20"
                  className="cursor-pointer"
                  onClick={onSelectFile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center flex-col gap-5 h-[50%]">
          <div className="flex justify-between w-[80%]">
            <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">
              Name <span className="text-[#F63E49]">*</span>
            </label>
            <input
              className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"
              placeholder="Person Name"
              name="name"
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-between w-[80%]">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">
              Position <span className="text-[#F63E49]">*</span>
            </label>
            <div className="flex items-center gap-2 rounded-md h-8 border dark:border-dark-gray bg-backgroundLight dark:backgroundDark relative w-[13rem]">
              <input
                placeholder="Select Position"
                id="title"
                className="placeholder:font-thin focus:outline-none text-primaryTextLight dark:text-primaryTextDark pl-2 w-[11rem] bg-backgroundLight dark:bg-backgroundDark"
                // onClick={onDropDown}
                readOnly
                value={leadershipStates.selectedPortfolio.title}
                // onChange={(event)=>setSelectedOption(event.target.value)}
              />
              {/* <div className="flex items-center gap-2">
                {
                  theme?.value == "light" ?
                  <SelectIcon
                  width="12"
                  height="12"
                  onClick={onDropDown}
                  className={
                    "cursor-pointer " +
                    (rotate ? "rotate-180 delay-200" : "delay-200")
                  }
                />:
                <SelectIconDark
                  width="12"
                  height="12"
                  onClick={onDropDown}
                  className={
                    "cursor-pointer " +
                    (rotate ? "rotate-180 delay-200" : "delay-200")
                  }
                />
                
                }
              </div>
              {showOptions && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 z-10 max-h-36 overflow-auto" id="executive-portfolio">
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"executive-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              return (
                            <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={portfolio.id}
                            onClick={() => onSelect(portfolio.title,portfolio)}>
                            <span>{portfolio.title}</span>
                            </div>                                  )
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )} */}
            </div>
          </div>
          <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact <span className="text-[#F63E49]">*</span></label>
              <input className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" placeholder="Person Contact" onChange={handleChange} name="contact"/>
            </div>
          <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={onAddExecutives}>
            {isCreatingExecutive?<BeatLoader color="white" size={10}/> :"Done"}
          </button>
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        className="hidden"
        id="leader-image-input"
        onChange={(event) => {
          setProfile(event.target.files[0]);
          setLeader(prev=>{
            return {...prev,picture:event.target.files[0]}
          })
        }}
      />
    </div>
  );
};

export default NewExecutive;
