import React from 'react';

const BackgroundImage = ({customstyle}) => {
    return (
        <React.Fragment>
            <img src={"/landing-image-crop.jpeg"} className={customstyle + " object-cover clip-image"} alt=""/>
        </React.Fragment>
    )
}

export default BackgroundImage;