const TermsAndConditions = () => {
  return (
    <div className="p-4 w-full md:w-[80%] lg:w-[60%] mx-auto">
      <h1 className="text-LinkLoungeRed font-bold text-xl text-center my-10">
        LinkLounge Terms and Conditions
      </h1>

      <p>
        <span className="font-bold">Date created:</span> 21st September 2023
      </p>

      <ol className="list-decimal px-4 my-5 list-outside">
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Acceptance of Terms</span>
          <span>By using LinkLounge, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the App.</span>

            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">User Eligibility</span>
          <span>You must be at least 14 years old to use the App. If you are under 14, you may only use the App with the consent of a parent or legal guardian.</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
            <span className="font-bold">Registration and User Accounts</span>
            <div>
                <ul className="list-disc list-inside">
                    <li className="mb-2">You must provide accurate and complete information during the registration process.</li>
                    <li className="mb-2">You are responsible for maintaining the confidentiality of your account credentials.</li>
                    <li className="mb-2">You are solely responsible for all activities that occur under your account.</li>
                    <li className="mb-2">You must notify us immediately of any unauthorized use of your account.</li>
                </ul>
            </div>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
            <span className="font-bold">Content and Conduct</span>
            <div>
                <ul className="list-disc list-inside">
                    <li className="mb-2">You are solely responsible for the content you post on the App.</li>
                    <li className="mb-2">You agree not to post or transmit any content that is illegal, offensive, or violates the rights of others.</li>
                    <li className="mb-2">We have the right to remove or restrict access to any content that violates these terms.</li>
                    <li className="mb-2">You agree not to engage in any activity that disrupts the App's functionality or harms other users.</li>
                </ul>
            </div>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Privacy and Data Collection</span>
          <span>We collect and use your personal information as outlined in our Privacy Policy, which is incorporated by reference into these terms.
By using the App, you consent to the collection and use of your information as described in our Privacy Policy. Visit <a href="https://www.LinkLounge.dev/privacy-policy" className="text-blue-500 underline">https://www.LinkLounge.dev/privacy-policy</a>  to read more.
</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Termination</span>
          <span>We reserve the right to suspend or terminate your account at our discretion, with or without notice, for any violation of these terms.
</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Limitation of Liability</span>
          <span>We are not liable for any damages, including but not limited to, direct, indirect, incidental, or consequential damages, arising from your use of the App.
</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Governing Law and Jurisdiction</span>
          <span>These terms are governed by the laws of Ghana, and any disputes shall be resolved in the courts of Ghana.
</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Changes to Terms</span>
          <span>We may update these terms from time to time, and any changes will be posted on the App. Your continued use of the App after such changes constitutes acceptance of the updated terms.
</span>
            </div>
        </li>
        <li className="mb-5">
            <div className="flex flex-col">
          <span className="font-bold">Contact Us</span>
          <span>If you have any questions or concerns about these terms, please contact us at <a href="support@teratecksolutions.com" className="text-blue-500 underline">support@teratecksolutions.com</a> or <a href="https://bit.ly/terateck-support" className="text-blue-500 underline">https://bit.ly/terateck-support</a>.</span>
            </div>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
