import { useContext, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { createPostContext } from "../../contexts/createPostContext";
import { Player,BigPlayButton } from "video-react";
const PreviewEditVideos = ({ videos,setShowUpload, onClose }) => {
  const {setPost,post} = useContext(createPostContext);
  const onRemovePicture = (id,name) => {
    if(post.previewImages.length === 1){
      setPost(prev=>{ return {...prev,preview:false,previewVideos:prev.previewVideos.filter(video=>video.id !== id),videos:prev.videos.filter(picture=>picture.name !== name)}});
      setShowUpload(true)
    }else{
      setPost(prev=>{ return {...prev,previewImages:prev.previewImages.filter(picture=>picture.id !== id),acceptedFiles:prev.acceptedFiles.filter(picture=>picture.name !== name)}});
    }
  }

  useEffect(()=>{
    console.log(videos)
  },[])
  return (
    <div className="mt-4 mb-4 w-full max-h-80 relative">
      <div className="w-full mb-4 h-80 gap-2">
      <Player
      playsInline
      poster={videos[0]?.thumbnail}
      src={videos[0]?.video}
      fluid={false}
  
        width={"100%"}
        height={"100%"}
        >
            <BigPlayButton position="center"/>
        </Player>
      </div>
    </div>
  );
};

export default PreviewEditVideos;
