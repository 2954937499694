import { useContext, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as EmojiIcon } from "../../assets/icons/emoji-icon.svg";
import { backgroundContext } from "../../contexts/postBackgroundContext";
import Backgrounds from "./Backgrounds";
import { createPostContext } from "../../contexts/createPostContext";
import EmojiPicker from "../EmojiPicker";
import { toast } from "react-toastify";

const PostBackground = ({ onClose,post,setPost,trending,setTrend}) => {
  const [showEmoji, setShowEmoji] = useState(false);
  const { background,setBackground } = useContext(backgroundContext);
  const [text,setText] = useState("");
  let bgColor;
  if(background){
    const colors = [];
    for(let color of background.bg_colors){
      colors.push(color.color)
    }
    bgColor = colors.length === 1 ? colors[0] : `linear-gradient(45deg, ${colors.join(",")})`
}

const onEmojiClick = (emoji) => {
  setPost((prev) => {
    if (prev.text) return { ...prev, text: prev.text + emoji.char };
    else return { ...prev, text: emoji.char };
  });
  setText(prev=>{
    if(prev){
      return prev + emoji.char
    }else{
      return emoji.char
    }
  })
};

function autoSizeTextarea() {
  const textarea = document.getElementById('text-input-background');
  textarea.style.height = "auto";
  textarea.style.height = textarea.scrollHeight + "px";
}

  const handleChange = (event) => {
    if(event.target.value.length <= 160){
      setPost(prev=>{
        return {...prev,text:event.target.value}
      })
      setTrend(prev=>{
        return {...prev,text:event.target.value}
      })
      setText(event.target.value);
    }else{
      toast.info("Text cannot be more than 160 characters",{position:"top-center",autoClose:2000,hideProgressBar:true})
      // setPost(prev=>{
      //   return {...prev,postWithBg:false}
      // })
    }
  };
  useEffect(() => {
    const textarea = document.querySelector("#text-input-background");
    if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [text]);
  useEffect(()=>{
    if(post.text.length > 160){
      toast.info("Text for background post cannot be more than 160 characters",{position:"top-center",autoClose:2000,hideProgressBar:true,style:{width:"25rem"}})
    }
    setText(post.text.slice(0,160));
  },[])
  return (
    <div
      className={"w-full h-72 flex flex-col rounded-md relative "}
      style={{ background: bgColor }}
    >
      <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0">
        <CloseIcon
          width="20"
          height="20"
          onClick={()=>{setBackground({});setPost(prev=>{return {...prev,theme:""}});onClose()}}
          className="cursor-pointer mr-2"
        />
      </div>
      <div
        className={
          "w-full items-center h-full justify-center flex font-semibold "
        }
      >
        <textarea
          className={
            "w-full max-h-full resize-none p-2 focus:outline-none rounded-md text-2xl leading-[2.5rem] scrollbar-hide text-center "
          }
          style={{
            background: "transparent",
            color: background.text_color,
          }}
          placeholder="What's up?"
          // rows={textareaheight}
          onChange={handleChange}
          value={text}
          id="text-input-background"
          onInput={autoSizeTextarea}
        />
      </div>
      <div className="flex justify-between">
        <Backgrounds position={"bottom-2 left-2"} trending={trending} />
        <EmojiIcon
          height="20"
          width="20"
          className="absolute bottom-2 right-2 cursor-pointer"
          onClick={()=>setShowEmoji(prev=>!prev)}
        />
      </div>
      {
            showEmoji && 
            <div className="z-20 absolute top-[100%] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" >
              <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
            </div>
        }
    </div>
  );
};

export default PostBackground;
