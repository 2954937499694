import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji-icon.svg";
import {ReactComponent as AddMoreImagesIcon} from "../../assets/icons/add-more-images-icon.svg";
import {ReactComponent as LeftIcon} from "../../assets/icons/left-icon.svg";
import {ReactComponent as RightIcon} from  "../../assets/icons/right-icon.svg";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { generateRandomId } from "../../helpers/generateRandomId";
import { toast } from "react-toastify";
import EmojiPicker from "../EmojiPicker";
import { addNewsContext } from "../../contexts/addNewsContext";



const EditNewsImages = ({onClose,showModal}) => {
    const {news,setNews} = useContext(addNewsContext);
    const [text,setText] = useState(news.pictures[0].description);
    const [previewIndex,setPreviewIndex] = useState(0);
    const [showEmoji, setShowEmoji] = useState(false);
    const onEmojiClick = (emoji) => {
        const picture = news.pictures[previewIndex];
        picture.description = text + emoji.char;
        setNews(prev=>{
            prev.pictures.splice(previewIndex,1,picture)
            return {...prev,pictures:prev.pictures}
        });
        setText(text + emoji.char);
        };

    const onClickNext = () => {
        if(previewIndex < news.pictures.length - 1){
            setText(news.pictures[previewIndex+1].description)
            setPreviewIndex(prev=>prev + 1);
        }
    }
    const onClickPrevious = () => {
        if(previewIndex > 0){
            setText(news.pictures[previewIndex-1].description)
            setPreviewIndex(prev=> prev - 1);
        }
    }
    const handleTextChange = (event) => {
        const picture = news.pictures[previewIndex];
        picture.description = event.target.value;
        setNews(prev=>{
            prev.pictures.splice(previewIndex,1,picture)
            return {...prev,pictures:prev.pictures}});
        setText(event.target.value);
    }
    const onDeleteImage = (id) => {
        setNews(prev=>{ 
            return {...prev,previewPictures:prev.previewPictures.filter(picture=>picture.id !== id),pictures:prev.pictures.filter(picture=>picture.id !== id)}
          });
        if(previewIndex > 0){
            setPreviewIndex(prev => prev - 1)
        }
    }

    const onDropAdditionalFiles = useCallback((acceptedFiles) => {
        const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId();
        images.push({
          image: objectUrl,
          id,
          name: file.name,
          uploadProgress:{total:0,progress:0},
          is_leading:false
        });
        setNews((prev) => {
          return {
            ...prev,
            pictures: [
              ...prev.pictures,
              { image: file, description: "",id,is_leading:false,buzz:"" },
            ],
          };
        });
      }
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: [...prev.previewPictures, ...images],
          preview: true,
        };
      });
    }
      });
    
      const { getRootProps:additionalRootProps, getInputProps:additionalInputProps, inputRef:additionalInputRef, acceptedFiles:additionalAcceptedFiles } = useDropzone({
        onDropAccepted:onDropAdditionalFiles,
        onDropRejected:()=>toast.error("Drag 'n' drop image files",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
        accept: {
          "image/jpeg": [".jpeg", ".jpg"],
          "image/png": [".png"],
          "image/webp": [".webp"],
        },
      });
    
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)] " style={{zIndex:300}}>
            <section className="w-[35%] bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md relative px-3 flex flex-col">
                
                <div className="max-h-full w-full flex relative pt-3">
                    <button className="absolute top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center" style={{zIndex:400}}>
                        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
                        <LeftIcon style={{zIndex:450}} height="20" width="20" onClick={onClickPrevious}/>
                    </button>
                    <div className="max-h-full w-full m-auto relative">
                    {news.pictures.length > 1 && <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onDeleteImage(news.previewPictures[previewIndex].id)}
                    className="cursor-pointer absolute top-3 right-3"
                    />}
                    <img src={news.previewPictures[previewIndex].image} alt="Moment Picture" width="100%" height="100%" className="max-h-[30rem] max-w-full object-cover rounded-md mb-5"/>
                    </div>
                    <button className="absolute top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center" style={{zIndex:400}}>
                        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
                        <RightIcon style={{zIndex:450}} height="20" width="20" onClick={onClickNext}/>
                    </button>
                    <div className="w-full h-10 absolute bottom-5">
                        <div className="px-3 shadow-6xl rounded-md flex justify-between h-full bg-cardBackgroundLight dark:bg-cardBackgroundDark opacity-[80%]">
                            <input placeholder="Enter description of image" className="w-[90%] focus:outline-none text-primaryTextLight dark:text-primaryTextDark bg-transparent rounded-md" onChange={handleTextChange} value={text}/>
                            <EmojiIcon height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => setShowEmoji((prev) => !prev)}/>
                        </div>
                        {showEmoji && (
                        <div className="absolute bottom-[100%] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{zIndex:400}}>
                            <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 items-center">
                    {
                        previewIndex+5 > news.previewPictures.length ?
                        news.previewPictures.slice(-5).map((picture,index)=>{
                            return (
                                <div className={"w-10 h-10 rounded-md" + (picture.id === news.pictures[previewIndex].id ? " border border-[#F63E49]" : "")} key={picture.id}>
                                    <img src={picture.image} alt="Moment Picture" width="100%" height="100%" className="h-full w-full object-cover rounded-md m-auto"/>
                                </div>
                            )
                        }) :
                        news.previewPictures.slice(previewIndex,previewIndex+5).map((picture,index)=>{
                            return (
                                <div className={"w-10 h-10 rounded-md" + (picture.id === news.pictures[previewIndex].id ? " border border-[#F63E49]" : "")} key={picture.id}>
                                    <img src={picture.image} alt="Moment Picture" width="100%" height="100%" className="h-full w-full object-cover rounded-md m-auto"/>
                                </div>
                            )
                        })

                    }
                    <div {...additionalRootProps({ className: "dropzone" })} className="">
                        <input className="input-zone" {...additionalInputProps({})}/>
                        <AddMoreImagesIcon width={20} height={20} className="cursor-pointer"/>
                    </div>
                </div>
                <div className="w-full flex justify-end mt-auto mb-4 pt-2">
                <button className="px-10 py-[0.125rem] mt-auto flex items-center bg-linear-grad text-white rounded-sm" onClick={onClose}>
                    Done
                </button>
                </div>
            </section>
        </div>
        // </div>
    )
};

export default EditNewsImages;