import {toast} from "react-toastify";
import { useQueryClient } from "react-query";
import { useContext } from "react";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import { hideTrend, reportTrend, turnOffTrendNotification, turnOnTrendNotification } from "../../api/trends";
import html2canvas from "html2canvas";

const TrendContextMenu = ({trendId,trendNotificationOff,onClose,isHallTrend,trendReported,shareTrend}) => {
    const {setMenu} = useContext(postTrendMenuContext);
    const queryClient = useQueryClient();
    const changePostNotificationStatus = async () => {
        if(trendNotificationOff === true){
            const response = await turnOnTrendNotification(trendId);
            if(response.error){
                onClose()
                toast.error("Could not turn on notifications for trend",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }else{
                queryClient.invalidateQueries("hall-trends");
                onClose()
                toast.success("Notifications for trend turned on",{position:"top-center",autoClose:2000,hideProgressBar:true});
            }
        }else{
            const response = await turnOffTrendNotification(trendId);
            if(response.error){
                onClose()
                toast.error("Could not turn off notifications for trend",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }else{
                queryClient.invalidateQueries("hall-trends");
                onClose()
                toast.success("Notifications for trend turned off",{position:"top-center",autoClose:2000,hideProgressBar:true});
            }
        }
    }

    const onHideTrend = async () => {
        const response = await hideTrend(trendId);
        if(response.error){
            toast.error("Could not hide trend",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            queryClient.invalidateQueries("hall-trends");
            toast.success("Trend hidden successfully",{autoClose:2000,position:"top-center",hideProgressBar:true})
        }
        onClose();
    }


    return (
        <div className="w-60 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-cardBackgroundDark">
            {
                isHallTrend ? 
                <>
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={changePostNotificationStatus}>
                {
                    trendNotificationOff ? "Turn on notifications for Trend" : "Turn off notifications for Trend"
                }
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-2 text-[#F63E49] cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark " onClick={()=>setMenu(prev=>{return {...prev,trend:true,trendId:trendId}})}>
                Delete this trend 
            </div>
                </> :
                <>
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={changePostNotificationStatus}>
                {
                    trendNotificationOff ? "Turn on notifications for Trend" : "Turn off notifications for Trend"
                }
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 py-2" onClick={onHideTrend}>Hide this Trend</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col">
                {
                    !trendReported ? 
                    <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark  pl-4 py-2 text-[#F63E49]" onClick={()=>{setMenu(prev=>{return {...prev,reportTrend:true,trendId:trendId}})}}>Report this Trend</span>
                    : <span className="cursor-pointer  pl-4 py-2 text-[#F63E49]">Trend Reported</span>

                }
            </div>
            </>
            }
        </div>
    )
}

export default TrendContextMenu;