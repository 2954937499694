import PageContainer from "../../components/PageContainer"
import {ReactComponent as NotFound} from "../../assets/icons/404.svg"
const NotFoundPage = () => {
    return (
        <div className="font-inter relative h-screen w-screen">
            <NotFound className="w-full h-full"/>
            
        </div>
    )
}

export default NotFoundPage