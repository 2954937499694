import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { useDeleteLeadershipPortfolio } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { hallContext } from "../../contexts/hallContext";
import { BeatLoader } from "react-spinners";

const RemoveLeader = () => {
    const queryClient = useQueryClient();
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    const {mutate:deleteLeaderInfo,isLoading:isDeletingLeader} = useDeleteLeadershipPortfolio();
    const onDelete = () => {
        deleteLeaderInfo(leadershipStates.leader.id,{
            onSuccess:(data)=>{
                toast.success("Leader deleted successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                queryClient.invalidateQueries(["leadership-portfolios",hall?.hallInfo.id]);
                queryClient.invalidateQueries("current-hall-executives");
                setLeadershipStates(prev=>{return {...prev,leader:{},removeLeader:false}});
            },
            onError:(error)=>{
                toast.error("Failed to delete leader",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
        })
    }
    const onCancel = () => {
        setLeadershipStates(prev=>{return {...prev,leader:{},removeLeader:false}});
    }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:"9999"}}>
            <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 flex flex-col gap-10 min-w-[20rem]">
                <p className="text-primaryTextLight dark:text-primaryTextDark font-medium">Do you want to delete this executive?</p>
                <div className="flex ml-auto gap-4 mt-auto">
                    <button className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md" onClick={onCancel}>Cancel</button>
                    <button className="bg-[#F63E49] text-white py-1 px-2 rounded-md" onClick={onDelete}>{isDeletingLeader ? <BeatLoader color="white" size={10}/> :"Delete"}</button>
                </div>
            </div>            
        </div>
    )
}

export default RemoveLeader;