import { useNavigate } from "react-router-dom";
import OptionSelector from "./OptionSelector";
import { ReactComponent as CleanImagesIcon } from "../../assets/icons/clean-images.svg";
import { ReactComponent as EditImagesIcon } from "../../assets/icons/edit-images.svg";
import { ReactComponent as AddImagesIcon } from "../../assets/icons/add-images.svg";
import { ReactComponent as DeleteImage } from "../../assets/icons/bin-news-image.svg";
import React, { useContext, useState } from "react";
import { addNewsContext } from "../../contexts/addNewsContext";
import { toast } from "react-toastify";
import { useCreateNews, useGetNewsTypes, usePostNewsPic } from "../../hooks/newsHooks";
import pick from "lodash.pick";
import { userContext } from "../../contexts/userContext";
import { hallContext } from "../../contexts/hallContext";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import CircularProgressTracker from "../CircularProgressTracker";
import { useQueryClient } from "react-query";
const AddNews = ({ setAddPictures, setDiscard, setEditImages }) => {
  const nav = useNavigate();
  const { news, setNews } = useContext(addNewsContext);
  const { data: newsTypes } = useGetNewsTypes();
  const {user_state} = useContext(userContext);
  const {hall} = useContext(hallContext);
  const {mutateAsync:uploadNewsImage} = usePostNewsPic();
  const {mutate:createNews,isLoading:isCreatingNews} = useCreateNews();
  const [loading,setLoading] = useState(false);
  const queryClient = useQueryClient()
  const onRemovePicture = (id, name) => {
    if (news.previewPictures.length === 1) {
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: prev.previewPictures.filter(
            (picture) => picture.id !== id
          ),
          pictures: prev.pictures.filter((picture) => picture.id !== id),
          preview: false,
        };
      });
    } else {
      setNews((prev) => {
        const filteredPreviewImages = prev.previewPictures.filter((picture) => picture.id !== id)
        const filteredImages = prev.pictures.filter((picture) => picture.id !== id)
        return {
          ...prev,
          previewPictures: filteredPreviewImages.some(picture=>picture.is_leading === true) ? filteredPreviewImages : filteredPreviewImages.map((image,index)=>{
            if(index === 0){
              image.is_leading = true;
              return image;
            }
            return image;
          }),
          pictures: filteredImages.some(picture=>picture.is_leading === true) ? filteredImages : filteredImages.map((image,index)=>{
            if(index === 0){
              image.is_leading = true;
              return image;
            }
            return image;
          })
        };
      });
    }
  };

  const setLeadingImage = (id) => {
    setNews((prev) => {
      return {
        ...prev,
        pictures: prev.pictures.map((picture) => {
          if (picture.id === id) {
            return { ...picture, is_leading: true };
          } else {
            return { ...picture, is_leading: false };
          }
        }),
        previewPictures: prev.previewPictures.map((picture) => {
          if (picture.id === id) {
            return { ...picture, is_leading: true };
          } else {
            return { ...picture, is_leading: false };
          }
        }),
      };
    });
  };
  const onClearImages = () => {
    // setPreview(false);
    setNews((prev) => {
      return {
        ...prev,
        pictures: [],
        previewPictures: [],
        preview: false,
      };
    });
  };

  const uploadNewsPic = async (values,id) => {
    const formData = new FormData();
    for(let field in values){
        formData.append(field,values[field]);
    }
    // for (const pair of formData.entries()) {
    //     console.log(`${pair[0]}, ${pair[1]}`);
    //   }
    const onUploadProgress = (progressEvent) => {
        setNews(prev=>{
            return {
                ...prev,
                previewPictures:prev.previewPictures.map(picture=>{
                    if(picture.id === id){
                        return {...picture,uploadProgress:progressEvent}
                    }else{
                        return picture;
                    }
                })
            }
        })
    };
    const createdPicture = await uploadNewsImage({values:values,uploadProgress:onUploadProgress});
    return createdPicture;
  }
  const onCreateNews = () => {
    if(news.title.length === 0 || news.body.length === 0){
        toast.error("Please fill all required fields",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(news.pictures.length > 4){
        toast.error("Exceeded maximum number of images allowed",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(news.pictures.length === 0){
        toast.error("Attach at least one image",{position:"top-center",hideProgressBar:true,autoClose:2000});
    }
    else{
        const newsToBeSent = pick(news,["title","body","source","type","hall","is_hall_buzz","created_by","date_created"]);
        newsToBeSent.created_by = user_state?.user?.user?.id;
        newsToBeSent.date_created = moment().format();
        newsToBeSent.hall = hall?.hallInfo.id;
        newsToBeSent.is_hall_buzz = true;
        newsToBeSent.pictures = [];
        setLoading(true);
        createNews(newsToBeSent,{
            onSuccess:(data)=>{
                const uploadingImages = [];
                queryClient.invalidateQueries("hall-news");
                for(let picture of news.pictures){
                    const pictureToBeUploaded = {image:picture.image,description:picture.description,buzz:data.data.id,is_leading:picture.is_leading,}
                    uploadingImages.push(uploadNewsPic(pictureToBeUploaded,picture.id))
                }
                Promise.all(uploadingImages).then(results=>{
                    setLoading(false);
                    toast.success("News Uploaded",{position:"top-center",autoClose:2000,hideProgressBar:true});
                    queryClient.invalidateQueries(["hall-news",news.type]);
                    setNews({title:"",body:"",source:"",type:news.type,hall:"",is_hall_buzz:"",pictures:[],previewPictures:[],created_by:"",date_created:"",preview:false})
                  if(!results.every(res=>res.status === 201)){
                      toast.error("Could not upload all images",{autoClose:2000,position:"top-center",hideProgressBar:true});
                  }
            })},
            onError:(error)=>{
                toast.error("Failed to upload news",{position:"top-center",autoClose:2000,hideProgressBar:true})
            }
        })
    }
  }
  return (
    <div className="w-[55%] shrink-0">
      <div className="w-full h-16 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md shadow-3xl flex justify-between px-3 items-center">
        <h2 className="text-2xl text-primaryTextLight dark:text-primaryTextDark font-medium">Add News</h2>
        <div className="flex gap-4">
          <div className="flex flex-col">
            <span
              className="bg-linear-grad bg-clip-text text-transparent underline cursor-pointer text-sm inline-block mb-0"
              onClick={() => setDiscard(true)}
            >
              Discard
            </span>
            <span className="bg-linear-grad inline-block w-full h-[2px] mt-0"></span>
          </div>
          <button
            type="button"
            className="h-8 border font-light text-base flex bg-linear-grad items-center rounded-md px-5 justify-between cursor-pointer border-none"
            onClick={onCreateNews}
          >
            {loading ? <BeatLoader color="white" size="10"/>: <span className="text-white">Save</span>}
          </button>
        </div>
      </div>
      <div className="px-2">
        <div className="flex mt-3">
          <span className="text-primaryTextLight dark:text-primaryTextDark font-medium shrink-0 w-[25%]">
            Headline <span className="text-[#F63E49]">*</span>
          </span>
          <div className="w-full relative">
            <textarea
              className="w-full h-24 border resize-none dark:border-dark-gray rounded-md px-5 py-2 focus:outline-none text-primaryTextLight dark:text-primaryTextDark dark:bg-backgroundDark"
              placeholder="Enter headline for the news"
              value={news.title}
              onChange={(event) => {
                if (event.target.value.length <= 100) {
                  setNews((prev) => {
                    return { ...prev, title: `${event.target.value}` };
                  });
                } else {
                  if(!toast.isActive('text-headline')){
                    toast.info("Headline cannot be more than 100 characters.",{position:"top-center",hideProgressBar:true,autoClose:2000,toastId:"text-headline"});
                  }
                }
              }}
            />
            <span className="absolute bottom-3 right-3 text-gray-200 text-xs">
              100
            </span>
          </div>
        </div>
        <div className="flex mt-3">
          <span className="text-primaryTextLight dark:text-primaryTextDark font-medium shrink-0 w-[25%]">
            Source
          </span>
          <div className="w-full relative">
            <input
              className="w-full h-10 border dark:border-dark-gray shadow-3xl resize-none rounded-md px-5 py-2 focus:outline-none text-primaryTextLight dark:text-primaryTextDark dark:bg-backgroundDark"
              placeholder="Who or Where is the source"
              value={news.source}
              onChange={(event) => {
                setNews((prev) => {
                  return { ...prev, source: event.target.value };
                });
              }}
            />
          </div>
        </div>
        <div className="flex mt-3">
          <span className="text-primaryTextLight dark:text-primaryTextDark font-medium shrink-0 w-[25%]">
            Type <span className="text-[#F63E49]">*</span>
          </span>
          <div className="w-full">
            <OptionSelector
              options={newsTypes?.data?.results}
              onChange={(name, value) =>
                setNews((prev) => {
                  return { ...prev, type: value };
                })
              }
              name="type"
            />
          </div>
        </div>
        <div className="w-full h-60 rounded-md mt-3">
          <textarea
            className="w-full h-full rounded-md border dark:border-dark-gray resize-none px-3 py-2 focus:outline-none text-primaryTextLight dark:text-primaryTextDark dark:bg-backgroundDark"
            placeholder="Details *"
            value={news.body}
            onChange={(event) =>
              setNews((prev) => {
                return { ...prev, body: event.target.value };
              })
            }
          />
        </div>
        <div className="mt-5 flex justify-between">
            <div className="flex gap-1 items-center">
          <h4 className="text-primaryTextLight font-medium dark:text-primaryTextDark">Images <span className="text-[#F63E49]">*</span></h4>
            <span className="text-primaryTextLight opacity-80 text-sm dark:text-primaryTextDark">(max:4)</span>
            </div>
          <div className="flex gap-2">
            <button
              className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"
              onClick={() => setAddPictures(true)}
            >
              <AddImagesIcon />
              Add Media
            </button>
            <button
              className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"
              onClick={() => {
                if (news.pictures.length > 0) {
                  setEditImages(true);
                }
              }}
            >
              <EditImagesIcon />
              Edit All
            </button>
            <button
              className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"
              onClick={() => onClearImages()}
            >
              <CleanImagesIcon />
              Clean All
            </button>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 mt-3">
          {news.previewPictures.map((picture) => {
            return (
              <div className="relative" key={picture.id}>
                <input
                  type={"radio"}
                  name="news-image"
                  className="absolute left-2 top-2 w-4 h-4 bg-white accent-[#F63E49]"
                  onChange={() => setLeadingImage(picture.id)}
                  checked={picture.is_leading}
                />
                <img
                  src={picture.image}
                  className="h-24 w-36 object-cover rounded-md"
                />
                <DeleteImage
                  width={"15"}
                  height="15"
                  className="absolute bottom-2 left-2 cursor-pointer"
                  onClick={() => onRemovePicture(picture.id, picture.name)}
                />
                {
                (picture?.uploadProgress && picture?.uploadProgress?.progress && picture?.uploadProgress?.progress !== 1) && 
                <React.Fragment>
                <div className="w-full h-full absolute top-0 flex items-center justify-center z-10 bg-black opacity-[30%]">
                </div>
                    <div className="absolute top-0 z-50 w-full h-full flex items-center justify-center">

                    <CircularProgressTracker percentage={Math.round(picture?.uploadProgress?.progress*100)} width="40px" height="40px"/>
                    </div>

                </React.Fragment>
            }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddNews;
