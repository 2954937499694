import {ReactComponent as SearchIcon} from "../../assets/icons/search-icon.svg";
import {ReactComponent as SearchIconDark} from "../../assets/icons/search-icon-dark.svg";
import {ReactComponent as FilterIcon} from "../../assets/icons/filter-icon.svg";
import { BehaviorSubject,debounceTime,map,distinctUntilChanged,filter,switchMap,merge,of } from 'rxjs';
import { useContext, useState } from "react";
import { searchPostsByText } from "../../api/posts";
import { BarLoader } from "react-spinners";
import { useEffect } from "react";
import { hallContext } from "../../contexts/hallContext";
import { useSearchPosts } from "../../hooks/postHooks";
import { themeContext } from "../../contexts/themeContext";

const FilterPost = ({setTrending,trending,onFilterPosts,onFilterTrends,setSearching,setSearchedPosts,filtered,setSearchedPostsDetails,setShowTrendingPosts,showTrendingPosts}) => {
    const [searchTerm,setSearchTerm] = useState("");
    const [loading,setLoading] = useState(false);
    const [postSubject,setPostSubject] = useState(null);
    const {hall} = useContext(hallContext);
    const {theme} = useContext(themeContext);
    const {refetch,hasNextPage,fetchNextPage} = useSearchPosts(searchTerm,hall?.hallInfo.id,(data)=>{setLoading(false);setSearchedPosts(data)})
    const onChange = (event) => {
        setSearchTerm(event.target.value)
        if(postSubject === null){
            const sub = new BehaviorSubject('')
            setPostSubject(sub);
        }else{
            setLoading(true);
            setSearching(true);
            return postSubject.next(event.target.value)
        }
    }

    const postSearch = () => {
        if(postSubject !== null){
            const observable = postSubject.pipe(
                map(s => s.trim()),
                distinctUntilChanged(),
                debounceTime(200),
               
                )
                .subscribe(()=>{
                    refetch();
                })
                return () => {
                    observable.unsubscribe()
                    postSubject.unsubscribe()}
        }
    }

    useEffect(()=>{
        if(searchTerm === ""){
            setSearching(false);
            setLoading(false);
        }
    },[searchTerm])

    useEffect(()=>{
        // if(postSubject === null){
        //     const sub = new BehaviorSubject('')
        //     setPostSubject(sub);
        // }else{
            postSearch()
        // }
    },[postSubject])
    useEffect(()=>{        
            setSearchedPostsDetails({fetchNextPage,hasNextPage})
    },[hasNextPage])
    return (
        <div className="w-full h-14 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md px-4 flex justify-between items-center py-2 mb-5">
            <div className="flex gap-2 h-full w-30 items-center">
                <span className="text-primaryTextLight dark:text-primaryTextDark text-lg font-medium cursor-pointer">{!showTrendingPosts?"Post":"Trending"}</span>
                <span className={"inline-block w-[3px] h-[3px] rounded-full " + (theme.value == "light"? "bg-dot-gray":"bg-[#e4e4e4]")}></span>
                <span className="text-sm text-[#F63E49] cursor-pointer" onClick={()=>setShowTrendingPosts(prev=>!prev)}>{showTrendingPosts?"Post":"Trending"}</span>
            </div>
            <div className="h-8 w-80 flex justify-end gap-4">
                <div className="w-[50%] h-full flex flex-col border rounded-md items-center gap-1 dark:border-dark-gray">
                <div className="flex h-full items-center pl-2 rounded-md gap-2">
                    {theme?.value == "light" ? <SearchIcon width="15" height="15"/>:<SearchIconDark width="15" height="15"/>}
                    <input type="search" value={searchTerm} onChange={onChange} placeholder={!trending ? "Search for post..." : "Search for trend..."} className="focus:outline-none h-full w-full text-sm font-light rounded-md text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
                </div>
                {
                    loading && 
                    <BarLoader color="#F63E49" height={2} width="95%" className="w-full"/>
                }
                </div>
                <div className="flex h-full items-center w-[35%] bg-filter-grad px-2 gap-5 rounded-md cursor-pointer text-primaryTextLight" onClick={trending ? onFilterTrends : onFilterPosts}>
                    <FilterIcon/>
                    <span>{filtered ? "Filtered":"Filter"}</span>
                </div>
            </div>
            
        </div>
    )
}

export default FilterPost;