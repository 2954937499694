import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import SmallProfile from "../hallpage/SmallProfile";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import TrendContextMenu from "./TrendContextMenu";

const Trend = ({ children, trend }) => {
  const { hall } = useContext(hallContext);
  const [showContextMenu,setShowContextMenu] = useState(false);
  const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
  
  return (
    <div className={"w-full px-3 pt-4 pb-3 rounded-md flex flex-col mb-5 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark shadow-5xl" } id={`trend-${trend.id}`}>
      <div className="flex">
        <SmallProfile url={trend?.is_hall_trend ? hall?.hallInfo.picture : trend?.user_account.image}/>
        <div className="flex flex-col w-full">
          <div className="flex h-12 items-center w-full justify-between relative">
            <div className="flex flex-col text-xl ">
              <span>{trend?.is_hall_trend ? hall?.hallInfo.name : trend?.user_account?.username}</span>
              <span className="text-sm inline-flex gap-1 items-center">
                {trend?.is_hall_trend &&
                  <>
                {trend?.user_account.fullname}{" "}
                <span className="inline-block w-[3px] h-[3px] rounded-full bg-dot-gray"></span></>}
                {moment(trend?.date_created).format("DD/MM/YYYY h:mm a")}{" "}
                <span className="inline-block w-[3px] h-[3px] rounded-full bg-dot-gray"></span>
                <div className="flex gap-1">
                  {trend?.tags?.map((tag) => {
                    return (
                      <span className="underline text-[#0085FF]" key={tag.id}>{tag.short_hand}</span>
                    );
                  })}
                </div>
              </span>
            </div>
            <MenuIcon width="15" height="15" className="cursor-pointer" onClick={()=>setShowContextMenu(true)} />
            {
              showContextMenu && 
            <div className="absolute z-10 top-[0%] right-[0%]" ref={ref} id={`trend-context-${trend.id}`}>
              <TrendContextMenu trendId={trend.id} trendNotificationOff={trend.notification_off} onClose={()=>setShowContextMenu(false)} isHallTrend={trend.is_hall_trend} trendReported={trend.trend_reported}/>
            </div>
            }
          </div>
          <div>
          {children}
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <hr className="dark:border-dark-gray"/>
        <div className="w-full flex justify-evenly text-sm items-center mt-2">
          <span>
            Likes:{" "}
            <span className="underline text-[#F63E49]">
              {trend?.likes_count}
            </span>
          </span>
          <span>
            Comments:{" "}
            <span className="underline text-[#F63E49]">
              {trend?.comment_count}
            </span>
          </span>
        </div>
      </div>
      <div id="hidden-image"></div>
    </div>
  );
};

export default Trend;
