const TrendingCard = ({tags,text,likes,comments}) => {
    const bgColor = "linear-gradient(149.88deg, #F5FFCA 29.37%, #FFCAD4 134.52%)"
    const textColor = "rgba(54, 76, 102, 0.84)"
    return (
      // Content with background
      <div className="w-52 h-52 rounded-md mr-4 flex flex-col px-4 pb-2 pt-4 shrink-0" style={{background:bgColor,color:textColor}}>
        <p className="mt-auto text-center text-xl flex line-clamp-6">
          {text}
        </p>
        <div className="flex flex-col gap-2 mt-auto">
          <div className="text-sm flex gap-2 text-[#0085FF]">
            {tags.length === 0 ? <span>General</span> : tags.map(tag=>{
              return (
                <span key={tag.id}>{tag.short_hand}</span>
              )
            })}
          </div>
          <div className="text-sm flex justify-between">
              <span>Likes: <span>{likes}</span></span>
              <span>Comments: <span>{comments}</span></span>
          </div>
        </div>
      </div>
    );
  };
  
  export default TrendingCard;
  