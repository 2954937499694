import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import  {BeatLoader} from 'react-spinners'
import React, { useEffect, useState } from "react";
import { cancelRequest } from "../../api/hall";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import * as Diff from "diff";
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
import { useCheckAboutChangeRequestExists } from "../../hooks/hallHooks";


const AboutRequest = ({isLoading,onClose,aboutRequestData,setIsChangeRequested}) => {
    const [loading,setIsLoading] = useState(false)
    const {hall} = useContext(hallContext);
    const query = useQueryClient()

    const {data} = useCheckAboutChangeRequestExists(hall?.hallInfo.id);

    const onCancelRequest = async () => {
        setIsLoading(true)
        const response = await cancelRequest(data?.data?.change_request.id)
        if(response.error){
            toast.error("Could not cancel request",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            query.invalidateQueries("check-about-request");
            toast.success("Change request canceled",{autoClose:2000,hideProgressBar:true,position:"top-center"})
            setIsChangeRequested(false);
            onClose();
        }
        setIsLoading(false);
    }

    // const onCheckIsChangeRequested = (data) => {
    //       console.log(data)
    //       if(data?.data.requested === false){
    //         setIsAboutChangeRequested(false);
    //       }else{
    //         setIsAboutChangeRequested(true);
    //         setAboutRequestData(data?.data.change_request);
    //       }
    //     }
      
    

    const About = ({aboutRequestData}) => {
        const diff = Diff.diffWords(data?.data.change_request?.change_tables[0]?.old_value,data?.data?.change_request?.change_tables[0]?.new_value);
        return (
            <React.Fragment>
                {diff?.map((line,index)=>{
                    if(line.removed){
                        return (<React.Fragment key={index}><span className="text-[#F63E49] whitespace-pre-wrap">{line.value}</span></React.Fragment>)
                    }else if(line.added){
                        return (<React.Fragment key={index}><span className="text-green-500 whitespace-pre-wrap">{line.value}</span></React.Fragment>)
                    }else{
                        return (<React.Fragment key={index}><span className="whitespace-pre-wrap">{line.value}</span></React.Fragment>)
                    }
                })}
            </React.Fragment>
        )
    }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center z-10 absolute bg-[rgba(0,0,0,0.3)]">
        <section className="w-[40%] h-[450px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark relative">
            <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={onClose}/>
            <div className="flex justify-between items-center px-4 py-3">
                <span className="font-semibold text-lg">About Hall Change Request</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full px-4 py-4 h-[75%]">
                <p className="w-full h-full p-2 rounded-md overflow-y-auto  text-md bg-cardBackgroundLight dark:bg-cardBackgroundDark whitespace-pre-wrap">{<About aboutRequestData={aboutRequestData}/>}</p>
            </div>
            <div className="w-full text-center">
                <button className="bg-filter-grad text-primaryTextLight dark:text-primaryTextDark w-[50%] py-2 rounded-sm font-medium shadow-4xl" onClick={onCancelRequest}>{!loading ? "CANCEL REQUEST":<><BeatLoader color="#fff" size={10}/></>}</button>
            </div>
        </section>
        </div>
    )
}
export default AboutRequest

// {!isLoading ? "Cancel Request" : <> <BeatLoader color="#fff" size={10}/></>}