import { createContext, useReducer } from "react";
import { getUser } from "../helpers/manageUser";
import userReducer from "../reducers/userReducer";


// user context
export const userContext = createContext({});

const UserContextProvider = ({children}) => {
    const initialValues = {
        user:{}
    }
    const [state,dispatch] = useReducer(userReducer,initialValues);
    return (
        <userContext.Provider value={{user_state:state,setUser:dispatch}}>
            {children}
        </userContext.Provider>
    )
}

export default UserContextProvider