const userActions = {
    "login": "LOGIN",
    "refresh_token": "REFRESH_TOKEN",
    "logout": "LOGOUT",
    "create_user": "CREATE_USER",
    "update_user": "UPDATE_USER"
}

const hallActions = {
    "create_hall_info":"CREATE_HALL_INFO",
    "update_hall_info":"UPDATE_HALL_INFO",
    "patch_hall_info":"PATCH_HALL_INFO",
    "create_hall_moments":"CREATE_HALL_MOMENTS",
    "create_hall_leaders":"CREATE_HALL_LEADERS"
}

export {
    userActions,
    hallActions
}