import React, { useEffect, useRef, useState } from "react";

const TextPostContent = ({text}) => {
  const [showSeeMore,setShowSeeMore] = useState(false);
  const [seeMore,setSeeMore] = useState(false);
  const ref = useRef(null);

  useEffect(()=>{
    if (ref.current && ref.current.clientHeight > 60) {
      setSeeMore(true);
      setShowSeeMore(true);
    }else{
      setShowSeeMore(false);
    }
  },[ref])
  return (
    <div className="mt-4">
      <div className="text-base">
        <p ref={ref} className={seeMore ? `line-clamp-6 inline break-words` : "break-words"}>
        {text}{" "}
        </p>
        {
          showSeeMore && 
          <React.Fragment>
            {!seeMore && 
                        <span className="text-xs text-[#F63E49] inline cursor-pointer" onClick={()=>setSeeMore(true)}>See less</span>
    
            }
            {seeMore && 
              <span className="text-xs text-[#F63E49] inline cursor-pointer" onClick={()=>setSeeMore(false)}>See more</span>
              }
          </React.Fragment>  
        }
      </div>
    </div>
  );
};

export default TextPostContent;
