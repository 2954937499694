import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { createMoment, createMomentPicture, deleteEntireMoment, deleteMomentPicture, editMoment, editMomentPicture, filterMoments, getMoments, getSingleMoment } from "../api/moments";
import { toast } from "react-toastify";

const useGetHallMoments = (id) => {
    return useInfiniteQuery(["hall-moments",id],({pageParam})=>getMoments(pageParam || `/user/v1/moment/?hall=${id}`),{
        getNextPageParam: (lastPage) => lastPage.data.next,
        // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
        // onSuccess:(data)=>console.log(data),
        onError:(error)=>{
            toast.error("Failed to fetch hall moments",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }
      });
}

const useGetSingleMoment = (id) => {
    return useQuery(["single-hall-moment",id],()=>getSingleMoment(id),
    {
        // onSuccess:data=>console.log(data)
        onError:(error)=>{
            toast.error("Failed to fetch moment",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }
    }
    )
}

const useCreateHallMoment = () => {
    return useMutation((values)=>createMoment(values),{
        mutationKey:"create-hall-moment"
    })
}

const useCreateMomentPicture = () => {
    return useMutation((values)=>createMomentPicture(values.values,values.uploadProgress),{
        mutationKey:"create-moment-picture",
        retry:1
    })
}

const useEditMomentTitle = () => {
    return useMutation((values)=>editMoment(values.id,values.values),{mutationKey:"edit-moment-title"})
}

const useEditMomentPrivacy = () => {
    return useMutation((values)=>editMoment(values.id,values.values),{mutationKey:"edit-moment-privacy"})
}

const useEditMomentPictureTitle = () => {
    return useMutation((values)=>{
        const formdata = new FormData();
        formdata.append("description",values.values.description);
        formdata.append("id",values.id);
        return editMomentPicture(values.id,formdata)},{mutationKey:"edit-moment-picture-title"})
}

const useEditMomentPicturePrivacy = () => {
    return useMutation(values=>{
        const formdata = new FormData();
        formdata.append("privacy",values.values.privacy);
        formdata.append("id",values.id);
        return editMomentPicture(values.id,formdata)
    },{mutationKey:"edit-moment-picture-privacy"})
}

const useDeleteEntireMoment = () => {
    return useMutation(deleteEntireMoment,{mutationKey:"delete-entire-moment"});
}

const useDeleteMomentPicture = () => {
    return useMutation(deleteMomentPicture,{mutationKey:"delete-moment-picture"});
}

const useFilterMoments = (id,filtervalues) => {
    const filters = {}
    const filter = [];
    for(let value in filtervalues){
        if(filtervalues[value] !== ""){
            filters[value] = filtervalues[value];
        }
    }
    for (let key in filters) {
      filter.push(`${key}=${filters[key]}`);
    }    
    return useInfiniteQuery(["filtered-hall-moments",id],(params)=>filterMoments({pageParam:params.pageParam || `/user/v1/moment/?hall=${id}&${filter.join("&")}`}),{
        getNextPageParam: (lastPage) => lastPage.data.next,
        // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
        // onSuccess:(data)=>console.log(data),
        // onError:(error)=>console.log(error),
        enabled:false
      });
}

export {
    useGetHallMoments,
    useGetSingleMoment,
    useCreateHallMoment,
    useCreateMomentPicture,
    useEditMomentTitle,
    useEditMomentPrivacy,
    useEditMomentPictureTitle,
    useEditMomentPicturePrivacy,
    useDeleteEntireMoment,
    useDeleteMomentPicture,
    useFilterMoments,
}