export const getUser = () => {
    return JSON.parse(sessionStorage.getItem('user')) || JSON.parse(localStorage.getItem('user'));
}

export const getLocalStorageUser = () => {
    return JSON.parse(localStorage.getItem('user'));
}

export const getSessionStorageUser = () => {
    return JSON.parse(sessionStorage.getItem('user'));
}

export const storeLocalStorageUser = (user) => {
        localStorage.setItem('user',JSON.stringify(user));
}

export const storeSessionStorageUser = (user) => {
    sessionStorage.setItem('user',JSON.stringify(user));
}

export const removeUser = () => {
    sessionStorage.removeItem('user') || localStorage.removeItem('user');
}

export const removeLocalStorageUser = () => {
    localStorage.removeItem('user');
}

export const removeSessionStorageUser = () => {
    sessionStorage.removeItem('user');
}