import { hidePost, turnOffPostNotification, turnOnPostNotification } from "../../api/posts";
import {toast} from "react-toastify";
import { useQueryClient } from "react-query";
import { useContext, useEffect } from "react";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import { hallContext } from "../../contexts/hallContext";

const PostContextMenu = ({postId,postNotificationOff,onClose,isHallPost,postReported,setShowChangePrivacy,onEditPost,isPostPoll}) => {
    const {setMenu} = useContext(postTrendMenuContext);
    const {hall} = useContext(hallContext);
    const queryClient = useQueryClient();
    const changePostNotificationStatus = async () => {
        if(postNotificationOff === true){
            const response = await turnOnPostNotification(postId);
            if(response.error){
                toast.error("Could not turn on notifications for post",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }else{
                queryClient.invalidateQueries("hall-posts");
                toast.success("Notifications for post turned on",{position:"top-center",autoClose:2000,hideProgressBar:true});
            }
        }else{
            const response = await turnOffPostNotification(postId);
            if(response.error){
                toast.error("Could not turn off notifications for post",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }else{
                queryClient.invalidateQueries("hall-posts");
                toast.success("Notifications for post turned off",{position:"top-center",autoClose:2000,hideProgressBar:true});
            }
        }
        onClose()
    }

    const onHidePost = async () => {
        const response = await hidePost(postId);
        if(response.error){
            toast.error("Could not hide post",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            queryClient.invalidateQueries("hall-posts");
            toast.success("Post hidden successfully",{autoClose:2000,position:"top-center",hideProgressBar:true})
        }
        onClose();
    }


    return (
        <div className="w-60 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-backgroundDark">
            {
                isHallPost ? 
            <>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                {
                    !isPostPoll &&
                    <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 py-2" onClick={onEditPost}>Edit this Post</span>
                    
                }
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 py-2" onClick={()=>{onClose();setShowChangePrivacy(true)}}>Change who can reply to this</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={changePostNotificationStatus}>
                {
                    postNotificationOff ? "Turn on notifications for Post" : "Turn off notifications for Post"
                }
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-2 text-[#F63E49] cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark " onClick={()=>setMenu(prev=>{ return {...prev,post:true,postId:postId}})}>
                Delete this post 
            </div>
            </> :
            <>
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={changePostNotificationStatus}>
                {
                    postNotificationOff ? "Turn on notifications for Post" : "Turn off notifications for Post"
                }
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 py-2" onClick={onHidePost}>Hide this Post</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-[#F63E49]">
                {
                    !postReported ? 
                    <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark  pl-4 py-2" onClick={()=>setMenu(prev=>{return {...prev,reportPost:true,postId:postId}})}>Report this Post</span>
                    : <span className="cursor-pointer  pl-4 py-2">Post Reported</span>

                }
            </div>
            </>
            }
        </div>
    )
}

export default PostContextMenu;