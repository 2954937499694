import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import Banner from "../../components/hallpage/Banner";
import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import { logoutContext } from "../../contexts/logoutContext";
import { useLogout } from "../../hooks/authHooks";
import NewsHeader from "../../components/news/NewsHeader";
import NewsList from "../../components/news/NewsList";
import AddNews from "../../components/news/AddNews";
import { useGetHallNews, useGetNewsTypes } from "../../hooks/newsHooks";
import { hallContext } from "../../contexts/hallContext";

const NewsPage = () => {
const [category,setCategory] = useState("General")
const {logout,setLogout} = useContext(logoutContext);
const {data:newsTypes} = useGetNewsTypes();
const {onLogout} = useLogout();
useEffect(()=>{
  document.title = "LinkLounge | News"
},[])
useEffect(()=>{
  setCategory(newsTypes?.data.results[0])
},[newsTypes?.data])
return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto">
      <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-20`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full mt-3 flex-col gap-1 pb-5">
            <NewsHeader category={category} setCategory={setCategory} categories={newsTypes?.data?.results}/>
            <NewsList activeCategory={category}/>
          </section>
        </main>
      </div>
        {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
        }
    </PageContainer>
  );
};

export default NewsPage;
