import pick from "lodash.pick";
import { useState,useContext } from "react";
import { useQueryClient } from "react-query";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { leadershipContext } from "../../contexts/leadershipContext";
import { useUpdateLeadershipPortfolio } from "../../hooks/leadersHooks";
import { hallContext } from "../../contexts/hallContext";
import { themeContext } from "../../contexts/themeContext";

const EditPortofolio = () => {
    const {theme} = useContext(themeContext);
    const {hall} = useContext(hallContext); 
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const [rotate,setRotate] = useState(false);
    const [showOptions,setShowOptions] = useState(false);
    const [selectedOption,setSelectedOption] = useState(leadershipStates.portofolio.leadership_type);
    const [selectedStatusOption,setSelectedStatusOption] = useState(leadershipStates.portofolio.is_active ? "Active" : "Inactive");
    const [rotatestatus,setRotateStatus] = useState(false);
    const [showStatusOptions,setShowStatusOptions] = useState(false);
    const [portfolio,setPortfolio] = useState(leadershipStates.portofolio);
    const {mutate:updatePortfolio,isLoading} = useUpdateLeadershipPortfolio();
    const queryClient = useQueryClient();
    const onDropDown = () => {
        setShowOptions(prev=>!prev);
        setRotateStatus(prev=>!prev);
    }
    const onDropDownStatus = () => {
      setShowStatusOptions(prev=>!prev);
      setRotate(prev=>!prev);
  }
    const onSelect = (value) => {
        setSelectedOption(value);
        setPortfolio(prev=>{
          return {...prev,leadership_type:value}
        })
        setRotate(false);
        setShowOptions(false);
    }
    const onSelectStatus = (value) => {
      setSelectedStatusOption(value);
      setPortfolio(prev=>{
        return {...prev,is_active:value === "Active" ? true : false}
      })
      setRotateStatus(false);
      setShowStatusOptions(false);
  }
    const handleTitleChange = (event) => {
      setPortfolio(prev=>{
        return {...prev,title:event.target.value}
      });
    }

    const editPortfolio = () => {
      const values = pick(portfolio,["title","leadership_type","hall","is_active"]);
      updatePortfolio({values,id:portfolio.id},{
        onSuccess:(data)=>{
          toast.success("Portfolio updated",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          queryClient.invalidateQueries(["leadership-portfolios"])
          queryClient.invalidateQueries(["executive-leadership-portfolios",hall?.hallInfo.id])
          queryClient.invalidateQueries(["management-leadership-portfolios",hall?.hallInfo.id]);
            
          setLeadershipStates(prev=>{return {...prev,editPortofolio:false,portofolio:{}}});
        },
        onError:(error)=>{
          toast.error("Could not update portfolio",{autoClose:2000,hideProgressBar:true,position:"top-center"});
        }
      })
    }
  return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
      style={{ zIndex: 300 }}
    >
      <div className="w-[30%] pb-5 bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
          <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>{setLeadershipStates(prev=>{return {...prev,editPortofolio:false,portofolio:{}}})}}/>
        <div className="h-[2.5rem] flex justify-between px-4 items-center">
          <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">Edit Portofolio</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="flex flex-col items-center h-[75%] pt-5">
        <div className="flex gap-10 mb-5 items-center">
            <label htmlFor="title" className="text-primaryTextLight dark:text-primaryTextDark">Title</label>
            <input placeholder="Portofolio Title" id="title" className="placeholder:font-thin dark:border-dark-gray px-4 rounded-md h-10 border focus:outline-none w-[15rem] text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" value={portfolio.title} onChange={handleTitleChange}/>
        </div>
        <div className="flex gap-9 items-center mb-5">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">Type</label>
            <div className="flex items-center gap-2 rounded-md h-10 border dark:border-dark-gray bg-cardBackgroundLight dark:bg-cardBackgroundDark relative w-[15rem]">
            <input placeholder="Select Type" id="title" className="placeholder:font-thin focus:outline-none text-primaryTextLight dark:text-primaryTextDark pl-4 w-[13rem] bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDown} value={selectedOption || ""} onChange={(event)=>setSelectedOption(event.target.value)}/>
            <div className="flex items-center gap-2">
            {
              theme?.value == "light" ? <SelectIcon width="12" height="12" onClick={onDropDown} className={
                "cursor-pointer " +
                (rotate ? "rotate-180 delay-200" : "delay-200")
                }/>:
                <SelectIconDark width="12" height="12" onClick={onDropDown} className={
                  "cursor-pointer " +
                  (rotate ? "rotate-180 delay-200" : "delay-200")
                  }/>
            }
            </div>
                {showOptions && 
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[15rem] absolute top-10 z-10 max-h-36 overflow-auto">
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" onClick={()=>onSelect("Executive")}>
                        <span>{"Executive"}</span>
                    </div>
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" onClick={()=>onSelect("Management")}>
                        <span>{"Management"}</span>
                    </div>
                </div>
                }
            </div>
        </div>
        <div className="flex gap-7 items-center">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">Status</label>
            <div className="flex items-center gap-2 rounded-md h-10 border dark:border-dark-gray bg-cardBackgroundLight dark:bg-cardBackgroundDark relative w-[15rem]">
            <input placeholder="Select Type" id="title" className="placeholder:font-thin focus:outline-none text-primaryTextLight dark:text-primaryTextDark pl-4 w-[13rem] bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDownStatus} value={selectedStatusOption || ""} onChange={(event)=>setSelectedStatusOption(event.target.value)}/>
            <div className="flex items-center gap-2">
            {
              theme?.value == "light" ? <SelectIcon width="12" height="12" onClick={onDropDownStatus} className={
                "cursor-pointer " +
                (rotatestatus ? "rotate-180 delay-200" : "delay-200")
                }/>:
                <SelectIconDark width="12" height="12" onClick={onDropDownStatus} className={
                  "cursor-pointer " +
                  (rotatestatus ? "rotate-180 delay-200" : "delay-200")
                  }/>
            }
            </div>
                {showStatusOptions && 
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[15rem] absolute top-10 z-10 max-h-36 overflow-auto">
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" onClick={()=>onSelectStatus("Active")}>
                        <span>{"Active"}</span>
                    </div>
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" onClick={()=>onSelectStatus("Inactive")}>
                        <span>{"Inactive"}</span>
                    </div>
                </div>
                }
            </div>
        </div>
        <button className="bg-linear-grad text-white px-4 rounded-md mt-5 text-xl" onClick={editPortfolio}>{!isLoading ? "Save" : <BeatLoader size={10} color="white"/>}</button>

        </div>
      </div>
    </div>
  );
};

export default EditPortofolio;
