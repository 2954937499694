import { useGetNewHallMembers } from "../../hooks/membersHooks";
import NewMemberCard from "./NewMemberCard";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";
import { ClipLoader } from "react-spinners";

const NewMembersList = ({setShowNewMember, setNewMember,searching,searchedMembers}) => {
    const {data:newMembers,isLoading,isFetching} = useGetNewHallMembers();
    if(isLoading){
        return (
            <div className="h-36 w-full flex justify-center mt-10">
                <ClipLoader size={80} color="#F63E49"/>
            </div>
        )
    }
    if(!isLoading && !isFetching && newMembers?.data.length === 0 || (searching && searchedMembers?.length === 0)){
        return (
            <div className="flex items-center justify-center w-full h-full">
                <NoDataIcon height="400" width="400"/>
            </div>
        )
    }
    return (
        <div className="flex flex-wrap gap-5 mt-4 pl-8 pb-4">
            {!searching ? 
            newMembers?.data?.map(newmember=>{
                return (
                    <NewMemberCard member={newmember} key={newmember.id} setShowNewMember={setShowNewMember} setNewMember={setNewMember}/>
                )
            }) :
            searchedMembers?.map(newmember=>{
                return (
                    <NewMemberCard member={newmember} key={newmember.id} setShowNewMember={setShowNewMember} setNewMember={setNewMember}/>
                )
            })
            
        }
        </div>
    )
}

export default NewMembersList;