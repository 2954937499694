import defaultProfile from "../../assets/images/default-profile.png";

const SmallProfile = ({url}) => {
  return (
    <div className="w-14 h-14 mr-4 shrink-0">
      <img
        src={url || defaultProfile}
        alt="Profile"
        height="100%"
        width="100%"
        className="rounded-full h-14 w-14"
      />
    </div>
  );
};

export default SmallProfile;