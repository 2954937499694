import { useContext, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import { memberContext } from "../../contexts/memberContext";
import {
  useBlockHallMember,
  useGetBlacklistReasons,
} from "../../hooks/membersHooks";
import { useQueryClient } from "react-query";
import OptionSelector from "../posts-trending/OptionSelector";

const BlockMemberModal = () => {
  const [reason, setReason] = useState("");
  const { mutate: blockMember, isLoading } = useBlockHallMember();
  const { setMemberOptions, memberOptions } = useContext(memberContext);
  const { hall } = useContext(hallContext);
  const { data: blacklistReasons } = useGetBlacklistReasons();
  const queryClient = useQueryClient();

  const onBlockMember = () => {
    if (reason.length !== 0) {
      blockMember(
        {
          hall: hall?.hallInfo.id,
          user: memberOptions.blockMember.id,
          reason: reason,
        },
        {
          onSuccess: (data) => {
            setMemberOptions((prev) => {
              return { ...prev, block: false, blockMember: "" };
            });
            queryClient.invalidateQueries("blocked-hall-members");
            toast.success("Member blocked", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
          onError: (error) => {
            toast.error("Could not block member", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
        }
      );
    } else {
      toast.error("Reason cannot be empty", {
        autoClose: 2000,
        position: "top-center",
        hideProgressBar: true,
      });
    }
  };
  return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.2)]"
      style={{ zIndex: "9999" }}
    >
      <div className="flex flex-col bg-[#f6f6f6] w-[22rem] h-60 p-4 rounded-md gap-2 dark:bg-[#121212] relative">
          <CloseIcon
            width="25"
            height="25"
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
            onClick={() => {
              setMemberOptions((prev) => {
                return { ...prev, block: false, blockMember: "" };
              });
            }}
          />
        <div className="flex justify-between">
          <label htmlFor="reason" className="text-primaryTextLight dark:text-primaryTextDark">
            Why are you blocking this member?
          </label>
        </div>
        <div className="w-full h-[2.5rem] rounded-md mt-2">
          <OptionSelector
            options={blacklistReasons?.data?.results}
            name="reason"
            onChange={(name, value) => setReason(value)}
          />
          </div>

        <div className="w-full flex justify-end gap-4 mt-auto">
          <button
            className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md cursor-pointer"
            onClick={() => {
              setMemberOptions((prev) => {
                return { ...prev, block: false, blockMember: "" };
              });
            }}
          >
            Cancel
          </button>
          <button
            className="bg-[#F63E49] text-white py-1 px-2 rounded-md cursor-pointer"
            onClick={onBlockMember}
          >
            {!isLoading ? "Block" : <BeatLoader color="white" size={10} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlockMemberModal;
