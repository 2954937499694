import moment from "moment";
import ProgressiveImage from "react-progressive-graceful-image";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HallConnectLogo } from "../../assets/icons/hall-connect-logo.svg";
import {ReactComponent as HallNewsIndicator} from "../../assets/icons/hall-news-indicator.svg";

const NewsCard = ({ news }) => {
  const nav = useNavigate()
  return (
    <div className="flex flex-col w-[18rem] h-[20rem] bg-white dark:bg-cardBackgroundDark rounded-md pb-3 cursor-pointer" onClick={()=>nav(`${news.id}`)}>
      <ProgressiveImage
        src={
          news.pictures.length > 0 ? news.pictures?.find((picture) => picture.is_leading === true)?.image ||
          news.pictures[0]?.image : ""
        }
        placeholder=""
      >
        {(src, loading) => {
          return loading ? (
            <div
              className="h-[60%] w-full loader rounded-md"
              style={{ backgroundColor: "#777" }}
            />
          ) : (
            <img
              src={src}
              width={"100%"}
              height="100%"
              className="w-full h-[60%] rounded-t-md object-cover"
            />
          );
        }}
      </ProgressiveImage>
      <div className="w-full px-5 mt-5">
        <h2 className="text-primaryTextLight dark:text-primaryTextDark text-base leading-5 font-medium line-clamp-3 opacity-80 h-16 break-words">
          {news.title}
        </h2>
        <div className="flex gap-2 items-center text-xs text-[#7A7D81] dark:text-primaryTextDark font-light mt-5">
            {
              !news.is_hall_buzz ? <HallConnectLogo width={"15"} height="15"/> : <HallNewsIndicator width={"15"} height="15"/>
            }                          <span className="max-w-[50%] line-clamp-1">{!news.is_hall_buzz ? "LinkLounge" : news.created_by_useraccount.fullname}</span>

          <span className="inline-block w-[3px] h-[3px] rounded-full bg-dot-gray dark:bg-primaryTextDark"></span>
          <span className="">{moment(news.date_created).fromNow()}</span>
        </div>
      </div>{" "}
    </div>
  );
};

export default NewsCard;
