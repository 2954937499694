import { useQueryClient } from "react-query";
import { cancelRequest } from "../../api/hall";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import  {BeatLoader} from 'react-spinners'
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useCheckProfileChangeRequestExists } from "../../hooks/hallHooks";
import { hallContext } from "../../contexts/hallContext";

const ProfileRequest = ({onClose,profileRequestData,setIsChangeRequested}) => {
    const [loading,setIsLoading] = useState(false)
    const {hall} = useContext(hallContext);
    const {data} = useCheckProfileChangeRequestExists(hall?.hallInfo.id)
    const query = useQueryClient()
    const onCancelRequest = async () => {
        setIsLoading(true)
        const response = await cancelRequest(data?.data?.change_request.id)
        if(response.error){
            toast.error("Could not cancel request",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            query.invalidateQueries("check-profile-request");
            toast.success("Change request canceled",{autoClose:2000,hideProgressBar:true,position:"top-center"})
            setIsChangeRequested(false);
            onClose();
        }
        setIsLoading(false);
    }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:200}}>
        <section className="w-[30%] min-h-[200px] pb-6 bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={onClose}/>
            <div className="flex justify-between items-center px-4 py-3">
                <span className="font-semibold text-lg">Hall Info Change Request</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full px-4 py-4 h-full flex items-center justify-center">
                <div className="flex flex-col gap-5 items-center w-full h-full">
                {data?.data?.change_request?.change_tables?.map(request=>{
                    if(request.item_to_change === "Name"){
                        return (
                            <div className="flex flex-col w-[70%]" key={request.id}>
                                <span className="mb-2 font-medium">Name</span>
                                <div className="flex gap-4 ml-6"><span>Old :</span><span>{request.old_value}</span></div>
                                <div className="flex gap-3 ml-6"><span>New :</span><span>{request.new_value}</span></div>
                            </div>
                        )
                    }else if(request.item_to_change === "Nick Name"){
                        return (
                            <div className="flex flex-col w-[70%]" key={request.id}>
                                <span className="mb-2 font-medium">Nickname</span>
                                <div className="flex gap-4 ml-6"><span>Old :</span><span>{request.old_value}</span></div>
                                <div className="flex gap-3 ml-6"><span>New :</span><span>{request.new_value}</span></div>
                            </div>
                        )
                    }else if(request.item_to_change === "Collective Name"){
                        return (
                            <div className="flex flex-col w-[70%]">
                            <span className="mb-2 font-medium">Collective Name</span>
                            <div className="flex gap-4 ml-6"><span>Old :</span><span>{request.old_value}</span></div>
                            <div className="flex gap-3 ml-6"><span>New :</span><span>{request.new_value}</span></div>
        
                        </div>
                        )
                    }
                })}
                </div>
            </div>
            <div className="w-full text-center">
                <button className="bg-filter-grad text-primaryTextLight dark:text-primaryTextDark w-[70%] py-2 rounded-sm font-medium shadow-4xl" onClick={onCancelRequest}>{!loading ? "CANCEL REQUEST":<><BeatLoader color="#fff" size={10}/></>}</button>
            </div>
        </section>
        </div>
    )
}

export default ProfileRequest