import { useContext, useEffect, useRef, useState } from "react";
import {ReactComponent as MenuIcon} from "../../assets/icons/menu-icon.svg";
import {ReactComponent as MenuIconDark} from "../../assets/icons/menu-icon-dark.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import LeaderContextMenu from "./LeaderContextMenu";
import { themeContext } from "../../contexts/themeContext";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as HallConnectIconDark } from "../../assets/icons/hall-connect-icon-dark.svg";

const ManagementHistoryCard = ({executive,leaderCategory,linked_account}) => {
  const {theme} = useContext(themeContext);
  const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
  const [showContextMenu,setShowContextMenu] = useState(false);
  const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });  return (
    <div className="w-40 border dark:border-dark-gray h-44 py-4 rounded-xl flex px-2 relative">
        <div className="flex flex-col items-center w-[90%] justify-center">

      <div className="w-24 h-24 rounded-md mx-auto relative">
        <img
          src={
            executive.picture || defaultProfile
          }
          alt="Profile"
          width="100%"
          height="100%"
          className="h-full w-full object-cover rounded-lg"
        />
        {
          linked_account && 
          <div className="absolute flex items-center justify-center bottom-[2px] right-[2px] w-5 h-5 rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={()=>setLeadershipStates(prev=>{return {...prev,showLinkedAccount:true,linkedAccount:{...linked_account}}})}>
            <HallConnectIconDark width={25} height={25}/>    
          </div>
        }
      </div>
      <div className="flex flex-col text-sm text-primaryTextLight text-center dark:text-primaryTextDark mt-auto items-center">
        <span className="font-semibold">{executive.name}</span>
        <span className="text-xs">{`${executive.start_date.slice(0,4)}/${executive.end_date?.slice(0,4) || "-"}`}</span>
      </div>
        </div>
      {/* <div className="h-full ml-auto flex cursor-pointer">
        {
          theme?.value == "light" ?
          <MenuIcon width="15" height="15" onClick={()=>setShowContextMenu(true)} />

          :       <MenuIconDark width="15" height="15" onClick={()=>setShowContextMenu(true)} />

        }
      </div>
      {
                showContextMenu &&
            <div className="absolute right-[0px] top-[10px] z-10" ref={ref}>
                <LeaderContextMenu leader={executive} leaderCategory={leaderCategory} disableChange={true}/>
            </div>
            } */}
    </div>
  );
};

export default ManagementHistoryCard;
