import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji-icon.svg";
import {ReactComponent as EmojiIconDark} from "../../assets/icons/emoji-icon-dark.svg";
import {ReactComponent as AddMoreImagesIcon} from "../../assets/icons/add-more-images-icon.svg";
import {ReactComponent as LeftIcon} from "../../assets/icons/left-icon.svg";
import {ReactComponent as RightIcon} from  "../../assets/icons/right-icon.svg";
import { useCallback, useContext, useState } from "react";
import { createMomentContext } from "../../contexts/createMomentContext";
import SelectPrivacy from "./SelectPrivacy";
import { useDropzone } from "react-dropzone";
import { generateRandomId } from "../../helpers/generateRandomId";
import { toast } from "react-toastify";
import EmojiPicker from "../EmojiPicker";
import { themeContext } from "../../contexts/themeContext";



const EditMomentPicture = ({onClose,showModal}) => {
    const {theme} = useContext(themeContext)
    const {momentData,setMomentData} = useContext(createMomentContext);
    const [text,setText] = useState(momentData.momentPictures[0].description);
    const [previewIndex,setPreviewIndex] = useState(0);
    const [privacy,setPrivacy] = useState(momentData.momentPictures[0].privacy);
    const [showEmoji, setShowEmoji] = useState(false);
    const onEmojiClick = (emoji) => {
        const picture = momentData.momentPictures[previewIndex];
        picture.description = text + emoji.char;
        setMomentData(prev=>{
            prev.momentPictures.splice(previewIndex,1,picture)
            return {...prev,momentPictures:prev.momentPictures}
        });
        setText(text + emoji.char);
        };

    const onClickPictureThumbnail = (index) => {
        setText(momentData.momentPictures[index].description)
        setPrivacy(momentData.momentPictures[index].privacy)
        setPreviewIndex(index);
    }
    const onClickNext = () => {
        if(previewIndex < momentData.momentPictures.length - 1){
            setText(momentData.momentPictures[previewIndex+1].description)
            setPrivacy(momentData.momentPictures[previewIndex+1].privacy)
            setPreviewIndex(prev=>prev + 1);
        }
    }
    const onClickPrevious = () => {
        if(previewIndex > 0){
            setText(momentData.momentPictures[previewIndex-1].description)
            setPrivacy(momentData.momentPictures[previewIndex-1].privacy)
            setPreviewIndex(prev=> prev - 1);
        }
    }
    const handleTextChange = (event) => {
        const picture = momentData.momentPictures[previewIndex];
        picture.description = event.target.value;
        setMomentData(prev=>{
            prev.momentPictures.splice(previewIndex,1,picture)
            return {...prev,momentPictures:prev.momentPictures}});
        setText(event.target.value);
    }
    const onDeleteImage = (id) => {
        setMomentData(prev=>{ 
            return {...prev,momentPicturesPreview:prev.momentPicturesPreview.filter(picture=>picture.id !== id),momentPictures:prev.momentPictures.filter(picture=>picture.id !== id)}
          });
        if(previewIndex > 0){
            setPreviewIndex(prev => prev - 1)
        }
    }

    const onChangeMomentPicturePrivacy = (value) => {
        const picture = momentData.momentPictures[previewIndex];
        picture.privacy = value;
        setMomentData(prev=>{
            prev.momentPictures.splice(previewIndex,1,picture)
            return {...prev,momentPictures:prev.momentPictures}});
            setPrivacy(value)
    }

    const onDropAdditionalFiles = useCallback((acceptedFiles) => {
        const images = [];
        if (acceptedFiles) {
          for (const file of acceptedFiles) {
            const objectUrl = URL.createObjectURL(file);
            const id = generateRandomId();
            images.push({ image: objectUrl, id, name:file.name,isLoading:false,isUploaded:false,reUpload:false });
            setMomentData(prev=>{ return {...prev,momentPictures:[...prev.momentPictures,{image:file,description:"",id,privacy:"Public"}]}})
          }
          setMomentData(prev=>{return {...prev,momentPicturesPreview:[...prev.momentPicturesPreview,...images],preview:true}});
        }
      });
    
      const { getRootProps:additionalRootProps, getInputProps:additionalInputProps, inputRef:additionalInputRef, acceptedFiles:additionalAcceptedFiles } = useDropzone({
        onDropAccepted:onDropAdditionalFiles,
        onDropRejected:()=>toast.error("Drag 'n' drop image files",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
        accept: {
          "image/jpeg": [".jpeg", ".jpg"],
          "image/png": [".png"],
          "image/webp": [".webp"],
        },
      });
    
    return (
        // <div className={`modal${showModal ? '' : ' modal-hidden'}`}>
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)] " style={{zIndex:300}}>
            <section className="w-[35%] bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md relative px-3 flex flex-col">
                {/* <div className="absolute top-3 right-3" style={{zIndex:400}}>
                    <CloseIcon
                    width="20"
                    height="20"
                    onClick={onClose}
                    className="cursor-pointer"
                    />
                </div> */}
                <div className="max-h-full w-full flex relative pt-3">
                    <button className="absolute top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center" style={{zIndex:400}}>
                        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
                        <LeftIcon style={{zIndex:450}} height="20" width="20" onClick={onClickPrevious}/>
                    </button>
                    <div className="max-h-full w-full m-auto relative">
                    {momentData.momentPictures.length > 1 && <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onDeleteImage(momentData.momentPicturesPreview[previewIndex].id)}
                    className="cursor-pointer absolute top-3 right-3"
                    />}
                    <img src={momentData.momentPicturesPreview[previewIndex].image} alt="Moment Picture" width="100%" height="100%" className="max-h-[30rem] max-w-full object-cover rounded-md mb-5"/>
                    </div>
                    <button className="absolute top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center" style={{zIndex:400}}>
                        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
                        <RightIcon style={{zIndex:450}} height="20" width="20" onClick={onClickNext}/>
                    </button>
                    <div className="w-full h-10 absolute bottom-5">
                        <div className="px-3 shadow-6xl rounded-md flex justify-between h-full bg-cardBackgroundLight dark:bg-cardBackgroundDark opacity-[80%]">
                            <input placeholder="Enter description of image" className="w-[90%] focus:outline-none text-primaryTextLight dark:text-primaryTextDark bg-transparent rounded-md" onChange={handleTextChange} value={text}/>
                            {
                                theme?.value == "light" ?
                                <EmojiIcon height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => setShowEmoji((prev) => !prev)}/>
:                            <EmojiIconDark height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => setShowEmoji((prev) => !prev)}/>

                            }
                        </div>
                        {showEmoji && (
                        <div className="absolute bottom-[100%] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{zIndex:400}}>
                            <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 items-center">
                    {
                        previewIndex+5 > momentData.momentPicturesPreview.length ?
                        momentData.momentPicturesPreview.slice(-5).map((picture,index)=>{
                            return (
                                <div className={"w-10 h-10 rounded-md" + (picture.id === momentData.momentPictures[previewIndex].id ? " border border-[#F63E49]" : "")} key={picture.id}>
                                    <img src={picture.image} alt="Moment Picture" width="100%" height="100%" className="h-full w-full object-cover rounded-md m-auto"/>
                                </div>
                            )
                        }) :
                        momentData.momentPicturesPreview.slice(previewIndex,previewIndex+5).map((picture,index)=>{
                            return (
                                <div className={"w-10 h-10 rounded-md" + (picture.id === momentData.momentPictures[previewIndex].id ? " border border-[#F63E49]" : "")} key={picture.id}>
                                    <img src={picture.image} alt="Moment Picture" width="100%" height="100%" className="h-full w-full object-cover rounded-md m-auto"/>
                                </div>
                            )
                        })

                    }
                    <div {...additionalRootProps({ className: "dropzone" })} className="">
                        <input className="input-zone" {...additionalInputProps({})}/>
                        <AddMoreImagesIcon width={20} height={20} className="cursor-pointer"/>
                    </div>
                </div>
                <div className="w-full flex justify-between mt-auto mb-4 pt-2">
                <SelectPrivacy setMoment={onChangeMomentPicturePrivacy} privacy={privacy}/>
                <button className="px-10 py-[0.125rem] mt-auto flex items-center bg-linear-grad text-white rounded-sm" onClick={onClose}>
                    Done
                </button>
                </div>
            </section>
        </div>
        // </div>
    )
};

export default EditMomentPicture;