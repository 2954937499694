import { useParams } from "react-router-dom";
import {ReactComponent as ChatInvite} from "../../assets/icons/chat-invite.svg";
import { openAppOrStore } from "../../helpers/chat_invite";
const ChatInvitePage = () => {
    const {inviteId} = useParams()
    return (
        <div className="font-inter h-full w-full flex justify-center items-center">
            <div className="mx-auto flex justify-center w-[80%] md:w-[60%] lg:w-[40%] items-center flex-col">
                <h1 className="inline-block text-primaryTextLight text-center">👋 Welcome to LinkLounge! 📝 You've been invited to join an awesome group. 🎉 To accept the invitation and become part of the fun, simply click the button below. Let's get started together! 🚀</h1>
                <ChatInvite width={400} height={400}/>
                <button onClick={()=>openAppOrStore(inviteId)} type="button" className="text-white text-lg py-3 border flex bg-linear-grad items-center rounded-md px-5 justify-between cursor-pointer border-none">
                    Join Group
                </button>
            </div>
        </div>
    )
}

export default ChatInvitePage