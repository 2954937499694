import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as SearchIconDark } from "../../assets/icons/search-icon-dark.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import ExecutiveCard from "./ExecutiveCard";
import {
  useGetCurrentExecutives,
  useGetCurrentTenure,
  useGetPastExecutives,
  useGetPastThreeYearExecutives,
  useGetPastTwoYearExecutives,
  useGetPastYearExecutives,
  useSearchExecutives,
} from "../../hooks/leadersHooks";
import {
  BehaviorSubject,
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  switchMap,
  merge,
  of,
} from "rxjs";
import { ReactComponent as NoDataIcon } from "../../assets/icons/no-data.svg";
import { BarLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import React, { useContext, useEffect, useState } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { themeContext } from "../../contexts/themeContext";
import { Link } from "react-router-dom";
import moment from "moment";

const ExecutivesList = ({
  addExecutives,
  searching,
  setSearching,
  showFilteredExecutives,
  filteredExecutives,
}) => {
  const { hall } = useContext(hallContext);
  const { data: currentTenure, isLoading: isFetchingCurrentTenure } =
    useGetCurrentTenure(hall?.hallInfo.id);
  const { data: currentExecutives, isLoading: currentLoading } =
    useGetCurrentExecutives(hall?.hallInfo?.id);
  const { data: pastExecutives, isLoading: pastLoading } = useGetPastExecutives(
    hall?.hallInfo?.id
  );
  const { setLeadershipStates } = useContext(leadershipContext);
  const [subject, setSubject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    data: searchResults,
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useSearchExecutives(searchTerm, hall?.hallInfo.id, (data) => {
    setLoading(false);
  });
  const { theme } = useContext(themeContext);

  const onChange = async (event) => {
    setSearchTerm(event.target.value);
    if (subject) {
      setLoading(true);
      setSearching(true);
      return subject.next(event.target.value);
    }
  };

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject("");
      setSubject(sub);
    } else {
      const observable = subject
        .pipe(
          map((s) => s.trim()),
          distinctUntilChanged(),
          debounceTime(200)
        )
        .subscribe(() => {
          refetch();
        });
      return () => {
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
  }, [subject]);

  useEffect(() => {
    if (searchTerm === "") {
      setSearching(false);
      setLoading(false);
    }
  }, [searchTerm]);

  return (
    <div className="flex flex-col w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md px-4 pt-4 pb-16">
      {
        <React.Fragment>
          {
            <React.Fragment>
              <div className="flex justify-between items-center text-primaryTextLight dark:text-primaryTextDark text-sm">
                {(!searching && !showFilteredExecutives) ? 
                <Link
                to="add-executives"
                onClick={() =>
                  setLeadershipStates((prev) => {
                    return { ...prev, selectedTenure: currentTenure?.data };
                  })
                }
              >
                <span className="underline text-lg font-semibold cursor-pointer">
                  {((!isFetchingCurrentTenure) && (currentTenure?.data?.id !== undefined)) &&
                    `${currentTenure?.data?.start_date.slice(0, 4)}/${Number(
                      currentTenure?.data?.end_date.slice(0, 4)
                    )}`}{" "}
                  <span className="font-semibold">{"(Current)"}</span>
                </span>
              </Link> :
              <span className="font-semibold text-lg">Executive Leaders</span>
              }
                <div className="flex gap-6">
                  <div className="rounded-md border dark:border-dark-gray h-8 flex flex-col items-center justify-between">
                    <div className="flex w-52 bg-cardBackgroundLight dark:bg-cardBackgroundDark items-center gap-2 h-8 px-2 rounded-md">
                      {theme?.value == "light" ? (
                        <SearchIcon width="15" height="15" />
                      ) : (
                        <SearchIconDark width="15" height="15" />
                      )}
                      <input
                        type={"search"}
                        placeholder="Search for Executive"
                        className="text-sm font-light h-full focus:outline-none w-full rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                        onChange={onChange}
                        value={searchTerm}
                      />
                    </div>
                    {loading && (
                      <BarLoader
                        color="#F63E49"
                        height={2}
                        width="95%"
                        className="w-full"
                      />
                    )}
                  </div>
                  <div
                    className="flex h-full items-center w-28 py-1 bg-filter-grad px-2 gap-5 rounded-md cursor-pointer"
                    onClick={() =>
                      setLeadershipStates((prev) => {
                        return { ...prev, filterExecutives: true };
                      })
                    }
                  >
                    <FilterIcon />
                    <span className="text-primaryTextLight">
                      {showFilteredExecutives ? "Filtered" : "Filter"}
                    </span>
                  </div>
                  <button
                    className="px-4 py-1 flex items-center bg-linear-grad text-white rounded-sm"
                    onClick={() =>
                      setLeadershipStates((prev) => {
                        return { ...prev, addTenure: true };
                      })
                    }
                  >
                    Add New
                  </button>
                </div>
              </div>
              {!searching && !showFilteredExecutives ? (
                <React.Fragment>
                  <div className="flex flex-wrap gap-4 mt-8 w-full px-[10%] mb-14">
                    {currentLoading && (
                      <div className="w-full h-36 flex items-center justify-center bg-cardBackgroundLight dark:bg-cardBackgroundDark">
                        <ClipLoader size={80} color="#F63E49" />
                      </div>
                    )}
                    {!currentLoading &&
                    currentExecutives?.data?.results?.length === 0 ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <NoDataIcon height="350" width="350" />
                      </div>
                    ) : (
                      currentExecutives?.data?.results?.map((executive) => {
                        return (
                          <ExecutiveCard
                            name={executive.name}
                            picture={executive.picture}
                            position={executive.portofolio.title}
                            key={executive.id}
                            leader={executive}
                            leaderCategory="Executive"
                            linked_account={executive.useraccount}
                            showMenu={true}
                            isCurrentExecutive={true}
                          />
                        );
                      })
                    )}
                  </div>
                  {!pastLoading &&
                    pastExecutives?.data?.results.length !== 0 &&
                    pastExecutives?.data?.results?.map((pastTenure) => {
                      return (
                        <div
                          className="w-full flex flex-col mb-14"
                          key={pastTenure.id}
                        >
                          <div className="flex justify-between items-center">
                            <span className="inline-block h-[2px] bg-primaryTextLight dark:bg-dark-gray opacity-[50%] w-[40%]"></span>
                            <span className="text-primaryTextLight dark:text-primaryTextDark font-normal text-lg">{`${pastTenure?.start_date.slice(
                              0,
                              4
                            )}/${Number(
                              pastTenure?.end_date.slice(0, 4)
                            )}`}</span>
                            <span className="inline-block h-[2px] bg-primaryTextLight dark:bg-dark-gray opacity-[50%] w-[40%]"></span>
                          </div>
                          <div className="flex flex-wrap gap-4 mt-8 w-full px-[10%]">
                          {
                            pastTenure?.portfolios.map(executive=>{
                              return (
                                <ExecutiveCard
                                  name={executive.name}
                                  picture={executive.picture}
                                  position={executive.portofolio.title}
                                  key={executive.id}
                                  leader={executive}
                                  leaderCategory="Executive"
                                  linked_account={executive.useraccount}
                                  showMenu={true}
                                  isCurrentExecutive={false}
                                />
                              );
                            })
                          }
                          </div>
                        </div>
                      );
                    })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {searching && (
                    <React.Fragment>
                      {searchResults?.pages[0]?.data?.results.length === 0 ? (
                        <div className="w-full flex items-center justify-center">
                          {" "}
                          <NoDataIcon height={400} width={400} />
                        </div>
                      ) : (
                        <InfiniteScroll
                          dataLength={searchResults?.pages?.length * 64 || 64}
                          next={fetchNextPage}
                          // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                          hasMore={hasNextPage}
                          loader={
                            <div className="flex w-full justify-center mt-5">
                              <ClipLoader size={30} color="#F63E49" />
                            </div>
                          }
                          scrollableTarget={"page-container"}
                          className="scrollbar-hide flex flex-wrap gap-4 w-full mt-8 px-[10%]"
                        >
                          {searchResults?.pages?.map((group, i) => {
                            return group?.data?.results?.map((executive) => {
                              return (
                                <ExecutiveCard
                                  name={executive.name}
                                  picture={executive.picture}
                                  position={executive.portofolio.title}
                                  key={executive.id}
                                  leaderCategory="Executive"
                                  showTenure={true}
                                  start_date={executive?.tenure?.start_date || executive?.start_date}
                                  end_date={executive?.tenure?.end_date || executive?.end_date}
                                  isCurrentExecutive={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}
                                  showMenu={true}
                                  leader={executive}
                                />
                              );
                            });
                          })}
                        </InfiniteScroll>
                      )}
                    </React.Fragment>
                  )}
                  {showFilteredExecutives && (
                    <React.Fragment>
                      {filteredExecutives?.pages[0]?.data?.results.length ===
                      0 ? (
                        <div className="w-full flex items-center justify-center">
                          {" "}
                          <NoDataIcon height={400} width={400} />
                        </div>
                      ) : (
                        <InfiniteScroll
                          dataLength={searchResults?.pages?.length * 64 || 64}
                          next={fetchNextPage}
                          // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                          hasMore={hasNextPage}
                          loader={
                            <div className="flex w-full justify-center mt-5">
                              <ClipLoader size={30} color="#F63E49" />
                            </div>
                          }
                          scrollableTarget={"page-container"}
                          className="scrollbar-hide flex flex-wrap gap-4 w-full mt-8 px-[10%]"
                        >
                          {filteredExecutives?.pages?.map((group, i) => {
                            return group?.data?.results?.map((executive) => {
                              return (
                                <ExecutiveCard
                                  name={executive.name}
                                  picture={executive.picture}
                                  position={executive.portofolio.title}
                                  key={executive.id}
                                  leaderCategory="Executive"
                                  showTenure={true}
                                  start_date={executive?.start_date || executive?.tenure?.start_date}
                                  end_date={executive.end_date || executive?.tenure?.end_date}
                                  isCurrentExecutive={moment(new Date()).isSame(moment(executive.start_date|| executive?.tenure?.start_date),'year') || moment(new Date()).isSame(moment(executive.end_date || executive?.tenure?.end_date),'year')}
                                  showMenu={true}
                                  leader={executive}
                                />
                              );
                            });
                          })}
                        </InfiniteScroll>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default ExecutivesList;
