import authClient from "./authAxiosInstance";

const getTrends = ({pageParam,queryKey}) => {
  return authClient.get(pageParam || `/trend/v1/trends/?hall=${queryKey[1]}`);
};

const getTrendThemes = () => {
  return authClient.get("/trend/v1/trend-theme/");
};

const getTrendTags = (pageParam) => {
  return authClient.get(pageParam);
};

const createTrend = (trendPost, contentType) => {
  return authClient.post("/trend/v1/trends/", trendPost, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

const getTrendLeaderBoard = ({pageParam}) => {
  return authClient.get(pageParam || "/trend/v1/trend-leaderboard/");
};

const getTrendStatistics = () => {
  return authClient.get("/trend/v1/trend-stats/");
};

const searchTrendsByText = async (pageParam) => {
  return authClient.get(pageParam);
};

const filterTrends = async (pageParam) => {
  return authClient.get(pageParam);
};

const turnOffTrendNotification = async (id) => {
  const response = await authClient.get(
    `/trend/v1/trends/${id}/turn-off-notification/`
  );
  if (response.status === 200) {
    return response.data;
  }
  return { error: "Could not turn of notification" };
};

const turnOnTrendNotification = async (id) => {
  const response = await authClient.get(
    `/trend/v1/trends/${id}/turn-off-notification/?turnon=true`
  );
  if (response.status === 200) {
    return response.data;
  }
  return { error: "Could not turn of notification" };
};

const deleteHallTrend = (id) => {
  return authClient.delete(`/trend/v1/trends/${id}`);
};

const hideTrend = async (id) => {
  const response = await authClient.get(`/trend/v1/trends/${id}/hide-trend/`);
  if (response.status === 200) {
    return response.data;
  }
  return {
    error: "Could not hide trend",
  };
};

const reportTrend = async (values) => {
  return authClient.post(`/trend/v1/report-trend/`, values);
};

const getReportTrendReason = () => {
  return authClient.get(`/trend/v1/report-trend-reason/`);
};

export {
  getTrends,
  getTrendThemes,
  getTrendTags,
  createTrend,
  getTrendLeaderBoard,
  getTrendStatistics,
  searchTrendsByText,
  filterTrends,
  turnOffTrendNotification,
  turnOnTrendNotification,
  deleteHallTrend,
  hideTrend,
  reportTrend,
  getReportTrendReason,
};
