import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import Banner from "../../components/hallpage/Banner";
import MomentsHeader from "../../components/moments/MomentsHeader";
import MomentInfo from "../../components/moments/MomentInfo";
import MomentImage from "../../components/moments/MomentImage";
import { useDeleteEntireMoment, useDeleteMomentPicture, useFilterMoments, useGetHallMoments } from "../../hooks/momentsHooks";
import React, { useContext, useEffect, useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import CreateMoment from "../../components/moments/CreateMoment";
import { ClipLoader } from "react-spinners";
import { useLogout } from "../../hooks/authHooks";
import { logoutContext } from "../../contexts/logoutContext";
import ConfirmModal from "../../components/ConfirmModal";
import { momentMenuContext } from "../../contexts/momentMenuContext";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import SetAsProfilePictureModal from "../../components/moments/SetAsProfilePicture";
import SetAsCoverPhotoModal from "../../components/moments/SetAsCoverPhoto";
import Lightbox from "yet-another-react-lightbox";
import { createMomentContext } from "../../contexts/createMomentContext";
import EditMomentPicture from "../../components/moments/EditMomentPicture";
import EditMomentTitle from "../../components/moments/EditMomentTitle";
import EditMomentPictureTitle from "../../components/moments/EditMomentPictureTitle";
import AddMomentPictures from "../../components/moments/AddMomentPictures";
import InfiniteScroll from "react-infinite-scroll-component";
import FilterMoments from "../../components/moments/FilterMoments";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";
import { Link, useNavigate } from "react-router-dom";
import { backgrounds } from "../../constants/backgrounds";
import produce from "immer";
import { data } from "autoprefixer";
import MomentsGallery from "../../components/moments/MomentsGallery";
import moment from "moment";
import ProgressiveImage from "react-progressive-graceful-image";
import flatten from "lodash.flatten";


const MomentsPage = () => {
  const { user_state } = useContext(userContext);
  const navigate = useNavigate();
  const [createMoments, setCreateMoments] = useState(false);
  const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
  const {momentData,setMomentData} = useContext(createMomentContext)
  const {logout,setLogout} = useContext(logoutContext);
  const [viewImages,setViewImages] = useState(false);
  const [images,setImages] = useState([]);
  const [filterMoments,setFilterMoments] = useState(false);
  const {hall} = useContext(hallContext);
  const [filter,setFilter] = useState({year:"",month:"",day:"",privacy:"",post_type:""});
  const [showFilteredMoments,setShowFilteredMoments] = useState(false);
  const [lightBoxImages,setLightBoxImages] = useState([]);
  const {data:filteredMoments,isRefetching:isFiltering,isLoading:isInitialFiltering,refetch,fetchNextPage:fetchNextFiltered,hasNextPage:hasMoreFilteredMoments} = useFilterMoments(hall?.hallInfo.id,filter);
  const [gallery,setGallery] = useState([]);
  const [selectedIndex,setSelectedindex] = useState(null);
  const [showGallery,setShowGallery] = useState(false);
  const [galleryIndexes,setGalleryIndexes] = useState({});
  const [filteredGallery,setFilteredGallery] = useState([]);
  const [filteredGalleryIndexes,setFilteredGalleryIndexes] = useState({});

  const queryClient = useQueryClient();
  const {onLogout} = useLogout();

  const {mutate:deleteMoment,isLoading:deletingMoment} = useDeleteEntireMoment();
  const {mutate:deleteMomentPicture,isLoading:deletingMomentPicture} = useDeleteMomentPicture();
  const {isLoading,data:moments,fetchNextPage,hasNextPage} = useGetHallMoments(user_state?.user?.user?.hall);

  const fetchLighBoxImages = () => {
    if(moments?.pages !== undefined){
          const images = []
          const indexes = {}
          moments?.pages?.map(page=>{
            page?.data?.results?.forEach((moment,index)=>{
              images.push({type:"title",title:moment.title,published_at:moment.published_at,background:backgrounds[index % backgrounds.length]})
                const id = `moment-${moment.id}`
                indexes[id] = images.length - 1
              images.push(...moment.pictures.map((img,imgIndex)=>{
                const id = `picture-${img.id}`;
                indexes[id] = images.length + imgIndex
              return {...img,type:"image"}
            }))
            })
          })
          setGallery(images);
          setGalleryIndexes(indexes);
          
    }
  }

  const fetchFilteredLighBoxImages = () => {
    if(filteredMoments?.pages !== undefined && showFilteredMoments !== false){
          const images = []
          const indexes = {}
          filteredMoments?.pages?.map(page=>{
            page?.data?.results?.forEach((moment,index)=>{
              images.push({type:"title",title:moment.title,published_at:moment.published_at,background:backgrounds[index % backgrounds.length]})
                const id = `moment-${moment.id}`
                indexes[id] = images.length - 1
              images.push(...moment.pictures.map((img,imgIndex)=>{
                const id = `picture-${img.id}`;
                indexes[id] = images.length + imgIndex
              return {...img,type:"image"}
            }))
            })
          })
          setFilteredGallery(images);
          setFilteredGalleryIndexes(indexes);
          
    }
  }

  const onDeleteMoment = () => {
      deleteMoment(momentMenu.momentId,{
          onSuccess:(data)=>{
              queryClient.invalidateQueries("hall-moments");
              setMomentMenu(prev=>{return {...prev,deleteEntireMoment:false,momentId:""}});
              toast.success("Moment delete successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"})
          },
          onError:(error)=>{
              toast.error("Failed to delete moment",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          }
      })
  }

  const onDeleteMomentPicture = () => {
      deleteMomentPicture(momentMenu.pictureId,{
          onSuccess:(data)=>{
              queryClient.invalidateQueries("hall-moments");
              setMomentMenu(prev=>{ return {...prev,deleteMomentPicture:false,pictureId:""}});
              toast.success("Picture deleted from this moment successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          },
          onError:(error)=>{
              toast.error("Failed to delete picture from moment",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          }
      })
  }

  useEffect(()=>{
    document.title = "LinkLounge | Hall Moments"
  },[])
  useEffect(()=>{
    let timeout;
    if(showFilteredMoments && filteredMoments?.pages[0]?.data?.results.length === 0 ){
      timeout = setTimeout(()=>{
        setShowFilteredMoments(false);
      },8000)
    }
    return ()=>clearTimeout(timeout);
  },[showFilteredMoments])

  useEffect(()=>{
    fetchLighBoxImages()
  },[moments?.pages])

  useEffect(()=>{
    fetchFilteredLighBoxImages()
  },[filteredMoments?.pages,showFilteredMoments])

  useEffect(()=>{
    let timeout
    if(showGallery && !showFilteredMoments && (gallery[selectedIndex].type == "title" || selectedIndex === 0)){
      timeout = setTimeout(()=>{
        setSelectedindex(prev=>prev+1);
      },4000)
    }else if(showGallery && showFilteredMoments && (filteredGallery[selectedIndex].type == "title" || selectedIndex === 0)){
      timeout = setTimeout(()=>{
        setSelectedindex(prev=>prev+1);
      },4000)
    }
    return ()=>clearTimeout(timeout);
  },[selectedIndex])
  return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto" id="moments">
        <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
            <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-50`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full mt-3 flex-col gap-1 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 pb-10">
            <MomentsHeader onCreateMoment={() => setCreateMoments(true)} onFilter={()=>setFilterMoments(true)} showFilteredMoments={showFilteredMoments} momentDetail={false}/>
            { !showFilteredMoments ?
            <React.Fragment>
            { (isLoading) ? 
              <div className="w-full flex items-center justify-center mx-auto"><ClipLoader size={80} color="#F63E49"/></div> :
              
            <InfiniteScroll
            dataLength={moments?.pages?.length * 64 || 64}
            next={fetchNextPage}
            // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
            hasMore={hasNextPage}
            loader={<div className="flex w-full justify-center mt-5">
            <ClipLoader size={30} color="#F63E49"/>
        </div>}            scrollableTarget={"moments"}
            className="scrollbar-hide flex flex-wrap gap-5 w-full"
            >
              {moments?.pages[0]?.data?.results.length === 0 ?
                <div className="w-full flex items-center justify-center mt-10">
                  <NoDataIcon height="400" width="400"/>
                </div>
              :
              moments?.pages?.map((group,i)=>{
                return group?.data?.results?.map((moment,index)=>{
                return (
                  <React.Fragment key={moment.id}>
                    <MomentInfo
                      date={moment.published_at}
                      text={moment.title}
                      index={index}
                      momentId={moment.id}
                      hallMoment={moment}
                      onClickMoment={()=>setShowGallery(true)}
                      galleryIndex={galleryIndexes && galleryIndexes[`moment-${moment.id}`]}
                      setSelectedIndex={setSelectedindex}
                    />
                    {
                    moment.pictures.length <= 4 ? moment.pictures.map((picture) => {
                      return (
                        <MomentImage key={picture.id} image={picture.image} pictureId={picture.id} picture={picture}  onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}}
                        momentId={moment.id} onClickImage={()=>setShowGallery(true)}  setSelectedIndex={setSelectedindex}                                galleryIndex={galleryIndexes && galleryIndexes[`picture-${picture.id}`]}

                        />
                      );
                    }):
                    <React.Fragment>
                    {moment.pictures.slice(0,3).map(picture=>{
                      return (
                        <MomentImage key={picture.id} image={picture.image} pictureId={picture.id} picture={picture}  onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}}
                        momentId={moment.id}
                        onClickImage={()=>setShowGallery(true)}
                        galleryIndex={galleryIndexes && galleryIndexes[`picture-${picture.id}`]}
                        setSelectedIndex={setSelectedindex}

                        />
                      );
                    })
                    }
                    <div className="relative">
                      <MomentImage image={moment.pictures[3].image} pictureId={moment.pictures[3].id} picture={moment.pictures[3]} onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}} momentId={moment.id}/>
                        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-[60%] flex items-center justify-center z-10 text-white rounded-md cursor-pointer" onClick={()=>{localStorage.setItem("momentBackground",JSON.stringify(backgrounds[index % backgrounds.length]));setMomentMenu(prev=>{return {...prev,background:backgrounds[index % backgrounds.length]}});navigate(`/moments/${moment.id}`)}}>+{moment.pictures.length - 4}</div>
                    </div>
                    </React.Fragment>
                    }
                  </React.Fragment>
                );
              })})}
              </InfiniteScroll>
            }
            </React.Fragment> :
            <React.Fragment>
              { (isFiltering || isInitialFiltering) ? 
              <div className="w-full flex items-center justify-center mx-auto"><ClipLoader size={80} color="#F63E49"/></div> :
              
            <InfiniteScroll
            dataLength={filteredMoments?.pages?.length * 64 || 64}
            next={fetchNextFiltered}
            // style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
            hasMore={hasMoreFilteredMoments}
            loader={<div className="flex w-full justify-center mt-5">
            <ClipLoader size={30} color="#F63E49"/>
        </div>}            scrollableTarget={"moments"}
            className="scrollbar-hide flex flex-wrap gap-5 w-full"
            >
              {filteredMoments?.pages[0]?.data?.results.length === 0 ?
                <div className="w-full flex items-center justify-center mt-10">
                  <NoDataIcon height="400" width="400"/>
                </div>
              :
              filteredMoments?.pages?.map((group,i)=>{
                return group?.data?.results?.map((moment,index)=>{
                            return (
                              <React.Fragment key={moment.id}>
                              <MomentInfo
                                date={moment.published_at}
                                text={moment.title}
                                index={index}
                                momentId={moment.id}
                                hallMoment={moment}
                                onClickMoment={()=>setShowGallery(true)}
                                galleryIndex={filteredGalleryIndexes && filteredGalleryIndexes[`moment-${moment.id}`]}
                                setSelectedIndex={setSelectedindex}
                              />
                              {
                              moment.pictures.length <= 4 ? moment.pictures.map((picture) => {
                                return (
                                  <MomentImage key={picture.id} image={picture.image} pictureId={picture.id} picture={picture}  onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}}
                                  momentId={moment.id}
                                  onClickImage={()=>setShowGallery(true)}
                        galleryIndex={filteredGalleryIndexes && filteredGalleryIndexes[`picture-${picture.id}`]}
                        setSelectedIndex={setSelectedindex}

                                  />
                                );
                              }):
                              <React.Fragment>
                              {moment.pictures.slice(0,3).map(picture=>{
                                return (
                                  <MomentImage key={picture.id} image={picture.image} pictureId={picture.id} picture={picture}  onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}}
                                  momentId={moment.id}
                                  onClickImage={()=>setShowGallery(true)}
                        galleryIndex={filteredGalleryIndexes && filteredGalleryIndexes[`picture-${picture.id}`]}
                        setSelectedIndex={setSelectedindex}

                                  />
                                );
                              })
                              }
                              <div className="relative">
                                <MomentImage image={moment.pictures[3].image} pictureId={moment.pictures[3].id} picture={moment.pictures[3]} onViewImage={()=>{setImages(moment.pictures);setViewImages(true)}} momentId={moment.id}/>
                                  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-[60%] flex items-center justify-center z-10 text-white rounded-md cursor-pointer" onClick={()=>{setMomentMenu(prev=>{return {...prev,background:backgrounds[index % backgrounds.length]}});navigate(`/moments/${moment.id}`)}}>+{moment.pictures.length - 4}</div>
                              </div>
                              </React.Fragment>
                              }
                            </React.Fragment>
                );
              })})}
              </InfiniteScroll>
            }
            </React.Fragment>
          }
          </section>
        </main>
      </div>
      {createMoments && 
    <div className="absolute h-full w-full z-50">
    <CreateMoment onClose={()=>setCreateMoments(false)}/>
    </div>
    }
    {
      filterMoments && 
      <FilterMoments setFiltered={setFilterMoments} onClose={()=>setFilterMoments(false)} filterMoments={refetch} setFilter={setFilter} filter={filter} setShowFilteredMoments={setShowFilteredMoments}/>
    }
    {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        momentMenu.deleteEntireMoment &&
        <ConfirmModal question={"Do you want to delete all the pictures in this moment"} confirmAction={"Delete"} onCancel={()=>setMomentMenu(prev=>{return {...prev,deleteEntireMoment:false,momentId:""}})} onConfirm={onDeleteMoment} isLoading={deletingMoment}/>
      }
      {
        momentMenu.deleteMomentPicture &&
        <ConfirmModal question={"Do you want to delete this picture from the moments"} confirmAction={"Delete"} onCancel={()=>{setMomentMenu(prev=>{return {...prev,deleteMomentPicture:false,pictureId:""}})}} onConfirm={onDeleteMomentPicture} isLoading={deletingMomentPicture}/>
      }
      {
        momentMenu.setAsProfilePicture && 
        <SetAsProfilePictureModal/>
      }
      {
        momentMenu.setAsCoverPhoto &&
        <SetAsCoverPhotoModal/>
      }
      {
        momentMenu.editMomentTitle && 
        <EditMomentTitle/>
      }
      {
        momentMenu.editMomentPictureTitle && 
        <EditMomentPictureTitle/>
      }
      {
        momentMenu.addMomentPictures && 
        <div className="absolute h-full w-full z-50">
          <AddMomentPictures/>
        </div>
      }
      {
        momentData.editMomentPictures &&
        <EditMomentPicture onClose={()=>setMomentData(prev=>{return {...prev,editMomentPictures:false}})}/>
      }
      {
          <MomentsGallery onClose={()=>setShowGallery(false)} moments={gallery} show={showGallery  && !showFilteredMoments} selectedIndex={selectedIndex} setSelectedIndex={setSelectedindex}/>
      }
      {
        <MomentsGallery onClose={()=>setShowGallery(false)} moments={filteredGallery} show={showGallery  && showFilteredMoments} selectedIndex={selectedIndex} setSelectedIndex={setSelectedindex}/>
      }
    </PageContainer>
  );
};

export default MomentsPage;
