import SmallProfile from "../hallpage/SmallProfile";
import { useCallback, useState } from "react";
import { ReactComponent as EmojiIcon } from "../../assets/icons/emoji-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-cloud-icon-dark.svg";
import { ReactComponent as UploadIconDark } from "../../assets/icons/upload-cloud-icon.svg";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
// import Datepicker from "../hallpage/Datepicker";
// import ScheduleSelector from "../posts-trending/ScheduleSelector";
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
// import SelectPrivacy from "./SelectPrivacy";
// import {
//   useCreateHallMoment,
//   useCreateMomentPicture,
// } from "../../hooks/momentsHooks";
import { useQueryClient } from "react-query";
// import { ReactComponent as CleanImagesIcon } from "../../assets/icons/clean-images.svg";
// import { ReactComponent as EditImagesIcon } from "../../assets/icons/edit-images.svg";
// import { ReactComponent as AddImagesIcon } from "../../assets/icons/add-images.svg";
import { generateRandomId } from "../../helpers/generateRandomId";
// import MomentPreview from "./MomentPreview";
// import { createMomentContext } from "../../contexts/createMomentContext";
import pick from "lodash.pick";
// import { momentMenuContext } from "../../contexts/momentMenuContext";
import { BeatLoader } from "react-spinners";
import { addNewsContext } from "../../contexts/addNewsContext";
import NewsPicturesPreview from "./NewsPicturesPreview";
import { themeContext } from "../../contexts/themeContext";

const AddNewsPictures = ({ open,setAddPictures }) => {
  const {theme} = useContext(themeContext);
  const { hall } = useContext(hallContext);
  const {news,setNews} = useContext(addNewsContext);
  const [loading,setLoading] = useState(false);
//   const [moment, setMoment] = useState({
//     hall: hall?.hallInfo.id,
//     title: "",
//     privacy: "Public",
//     pictures: [],
//   });
//   const { mutateAsync: createMomentPicture, isLoading: isUploadingPicture } =
//     useCreateMomentPicture();
//   const queryClient = useQueryClient();

  const onDrop = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      const files = Array.from(acceptedFiles)
      for (let i=0;i < files.length;i++) {
        const objectUrl = URL.createObjectURL(files[i]);
        const id = generateRandomId();
        images.push({
          image: objectUrl,
          id,
          name: files[i].name,
          uploadProgress:{},
          is_leading:i===0?true:false
        });
        setNews((prev) => {
          return {
            ...prev,
            pictures: [
              ...prev.pictures,
              { image: files[i], description: "",id,is_leading:i===0?true:false,buzz:"" },
            ]
          };
        });
      }
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: [...prev.previewPictures, ...images],
          preview: true,
        };
      });
    }
  });

  const { getRootProps, getInputProps, inputRef, acceptedFiles } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: () =>{
      toast.error("Drag 'n' drop image files", {
        autoClose: 2000,
        position: "top-center",
        hideProgressBar: true,
      })},
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onDropAdditionalFiles = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId();
        images.push({
          image: objectUrl,
          id,
          name: file.name,
          uploadProgress:{},
          is_leading:false
        });
        setNews((prev) => {
          return {
            ...prev,
            pictures: [
              ...prev.pictures,
              { image: file, description: "",id,is_leading:false,buzz:"" },
            ],
          };
        });
      }
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: [...prev.previewPictures, ...images],
          preview: true,
        };
      });
    }
  });

  const {
    getRootProps: additionalRootProps,
    getInputProps: additionalInputProps,
    inputRef: additionalInputRef,
    acceptedFiles: additionalAcceptedFiles,
  } = useDropzone({
    onDropAccepted: onDropAdditionalFiles,
    onDropRejected: () =>{
      toast.error("Drag 'n' drop image files", {
        autoClose: 2000,
        hideProgressBar: true,
        position: "top-center",
      })},
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onClearAdditionalImages = () => {
    // setPreview(false);
    setNews((prev) => {
      return {
        ...prev,
        pictures: [],
        previewPictures: [],
        preview: false,
      };
    });
    additionalAcceptedFiles.splice(0, additionalAcceptedFiles.length);
    additionalAcceptedFiles.length = 0;
    if (additionalInputRef.current !== null) {
      additionalInputRef.current.value = "";
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center relative z-50">
      <div className="absolute opacity-[50%] h-full w-full bg-black"></div>
      <div className="w-[40%] bg-backgroundLight dark:bg-cardBackgroundDark rounded-md pb-5 z-50 relative">
          <CloseIcon
            width="25"
            height="25"
            onClick={() => {
                setAddPictures(false);
                setNews(prev=>{return {...prev,preview:false}})
            //   onClearImages();
            //   onClearAdditionalImages();
            }}
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
          />
        <div className="w-full px-4 flex items-center justify-between py-3 border-b">
          <p className="text-primaryTextLight text-lg dark:text-primaryTextDark font-semibold">Add News Images</p>
        </div>
        <div className="px-4 w-full mt-5">
        {!news.preview ? (
          <div className="w-full h-48 px-4 bg-backgroundLight dark:bg-backgroundDark flex flex-col rounded-md relative">
            <div
              className="flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0"
              {...getRootProps({
                className:
                  "dropzone flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0",
              })}
            >
              <input className="input-zone" {...getInputProps({})} />
              <p
                className="dropzone-content w-[80%] h-full flex flex-col justify-center items-center cursor-pointer bg-[#DBE0E1] dark:bg-backgroundDark"
                onClick={open}
              >
                {
                  theme.value == "dark" ?
                  <UploadIconDark width="70" height="70" />
                  :
                  <UploadIcon width="70" height="70" />
                }
                <span
                  className="text-xs dark:text-primaryTextDark text-primaryTextLight"
                  // style={{ color: "rgba(54, 76, 102, 0.84)" }}
                >
                  Drop a file here or click to upload
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col">
            {/* <div className="flex gap-1">
              <div
                {...additionalRootProps({ className: "dropzone" })}
                className=""
              >
                <input className="input-zone" {...additionalInputProps({})} />
              </div>
              
            </div> */}
            <div className="min-h-[15rem] flex items-center justify-center">
              <NewsPicturesPreview />
            </div>
          </div>
        )}
        <div className="flex justify-center mt-6">
          <button
            className="bg-[#F63E49] text-white py-1 px-28 rounded-sm"
            onClick={()=>{setAddPictures(false);setNews(prev=>{return {...prev,preview:false}})}}
          >
            Save
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};
export default AddNewsPictures;
