import RelatedNewsCard from "./RelatedNewsCard"

const RelatedNews = ({relatedNews,isLoading}) => {
    return (
        <div className="flex flex-col gap-5">
            <h2 className="font-inter font-normal text-2xl dark:text-primaryTextDark leading-5 text-primaryTextLight opacity-80">Related News</h2>
            {relatedNews?.map(news=>{
                return (
                    <RelatedNewsCard key={news.id} news={news}/>
                )
            })}
        </div>
    )
}

export default RelatedNews;