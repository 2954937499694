import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
  addLeaderPortfolio,
  addLeadershipPortfolios,
  declineLeadershipChangeRequest,
  filterExecutives,
  getCurrentExecutives,
  getCurrentManagement,
  getLeadershipChangeRequest,
  getLeadershipPortfolios,
  getLeadershipPortfoliosByType,
  getPastExecutives,
  getPastManagement,
  getPastThreeYearExecutives,
  getPastTwoYearExecutives,
  getPastYearExecutives,
  linkAccount,
  registerLeadershipChangeRequest,
  searchLeaders,
  updateLeadershipPortfolio,
  updateUserPortfolio,
  createTenure,
  getCurrentTenure,
  deleteLeaderPortfolio,
  unlinkAccount,
  getHallTenures
} from "../api/leaders";
import { toast } from "react-toastify";

const useGetCurrentExecutives = (hall) => {
  return useQuery("current-hall-executives",()=>getCurrentExecutives(hall),
  {
    // onSuccess:data=>console.log(data)
    onError:(error)=>{
      toast.error("Failed to fetch current executives",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  }
  );
};

const useGetPastExecutives = (hall) => {
  return useQuery("past-hall-leaders",()=>getPastExecutives(hall),{
    // onSuccess:data=>{
    //   console.log(data);
    // },
    onError:error=>{
      toast.error("Failed to fetch past executives",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  })
}

const useGetPastYearExecutives = () => {
  return useQuery("past-hall-executives", getPastYearExecutives);
};

const useGetPastTwoYearExecutives = () => {
  return useQuery("past-hall-executives-2", getPastTwoYearExecutives);
};

const useGetPastThreeYearExecutives = () => {
  return useQuery("past-hall-executives-3", getPastThreeYearExecutives);
};

const useGetCurrentManagement = (hall) => {
  return useQuery("current-hall-management",()=>getCurrentManagement(hall),
  {
    // onSuccess:data=>console.log(data)
    onError:(error)=>{
      toast.error("Failed to fetch current management",{position:"top-center",hideProgressBar:true,autoClose:2000})
    }
  });
};

const useGetPastManagement = (hall) => {
  return useQuery("past-management",()=>getPastManagement(hall),{
    // onSuccess:(data)=>console.log(data)
    onError:error=>{
      toast.error("Failed to fetch past management",{position:"top-center",autoClose:2000,hideProgressBar:true})
    }
  });
};

const useSearchManagement = (search,hall,onSuccess) => {
  return useInfiniteQuery(["search-hall-management",hall],({pageParam})=>searchLeaders(pageParam || `/user/user-portfolio/?search=${search}&hall=${hall}&portofolio=Management`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useSearchExecutives = (search,hall,onSuccess) => {
  return useInfiniteQuery(["search-hall-executives",hall],({pageParam})=>searchLeaders(pageParam || `/user/user-portfolio/?search=${search}&hall=${hall}&portofolio=Executive`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useGetLeadershipPortfolios = (hall_id) => {
  return useInfiniteQuery(["leadership-portfolios",hall_id],getLeadershipPortfolios,{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:(data)=>console.log(data),
    onError:(error)=>{
      toast.error("Failed to fetch leadership portfolios",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  });
};

const useGetLeadershipPortfoliosByType = (hall_id,leadership_type) => {
    return useInfiniteQuery(["leadership-portfolios-by-type",hall_id],(params)=>getLeadershipPortfoliosByType(params,leadership_type),{
      getNextPageParam: (lastPage) => lastPage.data.next,
      // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
      // onSuccess:(data)=>console.log(data),
      // onError:(error)=>console.log(error)
    });
  }

const useGetExecutiveLeadershipPortfolios = (hall_id,leadership_type) => {
  return useInfiniteQuery(["executive-leadership-portfolios",hall_id],(params)=>getLeadershipPortfoliosByType(params,leadership_type),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:(data)=>console.log(data),
    // onError:(error)=>console.log(error)
  });
}

const useGetManagementLeadershipPortfolios = (hall_id,leadership_type) => {
  return useInfiniteQuery(["management-leadership-portfolios",hall_id],(params)=>getLeadershipPortfoliosByType(params,leadership_type),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:(data)=>console.log(data),
    // onError:(error)=>console.log(error)
  });
}

const useAddLeadershipPortfolios = () => {
  return useMutation(addLeadershipPortfolios,{
    mutationKey:"add-leadership-portfolios"
  })
}

const useUpdateLeadershipPortfolio = () => {
  return useMutation(({values,id})=>updateLeadershipPortfolio(values,id),{
    mutationKey:"update-leadership-portfolio"
  })
}

const useAddLeader = () => {
  return useMutation(addLeaderPortfolio,{
    mutationKey:"add-new-leader",
    retry:false
  })
}

const useLinkAccount = () => {
  return useMutation(({values,id})=>linkAccount(values,id),{
    mutationKey:"link-user-account"
  })
}

const useUpdateUserPortfolio = () => {
  return useMutation(({values,id})=>updateUserPortfolio(values,id),{
    mutationKey:"update-user-portfolio"
  })
}

const useFilterExecutives = (filtervalues,hall,onSuccess) => {
  const filters = {}
    const filter = [];
    for(let value in filtervalues){
        if(filtervalues[value] !== ""){
            filters[value] = filtervalues[value];
        }
    }
    for (let key in filters) {
      filter.push(`${key}=${filters[key]}`);
    }
  return useInfiniteQuery(["search-hall-executives",hall],({pageParam})=>filterExecutives(pageParam || `/user/user-portfolio/?hall=${hall}&portofolio=Executive&${filter.join('&')}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useFilterManagement = (filtervalues,hall,onSuccess) => {
  const filters = {}
    const filter = [];
    for(let value in filtervalues){
        if(filtervalues[value] !== ""){
            filters[value] = filtervalues[value];
        }
    }
    for (let key in filters) {
      filter.push(`${key}=${filters[key]}`);
    }
  return useInfiniteQuery(["search-hall-executives",hall],({pageParam})=>filterExecutives(pageParam || `/user/user-portfolio/?hall=${hall}&portofolio=Management&${filter.join('&')}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useRegisterLeadershipChangeRequest = () => {
  return useMutation(registerLeadershipChangeRequest,{
    mutationKey:"register-leadership-change-request"
  })
}

const useDeclineLeadershipChangeRequest = (id,onSuccess,onError) => {
  return useQuery(["decline-change-request",id],()=>declineLeadershipChangeRequest(id),{
    onSuccess,
    onError,
    enabled:false
  })
}

const useGetLeadershipChangeRequest = (id) => {
  return useQuery(["leadership-change-request",id],()=>getLeadershipChangeRequest(id),
  // {
  //   // onSuccess:data=>console.log(data)
  // }
  )
}

const useCreateTenure = () => {
  return useMutation(createTenure,{
    mutationKey:"create-tenure"
  })
}

const useGetCurrentTenure = (hall) => {
  return useQuery("current-tenure",()=>getCurrentTenure(hall),{
    // onSuccess:(data)=>{
    //   console.log(data);
    // },
    onError:(error)=>{
      toast.error("Failed to fetch current tenure",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  })
}

const useDeleteLeadershipPortfolio = () => {
  return useMutation(deleteLeaderPortfolio,{
    mutationKey:"delete-leader-portfolio"
  })
}

const useUnlinkAccount = () => {
  const queryClient = useQueryClient()
  return useMutation((id)=>unlinkAccount(id),{
    mutationKey:"unlink-account",
    enabled:false,
    onSuccess:(data)=>{
      queryClient.invalidateQueries(["leadership-portfolios"]);
      queryClient.invalidateQueries("current-hall-executives");
      // queryClient.invalidateQueries("past-hall-leaders");
      queryClient.invalidateQueries("current-hall-management")
      toast.success("User account unlinked successfully",{
        position:"top-center",
        hideProgressBar:true,
        autoClose:2000
      })
    },
    onError:(error)=>{
      toast.error("Failed to unlink user account",{
        position:"top-center",
        hideProgressBar:true,
        autoClose:2000
      })
    }

  })
}

const useGetHallTenures = (hall_id) => {
  return useQuery("get-hall-tenures",()=>getHallTenures(hall_id),{
    // onSuccess:(data)=>{
    //   console.log(data);
    // },
    onError:(error)=>{
      toast.error("Failed to fetch hall tenures",{autoClose:2000,position:"top-center",hideProgressBar:true})
    }
  })
}

export {
  useGetCurrentExecutives,
  useGetPastExecutives,
  useGetPastYearExecutives,
  useGetPastThreeYearExecutives,
  useGetPastTwoYearExecutives,
  useGetCurrentManagement,
  useGetPastManagement,
  useGetLeadershipPortfolios,
  useGetLeadershipPortfoliosByType,
  useAddLeadershipPortfolios,
  useUpdateLeadershipPortfolio,
  useAddLeader,
  useLinkAccount,
  useUpdateUserPortfolio,
  useGetExecutiveLeadershipPortfolios,
  useGetManagementLeadershipPortfolios,
  useSearchManagement,
  useSearchExecutives,
  useFilterExecutives,
  useFilterManagement,
  useRegisterLeadershipChangeRequest,
  useDeclineLeadershipChangeRequest,
  useGetLeadershipChangeRequest,
  useCreateTenure,
  useGetCurrentTenure,
  useDeleteLeadershipPortfolio,
  useUnlinkAccount,
  useGetHallTenures
};
