import axios from "axios";
import { getAccessToken } from "../helpers/manageToken";
import { refreshToken } from "./auth";

const authClient = axios.create({
    baseURL:process.env.REACT_APP_API_URL
})
// Appending token to header
authClient.interceptors.request.use(
    async(config) =>{
        const access_token = getAccessToken();
        if(access_token?.token){
            config.headers = {
                ...config.headers,
                "Authorization": `Bearer ${access_token?.token}`
            }
        }
        return config;
    },
    (error)=> Promise.reject(error)
)

authClient.interceptors.response.use(
    (response)=> response,
    async (error) => {
        const config = error?.config;

        if(error?.response?.status === 401 && !config?.sent){
            config.sent = true;

            const result = await refreshToken();

            if(result?.access){
                config.headers = {
                    ...config.headers,
                    "Authorization": `Bearer ${result?.access}`,
                };
            }

            return authClient(config);
        }
        // else if(error?.response?.status === 401 && config?.sent){
        //     // window.
        // }
        return Promise.reject(error);
    }
)

export default authClient;