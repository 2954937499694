import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { hallContext } from "../../contexts/hallContext";
import { useGetManagementLeadershipPortfolios } from "../../hooks/leadersHooks";
import { themeContext } from "../../contexts/themeContext";
const SelectPosition = ({filterByPosition,setFiltered}) => {
  const {theme} = useContext(themeContext);
  const [rotate, setRotate] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const {hall} = useContext(hallContext);
  const [selectedOption, setSelectedOption] = useState("All");
  const {data:portfolios,isLoading:fetchingPortfolios,fetchNextPage,hasNextPage} = useGetManagementLeadershipPortfolios(hall?.hallInfo.id,"Management")

  const onDropDown = () => {
    setRotate((prev) => !prev);
    setShowHidden((prev) => !prev);
  };

  const onSelect = (value) => {
    if(value === "All")
    {
      setSelectedOption("All");
      setFiltered(false);
    }else{
      setSelectedOption(value);
      filterByPosition(value)
      setFiltered(true);
    }
    setRotate(false);
    setShowHidden(false);
  };
  return (
    <div className="w-40 relative border border-[#364C66] dark:border-dark-gray rounded-md">
      <div className="h-7 w-full text-primaryTextLight dark:text-primaryTextDark text-sm flex items-center justify-between px-2 pl-3 rounded-sm cursor-pointer" onClick={onDropDown}>
        <span className="font-light">{selectedOption}</span>
        <div className="flex items-center gap-2">
          {
            theme?.value == "light" ?
            <SelectIcon width="12" height="12"
          onClick={onDropDown}
          className={
            "cursor-pointer " +
            (rotate ? "rotate-180 delay-200" : "delay-200")
          }
          />:<SelectIconDark width="12" height="12"
          onClick={onDropDown}
          className={
            "cursor-pointer " +
            (rotate ? "rotate-180 delay-200" : "delay-200")
          }
          />
          }
        </div>
      </div>
      {showHidden && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 right-0 z-10 max-h-36 overflow-auto" id="management-portfolio">
                  <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight"
                            onClick={() => onSelect("All")}>
                            <span>All</span>
                            </div>
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"management-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              return (
                            <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={portfolio.id}
                            onClick={() => onSelect(portfolio.title)}>
                            <span>{portfolio.title}</span>
                            </div>                                  )
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )}
    </div>
  );
};

export default SelectPosition;
