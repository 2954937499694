import NavComponent from "./NavComponent";
import { ReactComponent as HallIcon } from "../assets/icons/hall-icon.svg";
import { ReactComponent as PostTrendingIcon } from "../assets/icons/posts-trending-icon.svg";
import { ReactComponent as MembersIconDark } from "../assets/icons/members-icon-dark.svg";
import { ReactComponent as MembersIconDarkTheme } from "../assets/icons/members-icon-dark-theme.svg";
import { ReactComponent as MembersIconWhite } from "../assets/icons/members-icon-white.svg";
import { ReactComponent as LeadershipIconDark } from "../assets/icons/leadership-icon-dark.svg";
import { ReactComponent as LeadershipIconDarkTheme } from "../assets/icons/leadership-icon-dark-theme.svg";
import { ReactComponent as LeadershipIconWhite } from "../assets/icons/leadership-icon-white.svg";
import { ReactComponent as MomentsIcon } from "../assets/icons/moments-icon.svg";
import { ReactComponent as MomentsIconDarkTheme } from "../assets/icons/moments-icon-dark-theme.svg";
import { ReactComponent as AdsMonetizationIcon } from "../assets/icons/ads-monetization-icon.svg";
import { ReactComponent as AdsMonetizationIconWhite } from "../assets/icons/ads-monetization-icon-white.svg";
import { ReactComponent as HallConnectIcon } from "../assets/icons/hall-connect-logo.svg";
import { ReactComponent as MomentsIconWhite } from "../assets/icons/moment-icon-white.svg";
import { ReactComponent as MessagesIconDark } from "../assets/icons/message-icon-dark.svg";
import { ReactComponent as MessagesIconDarkTheme } from "../assets/icons/message-icon-dark-theme.svg";
import { ReactComponent as MessagesIconWhite } from "../assets/icons/message-icon-white.svg";
import { ReactComponent as NewsIconWhite } from "../assets/icons/news-icon-white.svg";
import { ReactComponent as NewsIconDark } from "../assets/icons/news-icon-dark.svg";
import { ReactComponent as NewsIconDarkTheme } from "../assets/icons/news-icon-dark-theme.svg";

import { useContext, useState } from "react";
import { userContext } from "../contexts/userContext";
import { useParams } from "react-router-dom";
import { themeContext } from "../contexts/themeContext";

const Sidebar = () => {
  const params = useParams();
  const [activelink, setActiveLink] = useState("");
  const { user_state } = useContext(userContext);
  const {theme} = useContext(themeContext);
  return (
    <div className="w-56">
      <div className="flex w-full items-center gap-5 mb-4 h-16 text-primaryTextLight dark:text-primaryTextDark text-base font-semibold">
        <HallConnectIcon width="40" height="40" />
        <span>LinkLounge ADMIN</span>
      </div>
      <hr className="mb-8 dark:border-dark-gray" />
      <div className="flex flex-col gap-4">
        <NavComponent
          label="Hallpage"
          path="/hallpage"
          setActiveLink={setActiveLink}
        >
          <HallIcon
            width="14"
            height="14"
            fill={activelink === "/hallpage" ? "white" : theme?.value == "light" ? "#364C66":"#6c6c6c"}
          />
        </NavComponent>
        <NavComponent
          label="Posts"
          setActiveLink={setActiveLink}
          path="/posts"
        >
          <PostTrendingIcon
            width="14"
            height="14"
            fill={activelink === "/posts" ? "white" : theme?.value == "light" ? "#364C66":"#6c6c6c"}
          />
        </NavComponent>
        <NavComponent
          label="Members"
          setActiveLink={setActiveLink}
          path="/members"
        >
          {activelink === "/members" ? (
            <MembersIconWhite width="14" height="14" />
          ) : (
            <>
            {theme?.value == "light" ?
            <MembersIconDark width="14" height="14" />  :
            <MembersIconDarkTheme width="14" height="14" />
          }
            </>
          )}
        </NavComponent>
        {/* <NavComponent
          label="Messages"
          setActiveLink={setActiveLink}
          path="/messages"
        >
          {activelink === "/messages" ? (
            <MessagesIconWhite width="14" height="14" />
          ) : (
            <>
            {theme?.value == "light" ?
          <MessagesIconDark width="14" height="14" />:
          <MessagesIconDarkTheme width="14" height="14" />  
          }
            </>
          )}
        </NavComponent> */}
        <NavComponent
          label="Leadership"
          setActiveLink={setActiveLink}
          path="/leadership"
        >
          {activelink === "/leadership" ? (
            <LeadershipIconWhite width="14" height="14" />
          ) : (
            <>
            {
              theme?.value == "light" ?
              <LeadershipIconDark width="14" height="14" />:
              <LeadershipIconDarkTheme width="14" height="14" />
            }
            </>
          )}
        </NavComponent>
        {
        <NavComponent label="Moments" setActiveLink={setActiveLink} path="/moments">
        {activelink === "/moments" ? (
          <MomentsIconWhite width="14" height="14" />
          ) : (
          <>
          {theme?.value == "light" ? 
        <MomentsIcon width="14" height="14" />:
        <MomentsIconDarkTheme width="14" height="14" />  
        }
          </>
          )}
          </NavComponent>
        }
        { 
          <NavComponent
          label="News"
          setActiveLink={setActiveLink}
          path="/news"
        >
          {(activelink === "/news") ? (
          <NewsIconWhite width="14" height="14" />
          ) : (
            <>
            {theme?.value == "light"?
          <NewsIconDark width="14" height="14"/>:
          <NewsIconDarkTheme width="14" height="14"/>  
          }
            </>
          )}
        </NavComponent>
        }
        {/* <NavComponent
          label="Ads & Monetization"
          setActiveLink={setActiveLink}
          path="/ads-monetize"
        >
          {activelink === "/ads-monetize" ? (
          <AdsMonetizationIconWhite width="14" height="14" />
          ) : (
            <AdsMonetizationIcon width="14" height="14" />
          )}
        </NavComponent> */}
      </div>
    </div>
  );
};

export default Sidebar;
