import { createContext, useState } from "react"

export const logoutContext = createContext();
const LogoutContextProvider = ({children}) => {
    const [logout,setLogout] = useState(false);
    return (
        <logoutContext.Provider value={{logout,setLogout}}>
            {children}
        </logoutContext.Provider>
    )
}

export default LogoutContextProvider;