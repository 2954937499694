import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavComponent = ({ children,label,path,setActiveLink }) => {
    const [active,setActive] = useState()
    const {pathname} = useLocation();

    useEffect(()=>{
        if(path === pathname || pathname.includes(path)) {
            setActive(true);
            setActiveLink(path);
        }
        else
            setActive(false);
    },[])
  return (
    <NavLink className={"flex h-10 w-full items-center px-2 rounded-[0.25rem] " + (active ? "shadow-3xl bg-cardBackgroundLight dark:bg-cardBackgroundDark" : "")} to={path}>
      <div className={"mr-3 w-6 h-6 rounded-[0.25rem] flex items-center justify-center " + (active ? "bg-[#F63E49]" : "bg-cardBackgroundLight dark:bg-cardBackgroundDark shadow-4xl")}>
        {children}
      </div>
      <span className={"text-sm font-light " + (active ? "text-primaryTextLight dark:text-primaryTextDark":"text-primaryTextLight dark:text-primaryTextDark")}>{label}</span>
    </NavLink>
  );
};

export default NavComponent;
