import { backgrounds } from "../../constants/backgrounds";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import ImageSlider from "./ImageSlider";

const MomentsGallery = ({
  onClickNext,
  onClickPrevious,
  onClose,
  children,
  showNext,
  showPrevious,
  moments,
  show,
  selectedIndex,
  setSelectedIndex
}) => {
//   const galleryImage = useMemo(()=>{
//     // if(moments?.pages !== undefined){
//       const images = []
//       const indexes = {}
//       const backgrounds = ["linear-gradient(101.79deg, #FFD7D7 -19.6%, #B8CBE1 123.64%)","linear-gradient(93.95deg, #C2D2FE -21.35%, #CDCDCD 140.29%)","linear-gradient(93.95deg, #C2EFFE -21.35%, #E1DBB8 140.29%)","linear-gradient(101.79deg, #FEC2E9 -19.6%, #B8CBE1 123.64%)"]
//       moments?.forEach((moment,index)=>{
//           images.push({type:"title",title:moment.title,published_at:moment.published_at,background:backgrounds[index % backgrounds.length]})
//             const id = `moment-${moment.id}`
//             indexes[id] = images.length - 1
//           images.push(...moment.pictures.map((img,imgIndex)=>{
//             const id = `picture-${img.id}`;
//             indexes[id] = images.length + imgIndex
//           return {...img,type:"image"}
//         }))
//         })
//       console.log(indexes);
//       setGallery(images);

//       return (
//         <ImageSlider images={gallery}/>
//       )
// // }
//   },[images])
  return (
      <>
        <ImageSlider images={moments} onClose={onClose} show={show} currentSlide={selectedIndex} setCurrentSlide={setSelectedIndex}/>
      </>
    // <div className="z-[100] h-screen flex flex-nowrap overflow-x-hidden absolute top-0 bg-black">
    //   <CloseIcon
    //     width="20"
    //     height="20"
    //     className="cursor-pointer absolute right-2 top-2 z-20"
    //     onClick={onClose}
    //   />
    //   {showPrevious && (
    //     <button
    //       className="absolute z-[150] top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center"
    //       onClick={onClickPrevious}
    //     >
    //       <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
    //       <LeftIcon className="z-[150]" height="20" width="20" />
    //     </button>
    //   )}
    //   {children}
    //   {showNext && (
    //     <button
    //       className="absolute z-[150] top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center"
    //       onClick={onClickNext}
    //     >
    //       <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
    //       <RightIcon className="z-[150]" height="20" width="20" />
    //     </button>
    //   )}
    // </div>
  );
};

export default MomentsGallery;
