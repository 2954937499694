import { useContext, useState } from "react";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import { ReactComponent as HallConnectIcon } from "../../assets/icons/hall-connect-icon.svg";
import { ReactComponent as HallConnectIconDark } from "../../assets/icons/hall-connect-icon-dark.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import { leadershipContext } from "../../contexts/leadershipContext";
import { themeContext } from "../../contexts/themeContext";
import { useQueryClient } from "react-query";
import { useLinkAccount, useUnlinkAccount } from "../../hooks/leadersHooks";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { hallContext } from "../../contexts/hallContext";

const NewLeaderAccount = ({portfolio,selectedTenure}) => {
    const {theme} = useContext(themeContext);
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    // const {mutate:linkAccount,isLoading:isUnlinkingAccount} = useLinkAccount();
    const {mutate:unlinkUserAccount,isLoading,} = useUnlinkAccount();
    const queryClient = useQueryClient();
    const {hall} = useContext(hallContext);

    
    return (
        <div className="w-full h-20 border rounded-md flex items-center px-2">
            <div className="h-[90%] w-20 relative">
                <img alt="Leader Profile" height={"100%"} width="100%" className="object-cover w-full h-full rounded-md" src={(portfolio.current_portfolio[0] && portfolio.current_portfolio[0].picture) || defaultProfile}/>
                {/* <div className="h-6 w-6 flex items-center justify-center rounded-full bg-cardBackgroundLight  absolute bottom-[3px] right-[3px]">
                    <CameraIcon height="15" width="15" className="cursor-pointer" onClick={onSelectFile}/>
                </div> */}
            </div>
           {
            portfolio.current_portfolio.length !== 0 ? 
            <div className="text-primaryTextLight dark:text-primaryTextDark w-[25%] ml-10">
            {portfolio.current_portfolio[0].name}
        </div> :
         <div className="text-primaryTextLight dark:text-primaryTextDark w-[25%] ml-10">
         <span className="italic opacity-[80%]">Unassigned</span>
        </div>
        
           }
            {
                portfolio.current_portfolio.length !== 0 ?
                <div className="w-[20%] text-primaryTextLight dark:text-primaryTextDark">
                {portfolio.title}
            </div>:
            <div className="w-[20%] italic text-primaryTextLight dark:text-primaryTextDark opacity-[80%]">
            {portfolio.title}
            </div>


            }
            <div className="w-[10%]">
                {
                   (portfolio.current_portfolio.length !== 0 && portfolio.current_portfolio[0].user) &&
                    <>
                    {theme?.value == "light" ?
                    <HallConnectIcon width={20} height={20}/>:
                    <HallConnectIconDark width={20} height={20}/>    
                }
                    </>
                }
            </div>
            {
                (portfolio.current_portfolio.length !== 0 && portfolio.current_portfolio[0].user) ?
                <button className="bg-filter-grad text-primaryTextLight w-32 rounded-sm mr-5"
                onClick={()=>unlinkUserAccount(portfolio.current_portfolio[0].id)}
                >{isLoading ? <BeatLoader color="white" size={10}/> :"Unlink Account"}</button>
                :
                portfolio.current_portfolio.length === 0 ?
                <button className="bg-disabledLinkAccount opacity-[40%] text-primaryTextLight w-32 rounded-sm mr-5" disabled
                //  onClick={()=> setLeadershipStates(prev=>{return {...prev,linkAccount:true,previous:"add-executive",leader:portfolio?.current_portfolio[0]}})} 
                 >{"Link Account"}</button>
                 :
                 <button className="bg-filter-grad text-primaryTextLight w-32 rounded-sm mr-5"
                 onClick={()=>setLeadershipStates(prev=>{return {...prev,linkAccount:true,previous:"add-executive",leader:portfolio?.current_portfolio[0],leaderCategory:"Executive"}})} 
                 >{"Link Account"}</button>

            }
            {
                portfolio.current_portfolio.length !== 0 ?
                <button className="bg-filter-grad text-[#F63E49] px-4 rounded-sm"
             onClick={()=>setLeadershipStates(prev=>{return {...prev,removeLeader:true,leader:portfolio.current_portfolio[0]}})}
             >Delete</button>:
            <button className="bg-filter-grad text-primaryTextLight px-4 rounded-sm"
             onClick={()=>setLeadershipStates(prev=>{return {...prev,addExecutive:true,selectedPortfolio:portfolio,selectedTenure:selectedTenure}})}
             >Assign</button>
            }
            <input type="file" accept="image/*" id="choose-image" className="hidden" 
            // onChange={onChange}
            />
        </div>
    )
}

export default NewLeaderAccount;