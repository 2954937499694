import { useState,useMemo } from "react";
import getAllYears from "../helpers/getAllYears";
import isLeapYear from "@stdlib/assert-is-leap-year"
import getDays from "../helpers/getDays";

const YearMonthDayPicker = ({handleChange,year,month,day}) => {
    const months = [{month:"January",value:1},{month:"February",value:2},{month:"March",value:3},{month:"April",value:4},{month:"May",value:5},{month:"June",value:6},{month:"July",value:7},{month:"August",value:8},{month:"September",value:9},{month:"October",value:10},{month:"November",value:11},{month:"December",value:12}];
    const getYears = useMemo(()=>getAllYears(1900),[]) 
    const onChange = (event) => {
        handleChange(event.target.name,event.target.value);
    }
    const getAllDays = () => {
        if(isLeapYear(year) & Number(month) === 2){
            return getDays(29)
        }else if(!isLeapYear(year) && Number(month) === 2){
            return getDays(28)
        }else if(Number(month) === 4 || Number(month) === 6 || Number(month) === 9 || Number(month) === 11){
            return getDays(30)
        }else{
            return getDays(31)
        }
    }
    return (
        <div className="flex gap-2">
            <div className="w-[5rem] h-[2.5rem] rounded-md">
                <select name="year" onChange={onChange} className="focus:outline-none w-[5rem] h-[2.5rem] rounded-md border border-[#d9d9d9] dark:border-dark-gray bg-cardBackgroundLight dark:bg-cardBackgroundDark">
                    <option value="">Year</option>
                    {getYears.map((year,index)=>{
                        return (
                            <option key={index}>{year}</option>
                        )
                    })}
                </select>
            </div>
            <div className="w-[5.5rem] h-[2.5rem] rounded-md"> 
                <select disabled={year.length === 0 ? true : false} name="month" onChange={onChange} className="focus:outline-none w-[5.5rem] h-[2.5rem] rounded-md border border-[#d9d9d9] bg-cardBackgroundLight dark:bg-cardBackgroundDark">
                    <option value="" className="font-light">Month</option>
                    {months.map((month,index)=>{
                        return (
                            <option key={index} value={month.value}>{month.month}</option>
                        )
                    })}
                </select>
            </div>
            <div className="w-[5rem] h-[2.5rem] rounded-md">
                <select disabled={(month.length === 0) ? true : false} onChange={onChange} name="day" className="focus:outline-none w-[5rem] h-[2.5rem] rounded-md border border-[#d9d9d9] bg-cardBackgroundLight dark:bg-cardBackgroundDark">
                    <option value="">Day</option>
                    {
                        getAllDays().map((day,index)=>{
                            return (
                                <option key={index}>{day}</option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

export default YearMonthDayPicker;