import { getRefreshToken, removeAccessToken, removeRefreshToken, storeAccessToken } from "../helpers/manageToken";
import { removeUser } from "../helpers/manageUser";
import client from "./axiosInstance";
import mem from "mem";

const login = (values) => {
  return client.post("/auth/v1/login/", values);
};

const forgotPassword = (values) => {
  return client.post("/auth/v1/password-reset-otp/", values);
};

const confirmResetOTP = (values) => {
  return client.post("/auth/v1/confirm-password-reset-otp/",values);
}

const changePassword  = (values) => {
  return client.post("/auth/v1/password-reset/",values);
}

const logout = () => {
  return client.post("/auth/v1/logout/");
}

const refreshToken = mem(async () => {
    const refresh_token = getRefreshToken();
    try{
        const response = await client.post("/auth/v1/token/refresh/", {
            refresh: refresh_token?.token
          });
          const new_access_token = response.data;
          if(!new_access_token?.access){
            removeAccessToken();
            removeRefreshToken();
            removeUser();
          }
          storeAccessToken({token:new_access_token.access,expiry:new Date(Date.now() + 72 * 60 * 60 * 1000)});
          return new_access_token;
    }catch(error){
        removeAccessToken();
        removeRefreshToken();
        removeUser();
    }
},{maxAge:10000});

export { login, forgotPassword, logout, refreshToken,confirmResetOTP,changePassword};
