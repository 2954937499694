import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import Banner from "../../components/hallpage/Banner";
import AddExecutiveList from "../../components/leadership/AddExecutiveList";
import AddPortofolio from "../../components/leadership/AddPortofolio";
import CurrentManagementList from "../../components/leadership/CurrentManagementList";
import RegisterPortofolioChange from "../../components/leadership/RegisterPortfolioChange";
import EditPortofolio from "../../components/leadership/EditPortofolio";
import ExecutivesList from "../../components/leadership/ExecutivesList";
import LeadershipHeader from "../../components/leadership/LeadershipHeader";
import LeadershipPortofolios from "../../components/leadership/LeadershipPortofolios";
import LinkAccount from "../../components/leadership/LinkAccount";
import ManagementHistory from "../../components/leadership/ManagementHistory";
import NewExecutive from "../../components/leadership/NewExecutive";
import NewManagement from "../../components/leadership/NewManagement";
import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import { leadershipContext } from "../../contexts/leadershipContext";
import { logoutContext } from "../../contexts/logoutContext";
import { useLogout } from "../../hooks/authHooks";
import EditLeadershipPortfolio from "../../components/leadership/EditLeadershipPortfolio";
import Discard from "../../components/leadership/Discard";
import RemoveLeader from "../../components/leadership/RemoveLeader";
import FilterExecutives from "../../components/leadership/FilterExecutives";
import FilterManagement from "../../components/leadership/FilterManagement";
import LeadershipChangeRequested from "../../components/leadership/LeadershipChangeRequested";
import LinkedAccount from "../../components/leadership/LinkedAccount";
import AddTenure from "../../components/leadership/AddTenure";

const LeadershipPage = () => {
const [executives,setExecutives] = useState(true)
const {logout,setLogout} = useContext(logoutContext);
const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
const [addExecutives,setAddExecutives] = useState(false);
const [searchingManagement,setSearchingManagement] = useState(false);
const [searchingExecutives,setSearchingExecutives] = useState(false);
const [filteredExecutives,setFilteredExecutives] = useState([]);
const [showFilteredExecutives,setShowFilteredExecutives] = useState(false);
const [filter,setFilter] = useState(false);
const [showFilteredManagement,setShowFilteredManagement] = useState(false);
const [filteredManagement,setFilteredManagement] = useState([]);
const {onLogout} = useLogout();
useEffect(()=>{
  document.title = "LinkLounge | Hall Leadership"
},[])
useEffect(()=>{
let timeout;
if(showFilteredManagement && filteredManagement?.pages[0]?.data?.results.length === 0){
  timeout = setTimeout(()=>{
    setShowFilteredManagement(false);
  },8000)
}else if(showFilteredExecutives && filteredExecutives?.pages[0]?.data?.results.length === 0){
  timeout = setTimeout(()=>{
    setShowFilteredExecutives(false)
  },8000)
}
return ()=>clearTimeout(timeout);
},[showFilteredExecutives,showFilteredManagement])
  return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto">
      <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full top-0 sticky z-20`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full mt-3 flex-col gap-1 pb-5">
            {
              !addExecutives ? 
              <>
                <LeadershipHeader executives={executives} setExecutives={setExecutives}/>
                {executives ?
                <ExecutivesList addExecutives={()=>setAddExecutives(true)} searching={searchingExecutives} setSearching={setSearchingExecutives} showFilteredExecutives={showFilteredExecutives} filteredExecutives={filteredExecutives}/> :
                  <React.Fragment>
                    <CurrentManagementList setSearching={setSearchingManagement} searching={searchingManagement} showFilteredManagement={showFilteredManagement} filteredManagement={filteredManagement}/>
                    {!searchingManagement && !showFilteredManagement && 
                    <ManagementHistory/>
                    }
                  </React.Fragment>
                }
              </> :
              <AddExecutiveList onCancel={()=>{setLeadershipStates(prev=>{return {...prev,showDiscard:true}})}} closeAddExecutivesList={()=>setAddExecutives(false)}/>
            }
          </section>
        </main>
      </div>
        {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        leadershipStates.showPortofolios &&
        <LeadershipPortofolios/>
      }
      {
        leadershipStates.addPortofolio &&
        <AddPortofolio/>
      }
      {
        leadershipStates.addManagement &&
        <NewManagement/>
      }
      {
        leadershipStates.addExecutive && 
        <NewExecutive/>
      }
      {
        leadershipStates.editPortofolio &&
        <EditPortofolio/>
      }
      {
        leadershipStates.linkAccount && 
        <LinkAccount/>
      }
      {
        leadershipStates.registerChange &&
        <RegisterPortofolioChange/>
      }
      {
        leadershipStates.editLeadershipPortfolio &&
        <EditLeadershipPortfolio/>
      }
      {
        leadershipStates.showDiscard && 
        <Discard onConfirm={()=>{setAddExecutives(false);setLeadershipStates(prev=>{return {...prev,showDiscard:false,executives:[]}})}} onCancel={()=>{setLeadershipStates(prev=>{return {...prev,showDiscard:false}})}}/>
      }
      {
        leadershipStates.removeLeader && 
        <RemoveLeader/>
      }
      {
        leadershipStates.filterExecutives && 
        <FilterExecutives setFilteredExecutives={setFilteredExecutives} setShowFilteredExecutives={setShowFilteredExecutives} filter={filter} setFilter={setFilter} onClose={()=>{setLeadershipStates(prev=>{return {...prev,filterExecutives:false}})}} setSearching={setSearchingExecutives}/>
      }
      {
        leadershipStates.filterManagement &&
        <FilterManagement setFilter={setFilter} setShowFilteredManagement={setShowFilteredManagement} filter={filter} setFilteredManagement={setFilteredManagement} onClose={()=>{setLeadershipStates(prev=>{return {...prev,filterManagement:false}})}} setSearching={setSearchingManagement}/>
      }
      {
        leadershipStates.changeRequested && 
        <LeadershipChangeRequested/>
      }
      {
        leadershipStates.showLinkedAccount &&
        <LinkedAccount/>
      }
      {
        leadershipStates.addTenure && 
        <AddTenure addExecutive={()=>setAddExecutives(true)}/>
      }
    </PageContainer>
  );
};

export default LeadershipPage;
