import { useContext, useEffect } from "react";
import { useState } from "react";
import AboutHall from "../../components/hallpage/AboutHall";
import Banner from "../../components/hallpage/Banner";
import EditAboutModal from "../../components/hallpage/EditAboutModal";
import EditCoverPhotoModal from "../../components/hallpage/EditCoverPhotoModal";
import EditHallInfoModal from "../../components/hallpage/EditHallInfoModal";
import EditProfileModal from "../../components/hallpage/EditProfileModal";
import EditProfilePictureModal from "../../components/hallpage/EditProfilePictureModal";
import Leadership from "../../components/hallpage/Leadership";
import Moments from "../../components/hallpage/Moments";
import SelectTagsModal from "../../components/posts-trending/SelectTagsModal";
import PageContainer from "../../components/PageContainer";
import CreatePost from "../../components/posts-trending/CreatePost";
import FilterPost from "../../components/posts-trending/FilterPost";
import TrendingPosts from "../../components/posts-trending/TrendingPosts";
import Sidebar from "../../components/Sidebar";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import { useCheckAboutChangeRequestExists, useGetHallInfo } from "../../hooks/hallHooks";
import { hallActions } from "../../reducers/actions";
import AllPosts from "../../components/posts-trending/AllPosts";
import { storeSessionStorageHall } from "../../helpers/manageHall";
import { useDeletePost, useGetThemes } from "../../hooks/postHooks";
import { storePostThemes, storeTrendThemes } from "../../helpers/manageThemes";
import AllTrends from "../../components/posts-trending/AllTrends";
import { useDeleteTrend, useGetTrendThemes } from "../../hooks/trendHooks";
import FilterPostModal from "../../components/posts-trending/FilterPostModal";
import FilterTrendsModal from "../../components/posts-trending/FilterTrendsModal";
import AboutHallModal from "../../components/hallpage/AboutHallModal";
import AboutRequest from "../../components/hallpage/AboutRequest";
import ProfileRequest from "../../components/hallpage/ProfileRequest";
import SearchedPosts from "../../components/posts-trending/SearchedPosts";
import FilterTrend from "../../components/posts-trending/FilterTrend";
import Lightbox from "yet-another-react-lightbox";
import { logoutContext } from "../../contexts/logoutContext";
import ConfirmModal from "../../components/ConfirmModal";
import { useLogout } from "../../hooks/authHooks";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import ReportPostModal from "../../components/posts-trending/ReportPostModal";
import ReportTrendModal from "../../components/posts-trending/ReportTrend";
import coverPhoto from "../../assets/images/cover-photo-small-size.jpeg";

const HallPage = () => {
  const [trending, setTrending] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showEditHallInfoModal, setShowEditHallInfoModal] = useState(false);
  const [showEditCoverPhotoModal, setShowEditCoverPhotoModal] = useState(false);
  const [showFilterTrendsModal,setShowFilterTrendsModal] = useState(false);
  const [showFilterPostsModal,setShowFilterPostsModal] = useState(false);
  const [showEditProfilePictureModal, setShowEditProfilePictureModal] = useState(false);
  const [showSelectTagsModal, setShowSelectTagsModal] = useState(false);
  const [showAboutHallModal,setShowAboutHallModal] = useState(false);
  const [showAboutRequest,setShowAboutRequest] = useState(false);
  const [showProfileRequest,setShowProfileRequest] = useState(false);
  const [showTrendingPosts,setShowTrendingPosts] = useState(false);
  
  const [aboutRequestData,setAboutRequestData] = useState();
  const [profileRequestData,setProfileRequestData] = useState();

  const [isAboutChangeRequested,setIsAboutChangeRequested] = useState(false);
  const [isProfileChangeRequested,setIsProfileChangeRequested] = useState(false);
  
  const [searchedPosts,setSearchedPosts] = useState([]);
  const [searchedPostsDetails,setSearchedPostsDetails] = useState({fetchNextPage:undefined,hasNextPage:false});

  const [searchedTrends,setSearchedTrends] = useState([]);
  const [searchedTrendsDetails,setSearchedTrendsDetails] = useState({fetchNextPage:undefined,hasNextPage:false});
  
  const [searching,setSearching] = useState(false);
  const [filtered,setFiltered] = useState(false);

  
  const [viewImages,setViewImages] = useState(false);
  const [images,setImages] = useState([]);
  
  
  const {menu,setMenu} = useContext(postTrendMenuContext);
  const { user_state } = useContext(userContext);
  const { hall, setHall } = useContext(hallContext);
  const {logout,setLogout} = useContext(logoutContext);
  const {onLogout} = useLogout();
  const {onDeletePost,isDeletingPost} = useDeletePost();
  const {onDeleteTrend,isDeletingTrend} = useDeleteTrend();

  const Posts = ({parentId}) => {
    useEffect(()=>{
      let timeout;
      if(filtered && searchedPosts.pages[0]?.data?.results.length === 0 && !trending){
        timeout = setTimeout(()=>{
          setFiltered(false);
        },8000)
      }
      else if(filtered && trending && searchedTrends.pages[0]?.data?.results.length === 0){
        timeout = setTimeout(()=>{
          setFiltered(false);
        },8000)
      }
      return ()=>clearTimeout(timeout)
    },[])
    if(searching || filtered){
      return (
        <SearchedPosts posts={searchedPosts} trends={searchedTrends} trending={trending} setViewImages={()=>setViewImages(true)} setImages={setImages} searchedPostsDetails={searchedPostsDetails} searchedTrendsDetails={searchedTrendsDetails} parentId={parentId}/>
        )
    }else if(!searching && !filtered && trending){
      return (
        <AllTrends setViewImages={()=>setViewImages(true)} setImages={setImages} parentId={parentId}/>
        )
    }else if(!searching && !filtered && !trending){
      return (
        <AllPosts setViewImages={()=>setViewImages(true)} setImages={setImages} parentId={parentId} setShowTrendingPosts={setShowTrendingPosts} showTrendingPosts={showTrendingPosts}/>
      )
    }
  }

  useEffect(()=>{
    document.title = "LinkLounge | Hall Page"
  },[])
  
  return (
    <PageContainer customstyle={"h-full w-full relative"}>
      <div className="w-full h-full flex justify-between overflow-auto">
      <section className="w-1/5  h-fit self-start mt-4 ml-4">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col mt-4 mr-4">
          <section
            className={
              "h-80 rounded-md mb-5 flex flex-col w-full shrink-0 bg-cover object-center"
            }
            style={{ backgroundImage: hall?.hallInfo?.cover_image ? `url(${hall?.hallInfo.cover_image})`: `url(${coverPhoto})` }}
          >
            <Banner
              onEditProfile={() => setShowEditProfileModal(true)}
              onEditProfilePicture={() => setShowEditProfilePictureModal(true)}
              show={true}
            />
          </section>
          <section className="flex w-full flex-row px-4">
            <div className="w-[35%] mr-8">
              <AboutHall onEdit={() => setShowAboutModal(true)} onSeeMore={()=>setShowAboutHallModal(true)} onAboutRequest={()=>setShowAboutRequest(true)} setAboutRequestData={setAboutRequestData} isChangeRequested={isAboutChangeRequested} setIsChangeRequested={setIsAboutChangeRequested}/>
              <Moments/>
              <Leadership />
            </div>
            <div className="w-6/10 pb-2 h-[920px] overflow-y-auto" id="scroll">
              <div className="">
              <CreatePost onSelectTag={() => setShowSelectTagsModal(true)}/>
              </div>
              {
                // !trending ? 
                <FilterPost setTrending={setTrending} trending={trending} onFilterPosts={()=>setShowFilterPostsModal(true)} onFilterTrends={()=>setShowFilterTrendsModal(true)} setSearchedPosts={setSearchedPosts} setSearching={setSearching} filtered={filtered} setSearchedPostsDetails={setSearchedPostsDetails} setShowTrendingPosts={setShowTrendingPosts} showTrendingPosts={showTrendingPosts}/>
                // : <FilterTrend setTrending={setTrending} trending={trending} onFilterPosts={()=>setShowFilterPostsModal(true)} onFilterTrends={()=>setShowFilterTrendsModal(true)} setSearchedPosts={setSearchedTrends} setSearching={setSearching} filtered={filtered} setSearchedTrendsDetails={setSearchedTrendsDetails}/>

              }
              <Posts parentId={"scroll"}/>
              </div>
          </section>
        </main>
      {showAboutModal && (
        <EditAboutModal onClose={() => setShowAboutModal(false)} />
      )}
      {showEditProfileModal && (
        <EditProfileModal
          onClose={() => setShowEditProfileModal(false)}
          onEditHallInfo={() => setShowEditHallInfoModal(true)}
          onEditCoverPhoto={() => setShowEditCoverPhotoModal(true)}
          onEditProfilePicture={() => setShowEditProfilePictureModal(true)}
          onProfileRequest={()=> setShowProfileRequest(true)}
          setProfileRequestData={setProfileRequestData}
          isChangeRequested={isProfileChangeRequested}
          setIsChangeRequested={setIsProfileChangeRequested}
        />
      )}
      {showEditHallInfoModal && (
        <EditHallInfoModal onClose={() => setShowEditHallInfoModal(false)} />
      )}
      {showEditCoverPhotoModal && (
        <EditCoverPhotoModal
          onClose={() => {
            setShowEditCoverPhotoModal(false);
          }}
        />
      )}
      {showEditProfilePictureModal && (
        <EditProfilePictureModal
          onClose={() => setShowEditProfilePictureModal(false)}
        />
      )}
      {showSelectTagsModal && (
        <SelectTagsModal setShowSelectTagsModal={setShowSelectTagsModal} />
      )}
      {
        showFilterPostsModal && (
          <FilterPostModal onClose={()=>setShowFilterPostsModal(false)} setSearchedPosts={setSearchedPosts} setFiltered={setFiltered} setSearchedPostsDetails={setSearchedPostsDetails}/>
        )
      }
      {
        showFilterTrendsModal && (
          <FilterTrendsModal onClose={()=>setShowFilterTrendsModal(false)} setSearchedTrends={setSearchedTrends} setFiltered={setFiltered} setSearchedTrendsDetails={setSearchedTrendsDetails}/>
        )
      }
      {
        showAboutHallModal && 
        <AboutHallModal onClose={()=>{setShowAboutHallModal(false)}} onAboutRequest={()=>setShowAboutRequest(true)}/>
      }
      {
        showAboutRequest &&  
        <AboutRequest aboutRequestData={aboutRequestData} onClose={()=>setShowAboutRequest(false)} setIsChangeRequested={setIsAboutChangeRequested}/>
      }
      {
        showProfileRequest &&
        <ProfileRequest profileRequestData={profileRequestData} onClose={()=>setShowProfileRequest(false)} setIsChangeRequested={setIsProfileChangeRequested}/>
      }
      {
        logout && 
        <ConfirmModal question={"Do you want to logout?"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        menu.post && 
        <ConfirmModal question={"Are you sure about deleting this post?"} confirmAction={"Delete"} onCancel={()=>setMenu(prev=>{ return {...prev,post:false,postId:""}})} onConfirm={onDeletePost} isLoading={isDeletingPost}/> 
      }
      {
        menu.trend && 
        <ConfirmModal question={"Are you sure about deleting this trend?"} confirmAction={"Delete"} onCancel={()=>setMenu(prev=>{ return {...prev,trend:false,trendId:""}})} onConfirm={onDeleteTrend} isLoading={isDeletingTrend}/> 
      }
      {
        menu.reportPost &&
        <ReportPostModal/>
      }
      {
        menu.reportTrend &&
        <ReportTrendModal/>
      }
        <Lightbox
        open={viewImages}
        close={() => setViewImages(false)}
        slides={[...images.map(img=>{return {src:img.image}})]}
      />
      </div>
    </PageContainer>
  );
};

export default HallPage;
