import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import Banner from "../../components/hallpage/Banner";
import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import { logoutContext } from "../../contexts/logoutContext";
import { useLogout } from "../../hooks/authHooks";
import NewsDetail from "../../components/news/NewsDetail";
import RelatedNews from "../../components/news/RelatedNews";
import { useParams } from "react-router-dom";
import { useGetRelatedNews, useGetSingleNews } from "../../hooks/newsHooks";

const NewsDetailPage = () => {
const [category,setCategory] = useState("General")
const {logout,setLogout} = useContext(logoutContext);
const {onLogout} = useLogout();
const {newsId} = useParams();
const {data:news,isLoading:isFetchingNews} = useGetSingleNews(newsId);
const {data:relatedNews,isLoading:isFetchingRelatedNews} = useGetRelatedNews(newsId);
useEffect(()=>{
  document.title = "LinkLounge | News"
},[])

return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto">
      <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-20`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full justify-between pb-5 mt-3 px-5">
            <NewsDetail news={news?.data} isLoading={isFetchingNews}/>
            <RelatedNews relatedNews={relatedNews?.data} isLoading={isFetchingRelatedNews}/>
          </section>
        </main>
      </div>
        {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
        }
    </PageContainer>
  );
};

export default NewsDetailPage;
