export const getHall = () => {
    return JSON.parse(sessionStorage.getItem('hall')) || JSON.parse(localStorage.getItem('hall'));
}

export const getLocalStorageHall = () => {
    return JSON.parse(localStorage.getItem('hall'));
}

export const getSessionStorageHall = () => {
    return JSON.parse(sessionStorage.getItem('hall'));
}

export const storeLocalStorageHall = (hall) => {
        localStorage.setItem('hall',JSON.stringify(hall));
}

export const storeSessionStorageHall = (hall) => {
    sessionStorage.setItem('hall',JSON.stringify(hall));
}

export const removeHall = () => {
    sessionStorage.removeItem('hall') || localStorage.removeItem('hall');
}

export const removeLocalStorageHall = () => {
    localStorage.removeItem('hall');
}

export const removeSessionStorageHall= () => {
    sessionStorage.removeItem('hall');
}