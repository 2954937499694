import { useContext } from "react";
import { postBackgroundContext, trendBackgroundContext } from "../../contexts/backgroundContext";
import { backgroundContext } from "../../contexts/postBackgroundContext";

const Backgrounds = ({ position, onPostWithBg, trending }) => {
  const {postBackgrounds} = useContext(postBackgroundContext);
  const {trendBackgrounds} = useContext(trendBackgroundContext)
  const backgrounds =  postBackgrounds
  const { setBackground } = useContext(backgroundContext);
  const selectBackground = (background) => {
    setBackground(background);
    onPostWithBg();
  };
  return (
    <div
      className={
        "flex gap-2 px-2 py-1 w-max h-8 bg-colors-grad absolute z-10 rounded-md items-center shadow-5xl " +
        position
      }
    >
      {backgrounds.map((bg_theme) => {
        const colors = [];
        for (let color of bg_theme.bg_colors) {
          colors.push(color.color);
        }
        const bgColor =
          colors.length === 1
            ? colors[0]
            : `linear-gradient(45deg, ${colors.join(",")})`;
        return (
          <div
            className={`h-6 w-6 shrink-0 cursor-pointer rounded-sm`}
            style={{ background: bgColor }}
            onClick={() => selectBackground(bg_theme)}
            key={bg_theme.id}
          ></div>
        );
      })}
    </div>
  );
};

export default Backgrounds;
