import { useRef, useState } from "react";
import { useGetTrendLeaderBoard } from "../../hooks/trendHooks";
import TrendingCard from "./TrendingCard";
import TrendingCardWithImage from "./TrendingCardWithImage";
import TrendingCardWithTheme from "./TrendingCardWithTheme";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGetTrendPosts } from "../../hooks/postHooks";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-icon.svg";

const TrendingPosts = ({showTrendingPosts}) => {
  const {
    isLoading,
    isFetching,
    data: trendingPosts,
    fetchNextPage,
    hasNextPage,
  } = useGetTrendPosts();
  const ref = useRef();
  const onScroll = (offset) => {
    ref.current.scrollTo({left:ref.current.scrollLeft+offset,behavior:'smooth'});
  };
  return (
    <div className="w-full mb-5 h-[20rem] rounded-md flex flex-col px-4 py-4 bg-cardBackgroundLight dark:bg-cardBackgroundDark relative">
      <span className="font-medium text-lg text-primaryTextLight dark:text-primaryTextDark cursor-pointer" onClick={showTrendingPosts}>
        Trending Now
      </span>
        <button
        className="absolute z-10 top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center"
        onClick={() => onScroll(-224)}
      >
        <span className="bg-black rounded-full opacity-[30%] h-full w-full absolute"></span>
        <LeftIcon className="z-20" height="20" width="20" />
      </button>
      <button
        className="absolute z-10 top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center"
        onClick={() => onScroll(224)}
      >
        <span className="bg-black rounded-full opacity-[30%] h-full w-full absolute"></span>
        <RightIcon className="z-20" height="20" width="20" />
      </button>
      {isLoading ? (
        <div className="h-full w-full flex items-center justify-center">
          <ClipLoader size={100} color="#F63E49" />
        </div>
      ) : (
        <div className="w-full h-full overflow-x-auto flex mt-2 scrollbar-hide" id="trending-posts-scroll" ref={ref}>
          {/* <InfiniteScroll
            dataLength={trendingPosts?.pages?.length * 64 || 64}
            next={fetchNextPage}
            style={{ display: "flex", flexDirection: "row" }} //To put endMessage and loader to the top.
            hasMore={false}
            loader={
              <div className="flex w-full items-center mt-5">
                <ClipLoader size={30} color="#F63E49" />
              </div>
            }
            scrollableTarget={"trending-posts-scroll"}
            className="w-full h-full overflow-x-auto flex mt-2"
            ref={ref}
          > */}
            {
            trendingPosts?.data?.results?.map((post, i) => {
              // return 
              // group?.data?.results?.map((post) => {
                if (
                  post.text &&
                  !post.theme &&
                  post.pictures.length === 0 &&
                  post.videos.length === 0 
                ) {
                  return (
                    <TrendingCard
                      key={post.id}
                      tags={post.tags}
                      text={post.text}
                      likes={post.likes_count}
                      comments={post.comment_count}
                    />
                  );
                } else if (
                //   post.text &&
                  post.pictures.length !== 0
                ) {
                  return (
                    <TrendingCardWithImage
                      key={post.id}
                      bgImage={post.pictures[0].image}
                      tags={post.tags}
                      text={post.text}
                      likes={post.likes_count}
                      comments={post.comment_count}
                    />
                  );
                } else if(post.videos.length !== 0){
                  return (
                    <TrendingCardWithImage
                      key={post.id}
                      bgImage={post.videos[0].thumbnail}
                      tags={post.tags}
                      text={post.text}
                      likes={post.likes_count}
                      comments={post.comment_count}
                    />
                  );
                } 
                else if (post.text && post.theme) {
                  return (
                    <TrendingCardWithTheme
                      theme={post.theme}
                      key={post.id}
                      tags={post.tags}
                      text={post.text}
                      likes={post.likes_count}
                      comments={post.comment_count}
                    />
                  );
                } else {
                  return "";
                }
              }
              )
            }
            {/* )} */}
          {/* </InfiniteScroll> */}
         </div>
      )}
      </div>
  );
};
export default TrendingPosts;
