import { getAccessToken, getLocalStorageRefreshToken } from "../helpers/manageToken"

const useIsAuthenticated = () => {
    const token = getAccessToken();
    if(token){
        return true;
    }
    return false;
}

export default useIsAuthenticated;