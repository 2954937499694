import { useContext } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
  changePostPrivacy,
  createPost,
  createPostPoll,
  createVideoPost,
  deleteHallPost,
  deletePostImage,
  deletePostVideo,
  editPost,
  filterPosts,
  getPosts,
  getPostStatistics,
  getPostTags,
  getPostThemes,
  getReportPostReasons,
  getTrendPosts,
  reportPost,
  searchPostsByText,
} from "../api/posts";
import { postTrendMenuContext } from "../contexts/postTrendMenuContext";
import { toast } from "react-toastify";
import { postBackgroundContext } from "../contexts/backgroundContext";

const useGetPosts = () => {
  return useInfiniteQuery(["hall-posts"], getPosts,{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess:(data)=>console.log(data),
  });
};

const useSearchPosts = (search,hall,onSuccess) => {
  return useInfiniteQuery(["search-hall-posts",hall],({pageParam})=>searchPostsByText(pageParam || `/posts/v1/posts/?search=${search}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useFilterPosts = (filters,hall,onSuccess) => {
  const filter = [];
  for (let key in filters) {
    if(filters[key] !== "")
    filter.push(`${key}=${filters[key]}`);
  }
  return useInfiniteQuery(["filter-hall-posts",hall],({pageParam})=>filterPosts(pageParam || `/posts/v1/posts/?${filter.join("&")}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useGetThemes = (onSuccess) => {
  const {setPostBackgrounds} = useContext(postBackgroundContext);
  return useQuery("post-themes", getPostThemes, {
    onSuccess:data=>{
      setPostBackgrounds(data?.data.results)
    },
    retry:true
  });
};

const useGetTrendPosts = () => {
  return useQuery(["trend-posts"],()=>getTrendPosts(`/posts/v1/post-trends/`),{
    // getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:data=>console.log(data),
    // onError:(error)=>console.log(error),
  })
}

const useGetPostTags = (onSuccess) => {
  return useInfiniteQuery(["get-post-tags"],({pageParam})=>getPostTags(pageParam || `/posts/v1/post-tags/`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
  });};

const useCreatePost = () => {
  return useMutation(
    ({ post, contentType }) => {
      return createPost(post, contentType);
    },
    {
      mutationKey: "create-post",
    }
  );
};

const useCreatePostPoll = () => {
  return useMutation((post) => createPostPoll(post), {
    mutationKey: "create-post-poll",
  });
};

const useGetPostStatistics = () => {
  return useQuery("post-statistics", getPostStatistics, {
    // onSuccess: (data) => console.log(data),
    // onError: (error) => console.log(error),
  });
};

const useDeletePost = (id) => {
  const { setMenu, menu } = useContext(postTrendMenuContext);
  const { mutate,isLoading:isDeletingPost } = useMutation(deleteHallPost, {
    mutationKey: "delete-post",
  });
  const queryClient = useQueryClient();

  const onDeletePost = () => {
    mutate(id || menu.postId, {
      onSuccess: () => {
        setMenu((prev) => {
          return { ...prev, post: false, postId: "" };
        });
        toast.success("Post deleted", {
          autoClose: 2000,
          hideProgressBar: true,
          position: "top-center",
        });
        queryClient.invalidateQueries("hall-posts");
      },
      onError: (error) => {
        setMenu((prev) => {
          return { ...prev, post: false, postId: "" };
        });
        toast.error("Could not delete post", {
          autoClose: 2000,
          hideProgressBar: true,
          position: "top-center",
        });
      },
    });
  };
  return { onDeletePost,isDeletingPost };
};

const useGetReportPostReasons = () => {
    return useQuery("report-post-reasons",getReportPostReasons);
}

const useReportPost = () => {
  return useMutation(reportPost, { mutationKey: "report-post" });
};

const useChangePostPrivacy = () => {
  return useMutation(
    (values) => changePostPrivacy(values.postId, { privacy: values.privacy }),
    { mutationKey: "change-post-privacy" }
  );
};

const useDeletePostImage = () => {
  return useMutation(deletePostImage, { mutationKey: "delete-post-image" });
};

const useEditPost = () => {
    return useMutation((values)=>{
        return editPost(values.postId,values.values,values.contentType)},{mutationKey:"delete-post"});
}

const useCreateVideoPost = () => {
  return useMutation((values)=>createVideoPost(values),{
    mutationKey:"create-video-post"
  })
}

const useDeletePostVideo = () => {
  return useMutation(deletePostVideo,{mutationKey:"delete-post-video"});
}

export {
  useGetPosts,
  useSearchPosts,
  useFilterPosts,
  useGetThemes,
  useCreatePost,
  useCreatePostPoll,
  useGetPostStatistics,
  useDeletePost,
  useReportPost,
  useChangePostPrivacy,
  useDeletePostImage,
  useEditPost,
  useGetReportPostReasons,
  useGetPostTags,
  useGetTrendPosts,
  useCreateVideoPost,
  useDeletePostVideo
};
