import { createContext, useEffect, useState } from "react";

export const themeContext = createContext();

const ThemeContextProvider = ({children})=>{
    const savedTheme = JSON.parse(localStorage.getItem("theme"));
    const [currentTheme,setCurrentTheme] = useState(savedTheme === "system" ? {name:"system",value:window.matchMedia("(prefers-color-scheme:dark)").matches?"dark":"light"}:{name:savedTheme || "light",value:savedTheme || "light"});
    const [theme,setTheme] = useState(currentTheme);
    
    const toggleTheme = (value) => {
        if(value==="system"){
            localStorage.setItem("theme",JSON.stringify("system"));
            const isDark = window.matchMedia("(prefers-color-scheme:dark)").matches
            isDark ? setTheme({name:"system",value:"dark"}) : setTheme({name:"system",value:"light"})
        }else{
            localStorage.setItem("theme",JSON.stringify(value));
            setTheme({name:value,value:value});
        }
    }
    useEffect(()=>{
        if(currentTheme.name === "system"){
            setTheme({name:currentTheme.name,value:currentTheme.value})
        }
    },[])
    useEffect(()=>{
        if(theme?.value === "dark"){
            document.documentElement.classList.add("dark");
        }else{
            document.documentElement.classList.remove("dark");
        }
    },[theme])

    return (
        <themeContext.Provider value={{toggleTheme,theme}}>
            {children}
        </themeContext.Provider>
    )
}

export default ThemeContextProvider;