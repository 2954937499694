import { ReactComponent as MessagesIcon } from "../../assets/icons/messages-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as MenuIconDark } from "../../assets/icons/menu-icon-dark.svg";
import defaultProfile from "../../assets/images/default-profile.png";

import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MemberContextMenu from "./MemberContextMenu";
import { themeContext } from "../../contexts/themeContext";
const Member = ({ setShowMember, member }) => {
  const {theme} = useContext(themeContext);
  const ref = useRef();
  const nav = useNavigate();
  const [showContextMenu,setShowContextMenu] = useState(false);
  const contextRef = useRef();

  const handleMemberMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowMember(false);
    }
  };
  
  const handleContextMenuMouseDown = (event) => {
    if (!contextRef.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMemberMouseDown);
    window.addEventListener("mousedown",handleContextMenuMouseDown);
    ref.current = document.getElementById('member-card');
    contextRef.current = document.getElementById('member-context')
  },[]);
  return (
    <div className="w-full h-full bg-[rgba(0,0,0,0.3)] flex items-center justify-center">
      <div className="px-4 py-6 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md relative" id='member-card' ref={ref}>
      <div className="flex px-4">
        <div className="flex flex-col gap-5">
        <div className="flex gap-2 items-end text-primaryTextLight dark:text-primaryTextDark">
          <span className="text-xl font-semibold">Member</span>
          <span className="text-[rgba(54, 76, 102, 0.56)] font-semibold opacity-[80%]">
            Profile
          </span>
        </div>
        <div className="mx-auto justify-between flex h-full flex-col mt-6 ml-4">
        <div className="w-32 h-[32] mb-4">
          <img
            src={member?.user.image || defaultProfile}
            alt="Member Profile"
            height="100%"
            width="100%"
            className="h-full w-full object-cover rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <span className="text-base text-primaryTextLight dark:text-primaryTextDark">{member.user.fullname}</span>
          <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{member.email}</span>
        </div>
        {/* <div className="flex items-center gap-10 mt-4 mb-10 relative">
          <div className="flex items-center w-32 px-2 rounded-sm bg-filter-grad text-primaryTextLight cursor-pointer" onClick={()=>{sessionStorage.setItem("userId",JSON.stringify(member.id));nav("/messages")}}>
            <MessagesIcon width="30" height="30" /> Messages
          </div>
{
  theme?.value == "light" ?
  <MenuIcon width="15" height="15" onClick={()=>setShowContextMenu(true)}/>
:          <MenuIconDark width="15" height="15" onClick={()=>setShowContextMenu(true)}/>

}          {
                showContextMenu &&
            <div className="absolute left-[70%] top-[0px] z-10" ref={contextRef} id="member-context">
                <MemberContextMenu member={member}/>
            </div>
            }
        </div> */}
        <div className="mt-5 ">
          <div className="flex gap-5">
            <div className="flex flex-col gap-3">
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Nickname</span>
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Date of birth</span>
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Online</span>{" "}
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Permission</span>
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Joined on</span>
            <span className="shrink-0 text-primaryTextLight dark:text-primaryTextDark">Last Active Date</span>
            </div>
            <div className="flex flex-col gap-3">
            <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">
              {member?.user?.nickname ? member.user.nickname : "N/A"}
            </span>
            <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">
              {member?.user?.dob
                ? moment(member.user.dob).format("YYYY/MM/DD")
                : "N/A"}
            </span>
            <div className="text-base">
            <span
              className={
                `inline-block w-[10px] h-[10px] rounded-full ` +
                (!member.is_online ? "bg-[#e0e0e0]" : "bg-active")
              }
            ></span></div>
            <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">{member?.user?.role?.name}</span>
            <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">{moment(member.created_at).format("YYYY/MM/DD")}</span>
            <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">{moment(member.last_seen).format("YYYY/MM/DD")}</span>
            </div>
          </div>
        </div>
      </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
