import { useContext } from "react";
import defaultProfile from "../../assets/images/default-profile.png";
import { leadershipContext } from "../../contexts/leadershipContext";

const Account = ({account}) => {
    const {setLeadershipStates} = useContext(leadershipContext);
    return (
        <div className="w-full h-10 flex gap-5 items-center mt-3 cursor-pointer" onClick={()=>setLeadershipStates(prev=> {return {...prev,account:account}})}>
            <div className="w-10 h-10">
                <img src={account?.user.image || defaultProfile} alt="Leader profile" className="w-full h-full object-cover rounded-full" width={"100%"} height={"100%"}/>
            </div>
            <span className="text-primaryTextLight dark:text-primaryTextDark">{account?.user.fullname}</span>
        </div>
    )
}

export default Account;