
const Toggle = ({onClick,state}) => {
    return (
        <div className="h-3 w-6 relative" onClick={onClick}>
            <div className={`w-6 border dark:border-dark-gray h-3 rounded-md absolute top-0 left-0 hover:cursor-pointer` + (!state ? ' bg-button-gray' : ' bg-[#F63E49]')}></div>
            <div className={`w-3 border dark:border-dark-gray h-3 rounded-full absolute z-10 top-0 bg-cardBackgroundLight  duration-300 hover:cursor-pointer` + (!state ? ' left-0' : ' translate-x-full')}></div>
        </div>
    )

}

export default Toggle;