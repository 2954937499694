import { getLocalStorageUser, getSessionStorageUser, storeLocalStorageUser, storeSessionStorageUser } from "../helpers/manageUser"
import { userActions } from "./actions"
const userReducer = (state,action) => {
    switch(action.type){
        case userActions.login:
            return {...state,user:action.payload.user}
        case userActions.update_user:
            const newUser = {...state,user:{...state.user,image:action.payload.image}}
            // if(getSessionStorageUser()){
            //     storeSessionStorageUser(newUser)
            // }else if(getLocalStorageUser()){
            //     storeLocalStorageUser(newUser)
            // }
            return newUser;
        // case userActions.refresh_token:
        //     return
        // case userActions.create_user:
        //     sessionStorage.setItem('user',JSON.stringify(action.payload.user));
        //     return {...state,user:action.payload.user};
        // case userActions.update_user:
        //     return {...state, user:{...state.user,[action.payload.property]:action.payload.value}};
        default:
            return "Invalid dispatch action";
    }
}

export default userReducer;