import React, { useState } from 'react';
import { useEffect } from 'react';
import '../App.css';
import json from "../assets/json/emojis.json";

const EmojiPicker = ({width,height,onEmojiSelect}) => {
    const [emojiIndex, setEmojiIndex] = useState(0);
    function createSvgHeading() {
        return json.map((item, index) =>
        <button className={`emoji-panel__btn ${emojiIndex === index ? 'active-category' : ''}`} key={index} onClick={() => setEmojiIndex(index)}> {createSvg(item.unicode, item.name)}</button >
        )
    }
    return (
        <div style={{width:width}} className="emoji-panel rounded-md pb-2 z-[100]">
        <div className="flex gap-[6px] overflow-x-hidden ml-2" style={{width:width}}>
            {createSvgHeading()}
        </div>
        <div className={"max-h-[300px] flex gap-1 flex-wrap overflow-y-auto px-2 rounded-b-md"} style={{height:height}}>
            {createSvgBody(emojiIndex,onEmojiSelect)}
        </div>
        </div>
    );
}

export function createSvgBody(index,onEmojiSelect) {
  return json[index].emojis.map((item, index) =>
    <button className="emoji-panel__btn" key={index} onClick={() => onEmojiSelect(item)}>{createSvg(item.unicode, item.title)}</button>
  )
}

export function createSvg(unicode, title) {
  return (
    <svg width="25" height="25">
      <use href={`/sprite.svg#${unicode}`}>
        <title>{title}</title>
      </use>
    </svg>)
}


export default EmojiPicker;