import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useGetManagementLeadershipPortfolios, useRegisterLeadershipChangeRequest } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import compareDate from "../../helpers/compareDate";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const LinkedAccount = () => {
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    
   
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[24rem] pb-5 max-h-[36rem] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setLeadershipStates(prev=>{return {...prev,showLinkedAccount:false,linkedAccount:{}}})}/>
                <div className="h-10 flex items-center justify-between px-4">
                    <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">Linked Account</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                <div className="overflow-y-scroll pb-4 h-[90%]">
                <div className="w-[80%] h-40 flex justify-center mt-5">
                    <div className="flex w-[14.5rem] justify-end">
                    <div className="w-32 h-32 relative">
                        <img src={leadershipStates.linkedAccount?.image ||  defaultProfile} alt="Leader Profile" width="100%" height="100%" className="w-36 h-full object-cover rounded-md"/>
                          {/* <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark absolute bottom-[0px] right-[0px]"> */}
                            {/* <CameraIcon height="20" width="20" className="cursor-pointer" onClick={onSelectFile}/> */}
                        {/* </div> */}
                    </div>
                    </div>
                </div>
                <div className="w-full flex items-center flex-col gap-5 h-[50%]">
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Name</label>
                        <span className="flex items-center border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark">{leadershipStates.linkedAccount.fullname}</span>
                    </div>
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Username</label>
                        <span className="flex items-center border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark">{leadershipStates.linkedAccount.username}</span>
                    </div>
                    
          {/* <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact</label>
              <input className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" placeholder="Person Contact" onChange={handleChange} name="contact"/>
            </div>
            <div className="flex justify-between w-[80%]">
              <div className="flex">
                <span className="text-primaryTextLight dark:text-primaryTextDark">Tenure</span>
              </div>
                <div className="flex flex-col gap-5 w-[13rem]">
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.start_date} placeholderText="Starting (MM/yyyy)" className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" name="start_date" onChange={(date)=>handleDateChange("start_date",date)} maxDate={new Date()}/>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.end_date} placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" name="end_date" onChange={(date)=>handleDateChange("end_date",date)}/>
                  </div>
                </div>
            </div>
            {
            leadershipStates.leaderCategory === "Management" &&
            <div className="flex items-center w-[80%] justify-end">
                <div className="w-[12.6rem] flex items-center gap-4">
                <Toggle state={dateUnknown} onClick={()=>{setDateUnknown(prev=>!prev);setLeader(prev=>{return {...prev,end_date:null}})}}/>
                <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{"To date (unknown)"}</span>
                </div>
            </div>
            } */}
            {/* <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={registerLeadershipChangeRequest}>{!isLoading ? "Done" : <BeatLoader color="white" size={10}/>}</button> */}
          </div>
          </div>
          </div>
          {/* <input type="file" accept="image/*" className="hidden" id="leader-image-input" onChange={(event)=>{setProfile(event.target.files[0]);setLeader(prev=>{return {...prev,picture:event.target.files[0]}})}}/> */}
        </div>
    )
}

export default LinkedAccount;