import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useGetLeadershipPortfoliosByType,useUpdateUserPortfolio } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import compareDate from "../../helpers/compareDate";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { themeContext } from "../../contexts/themeContext";

const EditLeadershipPortfolio = () => {
    const {theme} = useContext(themeContext);
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    const [leader,setLeader] = useState({name:leadershipStates.leader.name,user:leadershipStates.leader.user !== null ? leadershipStates.leader.user : "",portofolio:leadershipStates.leader.portofolio.id,hall:hall?.hallInfo.id,contact:leadershipStates.leader.contact,start_date:leadershipStates.leader.start_date,end_date:leadershipStates.leader.end_date,in_service:false});
    const [preview,setPreview] = useState(leadershipStates.leader.picture || "");
    const [profile,setProfile] = useState("");
    const [rotate, setRotate] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(leadershipStates.leader.portofolio.title);
    const [dateUnknown,setDateUnknown] = useState(false);
    const {data:portfolios,isLoading:fetchingPortfolios,fetchNextPage,hasNextPage} = useGetLeadershipPortfoliosByType(hall?.hallInfo.id,leadershipStates.leaderCategory)
    const {mutate:updateLeader,isLoading} = useUpdateUserPortfolio();
    const queryClient = useQueryClient();
    const onDropDown = () => {
    setShowOptions((prev) => !prev);
    setRotate((prev) => !prev);
  };
  const onSelect = (value,id) => {
    setSelectedOption(value);
    setLeader(prev=>{return {...prev,portofolio:id}})
    setRotate(false);
    setShowOptions(false);
  };
    const onSelectFile = () => {
        document.getElementById("leader-image-input").click()
    }

    const handleDateChange = (name,date) => {
      if(name === "end_date"){
        setDateUnknown(false);
      }
      setLeader(prev=> {return {...prev,[name]:date}})
  }

    const updateLeadershipPortfolio = () => {
      if(leader.name === ""){
        toast.error("Name field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.contact === ""){
        toast.error("Contact field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.end_date === "" || leader.start_date === ""){
        toast.error("Start and end date cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else{
        const formdata = new FormData();
        const newManagement = leader;
        const currentDate = new Date();
        if(dateUnknown){
          newManagement.end_date = null;
        }
        // check if management is in service
        if(newManagement.end_date !== "" && newManagement.end_date !== null){
          if(compareDate(currentDate,newManagement.start_date) === true && compareDate(currentDate,newManagement.end_date) === false){
            newManagement.in_service = true;
          }else{
            newManagement.in_service = false;
          }
        }else{
          newManagement.in_service = true;
        }
        for(let data in newManagement){
          if(data === "start_date" || data === "end_date"){
            if(newManagement[data] === null){
              formdata.append(data,"");
            }else{
              formdata.append(data,moment(newManagement[data]).format("YYYY-MM-DD"))
            }
          }else{
            formdata.append(data,newManagement[data]);
          }
        }
        updateLeader({values:formdata,id:leadershipStates.leader.id},{
          onSuccess:(data) => {
            toast.success("Leadership Portfolio updated",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            if(leadershipStates.leaderCategory === "Executive"){
                queryClient.invalidateQueries("current-hall-executives");
                queryClient.invalidateQueries("past-hall-executives");
                queryClient.invalidateQueries("past-hall-executives-2");
                queryClient.invalidateQueries("past-hall-executives-3");
            }else if(leadershipStates.leaderCategory === "Management"){
                queryClient.invalidateQueries("current-hall-management");
                queryClient.invalidateQueries("past-management");
            }
            setLeadershipStates(prev=>{return {...prev,editLeadershipPortfolio:false,leader:{}}})
          },
          onError:(error)=>{
            toast.error("Could not update leadership portfolio",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          }
        })
      }
    }

    const handleChange = (event) => {
      if(event.target.name ==="start_date" || event.target.name === "end_date"){
        const today = new Date();
        const day = today.getDate();
        setLeader(prev=> {return {...prev,[event.target.name]:`${event.target.value}-${day}`}})
      }else{
        setLeader(prev=> {return {...prev,[event.target.name]:event.target.value}})
      }
      }


    useEffect(() => {
        if (!profile) {
          setPreview(null);
          return;
        }
    
        const objectUrl = URL.createObjectURL(profile);
        setPreview(objectUrl);
    
        return () => URL.revokeObjectURL(objectUrl);
      }, [profile]);
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[24rem] max-h-[90%] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setLeadershipStates(prev=>{return {...prev,editLeadershipPortfolio:false,linkedAccount:{}}})}/>
                <div className="h-10 flex items-center justify-between px-4">
                    <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">Edit Leadership Portfolio</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                <div className="overflow-y-scroll pb-4 h-[90%]">
                <div className="w-[80%] h-40 flex justify-center mt-5">
                    <div className="flex w-[14.5rem] justify-end">
                    <div className="w-32 h-32 relative">
                        <img src={ preview || leadershipStates.leader.picture || defaultProfile} alt="Leader Profile" width="100%" height="100%" className="w-36 h-full object-cover rounded-md"/>
                          <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark absolute bottom-[0px] right-[0px]">
                            <CameraIcon height="20" width="20" className="cursor-pointer" onClick={onSelectFile}/>
                          </div>
                    </div>
                    </div>
                </div>
                <div className="w-full flex items-center flex-col gap-5 h-[50%]">
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Name</label>
                            <input className="focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark dark:border-dark-gray" placeholder="Person Name" name="name" onChange={handleChange} defaultValue={leadershipStates.leader.name}/>
                    </div>
                    <div className="flex justify-between w-[80%]">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">
              Portfolio
            </label>
            <div className="flex items-center gap-2 rounded-md h-8 border dark:border-dark-gray bg-backgroundLight dark:bg-backgroundDark relative w-[13rem]">
              <input
                placeholder="Select Position"
                id="title"
                className="focus:outline-none text-primaryTextLight dark:text-primaryTextDark pl-4 w-[11rem] bg-backgroundLight dark:bg-backgroundDark"
                value={leadershipStates.leader.portofolio.title}
                readOnly
                // onClick={onDropDown}
                // onChange={(event)=>setSelectedOption(event.target.value)}
              />
              {/* <div className="flex items-center gap-2">
                {
                  theme?.value == "light" ?
                  <SelectIcon
                  width="12"
                  height="12"
                  onClick={onDropDown}
                  className={
                    "cursor-pointer " +
                    (rotate ? "rotate-180 delay-200" : "delay-200")
                  }
                />:
                <SelectIconDark
                  width="12"
                  height="12"
                  onClick={onDropDown}
                  className={
                    "cursor-pointer " +
                    (rotate ? "rotate-180 delay-200" : "delay-200")
                  }
                />
                
                }
              </div> */}
              {/* {showOptions && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 z-10 max-h-36 overflow-auto " id="management-portfolio">
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"management-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              return (
                            <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={portfolio.id}
                            onClick={() => onSelect(portfolio.title,portfolio.id)}>
                            <span>{portfolio.title}</span>
                            </div>                                  )
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )} */}
            </div>
          </div>
          <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact</label>
              <input className="placeholder:font-thin focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark dark:border-dark-gray" placeholder="Person Contact" onChange={handleChange} name="contact" defaultValue={leadershipStates.leader.contact}/>
            </div>
            {
              leadershipStates.leaderCategory === "Management" && 
              <div className="flex justify-between w-[80%]">
            <div className="flex flex-col gap-5 justify-around">
                <span className="text-primaryTextLight dark:text-primaryTextDark">Start Date</span>
                <span className="text-primaryTextLight dark:text-primaryTextDark">End Date</span>
                </div>
                <div className="flex flex-col gap-5 w-[13rem]">
                <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={new Date(leader.start_date)} placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark dark:border-dark-gray" name="start_date" onChange={(date)=>handleDateChange("start_date",date)}/>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.end_date !== null ? new Date(leader.end_date):leader.end_date} placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark dark:border-dark-gray" name="end_date" onChange={(date)=>handleDateChange("end_date",date)}/>
                  </div>
                {/* <input className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" placeholder="Starting (MM/yyyy)" type={"month"} name="start_date" onChange={handleChange}/> */}
                {/* <input className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" placeholder="Ending (MM/yyyy)" type={"month"} name="end_date" onChange={handleChange}/> */}
                </div>
            </div>
            }
            {
                leadershipStates.leaderCategory === "Management" && 
            <div className="flex items-center w-[80%] justify-end">
                <div className="w-[12.6rem] flex items-center gap-4">
                <Toggle state={dateUnknown} onClick={()=>{setDateUnknown(prev=>!prev);setLeader(prev=>{return {...prev,end_date:null}})}}/>
                <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{"To date (unknown)"}</span>
                </div>
            </div>
            }
            <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={updateLeadershipPortfolio}>{!isLoading ? "Done" : <BeatLoader color="white" size={10}/>}</button>
          </div>
          </div>
          </div>
          <input type="file" accept="image/*" className="hidden" id="leader-image-input" onChange={(event)=>{setProfile(event.target.files[0]);setLeader(prev=>{return {...prev,picture:event.target.files[0]}})}}/>
        </div>
    )
}

export default EditLeadershipPortfolio;