import { useEffect } from "react";

const LinearProgressBar = ({percent}) => {
  return (
    <div className={"flex flex-wrap justify-center flex-col"}>
      <div className={"bg-[#e7e4da] rounded-full h-2 overflow-hidden relative"} style={{ width: "100%" }}>
        <div
          className={"rounded-full h-full w-full absolute bg-[#F63E49]"}
          style={{
            left: percent - 100 + "%",
            transition: "3s"
          }}
        />
      </div>
    </div>
  );
};

export default LinearProgressBar;
