const getDays = (max) => {
    const days = [];
    for (let day=1;day<=max;day++){
        days.push(day)
    }
    return days;
}

export default getDays;

