import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";

const Portofolio = ({portfolio}) => {
    const {setLeadershipStates} = useContext(leadershipContext);
    return (
        <div className="w-full flex py-2 px-6">
            <span className="w-[40%] text-primaryTextLight dark:text-primaryTextDark opacity-[80%]">{portfolio.title}</span>
            <span className="w-[30%] text-primaryTextLight dark:text-primaryTextDark opacity-[80%]">{portfolio.leadership_type}</span>
            <span className="w-[15%] text-primaryTextLight dark:text-primaryTextDark opacity-[80%]">{portfolio.is_active ? "Active":"Inactive"}</span>
            <button className="bg-filter-grad px-4 text-primaryTextLight rounded-sm h-6 ml-auto" onClick={()=>setLeadershipStates(prev=>{return {...prev,editPortofolio:true,portofolio:portfolio}})}>Edit</button>
        </div>
    )
}

export default Portofolio;