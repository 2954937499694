// const { useEffect, useState } = require("react");
import moment from "moment";
import React, { useEffect,useState } from "react";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import ProgressiveImage from "react-progressive-graceful-image";
import { ClipLoader } from "react-spinners";

const Indicator = ({ currentSlide, amountSlides, nextSlide }) => {
  return (
    <div className="flex flex-nowrap absolute bottom-[8px] w-full justify-center">
      {Array(amountSlides)
        .fill(1)
        .map((_, i) => (
          <div
          className={`w-[10px] h-[10px] rounded-full bg-white dark:bg-dark-gray m-[5px] transition-all duration-700 ease-in-out ` + (currentSlide === i ? "opacity-[100%]" : "opacity-[50%]")}
            key={i}
            onClick={() => nextSlide(i)}
          ></div>
        ))}
    </div>
  );
};

const Image = React.memo(({item,index,currentSlide})=>{
//  useEffect(()=>{
//   console.log(item)
//   },[])
  return(   <div
          className="h-full w-full shrink-0 bg-center bg-cover transition-all duration-700 ease-in-out flex items-center justify-center"
          key={item.id}
          style={{
            // backgroundImage: `url(${image.image})`,
            marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
          }}
        >
            {
          <div className="h-full w-full flex items-center justify-center">
          <ProgressiveImage src={item?.image} placeholder="">
              {(src, loading) => {
                // if(loading){
                //   return <div>
                //     <ClipLoader size="30" color="#F63E49"/>
                //   </div>
                // }else{
                  return <img src={src} alt="Moment" height="100%" width="100%" className={`w-full h-full object-cover transition-opacity duration-500 ease-in-out ${(loading) ? 'opacity-0' : 'opacity-100'}`}/>
                // }
          }}
              </ProgressiveImage>

          </div>
        }
        </div>)
})

const NewsImageSlider = ({
  images = [],
  autoPlay = true,
  autoPlayTime = 4000,
  onClose,
  show,
  children,
  // currentSlide,
  // setCurrentSlide,
  ...props
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  function nextSlide(slideIndex = currentSlide + 1) {
    const newSlideIndex = slideIndex >= images.length ? 0 : slideIndex;

    setCurrentSlide(newSlideIndex);
  }

  function prevSlide(slideIndex = currentSlide - 1){
    if(slideIndex >=0 ){
        setCurrentSlide(slideIndex);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, autoPlayTime);

    return () => clearTimeout(timer);
  }, [currentSlide]);

  return (
    <div
      className={"h-full w-full flex-nowrap overflow-x-hidden relative flex rounded-md"}
      {...props}
    >
    <div className="absolute z-20 top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center cursor-pointer" onClick={()=>prevSlide()}>
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <LeftIcon height={"20"} width={"20"} className="z-20"/>
    </div>
    <div className="absolute z-20 top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center cursor-pointer" onClick={()=>nextSlide()}>
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <RightIcon height={"20"} width={"20"} className="z-20"/>
    </div>
      {images?.map((image, index) => (
        <Image item={image} key={image.id} index={index} currentSlide={currentSlide}/>
      ))}
      <Indicator
        currentSlide={currentSlide}
        amountSlides={images.length}
        nextSlide={nextSlide}
      />
    </div>
  );
};

export default NewsImageSlider;
