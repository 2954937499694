import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import  {BeatLoader} from 'react-spinners'
import { useRequestChange } from "../../hooks/hallHooks";
import { useQueryClient } from "react-query";
const EditHallInfoModal = ({onClose}) => {
  const {hall} = useContext(hallContext);
  const [updates,setUpdates] = useState({});
  const {mutate:editHallInfo,isLoading} = useRequestChange();
  const query = useQueryClient();

  const onChange = (event) => {
    setUpdates(prevUpdates=>{
      return {...prevUpdates,[event.target.name]:event.target.value}
    })
  }
  const onChangeRequest = () => {
    const request = [];
    for (let update in updates){
      if(update === "Name"){
      if(updates[update] !== hall?.hallInfo?.name){
        const new_request = {item_to_change:"Name",old_value:hall?.hallInfo?.name,new_value:updates[update]};
        request.push(new_request);
      }
    }
    else if(update === "Nick Name"){
      if(updates[update] !== hall?.hallInfo?.nickname){
        const new_request = {item_to_change:"Nick Name",old_value:hall?.hallInfo?.nickname,new_value:updates[update]};
        request.push(new_request);
      }
    }
    else if(update === "Collective Name"){
      if(updates[update] !== hall?.hallInfo?.collective_name){
        const new_request = {item_to_change:"Collective Name",old_value:hall?.hallInfo?.collective_name,new_value:updates[update]};
        request.push(new_request);
      }
    }
    }
    if(request.length !== 0){
      editHallInfo({hall:hall?.hallInfo.id,change_type:"Profile",change_tables:request},{
          onSuccess:(data)=>{
            query.invalidateQueries("check-profile-request");
            toast.success("Change request submitted",{position:"top-center",autoClose:1000,hideProgressBar:true});
            onClose();
          },
          onError:(error)=>{
            toast.error("Failed to request change",{position:"top-center",autoClose:1000,hideProgressBar:true})
        }})
    }
  }

  return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:200}}>
      <section className="h-[60%] w-[35%] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark relative">
        <CloseIcon
          width="25"
          height="25"
          onClick={onClose}
          className="cursor-pointer absolute top-[-5px] right-[-5px]"
        />
        <div className="w-full px-4 py-4 flex items-center justify-between">
          <span className="text-lg font-semibold text-primaryTextLight dark:text-primaryTextDark">Edit Hall Info</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="flex flex-col w-full h-[70%] items-center gap-4 py-4">
            <div className="flex flex-col shrink-0 w-[50%]">
                <span className="text-sm">Name</span>
                <input type="text" name="Name" className="w-full h-10 rounded-md border shrink-0 px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark dark:border-dark-gray focus:outline-none" defaultValue={hall?.hallInfo?.name} onChange={onChange}/>
            </div>
            <div className="flex flex-col shrink-0 w-[50%]">
                <span className="text-sm">Nickname</span>
                <input type="text" name="Nick Name" className="w-full h-10 rounded-md border shrink-0 px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark dark:border-dark-gray focus:outline-none" defaultValue={hall?.hallInfo?.nickname} onChange={onChange}/>
            </div>
            <div className="flex flex-col shrink-0 w-[50%]">
                <span className="text-sm">Collective Name</span>
                <input type="text" name="Collective Name" className="w-full h-10 rounded-md border dark:border-dark-gray shrink-0 px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark focus:outline-none" defaultValue={hall?.hallInfo?.collective_name} onChange={onChange}/>
            </div>
        </div>
        <div className="w-full text-center mt-auto">
                <button className="bg-linear-grad text-white w-[50%] py-2 rounded-sm" onClick={onChangeRequest}>{!isLoading ? "Change Request" : <><BeatLoader color="#fff" size={10}/></>}</button>
            </div>
      </section>
    </div>
  );
};

export default EditHallInfoModal;
