import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { checkAboutChangeRequestExists, checkProfileChangeRequestExists, editHallInfo, getHallInfo, requestChange, updateHallCoverPhoto, updateHallProfilePicture, updatePicture } from "../api/hall";
import { hallContext } from "../contexts/hallContext";
import { hallActions } from "../reducers/actions";

const useGetHallInfo = (id) => {
    const {setHall} = useContext(hallContext);
    return useQuery(['hall-info'],()=>getHallInfo(id),{
        onSuccess:(data)=>{
            setHall(prev=>{return {...prev,hallInfo:data?.data}});
        },
        retry:true,
        enabled:false
        // refetchOnMount:true,
        // refetchOnReconnect:true,
        // refetchOnWindowFocus:true,
        // retry:true,
        // staleTime:1 * 60 * 1000
    })
}

const useEditHallInfo = (id) => {
    return useMutation((values)=>editHallInfo(id,values),{
        mutationKey:"edit-hall-info"
    })
}

const useUpdateProfilePicture = (id) => {
    return useMutation((data)=>{
       return updateHallProfilePicture(id,data)
    },{
        mutationKey:"update-profile-picture"
    })
}

const useUpdateCoverImage = (id) => {
    return useMutation((data)=>{
        return updateHallCoverPhoto(id,data)
    },{
        mutationKey:"update-cover-image"
    })
}

const useRequestChange = () => {
    return useMutation(requestChange,{
        mutationKey:"request-change"
    })
}

const useCheckProfileChangeRequestExists = (hall_id,onSuccess) => {
    return useQuery("check-profile-request",()=>checkProfileChangeRequestExists(hall_id),{
        onSuccess,
        refetchOnMount:true,
        refetchOnReconnect:true,
        refetchOnWindowFocus:true,
        retry:true,
        // staleTime:1 * 60 * 1000
    })
}

const useCheckAboutChangeRequestExists = (hall_id,onSuccess) => {
    return useQuery(["check-about-request",hall_id],()=>checkAboutChangeRequestExists(hall_id),{
        onSuccess,
        refetchOnMount:true,
        refetchOnReconnect:true,
        refetchOnWindowFocus:true,
        retry:true,
        // staleTime:1 * 60 * 1000
    })
}

export {useGetHallInfo,useEditHallInfo,useUpdateProfilePicture,useUpdateCoverImage,useRequestChange,useCheckAboutChangeRequestExists,useCheckProfileChangeRequestExists}