import { useEffect, useRef, useState } from "react";

const TextWithBgPostContent = ({ theme, text }) => {
  const colors = [];
  for(let color of theme.bg_colors){
    colors.push(color.color)
  }
  const bgColor = colors.length === 1 ? colors[0] : `linear-gradient(45deg, ${colors.join(",")})`
  const [seeMore,setSeeMore] = useState(false);
  const ref = useRef(null);

  useEffect(()=>{
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setSeeMore(true)
    }
  },[ref])
  return (
    <div className="mt-4 w-full">
      <div className="text-lg w-full">
        <div className="h-80 w-full flex items-center justify-center px-10 rounded-md" style={{background:bgColor,color:theme.text_color}}>
        <p ref={ref} className={`w-full text-center font-bold text-2xl break-words` + (seeMore ? " line-clamp-6" : "")}>
          {text}
        </p>
        </div>
      </div>
    </div>
  );
};

export default TextWithBgPostContent;
