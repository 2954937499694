import { useContext, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import { useGetReportPostReasons, useReportPost } from "../../hooks/postHooks";
import { useQueryClient } from "react-query";
import OptionSelector from "./OptionSelector";

const ReportPostModal = () => {
  const [reason, setReason] = useState("");
  const { menu, setMenu } = useContext(postTrendMenuContext);
  const { mutate: reportPost, isLoading } = useReportPost();
  const { data: postReasons } = useGetReportPostReasons();
  const queryClient = useQueryClient();
  const onReportPost = () => {
    if (reason.length !== 0) {
      reportPost(
        { post: menu.postId, reason },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries("hall-posts");
            setMenu((prev) => {
              return { ...prev, reportPost: false };
            });
            toast.success("Post reported", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
          onError: (error) => {
            toast.error("Could not report post", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
        }
      );
    } else {
      toast.error("Reason cannot be empty", {
        autoClose: 2000,
        position: "top-center",
        hideProgressBar: true,
      });
    }
  };
  return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.2)]"
      style={{ zIndex: "100" }}
    >
      <div className="flex flex-col bg-[#f6f6f6] dark:bg-cardBackgroundDark w-[22rem] h-60 p-4 rounded-md gap-2 relative">
          <CloseIcon
            width="25"
            height="25"
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
            onClick={() =>
              setMenu((prev) => {
                return { ...prev, reportPost: false };
              })
            }
          />
        <div className="flex justify-between">
          <label htmlFor="reason" className="text-primaryTextLight dark:text-primaryTextDark">
            Why are you reporting this post?
          </label>
        </div>
        <div className="w-full h-[2.5rem] rounded-md mt-2">
          <OptionSelector options={postReasons?.data?.results} name="reason" onChange={(name,value)=>setReason(value)}/>
        </div>
        <div className="w-full flex justify-end gap-4 mt-auto">
          <button
            className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md cursor-pointer"
            onClick={() =>
              setMenu((prev) => {
                return { ...prev, reportPost: false };
              })
            }
          >
            Cancel
          </button>
          <button
            className="bg-[#F63E49] text-white py-1 px-2 rounded-md cursor-pointer"
            onClick={onReportPost}
          >
            {!isLoading ? "Report" : <BeatLoader color="white" size={10} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPostModal;
