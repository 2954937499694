import { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import { useGetHallNews } from "../../hooks/newsHooks";
import NewsCard from "./NewsCard";
import { ReactComponent as NoDataIcon } from "../../assets/icons/no-data.svg";

const NewsList = ({ activeCategory, parentId }) => {
  const { hall } = useContext(hallContext);
  const {
    data: hallNews,
    hasNextPage,
    fetchNextPage,
    isLoading: isFetchingNews,
  } = useGetHallNews(hall?.hallInfo?.id, activeCategory?.id);

  return (
    <div className="flex flex-wrap gap-8">
      {isFetchingNews ? (
        <div className="flex w-full justify-center mt-2 mb-2">
          <ClipLoader size={30} color="#F63E49" />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={hallNews?.pages?.length * 64 || 64}
          next={fetchNextPage}
          style={{ display: "flex", flexDirection: "row", flexWrap:"wrap",gap:"2rem" }} //To put endMessage and loader to the top.
          hasMore={hasNextPage}
          scrollThreshold={1}
          loader={
            <div className="flex w-full justify-center mt-2 mb-2">
              <ClipLoader size={30} color="#F63E49" />
            </div>
          }
          scrollableTarget={parentId}
          className="scrollbar-hide"
        >
          {hallNews?.pages[0]?.data?.results?.length === 0 ? (
            <div className="w-full flex items-center justify-center mt-10">
              <NoDataIcon height="400" width="400" />
            </div>
          ) : (
            hallNews?.pages?.map((group, i) => {
              return group?.data?.results?.map((news) => {
                return <NewsCard key={news.id} news={news} />;
              });
            })
          )}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default NewsList;
