import {ReactComponent as MessagesIcon} from "../../assets/icons/messages-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as MenuIconDark } from "../../assets/icons/menu-icon-dark.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import MemberContextMenu from "./MemberContextMenu";
import { themeContext } from "../../contexts/themeContext";

const NewMemberCard = ({member,setShowNewMember,setNewMember}) => {
    const nav = useNavigate();
    const {theme} = useContext(themeContext);
    const [showContextMenu,setShowContextMenu] = useState(false);
    const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
    return (
        <div className="flex flex-col w-56 h-72 rounded-lg shadow-5xl pl-8 pb-4 pt-6 cursor-pointer dark:bg-cardBackgroundDark relative">
            <div className="absolute top-5 right-5">
                {
                    theme?.value == "light" ?
                    <MenuIcon width='15' height="15" className={"cursor-pointer"} onClick={()=>setShowContextMenu(true)}/>

                    :                <MenuIconDark width='15' height="15" className={"cursor-pointer"} onClick={()=>setShowContextMenu(true)}/>

                }
            </div>
            <div className="w-full flex flex-col" onClick={()=>{setNewMember(member);setShowNewMember(true)}}>
            <div className="h-36 w-32">
                <img src={member.user.image || defaultProfile} alt="Member Profile" height="100%" width="100%" className="w-full h-full rounded-xl object-cover"/>
            </div>
            <div className="mt-4">
                <span className="text-normal font-semibold text-primaryTextLight dark:text-primaryTextDark line-clamp-1 block">{member.user.fullname}</span>
                <span className="text-[14px] text-primaryTextLight dark:text-primaryTextDark truncate block">{member.email}</span>
            </div>
            </div>
            <div className="flex items-center gap-5 mt-auto">
                {/* <div className="flex items-center w-32 px-2 rounded-sm bg-filter-grad text-primaryTextLight cursor-pointer" onClick={()=>{sessionStorage.setItem("userId",JSON.stringify(member.id)); nav("/messages")}}>
                    <MessagesIcon width="30" height="30"/> Messages
                </div> */}
                {/* {
                    theme?.value == "light" ?
                    <MenuIcon width='15' height="15" className={"cursor-pointer"} onClick={()=>setShowContextMenu(true)}/>

                    :                <MenuIconDark width='15' height="15" className={"cursor-pointer"} onClick={()=>setShowContextMenu(true)}/>

                } */}
            </div>
            {
                showContextMenu &&
            <div className="absolute right-[5%] top-[5%] z-10" ref={ref}>
                <MemberContextMenu member={member}/>
            </div>
            }
        </div>
    )
}

export default NewMemberCard;