import flatten from "lodash.flatten";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import { useGetHallMoments } from "../../hooks/momentsHooks";
import { hallActions } from "../../reducers/actions";
import noMoments from "../../assets/icons/no-moments.png";
import Lightbox from "yet-another-react-lightbox";

const Moments = () => {
  const [viewImages,setViewImages] = useState(false);
  const nav = useNavigate();
  const { user_state } = useContext(userContext);
  const { data: moments, isLoading } = useGetHallMoments(
    user_state?.user?.user?.hall
  );

  const hallMoments = flatten(
    moments?.pages[0].data?.results?.map((moment) => {
      return moment.pictures;
    })
  );
  return (
    <div className="h-[340px] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-2 pl-4 pr-4 mb-5 flex flex-col">
      <div className="flex h-8 justify-between items-center w-full">
        <span className="text-primaryTextLight dark:text-primaryTextDark font-medium text-lg">
          Moments
        </span>
        <span
          className="text-sm text-[#F63E49] cursor-pointer"
          onClick={() => nav("/moments")}
        >
          See more
        </span>
      </div>
      {isLoading ? (
        <div className="flex flex-wrap justify-between">
          {Array(9)
            .fill(0)
            .map((placeholder, index) => {
              return (
                <div
                  className="h-[5.5rem] w-[5.5rem] mb-2 bg-slate-500 loader"
                  key={index}
                ></div>
              );
            })}
        </div>
      ) : hallMoments.length === 0 ? (
        <div className="flex justify-center items-center w-full h-full">
          <div className="flex flex-col items-center gap-2">
            <img
              src={noMoments}
              height="100%"
              width="100%"
              className=" w-10 h-10"
            />
            <span className="text-gray-400 text-sm">No Moments</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-between">
          {hallMoments.slice(0, 9).map((moment) => {
            return (
              <div className="h-[5.5rem] w-[5.5rem] mb-2 cursor-pointer" key={moment.id} onClick={()=>setViewImages(true)}>
                <img
                  src={moment.image}
                  alt="moments"
                  className="object-cover h-full w-full text-xs"
                />
              </div>
            );
          })}
        </div>
      )}
       <Lightbox
        open={viewImages}
        close={() => setViewImages(false)}
        slides={[...hallMoments.map(img=>{return {src:img.image}})]}
      />
    </div>
    // </div>
  );
};

export default Moments;
