import {ReactComponent as MessagesIcon} from "../../assets/icons/messages-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as MenuIconDark } from "../../assets/icons/menu-icon-dark.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import moment from "moment";
import MemberContextMenu from "./MemberContextMenu";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { memberContext } from "../../contexts/memberContext";
import { useNavigate } from "react-router-dom";
import { themeContext } from "../../contexts/themeContext";


const RowMember = ({member,setShowMember,setMember}) => {
    const nav = useNavigate();
    const [showContextMenu,setShowContextMenu] = useState(false);
    const {theme} = useContext(themeContext);
    const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
    return (
        <>
        <div className="flex text-primaryTextLight dark:text-primaryTextDark items-center mb-2 mt-2 w-full relative">
            <div className="flex items-center gap-2 w-[30%] cursor-pointer" onClick={()=>{setMember(member);setShowMember(true)}}>
                <div className="w-10 h-10 rounded-md">
                    <img src={member.user.image || defaultProfile} alt="Profile" width="100%" height="100%" className="h-full w-full object-cover rounded-lg"/>
                </div>
                <div className="flex flex-col justify-center w-[75%]">
                    <span className="text-sm font-semibold truncate block">{member.user.fullname}</span>
                    <span className="text-xs font-light truncate block">{member.email}</span>
                </div>
            </div>
            <span className="text-sm font-semibold text-center truncate w-[10%] flex justify-start">
                {member.user.nickname ? member.user.nickname : "N/A"}
            </span>
            {/* <span className="text-sm font-semibold w-[8%] flex justify-start">
                {member.dob ? moment(member.user.dob).format("YYYY/MM/DD") : "N/A"}
            </span> */}
            <div className="flex justify-center w-[10%]">

            <span className={`inline-block w-[10px] h-[10px] rounded-full ` + (!member.is_online ? "bg-[#e0e0e0]" : "bg-active")}></span>
            </div>
            <span className="text-sm font-semibold w-[15%] flex justify-start">{member.user.role.name}</span>
            <span className="text-sm font-semibold w-[10%]">{moment(member.created_at).format("YYYY/MM/DD")}</span>
            <span className="text-sm font-semibold w-[10%]">{moment(member.last_seen).format("YYYY/MM/DD")}</span>
            <div className="flex gap-5 items-center justify-end w-[10%]">
                {/* <MessagesIcon width="30" height="30" className="cursor-pointer" onClick={()=>{sessionStorage.setItem("userId",JSON.stringify(member.id)); nav("/messages")}}/> */}
                {
                    theme?.value == "light" ?
                    <MenuIcon width="15" height="15" className={"cursor-pointer" + (showContextMenu ? " z-10" :"")} onClick={()=>setShowContextMenu(true)}/>
:                <MenuIconDark width="15" height="15" className={"cursor-pointer" + (showContextMenu ? " z-10" :"")} onClick={()=>setShowContextMenu(true)}/>

                }
            </div>
            {
                showContextMenu &&
            <div className="absolute right-[40px] top-[10px] z-10" ref={ref}>
                <MemberContextMenu member={member}/>
            </div>
            }
        </div>
        <hr className="dark:border-dark-gray"/>
        </>
    );
}

export default RowMember;