import authClient from "./authAxiosInstance"

const getHallNews = (pageParam) => {
    return authClient.get(pageParam);
}

const createNews = (values) => {
    return authClient.post("/buzz/v1/buzz/",values);
}

const getSingleNews = (newsId) => {
    return authClient.get(`/buzz/v1/buzz/${newsId}/`)
}

const deleteNews = (newsId) => {
    return authClient.delete(`/buzz/v1/buzz/${newsId}/`)
}

const updateNews = (newsId,values) => {
    return authClient.patch(`buzz/v1/buzz/${newsId}/`,values,{
        headers:{
            "Content-Type":"application/json"
        }
    })
}

const getNewsTypes = () => {
    return authClient.get(`/buzz/v1/buzz-type/`);
}

const postNewsPic = (values,uploadProgress) => {
    return authClient.post(`/buzz/v1/buzzpic/`,values,{
        headers:{
            "Content-Type":"multipart/form-data"
        },
        onUploadProgress:uploadProgress
    })
}

const getRelatedNews = (newsId) => {
    return authClient.get(`/buzz/v1/buzz/${newsId}/related-buzz/`);
}

export {
    getHallNews,
    createNews,
    getSingleNews,
    deleteNews,
    updateNews,
    getNewsTypes,
    postNewsPic,
    getRelatedNews
}