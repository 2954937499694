import {ReactComponent as CloseIcon} from "../../assets/icons/close-icon.svg";
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";

const AboutHallModal = ({onClose}) => {
    const {hall} = useContext(hallContext);
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center z-10 absolute bg-[rgba(0,0,0,0.3)]">
        <section className="w-[40%] h-[450px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark relative">
            <CloseIcon width="25" height="25" onClick={onClose} className="cursor-pointer absolute top-[-5px] right-[-5px]"/>
            <div className="flex justify-between items-center px-4 py-3">
                <span className="font-semibold text-lg text-primaryTextLight dark:text-primaryTextDark">About Hall</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full px-4 py-4 h-[85%]">
                <textarea readOnly className="w-full h-full resize-none p-2 focus:outline-none rounded-md text-md bg-cardBackgroundLight dark:bg-cardBackgroundDark" value={hall?.hallInfo?.about}/>
            </div>
        </section>
        </div>
    )
}

export default AboutHallModal;