import { useRef } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { hallContext } from "../../contexts/hallContext";
import { useGetCurrentExecutives } from "../../hooks/leadersHooks";
import { hallActions } from "../../reducers/actions";
import LeadershipCard from "./LeadershipCard";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-icon.svg";
import noLeaders from "../../assets/icons/no-leaders.png";

const Leadership = () => {
  const ref = useRef();
  const nav = useNavigate();
  const { hall } = useContext(hallContext);
  
  const { data: leaders } = useGetCurrentExecutives(hall?.hallInfo.id);
  const onScroll = (offset) => {
    ref.current.scrollTo({left:ref.current.scrollLeft+offset,behavior:'smooth'});
  };
  return (
    <div className="h-64 w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md py-2 mb-5 relative">
{  leaders?.data?.results?.length !== 0 &&    <button
        className="absolute z-10 top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center"
        onClick={() => onScroll(-144)}
      >
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <LeftIcon className="z-20" height="20" width="20" />
      </button>}
      <div className="flex h-8 justify-between items-center w-full pl-4 pr-4">
        <span className="text-primaryTextLight dark:text-primaryTextDark font-medium text-md">Leadership</span>
        <span
          className="text-sm text-[#F63E49] cursor-pointer"
          onClick={() => nav("/leadership")}
        >
          See more
        </span>
      </div>
      {
        leaders?.data?.results?.length === 0 ?
        <div className="flex m-auto flex-col items-center h-full w-full justify-center gap-2">
          <img src={noLeaders} height="100%" width="100%" className="w-10 h-10"/>
          <span className="text-gray-400 text-sm">Current Leaders not added</span>
        </div> : 
        <div
        className="flex w-full relative overflow-x-scroll scrollbar-hide"
        ref={ref}
      >
        {leaders?.data?.results?.map((leader) => {
          return <LeadershipCard leader={leader} key={leader.id} />;
        })}
      </div>}
      {leaders?.data?.results?.length !== 0 &&
        <button
        className="absolute z-10 top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center"
        onClick={() => onScroll(144)}
      >
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <RightIcon className="z-20" height="20" width="20" />
      </button>}
    </div>
  );
};

export default Leadership;
