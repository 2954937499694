import { createContext, useState } from "react";

export const memberContext = createContext();

const MemberContextProvider = ({children}) => {
    const [memberOptions,setMemberOptions] = useState({block:false,blockMember:"",report:false,reportMember:"",addLeadershipPortfolio:false,leadershipMember:""});
    return (
        <memberContext.Provider value={{memberOptions,setMemberOptions}}>
            {children}
        </memberContext.Provider>
    )
}

export default MemberContextProvider