import React, { useContext, useEffect } from "react";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";
import Banner from "../../components/hallpage/Banner";
import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import { logoutContext } from "../../contexts/logoutContext";
import { useLogout } from "../../hooks/authHooks";
import RelatedNews from "../../components/news/RelatedNews";
import AddNews from "../../components/news/AddNews";
import AddNewsPictures from "../../components/news/AddNewsPictures";
import NewsPreview from "../../components/news/NewsPreview";
import { useNavigate } from "react-router-dom";
import EditNewsImages from "../../components/news/EditNewsImages";
import { addNewsContext } from "../../contexts/addNewsContext";

const AddNewsPage = () => {
  const nav = useNavigate()
const {logout,setLogout} = useContext(logoutContext);
const [addPictures,setAddPictures] = useState(false);
const [discard,setDiscard] = useState(false);
const [editImages,setEditImages] = useState(false);
const {news,setNews} = useContext(addNewsContext);
const {onLogout} = useLogout();
useEffect(()=>{
  document.title = "LinkLounge | News"
},[])

return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto">
      <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-20`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full justify-between gap-8 pb-5 mt-3 px-5">
            <AddNews setAddPictures={setAddPictures} setDiscard={setDiscard} setEditImages={setEditImages}/>
            <NewsPreview/>
         </section>
        </main>
      </div>
        {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
        }
        {
          addPictures && 
          <div className="absolute h-full w-full z-50">
          <AddNewsPictures setAddPictures={setAddPictures}/>
        </div>
        }
        {
          discard && 
          <ConfirmModal question={"Do you want to discard your write up?"} onCancel={()=>setDiscard(false)} onConfirm={()=>{setNews({title:"",body:"",source:"",type:"",hall:"",is_hall_buzz:"",pictures:[],previewPictures:[],created_by:"",date_created:"",preview:false});nav("/news")}} confirmAction="Discard"/>
        }
        {
          editImages && 
          <EditNewsImages onClose={()=>setEditImages(false)}/>
        }
    </PageContainer>
  );
};

export default AddNewsPage;
