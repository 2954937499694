import React from 'react';



const Input = ({label,name,placeholder,type,customstyle,onChange,onBlur}) => {
    return (
        <div className={customstyle}>
            <label htmlFor={name} className="font-medium text-base">{label}</label>
            <input id={name} placeholder={placeholder} type={type} className={"px-2 h-8 flex items-center border dark:border-dark-gray rounded-md w-full focus:outline-[#FE005B] placeholder:font-thin dark:bg-backgroundDark dark:text-primaryTextDark dark:focus:outline-none text-base"} onBlur={onBlur} onChange={onChange}/>
        </div>
    )
}

export default Input;