import { useState } from "react";
import PostBackground from "./PostBackground";
import PostDetails from "./PostDetails";
import PostInput from "./PostInput";
import SmallProfile from "../hallpage/SmallProfile";
import { backgroundContext } from "../../contexts/postBackgroundContext";
import { useCreatePost, useCreatePostPoll, useCreateVideoPost, useDeletePost } from "../../hooks/postHooks";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import pick from "lodash.pick";
import { createPostContext } from "../../contexts/createPostContext";
import { createTrendContext } from "../../contexts/createTrendsContext";
import { useCreateTrend } from "../../hooks/trendHooks";
import moment from "moment/moment";
import { hallContext } from "../../contexts/hallContext";
import { generateRandomId } from "../../helpers/generateRandomId";
import { userContext } from "../../contexts/userContext";
import {
  generateVideoThumbnails,
  importFileandPreview
} from "@rajesh896/video-thumbnails-generator";
import { object } from "prop-types";

const CreatePost = ({ onSelectTag }) => {
  const queryClient = useQueryClient();
  const {hall} = useContext(hallContext);
  const {user_state} = useContext(userContext);
  const { mutate: createPost, isLoading: isPostLoading } = useCreatePost();
  const {mutate: createTrend,isLoading: isTrendLoading} = useCreateTrend(); 
  const {mutate:createPostPoll,isLoading: isPostPollLoading} = useCreatePostPoll();
  const {background,setBackground} = useContext(backgroundContext)
  const {post,setPost} = useContext(createPostContext);
  const {trend,setTrend} = useContext(createTrendContext);
  const {mutate:createVideoPost,isLoading:isCreatingVideoPost} = useCreateVideoPost();
  const {onDeletePost} = useDeletePost()
  // const [postWithBg, setPostWithBg] = useState(post.theme ? true : false);

  // const [previewImages, setPreviewImages] = useState([]);
  // const [preview, setPreview] = useState(false);

  const setPreviewImages = (values) => setPost(prev=>{return {...prev,previewImages:[...prev.previewImages,...values]}})
  const setPreviewVideos = (values) => setPost(prev=>{return {...prev,previewVideos:[...values]}})
  const setPreview = (value) => setPost(prev=>{return {...prev,preview:value}})
  const [schedulePost, setSchedulePost] = useState(false);
  const [trending, setTrending] = useState(true);
  const [postOption, setPostOption] = useState({
    changeBackground: false,
    showUpload: false,
    showUploadVideo:false,
    showPoll: false,
  });

  const setShowUpload = (value) => {
    setPostOption({
      changeBackground: false,
      showPoll: false,
      showUpload: value,
      showUploadVideo:false
    });
    setPost(prev=>{
      return {...prev,postWithBg:false,theme:""}
    })
  };

  const setShowUploadVideo = (value) => {
    setPostOption({
      changeBackground: false,
      showPoll: false,
      showUpload: false,
      showUploadVideo:value
    });
    setPost(prev=>{
      return {...prev,postWithBg:false,theme:""}
    })
  };
  const setChangeBackground = (value) => {
    setPostOption({
      changeBackground: value,
      showPoll: false,
      showUpload: false,
      showUploadVideo:false
    });
  };
  const setShowPoll = (value) => {
    setPostOption({
      changeBackground: false,
      showPoll: value,
      showUpload: false,
      showUploadVideo:false
    });
    setPost(prev=>{
      return {...prev,postWithBg:false,theme:""}
    })
  };

  const onDrop = useCallback((acceptedFiles) => {
    const images = [];
    setPost(prev=>{ return {...prev,acceptedFiles:[...prev.acceptedFiles,...acceptedFiles]}})
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        images.push({ image: objectUrl, id: generateRandomId(), name:file.name });
      }
      setPreviewImages(images);
      setShowUpload(false);
      setPreview(true);
    }
  });

  const { getRootProps:initialRootProps, getInputProps:initialInputProps, inputRef:initialInputRef, acceptedFiles:initialAcceptedFiles } = useDropzone({
    onDropAccepted:onDrop,
    onDropRejected:()=>toast.error("Drag 'n' drop image files",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onDropVideo = useCallback((acceptedFiles) => {
    const preview = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function() {
        URL.revokeObjectURL(video.src);
        const duration = video.duration;
        console.log(duration);
        setPost(prev=>{ return {...prev,videos:[{file:file,duration:Number(duration).toFixed(2)}]}})
      }
      video.src = objectUrl
      preview.push({ video: objectUrl, id: generateRandomId(), name:file.name });
      }
      setPreviewVideos(preview);
      setShowUploadVideo(false);
      setPreview(true);
    }
  });

  const { getRootProps:initialVideoRootProps, getInputProps:initialVideoInputProps, inputRef:initialVideoInputRef, acceptedFiles:initialVideoAcceptedFiles } = useDropzone({
    onDropAccepted:onDropVideo,
    onDropRejected:()=>toast.error("Drag 'n' drop 1 video file",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
    accept: {
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/webm": [".webm"],
      // "video/ogg":[".ogg"]
    },
    maxFiles:1,
  });


  const onClearImages = () => {
    setPreview(false);
    setPost(prev=>{return {...prev,previewImages:[]}});
    // setPreviewImages([]);
    setPost(prev=>{return {...prev,acceptedFiles:[]}});
    initialAcceptedFiles.splice(0, initialAcceptedFiles.length);
    initialAcceptedFiles.length = 0;
    if (initialInputRef.current !== null) {
      initialInputRef.current.value = "";
    }
  };

  const onClearVideos = () => {
    setPreview(false);
    setPost(prev=>{return {...prev,previewVideos:[],videos:[]}});
    // setPreviewVideos([]);
    // setPost(prev=>{return {...prev,videos:[]}});
    initialVideoAcceptedFiles.splice(0, initialVideoAcceptedFiles.length);
    initialVideoAcceptedFiles.length = 0;
    if (initialVideoInputRef.current !== null) {
      initialVideoInputRef.current.value = "";
    }
  };

  const onPostSuccess = (msg,type) => {
    if(type === "post")
      queryClient.invalidateQueries("hall-posts");
    else if(type === 'trend')
      queryClient.invalidateQueries("hall-trends");
    onClearImages();
    setShowUpload(false);
    setChangeBackground(false);
    setShowPoll(false);
    setPost(prev=>{return {...prev,text:"",published_at:""}});
    setTrend(prev=>{return {...prev,text:"",published_at:""}});
    toast.success(msg, {
      position: "top-center",
      hideProgressBar: true,
    });
  }

// functions for making a post
  const onCreateTextPost = (schedule) => {
    if(post.text.length === 0){
      toast.error("Post Text not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(schedule && post.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
    const formData = !schedule ? pick(post,["text","pictures","user","privacy","is_hall_post"]) : pick(post,["text","pictures","user","privacy","is_hall_post","published_at","published"]);
    formData.user=user_state?.user?.user?.id
    formData.post_type = "TextPost";
    formData.tags = trend.tags;
    if(schedule){
      formData.published_at = moment(formData.published_at).format()
    }
    createPost({post:formData,contentType:"application/json"},{
      onSuccess:()=>{
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
        onPostSuccess("Post Created","post")
      },
      onError:(error)=> {
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
  }
  }

  const onCreatePostWithImages = (schedule) => {
    if(schedule && post.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else{
    const userPost = !schedule ? pick(post,["text","user","privacy","is_hall_post"]) : pick(post,["text","user","privacy","is_hall_post","published_at","published"]);
    const formData = new FormData();
    for (let data in userPost) {
      formData.append(data, post[data]);
    }
    for (let image of post.acceptedFiles) {
      formData.append("pictures", image);
    }
    for (let tag of trend.tags){
      formData.append("tags",tag);
    }
    formData.append("post_type","VisualPost");
    formData.append("user",user_state?.user?.user?.id);
    if(schedule){
      formData.append("published_at",moment(userPost.published_at).format())
      formData.append("published",false);
    }else{
      formData.append("published",true)
    }
    // for (const pair of formData.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }
    createPost({post:formData,contentType:"multipart/form-data"},{
      onSuccess:(data)=>{
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
        onPostSuccess("Post Created","post")
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
  }
  }

  const onCreatePostWithTheme = (schedule) => {
    if(post.text.length === 0){
      toast.error("Post text not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(schedule && post.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
    const formData = !schedule ? pick(post,["text","user","theme","pictures","privacy","is_hall_post"]) : pick(post,["text","user","theme","pictures","privacy","is_hall_post","published_at","published"])
    formData.theme = background.id;
    formData.user=user_state?.user?.user?.id;
    formData.post_type = "TextOnThemePost";
    formData.tags = trend.tags;
    if(schedule){
      formData.published_at = moment(formData.published_at).format();
    }
    createPost({post:formData,contentType:"application/json"},{
      onSuccess:()=>{
        onPostSuccess("Post Created","post")
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true});
      }
    });
  }
  }

  const onCreatePostWithVideos = async (schedule) => {
    if(schedule && post.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else{
    const userPost = !schedule ? pick(post,["text","user","privacy","is_hall_post"]) : pick(post,["text","user","privacy","is_hall_post","published_at","published"]);
    const formData = new FormData();
    for (let video of post.videos) {
      formData.append("video", video.file);
      formData.append("duration",video.duration)
      const thumbnail = await generateVideoThumbnails(video.file,5);
      // console.log(thumbnail[0]);
      const response = await fetch(thumbnail[3]);
      const thumbnail_blob = await response.blob();
      let thumbnail_name = video.file.name.split('.');
      thumbnail_name.pop();
      thumbnail_name.join('-');
      thumbnail_name = thumbnail_name + '.jpg';
      const thumbnail_file = new File(
        [thumbnail_blob],
        thumbnail_name,
        {
          type: "image/jpeg",
          lastModified: new Date()
        }
      );
      console.log(thumbnail_file)
      formData.append("thumbnail",thumbnail_file)
    }
    userPost.tags = trend.tags;
    userPost.post_type = "VideoPost";
    userPost.pictures = [];
    userPost.user = user_state?.user?.user?.id
    if(schedule){
      userPost.published_at = moment(userPost.published_at).format();
      userPost.published = false;
    }else{
      userPost.published = true
    }
    console.log(userPost);
    createPost({post:userPost,contentType:"application/json"},{
      onSuccess:async (data)=>{
        console.log(data);
        formData.append("post",data?.data?.id)
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`);
        }
        createVideoPost(formData
          ,{
          onSuccess:data=>{
            console.log(data);
            setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[],videos:[],previewVideos:[]})
            setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false,videos:[],previewVideos:[]})
            onPostSuccess("Post Created","post")
          },
          onError:error=>{
            console.log(error);
            console.log(data?.data?.id)
            onDeletePost(data?.data?.id);
            toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true});
          }
        }
        )
        // const response_json = await response.
        // console.log(response);
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
  }
  }
  // functions for making a trend
  const onCreateTextTrend = (schedule) => {
    if(trend.tags.length === 0){
      toast.error("Trend tags not set",{position:"top-center",autoClose:1000,hideProgressBar:true})
    }else if(trend.text.length === 0){
      toast.error("Trend text not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(schedule && trend.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
    const formData = !schedule ? pick(trend,["text","user","hall","tags","pictures","is_hall_trend"]) : pick(trend,["text","user","hall","tags","pictures","is_hall_trend","published_at","published"]);
    formData.trend_type = "TextPost"
    formData.user=user_state?.user?.user?.id
    formData.hall = hall?.hallInfo.id
    if(schedule){
      formData.published_at = moment(formData.published_at).format();
    }
    createTrend({trendPost:formData,contentType:"application/json"},{
      onSuccess:(data)=>{
        onPostSuccess("Trend Created","trend")
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })}
  }

  const onCreateTrendWithTheme = (schedule) => {
    if(trend.tags.length === 0){
      toast.error("Trend tags not set",{position:"top-center",autoClose:1000,hideProgressBar:true})
    }else if(trend.text.length === 0){
      toast.error("Trend text not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }else if(schedule && trend.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
    const formData = !schedule ? pick(trend,["text","user","hall","tags","pictures","theme","is_hall_trend"]) : pick(trend,["text","user","hall","tags","pictures","theme","is_hall_trend","published_at","published"]);
    formData.theme = background.id
    formData.user=user_state?.user?.user?.id
    formData.hall = hall?.hallInfo.id
    formData.trend_type = "TextOnThemePost"
    if(schedule){
      formData.published_at = moment(formData.published_at).format();
    }
    createTrend({trendPost:formData,contentType:"application/json"},{
      onSuccess: (data)=> {
        onPostSuccess("Trend Created","trend")
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
      },
      onError: (error)=> {
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
    }
  }

  const onCreateTrendWithImages = (schedule) => {
    const formData = new FormData();
    const userTrend = !schedule ? pick(trend,["text","user","is_hall_trend"]) : pick(trend,["text","user","is_hall_trend","published_at","published"]);
    if(userTrend.text === ""){
      toast.error("Trend text not set",{position:"top-center",autoClose:1000,hideProgressBar:true})
    }else if(trend.tags.length === 0){
      toast.error("Trend tags not set",{position:"top-center",autoClose:1000,hideProgressBar:true})
    }else if(schedule && trend.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
      for (let data in userTrend) {
        formData.append(data, post[data]);
      }
      for (let image of post.acceptedFiles) {
        formData.append("pictures", image);
      }
      for (let tag of trend.tags){
        formData.append("tags",tag);
      }
      formData.append("trend_type","VisualPost");
      formData.append("hall",hall?.hallInfo.id);
      formData.append("is_hall_trend",true);
      formData.append("user",user_state?.user?.user?.id);
      if(schedule){
        formData.append("published_at",moment(userTrend.published_at).format())
      }
      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`);
      // }
    createTrend({trendPost:formData,contentType:"multipart/form-data"},{
      onSuccess:(data)=>{
        onPostSuccess("Trend Created","trend")
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
    }
  }

  const onCreatePostPoll = (schedule) => {
    if(post.text.length === 0){
      toast.error("Poll text not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }
      else if(post.options_text.some(value=> value.value === "")){
        toast.error("Some poll options not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(post.end_date === "" || post.end_time === ""){
        toast.error("Poll end date and time not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(schedule && post.published_at === ""){
      toast.error("Schedule date not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
      const poll = !schedule ? pick(post,["text","end_date","end_time","privacy","is_hall_poll"]) : pick(post,["text","end_date","end_time","privacy","is_hall_poll","published_at","published"]);
      poll.end_date = moment(poll.end_date).format("YYYY-MM-DD")
      poll.options_text = post.options_text;
      poll.tags = trend.tags;
      const pollOptions = [];
      for (let option of poll.options_text){
        pollOptions.push(option.value);
      }
      poll.options_text = pollOptions;
      poll.post_type = "Poll"
      if(schedule){
        poll.published_at = moment(poll.published_at).format()
      }
      createPostPoll(poll,{
        onSuccess:(data)=>{
          setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
          setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
          onPostSuccess("Poll Created","post")},
        onError:(error)=>{
          toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
        }
      })
    }
  }

  const onPost = (schedule) => {
    if(postOption.showPoll){
      onCreatePostPoll(schedule);
      return ;
    }
    if(post.videos.length !== 0){
      onCreatePostWithVideos(schedule);
    }
    else if (post.acceptedFiles.length !== 0) {
    onCreatePostWithImages(schedule)
    } else {
    if(background === null){
      onCreateTextPost(schedule)
    }else{
      onCreatePostWithTheme(schedule)
    }
  }
  }

  const onTrend = (schedule) => {
    if(!postOption.showPoll){
      if (post.acceptedFiles.length !== 0) {
        onCreateTrendWithImages(schedule)
      } else {
        if(background === null){
          onCreateTextTrend(schedule)
        }else{
          onCreateTrendWithTheme(schedule)
        }
      }
    }
  }

  return (
    <backgroundContext.Provider value={{ background, setBackground }}>
      <div className="px-4 py-4 w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md flex mb-5">
        <SmallProfile url={hall?.hallInfo.picture}/>
        <div className="flex flex-col w-full">
          {!post.postWithBg ? (
            <div className="flex h-full items-center w-full justify-between">
              <PostInput post={post} setPost={setPost} trending={trending} trend={trend} setTrend={setTrend} setChangeBackground={setChangeBackground} postOption={postOption}/>
            </div>
          ) : (
            <PostBackground onClose={() => {setPost(prev=>{return {...prev,postWithBg:false}});setBackground(null)}} post={post} setPost={setPost} trend={trend} setTrend={setTrend} trending={trending}/>
          )}
          <div className="">
            <PostDetails
              onSelectTag={onSelectTag}
              onPostWithBg={() => setPost(prev=> {return {...prev,postWithBg:true}})}
              onPost={onPost}
              isLoading={isPostLoading || isTrendLoading || isPostPollLoading || isCreatingVideoPost}
              getRootProps={initialRootProps}
              getInputProps={initialInputProps}
              acceptedFiles={initialAcceptedFiles}
              getVideoRootProps={initialVideoRootProps}
              getVideoInputProps={initialVideoInputProps}
              acceptedVideoFiles={initialVideoAcceptedFiles}
              setPreview={setPreview}
              previewImages={post.previewImages}
              preview={post.preview}
              setPreviewImages={setPreviewImages}
              setPreviewVideos={setPreviewVideos}
              previewVideos={post.previewVideos}
              onDrop={onDrop}
              onDropVideo={onDropVideo}
              onClearImages={onClearImages}
              trending={trending}
              setTrending={setTrending}
              postOption={postOption}
              setShowPoll={setShowPoll}
              setChangeBackground={setChangeBackground}
              setShowUpload={setShowUpload}
              schedulePost={schedulePost}
              setSchedulePost={setSchedulePost}
              setShowUploadVideo={setShowUploadVideo}
              onClearVideos={onClearVideos}
            />
          </div>
        </div>
      </div>
    </backgroundContext.Provider>
  );
};

export default CreatePost;
