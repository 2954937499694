import { createContext, useState } from "react"

export const postTrendMenuContext = createContext();
const PostTrendMenuContextProvider = ({children}) => {
    const [menu,setMenu] = useState({post:false,reportPost:false,reportTrend:false,postId:"",trend:false,trendId:""})
    return (
        <postTrendMenuContext.Provider value={{menu,setMenu}}>
            {children}
        </postTrendMenuContext.Provider>
    )
}

export default PostTrendMenuContextProvider;