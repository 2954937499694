import defaultProfile from "../../assets/images/default-profile.png";

const LeadershipCard = ({leader,id}) => {
    return (
        < div className="h-40 w-28 bg-cardBackgroundLight dark:bg-cardBackgroundDark shadow-5xl mt-8 ml-8 p-2 flex flex-col items-center shrink-0" id={id}>
            <img src={(leader.picture) || defaultProfile} alt="leader's profile" className="h-7/10 text-xs w-full mb-1 object-cover rounded-sm"/>
            <span className="text-[10px] text-primaryTextLight dark:text-primaryTextDark font-normal line-clamp-1">{leader.name}</span>
            <span className="text-[10px] text-primaryTextLight dark:text-primaryTextDark font-normal">{leader.portofolio.title}</span>
        </div>
    )
}

export default LeadershipCard;