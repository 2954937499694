import { useGetPastManagement } from "../../hooks/leadersHooks";
import ManagementHistoryCard from "./ManagementHistoryCard";
import SelectPosition from "./SelectPosition";
import { ClipLoader } from "react-spinners";
import { ReactComponent as NoDataIcon } from "../../assets/icons/no-data.svg";
import { useContext, useState } from "react";
import { hallContext } from "../../contexts/hallContext";

const ManagementHistory = () => {
  const {hall} = useContext(hallContext);
  const { data: pastManagement, isLoading } = useGetPastManagement(hall?.hallInfo?.id);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [position, setPosition] = useState("");
  const [filtered, setFiltered] = useState(false);

  const filterByPosition = (position) => {
    setPosition(position);
    setFilteredHistory(pastManagement?.data[position]);
  };
  return (
    <div className="flex flex-col gap-4 px-4 py-4 pb-8 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md">
      <div className="flex justify-between">
        <span className="text-primaryTextLight dark:text-primaryTextDark font-semibold">Management History</span>
        <SelectPosition
          filterByPosition={filterByPosition}
          setFiltered={setFiltered}
        />
      </div>
      {isLoading && (
        <div className="flex items-center justify-center w-full">
          <ClipLoader size={80} color="#F63E49" />
        </div>
      )}
      {!isLoading && Object.keys(pastManagement?.data || {}).length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <NoDataIcon height="400" width="400" />
        </div>
      ) : (
        <>
          {/* <span className="text-primaryTextLight dark:text-primaryTextDark ml-8">Hall Masters</span> */}
          <div className="flex flex-col gap-4 ml-8">
            {!filtered ? (
              Object.keys(pastManagement?.data || {})?.map((management,index) => {
                return (
                  <div className="flex flex-col gap-2" key={index}>
                    <span className="text-primaryTextLight dark:text-primaryTextDark">{management}</span>
                    <div className="flex flex-wrap gap-4">
                      {pastManagement?.data[management].length === 0 ? (
                        <div className="flex items-center justify-center w-full h-full">
                          <NoDataIcon height="200" width="200" />
                        </div>
                      ) : (
                        pastManagement?.data[management]?.map((executive) => {
                          return (
                            <ManagementHistoryCard
                              executive={executive}
                              key={executive.id}
                              leader={executive}
                              leaderCategory={"Management"}
                              linked_account={executive.useraccount}
                            />
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex flex-col gap-2">
                <span className="text-primaryTextLight dark:text-primaryTextDark">{position}</span>
                <div className="flex flex-wrap gap-4">
                  {filteredHistory.length === 0 ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <NoDataIcon height="200" width="200" />
                    </div>
                  ) : (
                    filteredHistory?.map((executive) => {
                      return (
                        <ManagementHistoryCard
                          executive={executive}
                          key={executive.id}
                          leader={executive}
                          leaderCategory={"Management"}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ManagementHistory;
