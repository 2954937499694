import React, { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { ReactComponent as SelectIcon } from "../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../assets/icons/select-icon-dark.svg";
import { themeContext } from "../contexts/themeContext";
const FilterSelector = ({options,onChange,name,tagfilter,fetchNextPage,hasNextPage,isLoading,data}) => {
  const {theme} = useContext(themeContext)  
  const [selectedOption,setSelectedOption] = useState(options[0]);
    const [showOptions,setShowOptions] = useState(false);
    const [rotate,setRotate] = useState(false);
    const onDropDown = () => {
        setShowOptions(prev=>!prev);
        setRotate(prev=>!prev);
    }
    const onSelect = (value) => {
      setShowOptions(prev=>!prev);
      setRotate(prev=>!prev);
      if(value !== "All" || value !== "All Types"){
          onChange(name,value)
      }
      setSelectedOption(value);
    }
  return (
    <div className="w-96 relative rounded-md dark:border-dark-gray" style={{border:theme?.value=="light"?"1px solid #D9D9D9":"1px solid #6c6c6c"}}>
      <div className="h-10 w-full text-primaryTextLight dark:text-primaryTextDark flex items-center justify-between px-2 pl-3 rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDown}>
        <span className="font-light">{selectedOption}</span>
        <div className="flex items-center gap-2">
          {
            theme?.value == "light" ?
            <SelectIcon width="12" height="12" onClick={onDropDown} className={
              "cursor-pointer " +
              (rotate ? "rotate-180 delay-200" : "delay-200")
            }/>:
            <SelectIconDark width="12" height="12" onClick={onDropDown} className={
              "cursor-pointer " +
              (rotate ? "rotate-180 delay-200" : "delay-200")
            }/>
          }
        </div>
      </div>
      {showOptions && 
        <React.Fragment>
          {!tagfilter ?
          <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md mt-[1px] absolute top-10 w-full z-10 max-h-36 overflow-auto" id="filter-tags-scroll">
            {options.map((option,index)=>{
                return (
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={index} onClick={()=>onSelect(option)}>
                        <span>{option}</span>
                    </div>
                    )
                })}
            </div> :
            <React.Fragment>
            {isLoading ? <div className="flex w-full justify-center mt-5">
            <ClipLoader size={30} color="#F63E49"/>
        </div>:
            <InfiniteScroll
            dataLength={data?.pages?.length * 64 || 64}
            next={fetchNextPage}
            style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
            hasMore={hasNextPage}
            loader={<div className="flex w-full justify-center mt-5">
            <ClipLoader size={30} color="#F63E49"/>
        </div>}
            scrollableTarget={"filter-tags-scroll"}
            className="scrollbar-hide shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md mt-[1px] absolute top-10 w-full z-10 max-h-36 overflow-auto"
            >
              {options.map((option,index)=>{
                return (
                    <div className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={index} onClick={()=>onSelect(option)}>
                        <span>{option}</span>
                    </div>
                    )
                })}
           </InfiniteScroll>
          }
          </React.Fragment>
          }
        </React.Fragment>
      }
    </div>
  );
};

export default FilterSelector;