import SmallProfile from "../hallpage/SmallProfile";
import { useCallback, useState } from "react";
import { ReactComponent as EmojiIcon } from "../../assets/icons/emoji-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-cloud-icon-dark.svg";
import { ReactComponent as UploadIconDark } from "../../assets/icons/upload-cloud-icon.svg";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Datepicker from "../hallpage/Datepicker";
import ScheduleSelector from "../posts-trending/ScheduleSelector";
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
import SelectPrivacy from "./SelectPrivacy";
import {
  useCreateHallMoment,
  useCreateMomentPicture,
} from "../../hooks/momentsHooks";
import { useQueryClient } from "react-query";
import { ReactComponent as CleanImagesIcon } from "../../assets/icons/clean-images.svg";
import { ReactComponent as EditImagesIcon } from "../../assets/icons/edit-images.svg";
import { ReactComponent as AddImagesIcon } from "../../assets/icons/add-images.svg";
import { generateRandomId } from "../../helpers/generateRandomId";
import MomentPreview from "./MomentPreview";
import { createMomentContext } from "../../contexts/createMomentContext";
import pick from "lodash.pick";
import { momentMenuContext } from "../../contexts/momentMenuContext";
import { BeatLoader } from "react-spinners";
import Lightbox from "yet-another-react-lightbox";
import LinearProgressBar from "../LinearProgressBar";
import { themeContext } from "../../contexts/themeContext";

const AddMomentPictures = ({ open }) => {
  const {theme} = useContext(themeContext);
  const { hall } = useContext(hallContext);
  const { momentData, setMomentData } = useContext(createMomentContext);
  const { momentMenu, setMomentMenu } = useContext(momentMenuContext);
  const [loading,setLoading] = useState(false);
  const [moment, setMoment] = useState({
    hall: hall?.hallInfo.id,
    title: "",
    privacy: "Public",
    pictures: [],
  });
  const [showLightBox,setShowLightBox] = useState(false);

  const { mutateAsync: createMomentPicture, isLoading: isUploadingPicture } =
    useCreateMomentPicture();
  const queryClient = useQueryClient();

  const onDrop = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId();
        images.push({
          image: objectUrl,
          id,
          name: file.name,
          isLoading: false,
          isUploaded: false,
          uploadProgress:{}
        });
        setMomentData((prev) => {
          return {
            ...prev,
            momentPictures: [
              ...prev.momentPictures,
              { image: file, description: "", id,privacy:"Public" },
            ],
          };
        });
      }
      setMomentData((prev) => {
        return {
          ...prev,
          momentPicturesPreview: [...prev.momentPicturesPreview, ...images],
          preview: true,
        };
      });
    }
  });

  const { getRootProps, getInputProps, inputRef, acceptedFiles } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: () =>
      toast.error("Drag 'n' drop image files", {
        autoClose: 2000,
        position: "top-center",
        hideProgressBar: true,
      }),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onDropAdditionalFiles = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId();
        images.push({
          image: objectUrl,
          id,
          name: file.name,
          isLoading: false,
          isUploaded: false,
          reUpload:false,
          uploadProgress:{}
        });
        setMomentData((prev) => {
          return {
            ...prev,
            momentPictures: [
              ...prev.momentPictures,
              { image: file, description: "", id,privacy:"Public" },
            ],
          };
        });
      }
      setMomentData((prev) => {
        return {
          ...prev,
          momentPicturesPreview: [...prev.momentPicturesPreview, ...images],
          preview: true,
        };
      });
      // setPreview(true);
    }
  });

  const {
    getRootProps: additionalRootProps,
    getInputProps: additionalInputProps,
    inputRef: additionalInputRef,
    acceptedFiles: additionalAcceptedFiles,
  } = useDropzone({
    onDropAccepted: onDropAdditionalFiles,
    onDropRejected: () =>
      toast.error("Drag 'n' drop image files", {
        autoClose: 2000,
        hideProgressBar: true,
        position: "top-center",
      }),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onClearAdditionalImages = () => {
    // setPreview(false);
    setMomentData((prev) => {
      return {
        ...prev,
        momentPictures: [],
        momentPicturesPreview: [],
        preview: false,
      };
    });
    additionalAcceptedFiles.splice(0, additionalAcceptedFiles.length);
    additionalAcceptedFiles.length = 0;
    if (additionalInputRef.current !== null) {
      additionalInputRef.current.value = "";
    }
  };

  const onClearImages = () => {
    // setPreview(false);
    setMomentData((prev) => {
      return {
        ...prev,
        momentPictures: [],
        momentPicturesPreview: [],
        preview: false,
      };
    });
    acceptedFiles.splice(0, acceptedFiles.length);
    acceptedFiles.length = 0;
    if (inputRef.current !== null) {
      inputRef.current.value = "";
    }
  };


  const postMomentPicture = async (momentId, momentPicture) => {
    const uploadProgress = (event)=>{
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = false;
        momentPic.isLoading = true;
        momentPic.reUpload = false;
        momentPic.uploadProgress = event;
        prev.momentPicturesPreview.splice(pictureIndex,1);
        prev.momentPicturesPreview.push(momentPic);
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
      })
    }
    const formdata = new FormData();
    formdata.append("description",momentPicture.description);
    formdata.append("image",momentPicture.image);
    formdata.append("moment",momentId);
    formdata.append("privacy",momentPicture.privacy);
    const createdPicture = await createMomentPicture({values:formdata,uploadProgress});
    if(createdPicture.status === 201){
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = true;
        momentPic.isLoading = false;
        momentPic.reUpload = false;
        prev.momentPicturesPreview.splice(pictureIndex,1,momentPic)
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
      })
    }else{
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = false;
        momentPic.isLoading = false;
        momentPic.reUpload = true;
        prev.momentPicturesPreview.splice(pictureIndex,1,momentPic)
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
      })
    }
    return createdPicture;
    };

  const onPost = () => {
    if (momentData.momentPictures.length === 0) {
      toast.error("Moment pictures not uploaded");
    }else if(momentData.momentPictures.length > 20){
      toast.info("Cannot upload more than 20 pictures",{autoClose:2000,position:"top-center",hideProgressBar:true})
    } else {
        setLoading(true);
        const momentPictures = []
      for (let picture of momentData.momentPictures) {
        setMomentData((prev) => {
          const pictureIndex = prev.momentPicturesPreview.findIndex(
            (image) => image.id === picture.id
          );
          const momentPic = prev.momentPicturesPreview[pictureIndex];
          momentPic.isUploaded = false;
          momentPic.isLoading = true;
          momentPic.uploadProgress = {}
          prev.momentPicturesPreview.splice(pictureIndex, 1, momentPic);
          return { ...prev, momentPicturesPreview: prev.momentPicturesPreview };
        });
        momentPictures.push(postMomentPicture(momentMenu.momentId, picture));
      }
      Promise.all(momentPictures).then(results=>{
        setLoading(false);
        if(results.every(res=>res.status === 201)){
          toast.success("All moment images uploaded successfully",{autoClose:2000,position:"top-center",hideProgressBar:true})
        }else{
          toast.success("Could not upload all images. Please re-upload images that could not be uploaded",{autoClose:2000,position:"top-center",hideProgressBar:true});
        }
        // results.map((result,index)=>{
        //   if(result.status === 201){
        //     setMomentData(prev=>{
        //       const picture = prev.momentPicturesPreview[index];
        //       picture.isLoading = false;
        //       picture.isUploaded = true;
        //       picture.reUpload = false;
        //       prev.momentPicturesPreview.splice(index,1);
        //       return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
        //     })
        //   }else{
        //     setMomentData(prev=>{
        //       const picture = prev.momentPicturesPreview[index];
        //       picture.isLoading = false;
        //       picture.isUploaded = false;
        //       picture.reUpload = true;
        //       prev.momentPicturesPreview.unshift(index,1,picture);
        //       return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
        //     })
        //   }
        // })
        queryClient.invalidateQueries("hall-moments");
        queryClient.invalidateQueries("single-hall-moment",momentMenu.momentId);
        onClearImages();
        onClearAdditionalImages();
        setMomentMenu((prev) => {
            return { ...prev, addMomentPictures: false, momentId: "" };
        });
      })
    }
  };
  return (
    <div className="w-full h-full flex items-center justify-center relative z-50">
      <div className="absolute opacity-[50%] h-full w-full bg-black"></div>
      <div className="w-[40%] px-4 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md pb-5 z-50 relative">
          <CloseIcon
            width="25"
            height="25"
            onClick={() => {
              onClearImages();
              onClearAdditionalImages();
              setMomentMenu((prev) => {
                return { ...prev, addMomentPictures: false, momentId: "" };
              });
            }}
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
          />
        <div className="w-full flex items-center justify-between py-2">
          <p className="text-primaryTextLight dark:text-primaryTextDark font-medium">Add Moment Pictures</p>
        </div>
        {!momentData.preview ? (
          <div className="w-full h-60 bg-backgroundLight dark:bg-backgroundDark flex flex-col rounded-md relative">
            <div
              className="flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0"
              {...getRootProps({
                className:
                  "dropzone flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0",
              })}
            >
              <input className="input-zone" {...getInputProps({})} />
              <p
                className="dropzone-content w-full h-full flex flex-col justify-center items-center cursor-pointer"
                onClick={open}
              >
                {theme?.value == "light" ?
              <UploadIcon width="70" height="70" />:
              <UploadIconDark width="70" height="70" />  
              }
                <span
                  className="text-xs"
                  style={{ color: theme?.value == "light" ? "rgba(54, 76, 102, 0.84)" :"#6c6c6c" }}
                >
                  Drop a file here or click to upload
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col">
            <div className="flex gap-1">
              <div
                {...additionalRootProps({ className: "dropzone" })}
                className=""
              >
                <input className="input-zone" {...additionalInputProps({})} />
                <button className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm">
                  <AddImagesIcon />
                  Add Media
                </button>
              </div>
              <button
                className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"
                onClick={() =>
                  setMomentData((prev) => {
                    return { ...prev, editMomentPictures: true };
                  })
                }
              >
                <EditImagesIcon />
                Edit All
              </button>
              <button
                className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"
                onClick={() => {
                  onClearImages();
                  onClearAdditionalImages();
                }}
              >
                <CleanImagesIcon />
                Clean All
              </button>
            </div>
            <div className="min-h-[15rem] flex items-center justify-center">
              <MomentPreview />
            </div>
          </div>
        )}
        <div className="w-full mt-2 flex-col gap-[4px]">
          <LinearProgressBar percent={momentData?.momentPicturesPreview.length !== 0 ? Math.round(momentData?.momentPicturesPreview.filter(picture=>picture.isUploaded == true).length/momentData?.momentPicturesPreview.length)*100 : 0}/>
          <span className="text-sm text-primaryTextLight dark:text-primaryTextDark opacity-80">{momentData?.momentPicturesPreview.filter(picture=>picture.isUploaded == true).length} out of {momentData?.momentPicturesPreview.length} uploaded</span>
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="bg-[#F63E49] text-white py-1 px-4 rounded-md"
            onClick={onPost}
          >
            {!loading ? "Add" : <BeatLoader size={10} color="white"/>}
          </button>
        </div>
      </div>
      <Lightbox
        open={showLightBox}
        close={() => setShowLightBox(false)}
        slides={[...momentData.momentPicturesPreview.map(img=>{return {src:img.image}})]}
      />
    </div>
  );
};
export default AddMomentPictures;
