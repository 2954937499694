import { useState } from "react";
import {ReactComponent as DropdownIcon} from "../../assets/icons/dropdown-icon.svg";
import  {BeatLoader} from 'react-spinners'

const ScheduleSelector = ({schedulePost,setSchedulePost,onPost,isLoading}) => {
    const [rotate,setRotate] = useState(false);
    const [showHidden,setShowHidden] = useState(false);
    const onDropDown = (event) => {
        setRotate(prev=>!prev)
        setShowHidden(prev=>!prev)
    }
    const onSelect = () => {
        setSchedulePost(prev=>!prev);
        setShowHidden(prev=>!prev)
        setRotate(prev=>!prev);
    }
    return (
        <div className="w-32 relative">
            <div className="h-7 w-full bg-linear-grad text-white text-sm flex items-center justify-between px-2 pl-3 rounded-sm">
            <div className="w-2/3 flex items-center justify-center">
            {   schedulePost ? 
                <span className="font-medium cursor-default" onClick={()=>onPost(true)}>{!isLoading ? "SCHEDULE" : <BeatLoader color="white" size={10}/>}</span> : <span className="font-medium cursor-default" onClick={()=>onPost(false)}>{isLoading ? <BeatLoader color="#fff" size={10}/> :"POST"}</span>
            }
            </div>
            <div className="flex items-center gap-2">
            <div className="w-[0.5px] border-1 h-6 bg-[#e0e0e0]"></div>
            <DropdownIcon width="12" height="12" onClick={onDropDown} className={"cursor-pointer " + (rotate ? "rotate-180 delay-200" : "delay-200")}/>
            </div>
        </div>
        {
            showHidden && 
            <div className={"w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark duration-300 transition ease-in absolute top-[100%] cursor-default drop-shadow-3xl h-6 rounded-sm cursor-pointer pl-3 text-primaryTextLight dark:text-primaryTextDark text-sm"} onClick={onSelect}>
            {
                schedulePost ?
                <span className="w-2/3 flex items-center justify-center cursor-default">{"POST"}</span> : <span className="w-2/3 flex items-center justify-center cursor-default">{"SCHEDULE"}</span> 
            }
            </div>
        }
        
        </div>
    )
}

export default ScheduleSelector;