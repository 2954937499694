const isNumber = (number) => {
    return /^\d+$/.test(number);
}

const validatePhoneNumber = (telephoneNumber) => {
    let isValid = false;
    let isStartsWith0 = telephoneNumber.startsWith("0");
    let isStartsWith233 = telephoneNumber.startsWith("233");
    let isStartsWithPlus = telephoneNumber.startsWith("+233");
    let isTenDigits = telephoneNumber.length === 10;
    let is13Digits = telephoneNumber.length === 13;
    let is12Digits = telephoneNumber.length === 12;

    if(isStartsWith0 && isTenDigits && isNumber(telephoneNumber.slice(1))){
        isValid = true;
    }else if(isStartsWith233 && is12Digits && isNumber(telephoneNumber.slice(2))){
        isValid = true;
    }else if(isStartsWithPlus && is13Digits && isNumber(telephoneNumber.slice(3))){
        isValid = true;
    }else{
        isValid = false;
    }

    return isValid;
}

export default validatePhoneNumber;