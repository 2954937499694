import { useContext, useEffect } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { useUnlinkAccount } from "../../hooks/leadersHooks";

const LeaderContextMenu = ({leader,leaderCategory,disableChange,closeContextMenu,showLinkLinkLounge,isCurrentExecutive}) => {
  const {setLeadershipStates} = useContext(leadershipContext);
  const {mutate:unlinkUserAccount,isLoading,} = useUnlinkAccount();

  return (
    <div className="w-60 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-backgroundDark">
      {
        isCurrentExecutive ? 
        <>
        <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
        <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 gap-1 py-2" onClick={()=>setLeadershipStates(prev=>{return {...prev,leader:leader,leaderCategory:leaderCategory,editLeadershipPortfolio:true}})}>
          Edit leadership portfolio
        </span>
      </div>
      {
        // (leader.in_service === true) && 
        <>
          <hr className="dark:border-dark-gray"/>
          {
            leader.change_requested === false ? 
          <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>setLeadershipStates(prev=>{return {...prev,linkAccount:true,leader:leader,leaderCategory:leaderCategory,previous:"register-change"}})}>
            Register a change of person{" "}
          </div>
            :
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>setLeadershipStates(prev=>{return {...prev,changeRequested:true,leader:leader,leaderCategory:leaderCategory}})}>
            Change of leader requested{" "}
          </div>
          }
        </>
      }
     {
      showLinkLinkLounge && 
      <>
       <hr className="dark:border-dark-gray"/>
      {
        leader.user === null ?
        <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>setLeadershipStates(prev=>{ return {...prev,linkAccount:true,leader:leader,leaderCategory:leaderCategory,previous:"leadership"}})}>
        Link person to LinkLounge account{" "}
      </div>:
      <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
      dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>{unlinkUserAccount(leader.id);closeContextMenu()}}>
           Unlink person from LinkLounge{" "}
         </div>
      }
      </>
     }
        </>:
        <>
        {
          leader.user === null ?
          <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
     dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>setLeadershipStates(prev=>{ return {...prev,linkAccount:true,leader:leader,leaderCategory:leaderCategory,previous:"leadership"}})}>
          Link person to LinkLounge account{" "}
        </div>:
        <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
        dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>{unlinkUserAccount(leader.id);closeContextMenu()}}>
             Unlink person from LinkLounge{" "}
           </div>
        }
        </>
        
      }
    </div>
  );
};

export default LeaderContextMenu;
