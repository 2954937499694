import { Player, BigPlayButton } from "video-react";

const VideoPostContent = ({ video, setViewImages, setImages }) => {
  return (
    <div className="w-full mt-4">
      <div className="w-full h-96 gap-2">
        <Player
          playsInline
          poster={video?.thumbnail}
          src={video?.video}
          fluid={false}
          width={"100%"}
          height={"100%"}
        >
          <BigPlayButton position="center" />
        </Player>
      </div>
    </div>
  );
};

export default VideoPostContent;
