import { useContext, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import { useCheckProfileChangeRequestExists, useRequestChange } from "../../hooks/hallHooks";
import coverPhoto from "../../assets/images/cover-placeholder.png"

const EditProfileModal = ({onClose,onEditHallInfo,onEditCoverPhoto,onEditProfilePicture,setProfileRequestData,onProfileRequest,setIsChangeRequested,isChangeRequested}) => {
  const {user_state} = useContext(userContext);
  const {hall} = useContext(hallContext);
  const onCheckChangeRequest = (data) => {
    if(data?.data.requested === false){
      setIsChangeRequested(false)
    }else{
      setIsChangeRequested(true);
      setProfileRequestData(data?.data.change_request);
    }
  }
  const {data} = useCheckProfileChangeRequestExists(hall?.hallInfo.id,onCheckChangeRequest)
  
  return (
    <div className="h-full w-full overflow-y-scroll absolute top-0 left-0 scrollbar-hide flex items-center justify-center bg-[rgba(0,0,0,0.3)]" style={{zIndex:100}}>
      <div className="relative w-[45%] h-[90%]">
      <CloseIcon width="25" height="25" onClick={onClose} className="cursor-pointer absolute top-[-5px] right-[-5px] z-[150]"/>
      <section className="w-full h-full bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark overflow-y-auto relative">
        <div className="flex items-center justify-between px-4 py-4">
          <span className="font-semibold text-lg text-primaryTextLight dark:text-primaryTextDark">Edit Profile</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="flex flex-col px-4 py-4">
          <div className="flex items-center justify-between">
            <span>Profile Picture</span>
            <span className="underline text-[#F63E49] hover:cursor-pointer" onClick={onEditProfilePicture}>Change</span>
          </div>
          <div className="w-20 h-20 ml-auto mr-auto mt-1">
            <img
              src={hall?.hallInfo.picture}
              alt="Profile"
              height="100%"
              width="100%"
              className="rounded-full h-full w-full mr-4"
            />
          </div>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="flex flex-col px-4 py-4">
          <div className="flex items-center justify-between">
            <span>Cover Picture</span>
            <span className="underline text-[#F63E49]  hover:cursor-pointer" onClick={onEditCoverPhoto}>Change</span>
          </div>
          <div className="w-[70%] h-32 ml-auto mr-auto mt-1">
            <img
              src={hall?.hallInfo.cover_image || coverPhoto}
              alt="Profile"
              height="100%"
              width="100%"
              className="rounded-md h-full w-full mr-4 fit"
            />
          </div>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="flex flex-col px-4 py-4">
            <div className="flex items-center justify-between">
                <span>Hall Info</span>
                {
                  !data?.data.requested ? 
                  <span className="underline text-[#F63E49]  hover:cursor-pointer" onClick={onEditHallInfo}>Change Request</span> :
                  <div className="underline  hover:cursor-pointer flex flex-col" onClick={onProfileRequest}><span className="bg-clip-text text-transparent bg-request-grad hover:cursor-pointer">Change Requested</span></div>
                }
            </div>
            <div className="flex flex-col ml-8 mt-4 gap-2">
                <span>Name: <span className="font-semibold">{hall?.hallInfo?.name}</span></span>
                <span>Nickname: <span className="font-semibold">{hall?.hallInfo?.nickname}</span></span>
                <span>Collective Name: <span className="font-semibold">{hall?.hallInfo?.collective_name}</span></span>
            </div>

        </div>
      </section>
      </div>
    </div>
  );
};

export default EditProfileModal;
