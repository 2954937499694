import { useContext, useEffect } from "react";
import { logoutContext } from "../../contexts/logoutContext";
import { userContext } from "../../contexts/userContext";
import defaultProfile from "../../assets/images/default-profile.png";



const UserProfileOptions = ({innerref,onChangeTheme}) => {
const {user_state} = useContext(userContext);
const {setLogout} = useContext(logoutContext);

 return (
    <div className="mt-1 flex flex-col max-w-min rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark text-sm absolute z-20 right-0 top-[100%]" ref={innerref}>
        <div className="flex px-2 py-2 items-center">
            <span className="inline-block h-10 w-10 mr-2"><img src={user_state.user.user.image || defaultProfile} alt="Profile" className="h-10 w-10 mr-2 rounded-full" height="100%" width="100%" /></span>
            <div className="flex flex-col text-sm">
                <span className="w-max">{user_state.user.user.fullname}</span>
                <span className="text-sm">{user_state.user.email}</span>
            </div>
        </div>
        <hr className="dark:border-dark-gray"/>
        {/* <div className="flex flex-col">
            <span className="hover:bg-[#e0e0e0] 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark px-2 py-2 text-primaryTextLight dark:text-primaryTextDark">Settings</span>
            <span className="hover:bg-[#e0e0e0] 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark px-2 py-2 text-primaryTextLight dark:text-primaryTextDark">Pause Notifications to Me</span>
        </div>
        <hr className="dark:border-dark-gray"/> */}
        <span className="px-2 py-2 hover:bg-[#e0e0e0] 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark" onClick={onChangeTheme}>Theme</span>
        <hr className="dark:border-dark-gray"/>
        <span className="px-2 py-2 hover:bg-[#e0e0e0]  
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark rounded-b-md text-primaryTextLight dark:text-primaryTextDark" onClick={()=>setLogout(true)}>Sign Out</span>
    </div>
 )
}

export default UserProfileOptions;