import Cookies from "js-cookie";

 export const storeAccessToken = (tokenObject) => {
    // sessionStorage.setItem('access_token',JSON.stringify(tokenObject));
    
    Cookies.set('access_token',tokenObject.token,{path:"/",sameSite:'strict'})
}

export const storeAccessTokenWithExpiry = (tokenObject) => {
    const specifiedDate = new Date(tokenObject.expiry);
    const today = new Date();
    const differenceInMs = specifiedDate.getTime() - today.getTime();
    const numberOfDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    Cookies.set('access_token',tokenObject.token,{expires:numberOfDays,path:"/",sameSite:'strict'})
}

export const storeRefreshToken = (tokenObject) => {
    Cookies.set('refresh_token',tokenObject.token,{path:"/",sameSite:'strict'})
}

export const storeRefreshTokeWithExpiry = (tokenObject) => {
    const specifiedDate = new Date(tokenObject.expiry);
    const today = new Date();
    const differenceInMs = specifiedDate.getTime() - today.getTime();
    const numberOfDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    Cookies.set('refresh_token',tokenObject.token,{expires:numberOfDays,path:"/",sameSite:'strict'})
}

export const storeSessionStorageRefreshToken = (tokenObject) => {
        sessionStorage.setItem('refresh_token',JSON.stringify(tokenObject));
}

export const storeLocalStorageRefreshToken = (tokenObject) => {
    localStorage.setItem('refresh_token',JSON.stringify(tokenObject))
}

export const getAccessToken = () => {
    // return JSON.parse(sessionStorage.getItem('access_token'))
    const token = Cookies.get('access_token');
    return token ? {token} : null
}

export const getRefreshToken = () => {
    // return JSON.parse(sessionStorage.getItem('refresh_token')) || JSON.parse(localStorage.getItem('refresh_token'));
    const token = Cookies.get('refresh_token');
    return token ? {token} : null
}

export const getSessionStorageRefreshToken = () => {
    return JSON.parse(sessionStorage.getItem('refresh_token'));
}

export const getLocalStorageRefreshToken = () => {
    return JSON.parse(localStorage.getItem('refresh_token'))
}

export const removeAccessToken = () => {
    Cookies.remove("access_token");
}

export const removeRefreshToken = () => {
    Cookies.remove("refresh_token");
}

export const removeLocalStorageRefreshToken = () => {
    localStorage.removeItem('refresh_token');
}

export const removeSessionStorageRefreshToken = () => {
    sessionStorage.removeItem('refresh_token');
}
