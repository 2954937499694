import { useContext } from "react"
import { momentMenuContext } from "../../contexts/momentMenuContext"
import { saveAs } from 'file-saver'

const MomentImageContext = ({innerRef,pictureId,imageUrl,setShowContext,picture,onChangePrivacy}) => {
    const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
    const onDeleteMomentPicture = () => {
        setMomentMenu(prev=>{return {...prev,deleteMomentPicture:true,pictureId:pictureId}});
    }
    const onDownloadMomentPicture = () => {
        saveAs(imageUrl,imageUrl.split("/").slice(-1)[0]);
        setShowContext(false);
      }
    const onSetAsProfilePicture = () => {
        setMomentMenu(prev=>{ return {...prev,setAsProfilePicture:true,profilePicture:imageUrl}});
      }
      const onSetAsCoverPhoto = () => {
        setMomentMenu(prev=>{ return {...prev,setAsCoverPhoto:true,coverPhoto:imageUrl}});
      }
      const onEditMomentPictureTitle = () => {
        setMomentMenu(prev=>{ return {...prev,editMomentPictureTitle:true,momentPicture:picture}})
      }
    return (
        <div className="w-60 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-cardBackgroundDark" ref={innerRef}>
            <div className="w-full flex flex-col pl-4 gap-1 rounded-t-md py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onEditMomentPictureTitle}>
                <span>Edit moment title</span>
            </div>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onChangePrivacy}>
                Change privacy of picture
            </div>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onDownloadMomentPicture}>
                Download picture
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onSetAsProfilePicture}>
                Set as hall profile picture
            </div>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onSetAsCoverPhoto}>
                Set as hall cover photo
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-0.5 rounded-b-md text-[#F63E49] cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark" onClick={onDeleteMomentPicture}>
                Delete this picture
            </div>
        </div>
    )
}

export default MomentImageContext;