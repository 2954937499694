import { useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as DateIcon } from "../../assets/icons/date-icon.svg";
import Calendar from "react-calendar";
import moment from "moment";
import ReactDatepicker from "react-datepicker";
import { themeContext } from "../../contexts/themeContext";

const Datepicker = ({width,height,customstyling,placeholder,size,fill,date,setDate,minDate,maxDate,dateInputStyle,zIndex}) => {
  const {theme} = useContext(themeContext);
  const [focus, setFocus] = useState(false);
  const ref = useRef();
  const calendarRef = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setFocus(false);
    }
  };

  const onClickDateIcon = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
  return (
    <div className={`h-${height} relative w-fit`} style={{zIndex:zIndex}}>
      <div className={`flex gap-1 items-center w-${width} h-full border dark:border-dark-gray px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark ` +  customstyling}>
        <ReactDatepicker
          placeholderText={placeholder}
          selected={date}
          onChange={setDate}
          minDate={minDate}
          maxDate={maxDate}
          className={"focus:outline-none w-full text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark " + (dateInputStyle)}
          calendarClassName="bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
          dayClassName={(date)=>{return !(moment(date).isSameOrAfter(minDate,"day") && moment(date).isSameOrBefore(maxDate,"day")) ? "dark:text-primaryTextLight text-primaryTextLight": "text-primaryTextLight dark:text-primaryTextDark dark:hover:text-primaryTextLight"}}
          ref={calendarRef}
        />
        <div
          className="ml-auto h-full flex items-center gap-2"
        >
          <span className="inline-block h-full w-[1px] bg-[#e0e0e0] dark:bg-dark-gray"></span>
          <DateIcon
            width={size}
            height={size}
            fill={theme?.value == "light" ? fill || "#364C66" : "#6c6c6c"}
            onClick={onClickDateIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default Datepicker;
