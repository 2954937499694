import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import FilterSelector from "../FilterSelector";
import YearMonthDayPicker from "../YearMonthDayPicker";


const FilterMoments = ({onClose,filterMoments,setFiltered,setFilter,filter,setShowFilteredMoments}) => {    
    const onChange = (name,value) => {
        setFilter(prev=>{
            return {...prev,[name]:value}
        })
    }

    const onFilter = () => {
      filterMoments()
      setShowFilteredMoments(true)
      onClose()
    }

    const onClear = () => {
        setFilter({year:"",month:"",day:"",privacy:""});
        setFiltered(false);
        setShowFilteredMoments(false);
    }
  return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:9999}}>
      <section className="w-[40%] h-[350px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark flex flex-col relative">
          <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={onClose}/>
        <div className="flex justify-between items-center px-4 py-3">
          <span className="font-semibold text-lg">Filter Moments</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="w-[80%] px-10 mt-10">
          <div className="w-full flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Date</span>
            <div>
            <YearMonthDayPicker handleChange={onChange} year={filter.year} month={filter.month} day={filter.day}/>
            </div>
          </div>
          {/* <div className="w-full text-center flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Type</span>
            <FilterSelector options={["All Types","TextPost", "TextOnThemePost", "PicturePost", "Poll"]} name="post_type" onChange={onChange}/>
          </div> */}
          <div className="w-full text-center flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Privacy</span>
            <FilterSelector options={["All","Public","Hall","Private"]} onChange={onChange} name="privacy"/>
          </div>
        </div>
        <div className="flex w-full justify-end gap-5 items-center pr-4 mt-auto mb-8">
          <div className="flex flex-col items-center cursor-pointer" onClick={onClear}>
            <span
              className="font-medium text-2xl bg-linear-grad bg-clip-text text-transparent inline-block"
            >
              Clear
            </span>
            <span className="inline-block h-[2px] bg-linear-grad w-full"></span>
          </div>
          <button
            type="button"
            className="w-28 h-10 border text-white flex bg-linear-grad items-center rounded-md px-3 justify-center cursor-pointer"
            onClick={onFilter}
          >
            {"FILTER"}
          </button>
        </div>
      </section>
    </div>
  );
};
export default FilterMoments;
