import { useContext } from "react";
import { useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import {
  useGetReportTrendReason,
  useReportTrend,
} from "../../hooks/trendHooks";
import OptionSelector from "./OptionSelector";

const ReportTrendModal = () => {
  const [reason, setReason] = useState("");
  const { menu, setMenu } = useContext(postTrendMenuContext);
  const { mutate: reportTrend, isLoading } = useReportTrend();
  const { data: trendReasons } = useGetReportTrendReason();

  const onReportTrend = () => {
    if (reason.length !== 0) {
      reportTrend(
        { trend: menu.trendId, reason },
        {
          onSuccess: (data) => {
            setMenu((prev) => {
              return { ...prev, reportPost: false };
            });
            toast.success("Trend reported", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
          onError: (error) => {
            toast.error("Could not report trend", {
              autoClose: 2000,
              position: "top-center",
              hideProgressBar: true,
            });
          },
        }
      );
    } else {
      toast.error("Reason cannot be empty", {
        autoClose: 2000,
        position: "top-center",
        hideProgressBar: true,
      });
    }
  };
  return (
    <div
      className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.2)]"
      style={{ zIndex: "9999" }}
    >
      <div className="flex flex-col bg-[#f6f6f6] w-[22rem] h-60 p-4 rounded-md gap-2">
        <div className="flex justify-between">
          <label htmlFor="reason" className="text-primaryTextLight dark:text-primaryTextDark">
            Why are you reporting this trend?
          </label>
          <CloseIcon
            width="20"
            height="20"
            className="cursor-pointer"
            onClick={() =>
              setMenu((prev) => {
                return { ...prev, reportTrend: false };
              })
            }
          />
        </div>
        <div className="w-full h-[2.5rem] rounded-md mt-2">
          <OptionSelector
            options={trendReasons?.data?.results}
            name="reason"
            onChange={(name, value) => setReason(value)}
          />
        </div>
        <div className="w-full flex justify-end gap-4 mt-auto">
          <button
            className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md cursor-pointer"
            onClick={() =>
              setMenu((prev) => {
                return { ...prev, reportTrend: false };
              })
            }
          >
            Cancel
          </button>
          <button
            className="bg-[#F63E49] text-white py-1 px-2 rounded-md cursor-pointer"
            onClick={onReportTrend}
          >
            {!isLoading ? "Report" : <BeatLoader size={10} color="white" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportTrendModal;
