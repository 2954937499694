import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";  
import InfiniteScroll from "react-infinite-scroll-component";
import { BeatLoader, ClipLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useFilterExecutives, useGetHallTenures, useGetManagementLeadershipPortfolios } from "../../hooks/leadersHooks";
import { leadershipContext } from "../../contexts/leadershipContext";
import { set } from "lodash";
import { themeContext } from "../../contexts/themeContext";


const FilterExecutives = ({onClose,setFilteredExecutives,setFilter,filter,setShowFilteredExecutives,setSearching}) => {   
  const {theme} = useContext(themeContext);  
    const {setLeadershipStates} = useContext(leadershipContext);
    const [filterValues,setFilterValues] = useState({tenure_start_year:"",tenure_end_year:"",title:""});
    const [date, setDate] = useState({start_date:"",end_date:""});
    const [rotate, setRotate] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const {hall} = useContext(hallContext);
    const [selectedOption, setSelectedOption] = useState("Select Position");
    const {data:portfolios,isLoading:fetchingPortfolios,fetchNextPage,hasNextPage,refetch} = useGetManagementLeadershipPortfolios(hall?.hallInfo.id,"Executive");
    const {refetch:filterExecutives,isRefetching,isLoading} = useFilterExecutives(filterValues,hall?.hallInfo.id,(data)=>{setFilteredExecutives(data);setFilter(true);setShowFilteredExecutives(true);setSearching(false);onClose()})
    const {data:hallTenures,isLoading:isFetchingHallTenures} = useGetHallTenures(hall?.hallInfo?.id);
  const onDropDown = () => {
    setRotate((prev) => !prev);
    setShowHidden((prev) => !prev);
  };

  const onSelect = (value) => {
    setSelectedOption(value);
    if(value === "All"){
        setFilterValues(prev=>{ return {...prev,title:""}})
    }else{
        setFilterValues(prev=>{ return {...prev,title:value}})
    }
    setRotate(false);
    setShowHidden(false);
  };

  const onDateChange = (name,date) => {
    if(name === "start_date"){
        setDate(prev=>{return {...prev,start_date:date}});
        setFilterValues(prev=>{return {...prev,tenure_start_year:date.getFullYear()}})
    }
    if(name === "end_date"){
        setDate(prev=>{return {...prev,end_date:date}});
        setFilterValues(prev=>{return {...prev,tenure_end_year:date.getFullYear()}})
    }
  }

  const onClear = () => {
    setFilteredExecutives([]);
    setShowFilteredExecutives(false);
    setFilter(false);
    onClose();
  }
  useEffect(()=>{
    refetch()
  },[]);
  return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:9999}}>
      <section className="w-[40%] h-[350px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark flex flex-col relative">
          <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={onClose}/>
        <div className="flex justify-between items-center px-4 py-3">
          <span className="font-semibold text-lg">Filter Executives</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="w-[80%] px-10 mt-4">
        <div className="w-full flex py-4 gap-2 items-center">
        <span className="mr-5 font-medium w-20">Position</span>
        <div className={"relative border dark:border-dark-gray rounded-md p-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark w-[13rem]"}>
        <div className="h-7 w-full text-primaryTextLight dark:text-primaryTextDark flex items-center justify-between px-2 pl-3 rounded-sm cursor-pointer bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDown}>
            <span className="">{selectedOption}</span>
            <div className="flex items-center gap-2">
                {
                  theme?.value == "light" ?
                  <SelectIcon width="12" height="12"
                onClick={onDropDown}
                className={
                "cursor-pointer " +
                (rotate ? "rotate-180 delay-200" : "delay-200")
                }
                />:
                <SelectIconDark width="12" height="12"
                onClick={onDropDown}
                className={
                "cursor-pointer " +
                (rotate ? "rotate-180 delay-200" : "delay-200")
                }
                />
                }
            </div>
        </div>
        {showHidden && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 right-0 z-10 max-h-36 overflow-auto" id="management-portfolio">
                  <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0]"
                            onClick={() => onSelect("All")}>
                            <span>All</span>
                            </div>
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"management-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              return (
                            <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={portfolio.id}
                            onClick={() => onSelect(portfolio.title)}>
                            <span>{portfolio.title}</span>
                            </div>                                  )
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )}
              </div>
        </div>
          <div className="w-full flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-20">Tenure</span>
            <div className="flex w-[13rem] gap-2">
              <div className="w-1/2">
                    <DatePicker
                    selected={date.start_date}
                    onChange={(date) => onDateChange("start_date",date)}
                    isClearable
                    showYearPicker
                    dateFormat={"yyyy"}
                    placeholderText="Start Year"
                    className="p-2 rounded-md border dark:border-dark-gray focus:outline-none placeholder:font-thin w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                    calendarClassName="bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                    />
                </div>
                <div className="w-1/2">
                    <DatePicker
                    selected={date.end_date}
                    onChange={(date) => onDateChange("end_date",date)}
                    isClearable
                    showYearPicker
                    dateFormat={"yyyy"}
                    placeholderText="End Year"
                    className="p-2 rounded-md border dark:border-dark-gray focus:outline-none placeholder:font-thin w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                    calendarClassName="bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                    />
                </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-end gap-5 items-center pr-4 mt-auto mb-8">
          <div className="flex flex-col items-center cursor-pointer">
            <span
              className="font-medium text-2xl bg-linear-grad bg-clip-text text-transparent inline-block"
              onClick={onClear}
            >
              Clear
            </span>
            <span className="inline-block h-[2px] bg-linear-grad w-full"></span>
          </div>
          <button
            type="button"
            className="w-28 h-10 border text-white flex bg-linear-grad items-center rounded-md px-3 justify-center cursor-pointer dark:border-none"
            onClick={()=>{filterExecutives();}}
          >
            {(!isRefetching || isLoading) ?"FILTER":<BeatLoader color="white" size={10}/>}
          </button>
        </div>
      </section>
    </div>
  );
};
export default FilterExecutives;
