
const Discard = ({onCancel,onConfirm}) => {
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:"9999"}}>
            <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 flex flex-col gap-10 min-w-[20rem]">
                <p className="text-primaryTextLight dark:text-primaryTextDark font-medium">Do you want to discard all the executives added?</p>
                <div className="flex ml-auto gap-4 mt-auto">
                    <button className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md" onClick={onCancel}>Cancel</button>
                    <button className="bg-[#F63E49] text-white py-1 px-2 rounded-md" onClick={onConfirm}>{"Discard"}</button>
                </div>
            </div>            
        </div>
    )
}

export default Discard;