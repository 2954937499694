import { useState } from "react";
import Toggle from "../Toggle";
import Tag from "./Tag";
import { ReactComponent as AddTagIcon } from "../../assets/icons/add-tag-icon.svg";
import { ReactComponent as ChangeBackgroundIcon } from "../../assets/icons/change-background-icon.svg";
import { ReactComponent as PollIcon } from "../../assets/icons/poll-icon.svg";
import { ReactComponent as UploadImageIcon } from "../../assets/icons/camera.svg";
// import { ReactComponent as UploadFileIconDark } from "../../assets/icons/post-images-icon-dark.svg";
import { ReactComponent as PollIconDark } from "../../assets/icons/poll-icon-dark.svg";
import { ReactComponent as UploadVideoIcon } from "../../assets/icons/upload-video-icon.svg";
import ScheduleSelector from "./ScheduleSelector";
import Datepicker from "../hallpage/Datepicker";
import SelectViewers from "./SelectViewers";
import Backgrounds from "./Backgrounds";
import UploadFile from "./UploadFile";
import CreatePoll from "./CreatePoll";
import PreviewPictures from "./PreviewPictures";
import { useContext } from "react";
import { createTrendContext } from "../../contexts/createTrendsContext";
import { createPostContext } from "../../contexts/createPostContext";
import {ReactComponent as CleanImagesIcon} from "../../assets/icons/clean-images.svg";
import {ReactComponent as EditImagesIcon} from "../../assets/icons/edit-images.svg";
import {ReactComponent as AddImagesIcon} from "../../assets/icons/add-images.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import {toast} from "react-toastify";
import { generateRandomId } from "../../helpers/generateRandomId";
import PreviewEditPictures from "./PreviewEditPictures";
import { useCreateVideoPost, useDeletePostImage, useDeletePostVideo, useEditPost } from "../../hooks/postHooks";
import { useQueryClient } from "react-query";
import pick from "lodash.pick";
import { BeatLoader } from "react-spinners";
import { backgroundContext } from "../../contexts/postBackgroundContext";
import { userContext } from "../../contexts/userContext";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { themeContext } from "../../contexts/themeContext";
import { hallContext } from "../../contexts/hallContext";
import PreviewVideos from "./PreviewVideo";
import PreviewEditVideos from "./PreviewEditVideos";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";


const PostDetails = ({
  onSelectTag,
  onPostWithBg,
  onPost,
  isLoading,
  getRootProps,
  getInputProps,
  acceptedFiles,
  previewImages,
  preview,
  onDrop,
  onClearImages,
  setShowPoll,
  setShowUpload,
  setChangeBackground,
  postOption,
  trending,
  setTrending,
  schedulePost,
  setSchedulePost,
  onSchedulePost,
  setPreview,
  setPreviewImages,
  setShowUploadVideo,
  getVideoRootProps,
  getVideoInputProps,
  acceptedVideoFiles,
  setPreviewVideos,
  onDropVideo,
  previewVideos,
  onClearVideos,

}) => {
  const onToggle = () => {
    setTrending((prev) => !prev);
  };
  const {trend,setTrend} = useContext(createTrendContext);
  const {post,setPost} = useContext(createPostContext);
  const {background} = useContext(backgroundContext);
  const {user_state} = useContext(userContext);
  const {theme} = useContext(themeContext);
  const {hall} = useContext(hallContext);
  const queryClient = useQueryClient()

  const {mutate:editPost,isLoading:isEditing} = useEditPost();
  const {mutate:deleteImage} = useDeletePostImage();
  const {mutate:deleteVideo} = useDeletePostVideo();
  const {mutate:createVideoPost} = useCreateVideoPost();
  
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 1 * 24 * 60 * 60 * 1000);
  const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

  const onDateChange = (date) => {
    setPost(prev=>{
      return {...prev,published_at:date}
    })
    setTrend(prev=>{
      return {...prev,published_at:date}
    })
  }

  const onDeleteImage = (id) => {
      deleteImage(id,{
          // onSuccess:()=>{
          //     // toast.success("Image deleted successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"})
          // },
          onError:(error)=>{
              toast.error("Failed delete post image",{autoClose:2000,hideProgressBar:true,position:"top-center"});
          }
      })
  }

  const onDeleteVideo = (id) => {
    deleteVideo(id,{
      onError:error=>{
        toast.error("Failed to delete video",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }
    })
  }

  const onEditSuccess = (msg) => {
    queryClient.invalidateQueries("hall-posts");
    onClearImages();
    onClearAdditionalImages();
    setShowUpload(false);
    setChangeBackground(false);
    setShowPoll(false);
    setPost(prev=>{return {...prev,text:"",published_at:"",editPost:false}});
    setTrend(prev=>{return {...prev,text:"",published_at:""}});
    toast.success(msg, {
      position: "top-center",
      hideProgressBar: true,
    });
  }

  const onEditError = (msg,error) => {
    toast.error(msg, {
      position: "top-center",
      hideProgressBar: true,
    });
  }

  const deleteRemovedPictures = () => {
    const removedImages = [];
      post.pictures.forEach(picture=>{
        if(post.previewImages.findIndex(image=>image.id === picture.id) === -1){
          removedImages.push(picture);
        }
      });
      removedImages.forEach(image=>{
        onDeleteImage(image.id);
      })
  }

  const deleteRemovedVideos = () => {
    const removedVideos = [];
    console.log(post.videos);
    console.log(post.previewVideos);
    post.videos.forEach(video=>{
      if(post.previewVideos.findIndex(postVideo=>postVideo.id === video.id) === -1){
        console.log(video);
        removedVideos.push(video);
      }
    });
    removedVideos.forEach(video=>{
      onDeleteVideo(video.id);
    })
  }

  const editTextPost = () => {
    const formData = pick(post,["text","privacy","theme"]);
    formData.user = user_state?.user?.user?.id;
    formData.pictures = [];
    if(background){
      formData.theme = background.id;
      formData.post_type = "TextOnThemePost";
    }else{
      formData.post_type = "TextPost";
    }
    editPost({postId:post.postId,values:formData,contentType:"application/json"},{
      onSuccess:()=>{
        onEditSuccess("Post Edited")
        setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[]})
        setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
      },
      onError:(error)=> onEditError("Error editing post",error)
    })
  }

  const editPostWithImages = () => {
    const userPost = pick(post,["text","user","privacy","is_hall_post","theme"])
      const formData = new FormData();
      formData.append("post_type","VisualPost");
      for (let data in userPost) {
        formData.append(data, post[data]);
      }
      for (let image of post.acceptedFiles) {
        formData.append("pictures", image);
      }
      formData.append("user",user_state?.user?.user?.id)
      editPost({postId:post.postId,values:formData,contentType:"multipart/form-data"},{
        onSuccess:(data)=>{
          console.log(data);
          onEditSuccess("Post Edited")
          setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[],videos:[],previewVideos:[]})
          setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
        },
        onError:(error)=>onEditError("Error editing post",error)
      })
  }

  const editPostWithVideos = async () => {
    const userPost = pick(post,["text","user","privacy","is_hall_post"])
    const formData = new FormData();
    for (let video of post.videos) {
      formData.append("video", video.file);
      formData.append("duration",video.duration)
      const thumbnail = await generateVideoThumbnails(video.file,0);
      // console.log(thumbnail[0]);
      const response = await fetch(thumbnail[0]);
      const thumbnail_blob = await response.blob();
      let thumbnail_name = video.file.name.split('.');
      thumbnail_name.pop();
      thumbnail_name.join('-');
      thumbnail_name = thumbnail_name + '.jpg';
      const thumbnail_file = new File(
        [thumbnail_blob],
        thumbnail_name,
        {
          type: "image/jpeg",
          lastModified: new Date()
        }
      );
      console.log(thumbnail_file)
      formData.append("thumbnail",thumbnail_file)
    }
    userPost.tags = trend.tags;
    userPost.post_type = "VideoPost";
    userPost.pictures = [];
    userPost.user = user_state?.user?.user?.id
    console.log(userPost);
    editPost({postId:post.postId,values:userPost,contentType:"application/json"},{
      onSuccess:async (data)=>{
        console.log(data);
        formData.append("post",data?.data?.id)
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`);
        }
        createVideoPost(formData
          ,{
          onSuccess:data=>{
            console.log(data);
            setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[],videos:[],previewVideos:[]})
            setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false,videos:[],previewVideos:[]})
            onEditSuccess("Post Edited")
          }
        }
        )
        // const response_json = await response.
        // console.log(response);
      },
      onError:(error)=>{
        toast.error("Failed to post",{autoClose:2000,position:"top-center",hideProgressBar:true})
      }
    })
  }

  const onEditPost = () => {
    if(post.pictures.length !== 0){
      deleteRemovedPictures();
    }
    if(post.videos.length !== 0){
      deleteRemovedVideos();
    }
    if(post.acceptedFiles.length === 0 && post.videos.length === 0){
      editTextPost();
    }else if(post.acceptedFiles.length !== 0){
      editPostWithImages();
    }else if(post.videos.legnth !== 0){
      editPostWithVideos()
    }
  }

  const onCancelEdit = () => {
    setPost({text:"",user:user_state?.user?.user?.id || "",theme:"",pictures:[],privacy:"Public",options_text:[{name:"option1",value:""},{name:"option2",value:""}],end_date:"",end_time:"",is_hall_post:true,is_hall_poll:true,published_at:"",published:false,editPost:false,postWithBg:false,preview:false,previewImages:[],acceptedFiles:[],videos:[],previewVideos:[]})
    setTrend({text:"",user:user_state?.user?.user?.id || "",hall:hall?.hallInfo?.id,theme:"",privacy:"Public",pictures:[],tags:[],trendTags:[],trend_type:"",is_hall_trend:true,published_at:"",published:false})
    setShowUpload(false);
    setShowUploadVideo(false);
    setChangeBackground(false);
  }

  const onDropAdditionalFiles = useCallback((acceptedFiles) => {
    const images = [];
    setPost(prev=>{ return {...prev,acceptedFiles:[...prev.acceptedFiles,...acceptedFiles]}})
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        images.push({ image: objectUrl, id: generateRandomId(), name:file.name });
      }
      setPreviewImages(images);
      setShowUpload(false);
      setPreview(true);
    }
  });

  const { getRootProps:additionalRootProps, getInputProps:additionalInputProps, inputRef:additionalInputRef, acceptedFiles:additionalAcceptedFiles } = useDropzone({
    onDropAccepted:onDropAdditionalFiles,
    onDropRejected:()=>toast.error("Drag 'n' drop image files",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });


  const onClearAdditionalImages = () => {
    setPreview(false);
    setPost(prev=>{return {...prev,previewImages:[]}})
    // setPreviewImages([]);
    setPost(prev=>{return {...prev,acceptedFiles:[]}});
    additionalAcceptedFiles.splice(0, additionalAcceptedFiles.length);
    additionalAcceptedFiles.length = 0;
    if (additionalInputRef.current !== null) {
      additionalInputRef.current.value = "";
    }
  };


  return (
    <div className="w-full flex flex-col gap-6 mt-2">
      {postOption.showUpload && (
        <UploadFile
          onClose={() => setShowUpload(false)}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          // acceptedFiles={acceptedFiles}
          // onDrop={onDrop}
        />
      )}
      {postOption.showUploadVideo && (
        <UploadFile
          onClose={() => setShowUploadVideo(false)}
          getRootProps={getVideoRootProps}
          getInputProps={getVideoInputProps}
          acceptedFiles={acceptedVideoFiles}
          onDrop={onDropVideo}
        />
      )}
      {preview && post.previewImages.length > 0 && (
        <div className="w-full flex flex-col">
        <div className="flex">
          <div className="flex gap-1">
            <div {...additionalRootProps({ className: "dropzone" })}>
              <input className="input-zone" {...additionalInputProps({})}/>
              <button className="bg-filter-grad text-primaryTextLight flex gap-1 items-center px-1 rounded-sm text-sm"><AddImagesIcon/>Add Media</button>
            </div>
          {/* <button className="bg-filter-grad text-primaryTextLight dark:text-primaryTextDark flex gap-1 items-center px-1 rounded-sm text-sm"><EditImagesIcon/>Edit All</button> */}
          <button className="bg-filter-grad text-primaryTextLight flex gap-1 items-center px-1 rounded-sm text-sm" onClick={()=>{onClearImages();onClearAdditionalImages();setPreview(false);setShowUpload(true)}}><CleanImagesIcon/>Clean All</button>
          </div>
          <CloseIcon
          width="20"
          height="20"
          onClick={()=>{onClearImages();onClearAdditionalImages();setPreview(false);setShowUpload(true)}}
          className="cursor-pointer ml-auto"
        />
        </div>
        {
          !post.editPost ? 
          <PreviewPictures pictures={previewImages} onClose={()=>{onClearImages();onClearAdditionalImages();setPreview(false);setShowUpload(true)}} setShowUpload={setShowUpload} />
          : <PreviewEditPictures pictures={previewImages} onClose={()=>{onClearImages();onClearAdditionalImages();setPreview(false);setShowUpload(true)}} setShowUpload={setShowUpload}/>

        }
        </div>
      )}
      {preview && post.previewVideos.length > 0 && (
        <div className="w-full flex flex-col">
        <div className="flex">
          <div className="flex gap-1">
            <div {...getVideoRootProps({ className: "dropzone" })}>
              <input className="input-zone" {...getVideoInputProps({})}/>
              <button className="bg-filter-grad text-primaryTextLight flex gap-1 items-center px-1 rounded-sm text-sm"><AddImagesIcon/>Add Media</button>
            </div>
          <button className="bg-filter-grad text-primaryTextLight flex gap-1 items-center px-1 rounded-sm text-sm" onClick={()=>{onClearImages();onClearAdditionalImages();setPreview(false);setShowUpload(true)}}><CleanImagesIcon/>Clean All</button>
          </div>
          <CloseIcon
          width="20"
          height="20"
          onClick={()=>{onClearVideos();setPreview(false);setShowUploadVideo(true)}}
          className="cursor-pointer ml-auto"
        />
        </div>
        {
          !post.editPost ? 
          <PreviewVideos videos={post.previewVideos} onClose={()=>{onClearVideos();setPreview(false);setShowUploadVideo(true)}} setShowUpload={setShowUploadVideo}/>
          : <PreviewEditVideos videos={post.previewVideos} onClose={()=>{onClearVideos();setPreview(false);setShowUploadVideo(true)}} setShowUpload={setShowUploadVideo}/>

        }
        </div>
      )}
      {postOption.showPoll && <CreatePoll onClose={() => setShowPoll(false)} />}
      <div className="flex items-center gap-2">
        <div className="h-5 flex items-center gap-4">
          <SelectViewers />

          <div className="flex gap-2">
            <AddTagIcon
              width="15"
              height="15"
              onClick={onSelectTag}
              className="cursor-pointer focus:outline-none"
              id="addTag" data-tooltip-content="Add Tags"
            />
            {
              trend.trendTags.length == 0 ?
              <Tag tag={"General"}/>
              :
              trend.trendTags.map(tag=>{
                return <Tag tag={tag.short_hand} key={tag.id}/>
              })
            }
          </div>
          <div>
          <ReactTooltip anchorId="addTag" place="bottom" variant="light" effect="solid" className="text-xs text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <div className="flex gap-2 items-center cursor-pointer">
            {
              theme?.value == "light" ? 
              <UploadImageIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="addImage" data-tooltip-content="Add image to post"
                onClick={() => setShowUpload(!postOption.showUpload)}
              />
              :
              <UploadImageIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="addImage" data-tooltip-content="Add image to post"
                onClick={() => setShowUpload(!postOption.showUpload)}
              />

            }
            <ReactTooltip anchorId="addImage" place="bottom" variant="light" effect="solid" className="text-xs text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
          </div>
      
          
          
          <div className="relative h-5 w-5">
            {postOption.changeBackground && (
              <Backgrounds
                position={"bottom-[100%]"}
                onPostWithBg ={() => {
                  onPostWithBg();
                  setChangeBackground(false);
                }}
                trending={trending}
              />
            )}
            <div>
              <ChangeBackgroundIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                onClick={() => setChangeBackground(!postOption.changeBackground)}
                id="addBackground" data-tooltip-content="Add Background"
              />
              <ReactTooltip anchorId="addBackground" place="bottom" effect="solid" variant="light" className="text-xs text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
            </div>

            </div>
          <div className="flex gap-2 items-center cursor-pointer">
            {
              theme?.value == "light" ? 
              <UploadVideoIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="addVideo" data-tooltip-content="Add video to post"
                onClick={() => setShowUploadVideo(!postOption.showUploadVideo)}
              />:
              <UploadVideoIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="addVideo" data-tooltip-content="Add video to post"
                onClick={() => setShowUploadVideo(!postOption.showUploadVideo)}
              />

            }
            <ReactTooltip anchorId="addVideo" place="bottom" variant="light" effect="solid" className="text-xs text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
          </div>
          <div className="flex gap-2 items-center cursor-pointer">
            {
              theme?.value == "light" ?
              <PollIcon
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="createPoll" data-tooltip-content="Create a poll"
                onClick={() => setShowPoll(!postOption.showPoll)}
              />:
              <PollIconDark
                width="20"
                height="20"
                className="cursor-pointer focus:outline-none"
                id="createPoll" data-tooltip-content="Create a poll"
                onClick={() => setShowPoll(!postOption.showPoll)}
              />
            }
          <ReactTooltip anchorId="createPoll" place="bottom" variant="light" effect="solid" className="text-xs text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"/>
          </div>
        </div>
        <div className="flex gap-3">
      {schedulePost && !post.editPost && (
            <Datepicker
              width={32}
              placeholder="Schedule date"
              size={10}
              schedule={true}
              customstyling="rounded-md"
              position={"-30%"}
              minDate={tomorrow}
              maxDate={nextWeek}
              setDate={onDateChange}
              date={post.published_at}
              dateInputStyle={"placeholder:font-light placeholder:text-xs text-sm"}
              zIndex={20}
            />
          )}
          {!post.editPost ? 
          <ScheduleSelector
            schedulePost={schedulePost}
            setSchedulePost={setSchedulePost}
            onPost={onPost}
            isLoading={isLoading}
          /> : 
          <div className="flex gap-2">
            <button className="h-7 w-16 bg-linear-grad text-white text-sm flex items-center justify-center px-2 rounded-md" onClick={onEditPost}>{!isEditing ? "Edit" : <BeatLoader size={10} color="white"/>}</button>
            <button className="h-7 w-16 bg-filter-grad text-primaryTextLight text-sm flex items-center justify-center px-2 rounded-md" onClick={onCancelEdit}>Cancel</button>
          </div>
          }
      </div>
      </div>

    </div>
  );
};

export default PostDetails;
