import { useContext } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { createPostContext } from "../../contexts/createPostContext";
const PreviewPictures = ({ pictures,setShowUpload, onClose }) => {
  const {setPost,post} = useContext(createPostContext);
  const onRemovePicture = (id,name) => {
    if(post.previewImages.length === 1){
      setPost(prev=>{ return {...prev,preview:false,previewImages:prev.previewImages.filter(picture=>picture.id !== id),acceptedFiles:prev.acceptedFiles.filter(picture=>picture.name !== name)}});
      setShowUpload(true)
    }else{
      setPost(prev=>{ return {...prev,previewImages:prev.previewImages.filter(picture=>picture.id !== id),acceptedFiles:prev.acceptedFiles.filter(picture=>picture.name !== name)}});
    }
  }
  return (
    <div className="mt-4 mb-4 w-full max-h-80 relative">
      <div className=" w-full h-full gap-2">
        {pictures.length === 1 && (
          <div className="max-w-full gap-2 relative">
            <img
              src={pictures[0].image}
              alt="Post Images"
              width="100%"
              height="100%"
              className="rounded-md w-full max-h-80 object-cover"
            />
            <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
              <CloseIcon
                width="20"
                height="20"
                onClick={()=>onRemovePicture(pictures[0].id,pictures[0].name)}
                className="cursor-pointer"
              />
            </div>
          </div>
        )}
        {pictures.length === 2 && (
          <div className="w-full h-80 flex gap-1">
            {pictures.map((picture) => {
              return (
                <div className="w-1/2 h-full relative" key={picture.id}>
                  <img
                    src={picture.image}
                    alt="Post Images"
                    width="100%"
                    height="100%"
                    className="w-full h-full object-cover rounded-md"
                  />
                  <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                    <CloseIcon
                      width="20"
                      height="20"
                      onClick={()=>onRemovePicture(picture.id,picture.name)}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {pictures.length === 3 && (
          <div className="h-80 w-full flex gap-1 relative">
            <div className="w-1/2 h-full relative">
              <img
                src={pictures[0].image}
                alt="Post Images"
                width="100%"
                height="100%"
                className="w-full h-full object-cover rounded-md"
              />
              <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                <CloseIcon
                  width="20"
                  height="20"
                  onClick={()=>onRemovePicture(pictures[0].id,pictures[0].name)}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="w-1/2 h-80 flex flex-col justify-between">
              {pictures.slice(1).map((picture) => {
                return (
                  <div className="h-[49%] w-full relative" key={picture.id}>
                    <img
                      src={picture.image}
                      alt="Post Images"
                      width="100%"
                      height="100%"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                      <CloseIcon
                        width="20"
                        height="20"
                        onClick={()=>onRemovePicture(picture.id,picture.name)}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {pictures.length === 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[0].id,pictures[0].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[2].id,pictures[2].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[1].id,pictures[1].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[3].id,pictures[3].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {pictures.length > 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[0].id,pictures[0].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[2].id,pictures[2].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[1].id,pictures[1].name)}
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="w-full h-[49%] relative">
                <img
                  src={pictures[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md absolute"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 z-10">
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={()=>onRemovePicture(pictures[3].id,pictures[3].name)}
                    className="cursor-pointer"
                  />
                </div>
                <div className="absolute flex items-center justify-center w-full h-full bg-[#000000] opacity-70 text-white font-2xl rounded-md">{`+${
                  pictures.length - 4
                }`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewPictures;
