import { useContext, useEffect, useState } from "react";
import { addNewsContext } from "../../contexts/addNewsContext";
import SimpleImageSlider from "react-simple-image-slider";
import NewsImageSlider from "./NewsImageSlider";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


const NewsPreview = () => {
    const {news,setNews} = useContext(addNewsContext);
    const [width,setWidth] = useState(380);

    useEffect(()=>{
        document.getElementById("preview-section").onresize = () => {
            setWidth(document.getElementById("preview-section").clientWidth);
        }
    },[])
    return (
        <div className="w-[40%]" id="preview-section">
            <div className="h-16 flex items-center justify-start">

            <h1 className="font-inter font-medium text-lg text-primaryTextLight dark:text-primaryTextDark">News Preview</h1>
            </div>
            <div className="mt-3 font-imprima text-2xl leading-5 font-bold text-primaryTextLight dark:text-primaryTextDark opacity-80">
                <h2 className="w-full break-words whitespace-pre-wrap">
                {news.title}
                </h2>
            </div>
            {news.previewPictures.length > 0 &&
            <div className="w-full h-60 mt-5">
            <NewsImageSlider images={news.previewPictures}/>
            </div>
            }
      <div className="mt-5">
        <pre className="text-primaryTextLight opacity-80 whitespace-pre-wrap break-words font-inter font-light dark:text-primaryTextDark">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {news.body}
        </ReactMarkdown>
        </pre>
      </div>
        </div>
    )
}

export default NewsPreview;