import moment from "moment";
import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { ClipLoader } from "react-spinners";
import { ReactComponent as HallConnectLogo } from "../../assets/icons/hall-connect-logo.svg";
import { ReactComponent as HallNewsIndicator } from "../../assets/icons/hall-news-indicator.svg";
import NewsImageSlider from "./NewsImageSlider";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const NewsDetail = ({ news, isLoading }) => {
  return (
    <div className="w-[60%] flex flex-col">
      {!isLoading ? (
        <React.Fragment>
          <h1 className="text-primaryTextLight dark:text-primaryTextDark text-2xl font-light font-imprima line-clamp-3 opacity-80">
            {news?.title}
          </h1>{" "}
          <div className="flex gap-2 items-center text-xs text-[#7A7D81] font-light mt-5">
            {!news.is_hall_buzz ? (
              <HallConnectLogo width={"15"} height="15" />
            ) : (
              <HallNewsIndicator width={"15"} height="15" />
            )}
            <span>
              {!news.is_hall_buzz
                ? "LinkLounge"
                : news.created_by_useraccount.fullname}
            </span>
            <span className="inline-block w-[3px] h-[3px] rounded-full bg-dot-gray dark:bg-primaryTextDark"></span>
            <span className="">{moment(news?.date_created).fromNow()}</span>
          </div>
          <div className="w-full h-72 mt-5">
            {
              news?.pictures?.length > 1 ?
              <NewsImageSlider images={news?.pictures}/>
            :
                    <ProgressiveImage
              src={news.pictures.length > 0 ? news.pictures?.find((picture) => picture?.is_leading === true)?.image ||
                news.pictures[0]?.image : ""}
              placeholder=""
            >
              {(src, loading) => {
                return loading ? (
                  <div
                    className="h-full w-full loader rounded-md"
                    style={{ backgroundColor: "#777" }}
                  />
                ) : (
                  <img
                    src={src}
                    width={"100%"}
                    height="100%"
                    className="w-full h-full rounded-md object-cover"
                  />
                );
              }}
            </ProgressiveImage>
            }
                  
              
          </div>
          <div className="mt-5">
            <pre className="break-words whitespace-pre-wrap text-primaryTextLight dark:text-primaryTextDark font-inter font-light leading-5 text-base">
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {news.body} 
              </ReactMarkdown>
            </pre>
          </div>
        </React.Fragment>
      ) : (
        <div className="flex w-full justify-center mt-2 mb-2">
          <ClipLoader size={30} color="#F63E49" />
        </div>
      )}
    </div>
  );
};

export default NewsDetail;
