import {ClipLoader } from "react-spinners";
import { useGetPostStatistics } from "../../hooks/postHooks";
import millify from "millify";

const PostStatistics = () => {
    const {data:postStats,isLoading} = useGetPostStatistics()
    return (
        <div className="flex flex-col text-primaryTextLight dark:text-primaryTextDark gap-2 mb-2 px-4">
            <span className="font-semibold">Post Statistics</span>
            {
                isLoading ? <ClipLoader size={80} color="#F63E49"/> : <>
                <span className="text-sm">Total Posts: <span className="font-semibold">{millify(Number(postStats?.data.total_posts))}</span></span>
                <span className="text-sm">Hall Posts: <span className="font-semibold">{millify(Number(postStats?.data.hall_posts))}</span></span>
                <span className="text-sm">Shared Posts: <span className="font-semibold">{millify(Number(postStats?.data.shared_posts))}</span></span>
                <span className="text-sm">Total Likes: <span className="font-semibold">{millify(Number(postStats?.data.total_likes))}</span></span>
                <span className="text-sm">Total Comments: <span className="font-semibold">{millify(Number(postStats?.data.total_comments))}</span></span>
                <span className="text-sm">Total Posts Shares: <span className="font-semibold">{postStats?.data.total_post_shares ? millify(Number(postStats?.data.total_post_shares)) : 0}</span></span>
                </>
            }
        </div>
    )
}

export default PostStatistics;