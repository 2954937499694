import React, { useState } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import PageContainer from "../../components/PageContainer";
import { Formik, ErrorMessage } from "formik";
import { changePasswordSchema, ForgotPasswordSchema } from "../../schema/authSchema";
import { useLocation, useNavigate} from "react-router-dom";
import { useChangePassword} from "../../hooks/authHooks";
import pick from "lodash.pick";
import { toast } from "react-toastify";
import Toggle from "../../components/Toggle";
import { useEffect } from "react";
import PasswordInput from "../../components/PasswordInput";


const ResetPasswordPage = () => {
    const [showPassword,setShowPassword] = useState(false);
    const location = useLocation();
    const nav = useNavigate();
    const {mutate:changePassword,isLoading} = useChangePassword();
    const onSubmit = (values) => {
        if(location?.state?.email){
          values.email = location?.state?.email;
          values.new_password2 = values.new_password1;
          values.username = ""
        }else{
          values.username = location?.state?.username;
          values.new_password2 = values.new_password1;
          values.email = ""
        }

        changePassword(values,{
            onSuccess:(data)=>{
              toast.success("Password change successful",{autoClose:2000,hideProgressBar:true,position:"top-center"})
                nav('/');
            },
            onError:(error)=>{
                if(error?.response?.data?.error[0]=== 'This password has been used before.'){
                  toast.error('This password has been used before',{position:"top-center",hideProgressBar:true,autoClose:2000})
                }else{
                  toast.error('Failed to change password',{position:"top-center",hideProgressBar:true,autoClose:2000})
                }
            }
        })
    }

    useEffect(()=>{
      document.title = "LinkLounge | Reset Password"
    },[])
    return (
    <PageContainer customstyle="flex flex-col h-full">
      <main className="flex h-19/20 justify-between">
      <Formik
          initialValues={{new_password1:''}}
          validationSchema={changePasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange,handleSubmit,errors,touched,handleBlur }) => (
                <section className="flex flex-col w-full items-center justify-center sm:w-3/5">
                <form className="w-80 p-4 flex flex-col gap-5" onSubmit={handleSubmit}>
                    <h2 className="mb-2 font-bold text-2xl bg-linear-grad bg-clip-text text-transparent">Welcome Back</h2>
                    <p className="text-primaryTextLight dark:text-primaryTextDark mb-5 text-sm">Create a new password. Your password must be at least 8 characters, and must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol.</p>
                    <div className="mb-3">
                        <PasswordInput
                        name="new_password1"
                        customstyle="flex flex-col w-full gap-2 text-primaryTextLight dark:text-primaryTextDark text-xs"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {
                        errors.new_password1 && touched.new_password1 ?
                        <ErrorMessage name="new_password1" component="span" className="text-xs text-[#F63E49] font-light leading-3" /> : null
                        }
                    </div>
                    <div className="flex gap-4 items-center">
                    {/* <Toggle state={showPassword} onClick={()=>setShowPassword(prev=>!prev)}/> <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">Show password</span> */}
                    </div>
                    <Button text="RESET" type="submit" customstyle="w-full h-8 drop-shadow-md mb-5" loadingText="SUBMITTING" isLoading={isLoading}/>
                </form>
                </section>
          )}
        </Formik>
        <section className="w-2/5 h-full hidden sm:block">
          <BackgroundImage customstyle="h-full w-full" />
        </section>
      </main>
      <Footer/>
    </PageContainer>
  );
};

export default ResetPasswordPage;
