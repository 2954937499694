import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import Slider from "react-input-slider";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { userContext } from "../../contexts/userContext";
import { ReactComponent as CameraIcon } from "../../assets/icons/camera-icon-white.svg";
import AvatarEditor from "react-avatar-editor";
import { hallActions, userActions } from "../../reducers/actions";
import { hallContext } from "../../contexts/hallContext";
import { useUpdateProfilePicture } from "../../hooks/hallHooks";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { momentMenuContext } from "../../contexts/momentMenuContext";

const SetAsProfilePictureModal = () => {
//   const [profile, setProfile] = useState(null);
//   const [preview, setPreview] = useState("");
  const [state, setState] = useState({ x: 0, y: 0 });
  const { hall, setHall } = useContext(hallContext);
  const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
  const editor = useRef(null);

  const { mutate: updateProfile, isLoading } = useUpdateProfilePicture(
    hall?.hallInfo.id
  );


  const onUpdate = async (event) => {
    if (editor) {
      const croppedImageUrl = editor.current
        .getImageScaledToCanvas()
        .toDataURL();
      const result = await fetch(croppedImageUrl);
      const blob = await result.blob();
      const croppedImage = new File(
        [blob],
        `${hall?.hallInfo?.name}-profile-picture.${blob.type.split("/")[1]}`,
        { type: blob.type }
      );
      const formData = new FormData();
      formData.append("picture", croppedImage);
      updateProfile(formData, {
        onSuccess: (data) => {
          setHall(prev=>{return {...prev,hallInfo:data?.data}});
          toast.success("Image set as profile picture",{hideProgressBar:true,position:"top-center",autoClose:1000})
          setMomentMenu(prev=>{ return {...prev,setAsProfilePicture:false,profilePicture:""}});
        },
        onError: (error) => {
          toast.error("Failed to set as profile picture",{autoClose:2000,position:"top-center",hideProgressBar:true})
        },
      });
    }
  };

  const onCancel = () => {
    setMomentMenu(prev=>{ return {...prev,setAsProfilePicture:false,profilePicture:""}});
  }


  return (
    <>
      <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:200}}>
        <section className="h-[80%] w-[45%] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
            <CloseIcon
              width="25"
              height="25"
              onClick={onCancel}
              className="cursor-pointer absolute top-[-5px] right-[-5px]"
              // id="close-icon"
            />
          <div className="w-full px-4 py-4 flex items-center justify-between">
            <span className="dark:text-primaryTextDark text-primaryTextLight font-semibold text-xl">Profile Picture</span>
          </div>
          <hr className="dark:border-dark-gray"/>
          <div className="w-full text-center pt-6 mb-10">
            <span className="border-2 dark:border-dark-gray rounded-md px-4 py-2 text-primaryTextLight dark:text-primaryTextDark  font-normal text-xl">
              Position Picture
            </span>
          </div>
          <div className="w-60 h-60 relative ml-auto mr-auto border dark:border-dark-gray flex items-center justify-center overflow-auto bg-backgroundLight dark:bg-backgroundDark">
            (
              <AvatarEditor
                image={momentMenu.profilePicture}
                width={200}
                height={200}
                border={10}
                color={[128, 128, 128, 0.8]} // RGBA
                scale={1 + state.x / 25}
                rotate={0}
                borderRadius={500}
                className="bg-backgroundLight dark:bg-backgroundDark"
                ref={editor}
                crossOrigin="anonymous"
              />
            )
          </div>
          <div className="w-full flex gap-20 mt-5 ml-8 items-center mb-2">
            <span className="text-primaryTextLight dark:text-primaryTextDark">Zoom</span>
            <Slider
              axis="x"
              x={state.x}
              onChange={({ x }) => {
                setState({ ...state, x });
              }}
              styles={{
                track: {
                  backgroundColor: "rgba(246, 64, 73, 0.29);",
                  height: "5px",
                  width: "300px",
                },
                active: {
                  backgroundColor: "red",
                },
                thumb: {
                  width: 20,
                  height: 20,
                },
                disabled: {
                  opacity: 0.5,
                },
              }}
            />
          </div>
          <div className="w-full flex items-center justify-center">
            {isLoading ? (
              <BeatLoader color="#F63E49" size={10} />
            ) : (
              <span
                className="underline text-center font-medium text-xl text-[#F63E49] cursor-pointer"
                onClick={onUpdate}
              >
                Update
              </span>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default SetAsProfilePictureModal;
