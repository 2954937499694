import { React, createContext, useEffect } from "react";
import { useContext, useState } from "react";
import PageContainer from "../components/PageContainer";
import decodeToken from "../helpers/jwtDecode";
import { useGetHallInfo } from "../hooks/hallHooks";
import { useGetNewsTypes } from "../hooks/newsHooks";
import { useGetThemes } from "../hooks/postHooks";
import { useGetTrendThemes } from "../hooks/trendHooks";
import { useGetUser } from "../hooks/userHooks";
import {
  postBackgroundContext,
  trendBackgroundContext,
} from "./backgroundContext";
import { hallContext } from "./hallContext";
import { userContext } from "./userContext";
import { getRefreshToken } from "../helpers/manageToken";
import { refreshToken } from "../api/auth";

const loadingContext = createContext();

const LoadingPageProvider = ({ children }) => {
  //   const [enableQuery,setEnableQuery] = useState(false);
  const [user_id,setUserId] = useState(decodeToken());
  const [loading, setLoading] = useState(true);
  const { user_state } = useContext(userContext);
  const { isLoading: isFetchingUser,refetch } = useGetUser(user_id);
  const { postBackgrounds } = useContext(postBackgroundContext);
  const {hall} = useContext(hallContext);
  const { isLoading: isFetchingHallInfo,refetch:fetchHallInfo } = useGetHallInfo(
    user_state?.user?.user?.hall,
  );
  const {data:newsTypes} = useGetNewsTypes();
  const {isLoading:isFetchingBackgrounds,data:backgrounds} = useGetThemes();
  useEffect(() => {
    if (
      !isFetchingHallInfo &&
      !isFetchingUser &&
      user_state?.user?.user !== undefined &&
      hall?.hallInfo?.id !== undefined &&
      !isFetchingBackgrounds &&
      backgrounds?.data.results !== undefined &&
      newsTypes?.data?.results !== undefined
    ) {
      setLoading(false);
    }
  }, [
    isFetchingHallInfo,
    isFetchingUser,
    user_state.user,
    postBackgrounds,
    hall.hallInfo,
    newsTypes?.data,
    backgrounds?.data
  ]);
  useEffect(()=>{
    if(user_state.user.user !== undefined){
      fetchHallInfo()
    }
  },[user_state.user])

  useEffect(() => {
    if (user_id === null) {
      refreshToken()
        .then(() => {
          setUserId(decodeToken());
        })
        .catch((error) => {
          console.error('Error refreshing token:', error);
        });
    }
  }, []);
  return (
    <loadingContext.Provider value={loading}>
      {loading ? (
        <PageContainer customstyle={"h-full w-full relative"}>
          <div className="flex items-center justify-center w-full h-full z-50 absolute bg-backgroundLight dark:bg-backgroundDark">
            <div className="loader">
              <img src={"/hall-connect.png"} alt="App Logo" />
            </div>
          </div>
        </PageContainer>
      ) : (
        <>
          {children}
        </>
      )}
    </loadingContext.Provider>
  );
};

export { LoadingPageProvider };
