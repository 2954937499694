import authClient from "./authAxiosInstance";

const getHallInfo = (id) => {
    return authClient.get(`/user/v1/halls/${id}/`);
}

const editHallInfo = (id,values) => {
    return authClient.patch(`/user/v1/halls/${id}/`,values,{
        headers:{
            "Content-Type":"multipart/form-data"
        }
    });
}

const requestChange = (values) => {
    return authClient.post("/user/hall/v1/change-request/",values);
}

const checkProfileChangeRequestExists = (hall_id) => {
    return authClient.get(`/user/v1/halls/${hall_id}/profile-change-request/`);
}

const checkAboutChangeRequestExists = (hall_id) => {
    return authClient.get(`/user/v1/halls/${hall_id}/about-change-request/`);
}

const updatePicture = (id,updates) => {
    return authClient.patch(`/user/v1/halls/${id}/`,updates)
}

const updateHallProfilePicture = (id,values) => {
    return authClient.patch(`/user/v1/halls/${id}/change-profile-image/`,values,{
        headers:{
            "Content-Type":"multipart/form-data"
        }
    })
}

const updateHallCoverPhoto = (id,values) => {
    return authClient.patch(`/user/v1/halls/${id}/change-cover-image/`,values,{
        headers:{
            "Content-Type":"multipart/form-data"
        }
    })
}

const cancelRequest = async (id) => {
   const response = await authClient.get(`/user/hall/v1/change-request/${id}/cancel/`);
   if(response.status === 200){
    const results = response.data;
    return results;
    }
    return {error:"Could not cancel request"};
  }


export {getHallInfo,editHallInfo,updatePicture,requestChange,checkProfileChangeRequestExists,checkAboutChangeRequestExists,cancelRequest,updateHallCoverPhoto,updateHallProfilePicture}