import { useContext, useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { momentMenuContext } from "../../contexts/momentMenuContext"
import { useEditMomentTitle } from "../../hooks/momentsHooks";
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji-icon.svg";
import {ReactComponent as EmojiIconDark} from "../../assets/icons/emoji-icon-dark.svg";
import EmojiPicker from "../EmojiPicker";
import { themeContext } from "../../contexts/themeContext";


const EditMomentTitle = () => {
    const {theme} = useContext(themeContext);
    const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
    const {mutate:editMomentTitle,isLoading} = useEditMomentTitle();
    const [title,setTitle] = useState(momentMenu.moment?.title);
    const [showEmoji, setShowEmoji] = useState(false);
    const ref = useRef();
    const onEmojiClick = (emoji) => {
        setTitle(title + emoji.char)
    };
    const handleTextChange = (event) => {
        setTitle(event.target.value);
    }
    const queryClient = useQueryClient()

    const onEditMomentTitle = () => {
        if(title.length === 0){
            toast.error("New moment title not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            editMomentTitle({id:momentMenu.moment.id,values:{title:title}},{
                onSuccess:(data)=>{
                    queryClient.invalidateQueries("hall-moments");
                    queryClient.invalidateQueries("single-hall-moment",momentMenu.moment.id)
                    toast.success("Moment title updated",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                    setMomentMenu(prev=> { return {...prev,editMomentTitle:false,moment:{}}})
                },
                onError:(error)=>{
                    toast.error("Could not update moment title",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                }
            })
        }
    }
    const handleMouseDown = (event) => {
        if (!ref.current?.contains(event.target) && showEmoji === true) {
        setShowEmoji(false);
        } 
    };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:"9999"}}>
            <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 flex flex-col gap-5 min-w-[20rem]">
                <p className="text-primaryTextLight dark:text-primaryTextDark font-medium">Edit Moment Title</p>
                <div className="w-full h-10 relative" id="edit-moment-title">
                        <div className="px-3 rounded-md flex border dark:border-dark-gray justify-between h-full" >
                            <input className="w-full h-8 focus:outline-none px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" value={title} onChange={handleTextChange}/>
                            {
                                theme?.value == "light" ?
                                <EmojiIcon height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => {if(showEmoji === false){setShowEmoji(true)}}}/>
:                            <EmojiIconDark height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => {if(showEmoji === false){setShowEmoji(true)}}}/>

                            }
                        </div>
                        {showEmoji && (
                        <div className="absolute top-[100%] w-full flex items-center bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{zIndex:400}} ref={ref}>
                            <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
                        </div>
                        )}
                </div>
                <div className="flex ml-auto gap-4 mt-auto">
                    <button className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md" onClick={()=>setMomentMenu(prev=>{return {...prev,editMomentTitle:false,moment:{}}})}>Cancel</button>
                    <button className="bg-[#F63E49] text-white py-1 px-4 rounded-md" onClick={onEditMomentTitle}>{!isLoading ? "Edit" : <BeatLoader color="white" size={10}/>}</button>
                </div>
            </div>            
        </div>
    )
}

export default EditMomentTitle;