import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import { useGetTrends } from "../../hooks/trendHooks";
import ImagesPostContent from "./ImagesPostContent";
import Trend from "./Trend";
import TextPostContent from "./TextPostContent";
import TextWithBgPostContent from "./TextWithBgPostContent";
import TextWithImagesPostContent from "./TextWithImagesPostContent";
import TrendingPosts from "./TrendingPosts";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";

const AllTrends = ({setViewImages,setImages,parentId}) => {
  const { hall } = useContext(hallContext);
  const {isLoading,data:trends,fetchNextPage,hasNextPage} = useGetTrends(hall?.hallInfo.id);
  if(isLoading){
    return (
      <div className="w-full h-36 flex justify-center">
        <ClipLoader size={80} color="#F63E49"/>
      </div>
    )
  }
  return (
<InfiniteScroll
        dataLength={trends?.pages?.length * 64 || 64}
        next={fetchNextPage}
        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextPage}
        scrollThreshold={1}
        loader={<div className="flex w-full justify-center mt-2 mb-2">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}        scrollableTarget={parentId}
        className="scrollbar-hide w-full flex justify-center"
        >      <TrendingPosts />
{trends?.pages?.map((group,i)=>{
                return group?.data?.results?.map(trend=>{
                    if (trend.text && !trend.theme && trend.pictures.length === 0) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextPostContent text={trend.text} />
            </Trend>
          );
        } else if (
          // trend.text &&
           trend.pictures.length !== 0) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextWithImagesPostContent
                text={trend.text}
                pictures={trend.pictures}
                setViewImages={setViewImages}
                setImages={setImages}
              />
            </Trend>
          );
        } else if (trend.text && trend.theme) {
          return (
            <Trend key={trend.id} trend={trend}>
              <TextWithBgPostContent text={trend.text} theme={trend.theme} />
            </Trend>
          );
        } else {
          return "";
        }
      })})}
    </InfiniteScroll>
  );
};

export default AllTrends;
