import { useContext } from "react";
import { memberContext } from "../../contexts/memberContext";

const MemberContextMenu = ({member}) => {
    const {memberOptions,setMemberOptions} = useContext(memberContext);
    return (
        <div className="w-56 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-backgroundDark">
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                {/* <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 gap-1 py-2">Set permission to Hallpage</span> */}
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight pl-4 gap-1 py-2" onClick={()=>setMemberOptions(prev=>{return {...prev,addLeadershipPortfolio:true,leadershipMember:member}})}>Add leadership portfolio</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-2 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={()=>{setMemberOptions(prev=>{return {...prev,report:true,reportMember:member}})}}>
                Report account
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-2 text-[#F63E49] cursor-pointer hover:cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark" onClick={()=>{setMemberOptions(prev=>{return {...prev,block:true,blockMember:member}})}}>
                Block account
            </div>
        </div>
    )
}

export default MemberContextMenu;