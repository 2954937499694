import { createContext, useState } from "react"

export const leadershipContext = createContext();
const LeadershipContextProvider = ({children}) => {
    const [leadershipStates,setLeadershipStates] = useState({showPortofolios:false,addPortofolio:false,addManagement:false,addExecutive:false,executives:[],previous:"",editPortofolio:false,portofolio:{},linkAccount:false,account:{},leader:{},leaderCategory:"",linkedAccount:{},registerChange:false,editLeadershipPortfolio:false,showDiscard:false,removeLeader:false,filterExecutives:false,filterManagement:false,changeRequested:false,showLinkedAccount:false,addTenure:false,selectedTenure:{},selectedPortfolio:{}})
    return (
        <leadershipContext.Provider value={{leadershipStates,setLeadershipStates}}>
            {children}
        </leadershipContext.Provider>
    )
}

export default LeadershipContextProvider;