import { createContext, useReducer, useState } from "react";
import { getHall } from "../helpers/manageHall";
import hallReducer from "../reducers/hallReducer";

export const hallContext = createContext();

const HallContext = ({children}) => {
    // const [state,dispatch] = useReducer(hallReducer,{hallInfo:getHall() || {},hallLeaders:[],hallMoments:[]});
    const [hall,setHall] = useState({hallInfo:{}});
    return (
        <hallContext.Provider value={{hall,setHall}}>
            {children}
        </hallContext.Provider>
    )
}

export default HallContext