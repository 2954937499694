import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { ReactComponent as UserIcon } from "../../assets/icons/user-icon.svg";
import { userContext } from "../../contexts/userContext";
import ChangeTheme from "./ChangeTheme";
import UserProfileOptions from "./UserProfileOptions";

const User = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showThemeSettings,setShowThemeSettings] = useState(false);
  const { user_state } = useContext(userContext);
  const ref = useRef();
  const ref2 = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowOptions(false);
    }
    if(!ref2.current?.contains(event.target)){
      setShowThemeSettings(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
  return (
    <div className="h-[15px] hover:cursor-pointer relative">
      <div
        className="flex h-full"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        <UserIcon height="20" width="20" className="mr-[5px]" />
        <span
          className="text-sm text-white dark:text-primaryTextDark hover:cursor-pointer"
         
        >
          {user_state.user.username}
        </span>
      </div>
      {showOptions && <UserProfileOptions innerref={ref} onChangeTheme={()=>{setShowThemeSettings(prev=>!prev);setShowOptions(false)}} />}
      {showThemeSettings && 
      <ChangeTheme setShowChangeTheme={setShowThemeSettings} innerref={ref2}/>}
    </div>
  );
};

export default User;
