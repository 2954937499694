import { useContext } from "react";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import defaultProfile from "../../assets/images/default-profile.png";


const Profile = ({editProfilePicture}) => {
    const {hall} = useContext(hallContext);
    return (
        <div className="h-28 w-28 bg-gray-500 rounded-full bg-cover bg-top relative">
            <div className="h-full w-full rounded-full">
                <img src={hall?.hallInfo.picture || defaultProfile} alt="Profile" height="100%" width="100%" className="h-full w-full rounded-full"/>
            </div>
            <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark absolute top-[85px] left-[-8px]">
            <CameraIcon height="20" width="20" onClick={editProfilePicture} className="cursor-pointer"/>
            </div>
        </div>
    )
}

export default Profile;