import { useContext, useState } from "react";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { themeContext } from "../../contexts/themeContext";
const OptionSelector = ({options,onChange,name}) => {
    const {theme} = useContext(themeContext);
    const [selectedOption,setSelectedOption] = useState("Choose your reason");
    const [showOptions,setShowOptions] = useState(false);
    const [rotate,setRotate] = useState(false);
    const onDropDown = () => {
        setShowOptions(prev=>!prev);
        setRotate(prev=>!prev);
    }
    const onSelect = (value) => {
        setSelectedOption(value.reason);
        onChange(name,value.id)
        setRotate(false);
        setShowOptions(false);
    }
  return (
    <div className="w-full relative rounded-md" style={{border:"1px solid #D9D9D9"}}>
      <div className="h-10 w-full text-primaryTextLight dark:text-primaryTextDark flex items-center justify-between px-2 pl-3 rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={onDropDown}>
        <span className="font-light">{selectedOption}</span>
        <div className="flex items-center gap-2">
          {
            theme?.value == "light" ?
            <SelectIcon width="12" height="12" onClick={onDropDown} className={
              "cursor-pointer " +
              (rotate ? "rotate-180 delay-200" : "delay-200")
            }/>:
            <SelectIconDark width="12" height="12" onClick={onDropDown} className={
              "cursor-pointer " +
              (rotate ? "rotate-180 delay-200" : "delay-200")
            }/>
          }
        </div>
      </div>
      {showOptions && 
          <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md mt-[1px] absolute top-10 w-full z-10 max-h-28 overflow-auto">
            {options?.map((option)=>{
                return (
                    <div className="text-primaryTextLight dark:text-primaryTextDark flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={option.id} onClick={()=>onSelect(option)}>
                        <span>{option.reason}</span>
                    </div>
                    )
                })}
            </div>
      }
    </div>
  );
};

export default OptionSelector;