import { useContext } from "react"
import { useQuery } from "react-query"
import { getUser } from "../api/user"
import { userContext } from "../contexts/userContext"
import { userActions } from "../reducers/actions"

const useGetUser = (id) => {
    const {setUser} = useContext(userContext);
    return useQuery(["user",id],()=>getUser(id),{
        onSuccess:data=>{
            setUser({ type: userActions.login, payload:{user:data.data}})
        },
        // onError:error=>{
        //     console.log(error)
        // },
        enabled:!!id,
        retry:true
    })
}

export {useGetUser}