import { toast } from "react-toastify";
import { ReactComponent as SelectedIcon } from "../../assets/icons/correct-icon.svg";
import { useQueryClient } from "react-query";
import { useEditMomentPicturePrivacy} from "../../hooks/momentsHooks";

const ChangeMomentPicturePrivacy = ({innerRef,privacy,pictureId,setShowChangePrivacy,momentId}) => {
    const {mutate:changePrivacy} = useEditMomentPicturePrivacy();
    const queryClient = useQueryClient();

    const onChangePrivacy = (value) => {
        changePrivacy({id:pictureId,values:{privacy:value}},{
            onSuccess:(data)=>{
                setShowChangePrivacy(false);
                queryClient.invalidateQueries("hall-moments");
                queryClient.invalidateQueries("single-hall-moment",momentId)
                toast.success("Privacy changed",{autoClose:2000,position:"top-center",hideProgressBar:true})
            },
            onError:(error)=>{
                toast.error("Could not change privacy",{autoClose:2000,position:"top-center",hideProgressBar:true})
            }
        })
    }
    return (
        <div className="w-60 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-cardBackgroundDark" ref={innerRef}>
            <span className="text-primaryTextLight dark:text-primaryTextDark pl-4 font-medium text-md mb-5">Choose who can reply?</span>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight  pl-4 py-2 flex gap-10" onClick={()=>onChangePrivacy("Hall")}>Hall{"  "} {privacy === "Hall" && <SelectedIcon width="15" height="15" />}</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight  pl-4 py-2 flex gap-4" onClick={()=>onChangePrivacy("Private")}>Private{"  "} {privacy === "Private" && <SelectedIcon width="15" height="15" />}</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight  pl-4 py-2 flex gap-5" onClick={()=>onChangePrivacy("Public")}>Public{"  "} {privacy === "Public" && <SelectedIcon width="15" height="15" />}</span>
            </div>
        </div>
    )
}

export default ChangeMomentPicturePrivacy;