import {ReactComponent as EditIcon} from "../../assets/icons/edit-icon.svg";

const EditButton = ({onClick}) => {
    return (
        <button type="button" className="w-20 h-8 border flex bg-linear-grad items-center rounded-md px-3 justify-between cursor-pointer border-none" onClick={onClick}>
            <EditIcon width="14" height="14"/>
            <span className="text-white">Edit</span>
        </button>
    )
}

export default EditButton;