import React, { useContext } from "react";
import { useState } from "react";
import { useGetPosts, useGetTrendPosts } from "../../hooks/postHooks";
import ImagesPostContent from "./ImagesPostContent";
import Post from "./Post";
import PostWithPoll from "./PostWithPoll";
import TextPostContent from "./TextPostContent";
import TextWithBgPostContent from "./TextWithBgPostContent";
import TextWithImagesPostContent from "./TextWithImagesPostContent";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component'
import TrendingPosts from "./TrendingPosts";
import { hallContext } from "../../contexts/hallContext";
import VideoPostContent from "./VideoPostContent";
import TextPostWithVideo from "./TextPostWithVideo";


const AllPosts = ({setViewImages, setImages,parentId,setShowTrendingPosts,showTrendingPosts}) => {
    const {isLoading,data:posts,fetchNextPage,hasNextPage} = useGetPosts();
    const {isLoading:isFetchingTrendingPosts,data:trendingPosts,fetchNextPage:fetchNextTrends,hasNextPage:hasNextTrends,refetch} = useGetTrendPosts()
    const { hall } = useContext(hallContext);

    if(isLoading){
        return (
        <div className="flex w-full h-36 justify-center mt-10">
            <ClipLoader size={80} color="#F63E49"/>
        </div>
        )
    }
    return (
        !showTrendingPosts ? 
        <InfiniteScroll
        dataLength={posts?.pages?.length * 64 || 64}
        next={fetchNextPage}
        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextPage}
        scrollThreshold={1}
        loader={<div className="flex w-full justify-center mt-2 mb-2">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}
        
        refreshFunction={refetch}
        pullDownToRefresh={true}
        scrollableTarget={parentId}
        className="scrollbar-hide"
        >
            <TrendingPosts showTrendingPosts={()=>{setShowTrendingPosts(true)}}/>
        {/* <React.Fragment> */}
            {posts?.pages?.map((group,i)=>{
                return group?.data?.results?.map(post=>{
                if(post.shared_from === null){
                    if(post.polls.length !== 0){
                        return (
                            <Post key={post.id} post={post}>
                                <PostWithPoll polls={post.polls[0]} text={post.text}/>
                            </Post>
                        )
                    }
                    else if(post.videos.length !== 0 && !post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <VideoPostContent video={post.videos[0]}/>
                            </Post>
                        )
                    }else if(post.videos.length !== 0 && post.text){
                        return (
                        <Post key={post.id} post={post}>
                            <TextPostWithVideo text={post.text} video={post.videos[0]}/>
                        </Post>
                        )
                    }
                    else if(post.theme){
                        return (
                            <Post key={post.id} post={post}>
                                <TextWithBgPostContent text={post.text} theme={post.theme}/>
                            </Post>
                        )
                    }
                    else if(post.pictures && !post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <ImagesPostContent pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                            </Post>
                        )
                    }
                    else if(post.pictures.length !== 0 && post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <TextWithImagesPostContent text={post.text} pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                            </Post>
                        )
                    }
                    else if (post.text && post.pictures.length === 0 && !post.theme){
                        return (
                            <Post key={post.id} post={post}>
                                <TextPostContent text={post.text}/>
                            </Post>
                        )
                    }else{
                        return "";
                    }
                    }else{
                        if(post.shared_from.polls.length !== 0){
                            return (
                                <Post key={post.id} post={post.shared_from} shared={true} sharerName={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.name : post?.user_account.username} sharerProfileUrl={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.picture: post?.user_account.image}>
                                    <PostWithPoll polls={post.shared_from.polls[0]} text={post.shared_from.text}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.videos.length !== 0 && !post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <VideoPostContent video={post.shared_from.videos[0]}/>
                                </Post>
                            )
                        }else if(post.shared_from.videos.length !== 0 && post.shared_from.text){
                            return (
                            <Post key={post.id} post={post.shared_from}>
                                <TextPostWithVideo text={post.shared_from.text} video={post.shared_from.videos[0]}/>
                            </Post>
                            )
                        }
                        else if(post.shared_from.theme){
                            return (
                                <Post key={post.id} post={post.shared_from} shared={true} sharerName={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.name : post?.user_account.username} sharerProfileUrl={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.picture: post?.user_account.image}>
                                    <TextWithBgPostContent text={post.shared_from.text} theme={post.shared_from.theme}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.pictures && !post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from} shared={true} sharerName={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.name : post?.user_account.username} sharerProfileUrl={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.picture: post?.user_account.image}>
                                    <ImagesPostContent pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.pictures.length !== 0 && post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from} shared={true} sharerName={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.name : post?.user_account.username} sharerProfileUrl={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.picture: post?.user_account.image}>
                                    <TextWithImagesPostContent text={post.shared_from.text} pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                </Post>
                            )
                        }
                        else if (post.shared_from.text && post.shared_from.pictures.length === 0 && !post.shared_from.theme){
                            return (
                                <Post key={post.id} post={post.shared_from} shared={true} sharerName={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.name : post?.user_account.username} sharerProfileUrl={post?.is_hall_post || post?.polls[0]?.is_hall_poll ? hall?.hallInfo.picture: post?.user_account.image}>
                                    <TextPostContent text={post.shared_from.text}/>
                                </Post>
                            )
                        }else{
                            return "";
                        }
                    }
                })
                
            })}
        {/* </React.Fragment> */}
        </InfiniteScroll> :
        <InfiniteScroll
        dataLength={posts?.pages?.length * 64 || 64}
        next={fetchNextTrends}
        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
        hasMore={hasNextTrends}
        scrollThreshold={1}
        loader={<div className="flex w-full justify-center mt-2 mb-2">
        <ClipLoader size={30} color="#F63E49"/>
    </div>}
        scrollableTarget={parentId}
        className="scrollbar-hide"
        >
        {/* <React.Fragment> */}
            {trendingPosts?.pages?.map((group,i)=>{
                return group?.data?.results?.map(post=>{
                if(post.shared_from === null){
                    if(post.polls.length !== 0){
                        return (
                            <Post key={post.id} post={post}>
                                <PostWithPoll polls={post.polls[0]} text={post.text}/>
                            </Post>
                        )
                    }
                    else if(post.theme){
                        return (
                            <Post key={post.id} post={post}>
                                <TextWithBgPostContent text={post.text} theme={post.theme}/>
                            </Post>
                        )
                    }
                    else if(post.pictures && !post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <ImagesPostContent pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                            </Post>
                        )
                    }
                    else if(post.pictures.length !== 0 && post.text){
                        return (
                            <Post key={post.id} post={post}>
                                <TextWithImagesPostContent text={post.text} pictures={post.pictures} setImages={setImages} setViewImages={setViewImages}/>
                            </Post>
                        )
                    }
                    else if (post.text && post.pictures.length === 0 && !post.theme){
                        return (
                            <Post key={post.id} post={post}>
                                <TextPostContent text={post.text}/>
                            </Post>
                        )
                    }else{
                        return "";
                    }
                    }else{
                        if(post.shared_from.polls.length !== 0){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <PostWithPoll polls={post.shared_from.polls[0]} text={post.shared_from.text}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.theme){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <TextWithBgPostContent text={post.shared_from.text} theme={post.shared_from.theme}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.pictures && !post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <ImagesPostContent pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                </Post>
                            )
                        }
                        else if(post.shared_from.pictures.length !== 0 && post.shared_from.text){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <TextWithImagesPostContent text={post.shared_from.text} pictures={post.shared_from.pictures} setImages={setImages} setViewImages={setViewImages}/>
                                </Post>
                            )
                        }
                        else if (post.shared_from.text && post.shared_from.pictures.length === 0 && !post.shared_from.theme){
                            return (
                                <Post key={post.id} post={post.shared_from}>
                                    <TextPostContent text={post.shared_from.text}/>
                                </Post>
                            )
                        }else{
                            return "";
                        }
                    }
                })
                
            })}
        {/* </React.Fragment> */}
        </InfiniteScroll>
    )
}

export default AllPosts;