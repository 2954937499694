import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-cloud-icon.svg";
import { useEffect } from "react";

const UploadFile = ({ onClose, open,getRootProps, getInputProps}) => {

  // useEffect(() => {
  //   console.log(acceptedFiles);
  // }, [acceptedFiles]);
  return (
    <div className="w-full h-60 bg-backgroundLight dark:bg-backgroundDark flex flex-col rounded-md relative">
      <div className="w-full flex items-center justify-end px-2 py-2 absolute z-10">
        <CloseIcon
          width="20"
          height="20"
          onClick={onClose}
          className="cursor-pointer"
        />
      </div>
      <div
        className="flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0"
        {...getRootProps({ className: "dropzone flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0" })}
      >
        <input className="input-zone" {...getInputProps({})}/>
          <p className="dropzone-content w-full h-full flex flex-col justify-center items-center cursor-pointer" onClick={open}>
            <UploadIcon width="70" height="70" />
            <span className="text-xs text-primaryTextLight dark:text-primaryTextDark">
              Drop a file here or click to upload
            </span>
          </p>
      </div>
    </div>
  );
};

export default UploadFile;
