import moment from "moment/moment";
import { useContext } from "react";
import { FadeLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadDoneIcon } from "../../assets/icons/upload-done-icon.svg";
import { addNewsContext } from "../../contexts/addNewsContext";

const NewsPicturesPreview = () => {
  const { news, setNews } = useContext(addNewsContext);
  const onRemovePicture = (id, name) => {
    if (news.previewPictures.length === 1) {
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: prev.previewPictures.filter(
            (picture) => picture.id !== id
          ),
          pictures: prev.pictures.filter(
            (picture) => picture.id !== id
          ),
          preview:false
        };
      });
    } else {
      setNews((prev) => {
        return {
          ...prev,
          previewPictures: prev.previewPictures.filter(
            (picture) => picture.id !== id
          ),
          pictures: prev.pictures.filter(
            (picture) => picture.id !== id
          ),
        };
      });
    }
  }
  return (
    <div className="mt-4 mb-4 w-full max-h-80 relative">
      <div className=" w-full h-full gap-2">
        {news.previewPictures.length === 1 && (
          <div
            className={
              "max-w-full gap-2 relative"
            }
          >
            <img
              src={news.previewPictures[0].image}
              alt="Post Images"
              width="100%"
              height="100%"
              className="rounded-md w-full max-h-80 object-cover"
            />
            <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
              <CloseIcon
                width="20"
                height="20"
                onClick={() =>
                  onRemovePicture(
                    news.previewPictures[0].id,
                    news.previewPictures[0].name
                  )
                }
                className="cursor-pointer"
              />
            </div>
            {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
              {momentData.momentPicturesPreview[0].isLoading && (
                <FadeLoader width="5px" height="10px" color="white" />
              )}
              {momentData.momentPicturesPreview[0].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
            </div> */}
          </div>
        )}
        {news.previewPictures.length === 2 && (
          <div className="w-full h-80 flex gap-1">
            {news.previewPictures.map((picture) => {
              return (
                <div
                  className={
                    "w-1/2 h-full relative"
                  }
                  key={picture.id}
                >
                  <img
                    src={picture.image}
                    alt="Post Images"
                    width="100%"
                    height="100%"
                    className="w-full h-full object-cover rounded-md"
                  />
                  <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                    <CloseIcon
                      width="20"
                      height="20"
                      onClick={() => onRemovePicture(picture.id, picture.name)}
                      className="cursor-pointer"
                    />
                  </div>
                  {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
                  <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                    {picture.isLoading && (
                      <FadeLoader width="5px" height="20px" color="white" />
                    )}
                    {picture.isUploaded && (
                      <UploadDoneIcon width="40" height="40" />
                    )}
                  </div> */}
                </div>
              );
            })}
          </div>
        )}
        {news.previewPictures.length === 3 && (
          <div className="h-80 w-full flex gap-1 relative">
            <div
              className={
                "w-1/2 h-full relative"
              }
            >
              <img
                src={news.previewPictures[0].image}
                alt="Post Images"
                width="100%"
                height="100%"
                className="w-full h-full object-cover rounded-md"
              />
              <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                <CloseIcon
                  width="20"
                  height="20"
                  onClick={() =>
                    onRemovePicture(
                        news.previewPictures.id,
                        news.previewPictures.name
                    )
                  }
                  className="cursor-pointer"
                />
              </div>
              {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
              <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                {momentData.momentPicturesPreview[0].isLoading && (
                  <FadeLoader width="5px" height="20px" color="white" />
                )}
                {momentData.momentPicturesPreview[0].isUploaded && (
                  <UploadDoneIcon width="40" height="40" />
                )}
              </div> */}
            </div>
            <div className="w-1/2 h-80 flex flex-col justify-between">
              {news.previewPictures.slice(1).map((picture) => {
                return (
                  <div
                    className={
                      "h-[49%] w-full relative"
                    }
                    key={picture.id}
                  >
                    <img
                      src={picture.image}
                      alt="Post Images"
                      width="100%"
                      height="100%"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                      <CloseIcon
                        width="20"
                        height="20"
                        onClick={() =>
                          onRemovePicture(picture.id, picture.name)
                        }
                        className="cursor-pointer"
                      />
                    </div>
                    {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
                    <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                      {picture.isLoading && (
                        <FadeLoader width="5px" height="20px" color="white" />
                      )}
                      {picture.isUploaded && (
                        <UploadDoneIcon width="40" height="40" />
                      )}
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {news.previewPictures.length === 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[0].id,
                        news.previewPictures[0].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {momentData.momentPicturesPreview[0].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[0].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[2].id,
                        news.previewPictures[2].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {momentData.momentPicturesPreview[2].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[2].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[1].id,
                        news.previewPictures[1].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {momentData.momentPicturesPreview[1].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[1].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[3].id,
                        news.previewPictures[3].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {news.previewPictures[3].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[3].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
            </div>
          </div>
        )}
        {news.previewPictures.length > 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[0].id,
                        news.previewPictures[0].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:100}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]">
                  {momentData.momentPicturesPreview[0].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[0].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[2].id,
                        news.previewPictures[2].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:50}}>
                  {momentData.momentPicturesPreview[2].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[2].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[1].id,
                        news.previewPictures[1].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-full h-full absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {momentData.momentPicturesPreview[1].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[1].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
              </div>
              <div
                className={
                  "w-full h-[49%] relative"
                }
              >
                <img
                  src={news.previewPictures[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md absolute"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={() =>
                      onRemovePicture(
                        news.previewPictures[3].id,
                        news.previewPictures[3].name
                      )
                    }
                    className="cursor-pointer"
                  />
                </div>
                {/* <div className="w-1/2 h-[49%] absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto absolute top-[48%] left-[48%]" style={{zIndex:100}}>
                  {momentData.momentPicturesPreview[3].isLoading && (
                    <FadeLoader width="5px" height="20px" color="white" />
                  )}
                  {momentData.momentPicturesPreview[3].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
                <div className="absolute flex items-center justify-center w-full h-full bg-[#000000] opacity-70 text-white font-2xl rounded-md">{`+${
                  news.previewPictures.length - 4
                }`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsPicturesPreview;
