import SmallProfile from "../hallpage/SmallProfile";
import { useCallback, useState } from "react";
import { ReactComponent as EmojiIcon } from "../../assets/icons/emoji-icon.svg";
import { ReactComponent as EmojiIconDark } from "../../assets/icons/emoji-icon-dark.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload-cloud-icon-dark.svg";
import { ReactComponent as UploadIconDark } from "../../assets/icons/upload-cloud-icon.svg";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Datepicker from "../hallpage/Datepicker";
import ScheduleSelector from "../posts-trending/ScheduleSelector";
import { useContext } from "react";
import { hallContext } from "../../contexts/hallContext";
import SelectPrivacy from "./SelectPrivacy";
import { useCreateHallMoment, useCreateMomentPicture } from "../../hooks/momentsHooks";
import { useQueryClient } from "react-query";
import {ReactComponent as CleanImagesIcon} from "../../assets/icons/clean-images.svg";
import {ReactComponent as EditImagesIcon} from "../../assets/icons/edit-images.svg";
import {ReactComponent as AddImagesIcon} from "../../assets/icons/add-images.svg";
import { generateRandomId } from "../../helpers/generateRandomId";
import MomentPreview from "./MomentPreview";
import { createMomentContext } from "../../contexts/createMomentContext";
import pick from "lodash.pick";
import EmojiPicker from "../EmojiPicker";
import { validateYupSchema } from "formik";
import Lightbox from "yet-another-react-lightbox";
import LinearProgressBar from "../LinearProgressBar";
import { themeContext } from "../../contexts/themeContext";

const CreateMoment = ({ onClose, open }) => {
  const [preview, setPreview] = useState(false);
  const [schedulePost,setSchedulePost] = useState(false);
  const {hall} = useContext(hallContext);
  const {momentData,setMomentData} = useContext(createMomentContext);
  const [moment,setMoment] = useState({hall:hall?.hallInfo.id,title:"",privacy:'Public',pictures:[],published:true,published_at:""})
  const {mutate:createMoment,isLoading} = useCreateHallMoment();
  const {mutateAsync:createMomentPicture,isLoading:isUploadingPicture} = useCreateMomentPicture();
  const queryClient = useQueryClient();
  const [loading,setLoading] = useState(false);
  const [showLightBox,setShowLightBox] = useState(false);
  const {theme} = useContext(themeContext);
  const onDrop = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        if(file.name.length > 100){
          toast.info("Image name cannot be more than 100 characters",{autoClose:2000,position:"top-center",hideProgressBar:true});
          continue;
        }
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId()
        images.push({ image: objectUrl, id, name:file.name,isLoading:false,isUploaded:false,reUpload:false,uploadProgress:{} });
        setMomentData(prev=>{ return {...prev,momentPictures:[...prev.momentPictures,{image:file,description:"",id,privacy:"Public"}]}})
      }
      setMomentData(prev=>{return {...prev,momentPicturesPreview:[...prev.momentPicturesPreview,...images],preview:true}});
    }
  });

  const { getRootProps, getInputProps, inputRef, acceptedFiles } = useDropzone({
    onDropAccepted: onDrop,
    onDropRejected: () => toast.error("Drag 'n' drop image files",{autoClose:2000,position:"top-center",hideProgressBar:true}),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });

  const onDropAdditionalFiles = useCallback((acceptedFiles) => {
    const images = [];
    if (acceptedFiles) {
      for (const file of acceptedFiles) {
        const objectUrl = URL.createObjectURL(file);
        const id = generateRandomId();
        images.push({ image: objectUrl, id, name:file.name,isLoading:false,isUploaded:false,reUpload:false,uploadProgress:{} });
        setMomentData(prev=>{ return {...prev,momentPictures:[...prev.momentPictures,{image:file,description:"",id,privacy:"Public"}]}})
      }
      setMomentData(prev=>{return {...prev,momentPicturesPreview:[...prev.momentPicturesPreview,...images],preview:true}});
      // setPreview(true);
    }
  });

  const { getRootProps:additionalRootProps, getInputProps:additionalInputProps, inputRef:additionalInputRef, acceptedFiles:additionalAcceptedFiles } = useDropzone({
    onDropAccepted:onDropAdditionalFiles,
    onDropRejected:()=>toast.error("Drag 'n' drop image files",{autoClose:2000,hideProgressBar:true,position:"top-center"}),
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
    },
  });


  const onClearAdditionalImages = () => {
    // setPreview(false);
    setMomentData(prev=>{return {...prev,momentPictures:[],momentPicturesPreview:[],preview:false}})
    additionalAcceptedFiles.splice(0, additionalAcceptedFiles.length);
    additionalAcceptedFiles.length = 0;
    if (additionalInputRef.current !== null) {
      additionalInputRef.current.value = "";
    }
  };

  const onClearImages = () => {
    // setPreview(false);
    setMomentData(prev=>{return {...prev,momentPictures:[],momentPicturesPreview:[],preview:false}})
    acceptedFiles.splice(0, acceptedFiles.length);
    acceptedFiles.length = 0;
    if (inputRef.current !== null) {
      inputRef.current.value = "";
    }
  };

  const [showEmoji, setShowEmoji] = useState(false);
  const onEmojiClick = (emoji) => {
    setMoment(prev=>{
        if(prev.title)
            return {...prev,title:prev.title + emoji.char};
        else
            return {...prev,title:emoji.char}
        })
  };

  const handleChange = (event) => {
    handleInputChange(event);
  };

  const handleInputChange = (event) => {
    setMoment(prev=>{
        return {...prev,title:event.target.value}
    })
  };

  const postMomentPicture = async (momentId,momentPicture) => {
    const uploadProgress = (event)=>{
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = false;
        momentPic.isLoading = true;
        momentPic.reUpload = false;
        momentPic.uploadProgress = event;
        prev.momentPicturesPreview.splice(pictureIndex,1,momentPic)
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
      })
    }
    const formdata = new FormData();
    formdata.append("description",momentPicture.description);
    formdata.append("image",momentPicture.image);
    formdata.append("moment",momentId);
    formdata.append("privacy",momentPicture.privacy);
    const createdPicture = await createMomentPicture({values:formdata,uploadProgress});
    if(createdPicture.status === 201){
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = true;
        momentPic.isLoading = false;
        momentPic.reUpload = false;
        prev.momentPicturesPreview.splice(pictureIndex,1);
        prev.momentPicturesPreview.push(momentPic);
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview,totalUploaded:prev.totalUploaded + 1}
      })
    }else{
      setMomentData(prev=>{
        const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === momentPicture.id);
        const momentPic = prev.momentPicturesPreview[pictureIndex];
        momentPic.isUploaded = false;
        momentPic.isLoading = false;
        momentPic.reUpload = true;
        momentPic.momentId = momentId
        prev.momentPicturesPreview.splice(pictureIndex,1,momentPic)
        return {...prev,momentPicturesPreview:prev.momentPicturesPreview}
      })
    }
    return createdPicture;
  }

  const onPost = (schedule) => {
    if(moment.title.length === 0){
        toast.error("Please enter title of moment",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else if(momentData.momentPictures.length === 0){
        toast.error("Moment pictures not uploaded")
    }else if(momentData.momentPictures.length > 20){
      toast.info("Cannot upload more than 20 pictures",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
    else{
      setLoading(true)
      const momentInfo = schedule ? pick(moment,["hall","title","privacy","pictures","published_at","published"]) : pick(moment,["hall","title","privacy","pictures"])
      if(schedule){
        momentInfo.published = false;
      }
      createMoment(momentInfo,{
            onSuccess:(data)=>{
                toast.success("Moment created",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                const momentPictures = [];
                for(let picture of momentData.momentPictures){
                  setMomentData(prev=>{
                    const pictureIndex = prev.momentPicturesPreview.findIndex(image=>image.id === picture.id);
                    const momentPic = prev.momentPicturesPreview[pictureIndex];
                    momentPic.isUploaded = false;
                    momentPic.isLoading = true;
                    momentPic.reUpload = false;
                    momentPic.momentId = data?.data?.id;
                    prev.momentPicturesPreview.splice(pictureIndex,1,momentPic)
                    return {...prev,momentPicturesPreview:prev.momentPicturesPreview,totalUploaded:0}
                  })
                  momentPictures.push(postMomentPicture(data?.data?.id,picture));
                }
                Promise.all(momentPictures).then(results=>{
                  setLoading(false);
                  queryClient.invalidateQueries("hall-moments");
                if(results.every(res=>res.status === 201)){
                  onClearImages();
                  onClearAdditionalImages();
                  setMomentData(prev=>{ return {...prev,totalUploaded:0}})
                  onClose();                
                }
                else{
                  toast.error("Could not upload all images. Please re-upload images that could not be uploaded",{autoClose:2000,position:"top-center",hideProgressBar:true});
                }
                  // onClearImages();
                  // onClearAdditionalImages();
                  // onClose();
                })
            },
            onError:(error)=>{
                toast.error("Failed to create moment",{autoClose:2000,hideProgressBar:true,position:"top-center"})
            }
        })
    }
  }
  return (
    <div className="w-full h-full flex items-center justify-center relative z-50">
        <div className="absolute opacity-[50%] h-full w-full bg-black"></div>
      <div className="w-[40%] px-4 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md pb-5 z-50 relative">
      <CloseIcon
        width="25"
        height="25"
        onClick={()=>{onClearImages();onClearAdditionalImages();setMomentData(prev=>{return {...prev,totalUploaded:0}}); onClose()}}
        className="cursor-pointer absolute top-[-7px] right-[-7px]"
        />
        <div className="py-2 w-full flex">
          <SmallProfile url={hall?.hallInfo.picture}/>
          <div className="flex flex-col w-full">
            <div className="flex h-full items-center w-full justify-between">
              <div className="w-full relative">
                <div className="flex px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark py-2 rounded-md border-2 dark:border-dark-gray w-full h-full">
                  {/* <input type="text" placeholder="What's up?" className="h-full focus:outline-none w-full text-sm text-primaryTextLight dark:text-primaryTextDark placeholder:font-thin placeholder:text-sm" value={!trending ? post.text : trend.text} name="text" onChange={handleInputChange}/> */}
                  <input
                    className={
                      "w-full max-h-[100px] h-full resize-none focus:outline-none rounded-md scrollbar-hide text-base text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark placeholder:text-base"
                    }
                    placeholder="What moment is this?"
                    onChange={handleChange}
                    value={moment.title}
                    name="title"
                  />
                  {
                    theme?.value == "light" ?
                    <EmojiIcon
                    height="20"
                    width="20"
                    onClick={() => setShowEmoji((prev) => !prev)}
                    className="cursor-pointer mt-auto mb-auto"
                    name="title"
                  />:
                  <EmojiIconDark
                    height="20"
                    width="20"
                    onClick={() => setShowEmoji((prev) => !prev)}
                    className="cursor-pointer mt-auto mb-auto"
                    name="title"
                  />
                  }
                </div>
                {showEmoji && (
                  <div className="absolute top-[100%] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{zIndex:200}}>
                    <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
                  </div>
                )}
                <div className="w-full flex items-center justify-end px-2 py-2 absolute z-10">
            </div>
              
              </div>
            </div>
          </div>
        </div>
        {
            !momentData.preview ? <div className="w-full h-60 bg-backgroundLight dark:bg-backgroundDark flex flex-col rounded-md relative">
            {/* <div className="w-full flex items-center justify-end px-2 py-2 absolute z-10">
              <CloseIcon
                width="20"
                height="20"
                onClick={()=>{onClearImages();onClearAdditionalImages();setMomentData(prev=>{return {...prev,totalUploaded:0}}); onClose()}}
                className="cursor-pointer"
              />
            </div> */}
            <div
              className="flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0"
              {...getRootProps({
                className:
                  "dropzone flex flex-col w-full items-center justify-center h-full cursor-pointer absolute top-0",
              })}
            >
              <input className="input-zone" {...getInputProps({})} />
              <p
                className="dropzone-content w-full h-full flex flex-col justify-center items-center cursor-pointer"
                onClick={open}
              >
                {
                  theme?.value == "light" ?
                  <UploadIcon width="70" height="70"/>:
                  <UploadIconDark width="70" height="70"/> 
                }
                <span className="text-xs text-[rgba(54,76,102,0.84)] dark:text-primaryTextDark">
                  Drop a file here or click to upload
                </span>
              </p>
            </div>
          </div> :
          <div className="w-full flex flex-col">
            <div className="flex gap-1">
            
            <div {...additionalRootProps({ className: "dropzone" })} className="">
              <input className="input-zone" {...additionalInputProps({})}/>
              <button className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm"><AddImagesIcon/>Add Media</button>
            </div>
              <button className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm" onClick={()=>setMomentData(prev=>{return {...prev,editMomentPictures:true}})}><EditImagesIcon/>Edit All</button>
              <button className="bg-filter-grad text-primaryTextLight  flex gap-1 items-center px-1 rounded-sm text-sm" onClick={()=>{onClearImages();onClearAdditionalImages()}}><CleanImagesIcon/>Clean All</button>
              {/* <CloseIcon
                width="20"
                height="20"
                onClick={()=>{onClearImages();onClearAdditionalImages(); onClose()}}
                className="cursor-pointer ml-auto"
              /> */}
            </div>
            <div className="min-h-[15rem] flex items-center justify-center">
             <MomentPreview showLightBox={()=>setShowLightBox(true)} postMomentPicture={postMomentPicture}/>
            </div>
          </div>
        }
        <div className="w-full mt-2 flex-col gap-[4px]">
          <LinearProgressBar percent={momentData?.momentPicturesPreview.length !== 0 ? Math.round((momentData?.totalUploaded/momentData?.momentPicturesPreview.length)*100) : 0}/>
          <span className="text-sm text-primaryTextLight dark:text-primaryTextDark opacity-80">{momentData?.totalUploaded || 0} out of {momentData?.momentPicturesPreview.length} uploaded</span>
        </div>
        <div className="flex justify-between mt-6">
            <SelectPrivacy privacy={moment.privacy} setMoment={(value)=>setMoment(prev=>{
        return {...prev,privacy:value}
      })}/>
        <div className="flex gap-3" style={{zIndex:350}}>
          {schedulePost && (
            <Datepicker
              width={32}
              placeholder="Schedule date"
              size={10}
              schedule={true}
              customstyling="rounded-tr-sm rounded-br-sm"
              position={"-30%"}
              bottom={"100%"}
              top={"-600%"}
              setDate={(date)=>setMoment(prev=>{return {...prev,published_at:date}})}
              date={moment.published_at}
              dateInputStyle={"text-sm placeholder:text-xs"}
              minDate={new Date()}
            />
          )}
          <ScheduleSelector
            schedulePost={schedulePost}
            setSchedulePost={setSchedulePost}
            onPost={onPost}
            isLoading={loading}
          />
        </div>
        </div>
      </div>
      <Lightbox
        open={showLightBox}
        close={() => setShowLightBox(false)}
        slides={[...momentData.momentPicturesPreview.map(img=>{return {src:img.image}})]}
      />
    </div>
  );
};
export default CreateMoment;
