import { useEffect, useRef, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon-white.svg";
import ChangeMomentPicturePrivacy from "./ChangeMomentPicturePrivacy";
import MomentImageContext from "./MomentImageContext";

const dominantImageColor = "#777777";

const MomentImage = ({
  image,
  pictureId,
  onViewImage,
  picture,
  momentId,
  onClickImage,
  galleryIndex,
  setSelectedIndex,
}) => {
  const [showContext, setShowContext] = useState(false);
  const [showChangePrivacy, setShowChangePrivacy] = useState(false);
  const [position, setPosition] = useState("");

  const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContext(false);
      setShowChangePrivacy(false);
    }
  };
  const onMouseClick = (event) => {
    const mousePosition = event.pageX;
    if (mousePosition < window.innerWidth / 2) {
      setPosition("left");
    } else {
      setPosition("right");
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });

  return (
    <div
      className="h-40 w-40 rounded-md flex flex-col items-center justify-center relative shrink-0 cursor-pointer"
      style={{
        background:
          "linear-gradient(101.79deg, #FFD7D7 -19.6%, #B8CBE1 123.64%)",
      }}
    >
      <div
        className="bg-black opacity-[25%] h-full w-full absolute z-10 rounded-md"
        onClick={() => {
          if (!showContext && !showChangePrivacy) {
            setSelectedIndex(galleryIndex);
            onClickImage();
          }
        }}
      ></div>
      <span
        className="rounded-full w-5 h-5 flex items-center justify-center absolute top-1 right-1 z-20 cursor-pointer"
        style={{ background: "rgba(0, 0, 0, 0.25)" }}
        onClick={(event) => {
          onMouseClick(event);
          setShowContext(true);
        }}
      >
        <MenuIcon fill="white" height="15" width="15" />
      </span>
      <div
        className="h-full w-full"
      >
        <ProgressiveImage src={image} placeholder="">
          {(src, loading) => {
            return loading ? (
              <div
                className="h-full w-full loader rounded-md"
                style={{ backgroundColor: dominantImageColor }}
              />
            ) : (
              <img
                src={src}
                alt="Moment"
                height="100%"
                width="100%"
                className="h-full w-full object-cover rounded-md"
              />
            );
          }}
        </ProgressiveImage>
      </div>
      {showContext && (
        <div
          className="absolute top-[10px] right-0 z-30"
          style={position === "left" ? { left: "85%" } : { right: "0" }}
        >
          <MomentImageContext
            innerRef={ref}
            pictureId={pictureId}
            imageUrl={image}
            picture={picture}
            setShowContext={setShowContext}
            onChangePrivacy={(event) => {
              onMouseClick(event);
              setShowChangePrivacy(true);
              setShowContext(false);
            }}
          />
        </div>
      )}
      {showChangePrivacy && (
        <div
          className={`absolute top-[10px] z-30`}
          style={position === "left" ? { left: "85%" } : { right: "0" }}
        >
          <ChangeMomentPicturePrivacy
            innerRef={ref}
            privacy={picture.privacy}
            setShowChangePrivacy={setShowChangePrivacy}
            pictureId={pictureId}
            momentId={momentId}
          />
        </div>
      )}
    </div>
  );
};

{
  /* <ProgressiveImage src={pictures[0].image} placeholder="">
            {(src, loading) => {
              return loading ? (
                <div
                  className="h-96 w-full loader rounded-md"
                  style={{ backgroundColor: dominantImageColor }}
                />
              ) : (
                <img
                  src={src}
                  alt="Post Image"
                  className="rounded-md w-full max-h-96 object-cover cursor-pointer"
                  onClick={() => {
                    setImages(pictures);
                    setViewImages(true);
                  }}
                  width="100%"
                  height="100%"
                />
              );
            }}
          </ProgressiveImage> */
}

export default MomentImage;
