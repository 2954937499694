import { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { momentMenuContext } from "../../contexts/momentMenuContext"
import { useEditMomentPictureTitle } from "../../hooks/momentsHooks";
import {ReactComponent as EmojiIcon} from "../../assets/icons/emoji-icon.svg";
import {ReactComponent as EmojiIconDark} from "../../assets/icons/emoji-icon-dark.svg";
import { useRef } from "react";
import EmojiPicker from "../EmojiPicker";
import { themeContext } from "../../contexts/themeContext";

const EditMomentPictureTitle = () => {
    const {theme} = useContext(themeContext);
    const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
    const {mutate:editMomentPictureTitle,isLoading} = useEditMomentPictureTitle();
    const [description,setDescription] = useState(momentMenu.momentPicture?.description);
    const [showEmoji, setShowEmoji] = useState(false);
    const ref= useRef()
    const onEmojiClick = (emoji) => {
        setDescription(description + emoji.char)
    };
    const handleTextChange = (event) => {
        setDescription(event.target.value);
    }
    const queryClient = useQueryClient()

    const onEditMomentPictureTitle = () => {
        if(description.length === 0){
            toast.error("New moment title not set",{autoClose:2000,hideProgressBar:true,position:"top-center"})
        }else{
            editMomentPictureTitle({id:momentMenu.momentPicture.id,values:{description:description}},{
                onSuccess:(data)=>{
                    queryClient.invalidateQueries("hall-moments");
                    queryClient.invalidateQueries("single-hall-moment",momentMenu.momentPicture?.moment)
                    toast.success("Moment picture title updated",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                    setMomentMenu(prev=> { return {...prev,editMomentPictureTitle:false,momentPicture:{}}})
                },
                onError:(error)=>{
                    toast.error("Could not update moment title",{autoClose:2000,hideProgressBar:true,position:"top-center"});
                }
            })
        }
    }
    const handleMouseDown = (event) => {
        if (!ref.current?.contains(event.target) && showEmoji === true) {
            setShowEmoji(false);
        } 
    };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:"9999"}}>
            <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 flex flex-col gap-5 min-w-[20rem]">
                <p className="text-primaryTextLight dark:text-primaryTextDark font-medium">Edit Moment Picture Title</p>
                <div className="w-full h-10 relative">
                        <div className="px-3 rounded-md flex border dark:border-dark-gray justify-between h-full" id="edit-picture-title">
                        <input className="w-full h-8 focus:outline-none px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" value={description} onChange={handleTextChange}/>
                        {
                            theme?.value == "light" ?
                            <EmojiIcon height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => setShowEmoji((prev) => !prev)}/>
:                        <EmojiIconDark height="20" width="20" className="cursor-pointer mt-auto mb-auto" name="text" onClick={() => setShowEmoji((prev) => !prev)}/>

                        }
                        </div>
                        {showEmoji && (
                        <div className="absolute left-[1%] top-[100%] w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{zIndex:400}} ref={ref}>
                            <EmojiPicker  onEmojiSelect={onEmojiClick} width="100%" height={"15rem"}/>
                        </div>
                        )}
                    </div>
                <div className="flex ml-auto gap-4 mt-auto">
                    <button className="border border-[#F63E49] text-[#F63E49] py-1 px-2 rounded-md" onClick={()=>setMomentMenu(prev=>{return {...prev,editMomentPictureTitle:false,momentPicture:{}}})}>Cancel</button>
                    <button className="bg-[#F63E49] text-white py-1 px-4 rounded-md" onClick={onEditMomentPictureTitle}>{!isLoading ? "Edit" : <BeatLoader color="white" size={10}/>}</button>
                </div>
            </div>            
        </div>
    )
}

export default EditMomentPictureTitle;