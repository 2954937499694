import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";

const MomentsHeader = ({onCreateMoment,onFilter,showFilteredMoments,momentDetail}) => {
    return (
        <div className="flex justify-between mb-5">
            <span className="text-lg text-primaryTextLight dark:text-primaryTextDark font-semibold">Hall Moments</span>
            {
              !momentDetail &&
            <div className="flex gap-5">
            <div className="flex h-full items-center w-28 py-1 bg-filter-grad px-2 gap-5 rounded-md cursor-pointer" onClick={onFilter}>
            <FilterIcon />
            <span className="text-primaryTextLight">{!showFilteredMoments?"Filter":"Filtered"}</span>
          </div>
          <button className="px-4 py-1 flex items-center bg-linear-grad text-white rounded-sm" onClick={onCreateMoment}>
            New Moments
          </button>
            </div>
          }
        </div>
    )
}

export default MomentsHeader;