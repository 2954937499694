import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  blockHallMember,
  getBlockedHallMembers,
  getHallAccounts,
  getHallMembers,
  getMemberBlacklistReasons,
  getNewHallMembers,
  getReportMemberReasons,
  reportHallMember,
  searchAccounts,
} from "../api/members";
import { toast } from "react-toastify";

const useGetHallMembers = (hall_id, page, pageSize, onSuccess) => {
  return useQuery(
    ["hall-members", page, pageSize],
    () => getHallMembers(hall_id,(page-1)*pageSize,pageSize),
    {
      onSuccess,
      keepPreviousData: true,
      staleTime:5000,
    }
  );
};

const useGetHallAccounts = (hall_id) => {
  return useInfiniteQuery(["hall-accounts",hall_id],getHallAccounts,{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:(data)=>console.log(data),
    onError:(error)=>{
      toast.error("Failed to fetch hall members",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  });
};

const useGetNewHallMembers = () => {
  return useQuery("new-hall-members", getNewHallMembers, {
    // onSuccess: (data) => console.log(data),
    onError:(error)=>{
      toast.error("Failed to fetch new hall members",{autoClose:2000,hideProgressBar:true,position:"top-center"})
    }
  });
};

const useGetBlockedHallMembers = (id,page,pageSize,onSuccess) => {
  return useQuery(
    ["blocked-hall-members", id,page,pageSize],
    () => getBlockedHallMembers(id,page,pageSize),
    { onSuccess }
  );
};

const useBlockHallMember = () => {
  return useMutation(blockHallMember, { mutationKey: "block-hall-member" });
};

const useReportMember = () => {
  return useMutation(reportHallMember, { mutationKey: "report-hall-member" });
};

const useGetBlacklistReasons = () => {
  return useQuery("hall-blacklist-reasons", getMemberBlacklistReasons);
};

const useGetReportMemberReasons = () => {
    return useQuery("report-member-reasons",getReportMemberReasons,{
        // onSuccess:data=>console.log(data),
        // onError:error=>console.log(error)
    });
}

const useSearchAccounts = (hall_id,search,setLoading) => {
  return useInfiniteQuery(["searched-hall-accounts",hall_id],(query)=>searchAccounts(query,search),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess:(data)=>{setLoading(false)},
    // onError:(error)=>console.log(error),
    enabled:false,
  });}

export {
  useGetHallMembers,
  useGetHallAccounts,
  useGetNewHallMembers,
  useGetBlockedHallMembers,
  useBlockHallMember,
  useReportMember,
  useGetBlacklistReasons,
  useGetReportMemberReasons,
  useSearchAccounts
};
