import { useContext, useEffect, useRef, useState } from "react";
import  {ReactComponent as MenuIcon} from "../../assets/icons/menu-icon.svg";
import  {ReactComponent as MenuIconDark} from "../../assets/icons/menu-icon-dark.svg";
import LeaderContextMenu from "./LeaderContextMenu";
import defaultProfile from "../../assets/images/default-profile.png";
import ProgressiveImage from "react-progressive-graceful-image";
import { themeContext } from "../../contexts/themeContext";
import { ReactComponent as HallConnectIcon } from "../../assets/icons/hall-connect-icon.svg";
import { ReactComponent as HallConnectIconDark } from "../../assets/icons/hall-connect-icon-dark.svg";
import { leadershipContext } from "../../contexts/leadershipContext";
const dominantImageColor = "#777777";

const ExecutiveCard = ({picture,name,position,leader,leaderCategory,linked_account,showMenu,start_date,end_date,showTenure,isCurrentExecutive}) => {
  const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
  const {theme} = useContext(themeContext)
  const [showContextMenu,setShowContextMenu] = useState(false);
  const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });
  return (
    <div className="w-80 border dark:border-dark-gray h-24 py-2 rounded-xl flex px-2 relative">
      <div className="w-[25%] h-full rounded-md relative">
      <ProgressiveImage src={picture || defaultProfile} placeholder="">
      {(src, loading) => {
              return loading ? (
                <div
                  className="h-full w-full loader rounded-md"
                  style={{ backgroundColor: dominantImageColor }}
                />
              ) : (
<img
          src={(src) || defaultProfile}
          alt="Profile"
          width="100%"
          height="100%"
          className="h-full w-full object-cover rounded-lg"
        />              );
            }}
        
        </ProgressiveImage>
        {
          linked_account && 
          <div className="absolute flex items-center justify-center bottom-[2px] right-[2px] w-5 h-5 rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" onClick={()=>setLeadershipStates(prev=>{return {...prev,showLinkedAccount:true,linkedAccount:{...linked_account}}})}>
            <HallConnectIconDark width={25} height={25}/>    
          </div>
        }
      </div>
      <div className="flex flex-col text-sm text-primaryTextLight dark:text-primaryTextDark justify-center ml-4 h-full">
        <span className="font-semibold">{name}</span>
        <div className="flex gap-1 flex-wrap">
        <span className="text-xs">{position}</span>
        </div>
        {showTenure &&
        <span className="text-xs mt-2">{start_date?.slice(0,4)} / {end_date?.slice(0,4) || "-"}</span>
        }
      </div>
      {
        showMenu && 
        <div className="h-full ml-auto flex items-center">
{theme?.value == "light" ?
        <MenuIcon width="15" height="15" onClick={()=>setShowContextMenu(true)} className="cursor-pointer"/>
:        <MenuIconDark width="15" height="15" onClick={()=>setShowContextMenu(true)} className="cursor-pointer"/>
}
      </div>
      }
      {
                showContextMenu &&
            <div className="absolute right-[0px] top-[10px] z-10" ref={ref}>
                <LeaderContextMenu leader={leader} leaderCategory={leaderCategory} closeContextMenu={()=>setShowContextMenu(false)} showLinkLinkLounge={leaderCategory==="Executive"} isCurrentExecutive={isCurrentExecutive}/>
            </div>
            }
    </div>
  );
};

{/* <ProgressiveImage src={image} placeholder="">
                {(src, loading) => {
              return loading ? (
                <div
                  className="h-full w-full loader rounded-md"
                  style={{ backgroundColor: dominantImageColor }}
                />
              ) : (
                <img src={src} alt="Moment" height="100%" width="100%" className="h-full w-full object-cover rounded-md"/>
              );
            }}
                </ProgressiveImage> */}


export default ExecutiveCard;
