import React, { useEffect, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import PageContainer from "../../components/PageContainer";
import { Formik, ErrorMessage } from "formik";
import { ForgotPasswordSchema } from "../../schema/authSchema";
import { useNavigate } from "react-router-dom";
import { useForgotPassword } from "../../hooks/authHooks";
import pick from "lodash.pick";
import { toast } from "react-toastify";
import ConfirmOTPPage from "./ConfirmOTP.page";


const ForgotPasswordPage = () => {
    const [resetProvided,setResetProvided] = useState(false)
    const nav = useNavigate();
    const {mutate:forgotPassword,isLoading} = useForgotPassword();
    const [email,setEmail] = useState("");
    const [username,setUsername] = useState("");
    const onSubmit = (values) => {
        if(values.email_or_username.includes('@')){
            values.email = values.email_or_username;
            values = pick(values,['email']);
            values.username = "";
            setEmail(values.email);
        }else{
            values.username = values.email_or_username;
            values = pick(values,['username']);
            values.email = "";  
            setUsername(values.username);
        };
        forgotPassword(values,{
            onSuccess:(data)=>{
                setResetProvided(true);
            },
            onError:(error)=>{
                if(error.response.data.errorCode === 105 || error.response.data.errorCode === 106){
                  toast.error('Account not found for email/username',{position:"top-center",hideProgressBar:true,autoClose:2000})
                }else if(error.response.data.errorCode === 107){
                  toast.error('Account not found',{position:"top-center",hideProgressBar:true,autoClose:2000})
                }else{
                  toast.error('Failed to send email',{position:"top-center",hideProgressBar:true,autoClose:2000})
                }
            }
        })
    }

    useEffect(()=>{
      document.title = "LinkLounge | Forgot Password"
    },[])

    if(resetProvided){
      return <ConfirmOTPPage email={email} username={username}/>
    }

    return (
    <PageContainer customstyle="flex flex-col h-full">
      <main className="flex h-19/20 justify-between">
      <Formik
          initialValues={{email_or_username:''}}
          validationSchema={ForgotPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange,handleSubmit,errors,touched,handleBlur }) => (
                <section className="flex flex-col w-full items-center justify-center sm:w-3/5">
                <form className="w-80 p-4 flex flex-col gap-5" onSubmit={handleSubmit}>
                    <h2 className="mb-2 font-bold text-2xl bg-linear-grad bg-clip-text text-transparent">Forgotten Password?</h2>
                    <p className="text-primaryTextLight dark:text-primaryTextDark mb-5 text-sm">Enter your email you registered with</p>
                    <div className="mb-3">
                        <Input
                        label="Username/ Email"
                        placeholder="Username"
                        name="email_or_username"
                        type="text"
                        customstyle="flex flex-col w-full gap-2 text-primaryTextLight dark:text-primaryTextDark text-sm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                        {
                        errors.email_or_username && touched.email_or_username ?
                        <ErrorMessage name="email_or_username" component="span" className="text-xs text-[#F63E49] leading-3" /> : null
                        }
                    </div>
                    <Button text="RESET" type="submit" customstyle="w-full h-8 drop-shadow-md mb-5" loadingText="SUBMITTING" isLoading={isLoading}/>
                    <p className="text-sm text-primaryTextLight dark:text-primaryTextDark ml-auto mr-auto">Remember your password? <span className="bg-linear-grad bg-clip-text text-transparent hover:cursor-pointer" onClick={()=>nav('/')}>Sign In</span></p>
                </form>
                </section>
          )}
        </Formik>
        <section className="w-2/5 h-full hidden sm:block">
          <BackgroundImage customstyle="h-full w-full" />
        </section>
      </main>
      <Footer/>
    </PageContainer>
  );
};

export default ForgotPasswordPage;
