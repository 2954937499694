import React, { useContext, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";
import { hallContext } from "../../contexts/hallContext";
import { useGetBlockedHallMembers } from "../../hooks/membersHooks";
import BlockedRowMember from "./BlockedRowMember";
import MemberListFooter from "./MemberListFooter";
import RowMember from "./RowMember";
import { ClipLoader } from "react-spinners";

const BlockedMembersList = ({setShowBlockedMembers}) => {
  const {hall} = useContext(hallContext);
  const [pageSize,setPageSize] = useState(10);
  const [page,setPage] = useState(1);
  const [count,setCount] = useState(); 
  const onFetchBlockedHallMembers = (data) => {
    setCount(data?.data.count)
  }
  const {data:blockedlist,isLoading} = useGetBlockedHallMembers(hall?.hallInfo?.id,page,pageSize,onFetchBlockedHallMembers);
  return (
    <div className="w-full h-full">
      <div className="flex justify-between w-full">
        <span className="text-primaryTextLight dark:text-primaryTextDark text-xl font-semibold">BlockList</span>
        <div className="flex gap-4">
        {/* <div className="flex w-52 bg-cardBackgroundLight dark:bg-cardBackgroundDark items-center gap-2 rounded-md h-8 px-2 mr-4 z-10 border">
          <SearchIcon width="15" height="15" />
          <input
            type={"search"}
            placeholder="Search for Members"
            className="text-sm font-light h-full focus:outline-none w-full text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"
          />
        </div> */}
        <button type="button" className="w-40 h-8 border text-white flex bg-linear-grad items-center rounded-md px-3 justify-center cursor-pointer" onClick={()=>setShowBlockedMembers(false)}>
            Members
        </button>
      </div>
      </div>
      {isLoading ? <div className="w-full h-36 flex items-center mt-10 justify-center">
                      <ClipLoader size={80} color="#F63E49"/>
                  </div>:
        blockedlist?.data.results.length !== 0 ?
        <div className="h-full">
        <div>
        <div className="flex text-sm mt-6 mb-2 text-[rgba(54, 76, 102, 0.56)] dark:text-primaryTextDark">
            <span className="w-[25%]">PROFILE</span>
            <span className="w-[20%]">DATE</span>
            <span>REASON</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        {blockedlist?.data?.results.map(member=>{
          return (
            <React.Fragment key={member.id}>
            <BlockedRowMember member={member}/>
            <hr className="dark:border-dark-gray"/>
            </React.Fragment>
          )
        })}
      </div>
      <MemberListFooter page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} count={blockedlist?.data.count}/></div> :
      <div className="w-full flex items-center justify-center mt-10">
        <NoDataIcon height="400" width="400"/>
       </div> 
      }
    </div>
  );
};

export default BlockedMembersList;
