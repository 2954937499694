import { useContext } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import {
  createTrend,
  deleteHallTrend,
  filterTrends,
  getReportTrendReason,
  getTrendLeaderBoard,
  getTrends,
  getTrendStatistics,
  getTrendTags,
  getTrendThemes,
  reportTrend,
  searchTrendsByText,
} from "../api/trends";
import {
  deletePostContext,
  postTrendMenuContext,
} from "../contexts/postTrendMenuContext";
import { toast } from "react-toastify";
import { trendBackgroundContext } from "../contexts/backgroundContext";

const useGetTrends = (hall_id) => {
  return useInfiniteQuery(["hall-trends",hall_id],(params)=> getTrends(params),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    // onSuccess:(data)=>console.log(data),
  });
};

const useSearchTrends = (search,hall,onSuccess) => {
  return useInfiniteQuery(["search-hall-trends",hall],({pageParam})=>searchTrendsByText(pageParam || `/trend/v1/trends/?search=${search}&hall=${hall}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useFilterTrends = (filters,hall,onSuccess) => {
  const filter = [];
  for (let key in filters) {
    if(filters[key] !== ""){
      filter.push(`${key}=${filters[key]}`);
    }
  }
  return useInfiniteQuery(["filter-hall-trends",hall],({pageParam})=>filterTrends(pageParam || `/trend/v1/trends/?${filter.join("&")}&hall=${hall}`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
    enabled:false,
  });
}

const useGetTrendThemes = () => {
  const {setTrendBackgrounds} = useContext(trendBackgroundContext);
  return useQuery("trend-themes", getTrendThemes, { 
    onSuccess: data=>{
      setTrendBackgrounds(data?.data.results);
    } ,
    retry:true
  });
};

const useGetTrendTags = (onSuccess) => {
  return useInfiniteQuery(["get-trend-tags"],({pageParam})=>getTrendTags(pageParam || `trend/v1/trend-tags`),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // getPreviousPageParam: (lastPage,pages) => pages[pages.length - 1].data.previous,
    onSuccess,
    // onError:(error)=>console.log(error),
  });};

const useCreateTrend = () => {
  return useMutation(
    ({ trendPost, contentType }) => createTrend(trendPost, contentType),
    {
      mutationKey: "create-trend",
    }
  );
};

const useGetTrendLeaderBoard = () => {
  return useInfiniteQuery("trend-leaderboard",(params)=> getTrendLeaderBoard(params),{
    getNextPageParam: (lastPage) => lastPage.data.next,
    // onSuccess:data=>{
    //   console.log(data);
    // }
  });
};

const useGetTrendStatistics = () => {
  return useQuery("trend-statistics", getTrendStatistics, 
  // {
  //   // onSuccess: (data) => console.log(data),
  // }
  );
};

const useDeleteTrend = () => {
  const { setMenu, menu } = useContext(postTrendMenuContext);
  const { mutate,isLoading:isDeletingTrend } = useMutation(deleteHallTrend, {
    mutationKey: "delete-trend",
  });
  const queryClient = useQueryClient();

  const onDeleteTrend = () => {
    mutate(menu.trendId, {
      onSuccess: () => {
        setMenu((prev) => {
          return { ...prev, trend: false, postId: "" };
        });
        toast.success("Trend deleted", {
          autoClose: 2000,
          hideProgressBar: true,
          position: "top-center",
        });
        queryClient.invalidateQueries("hall-trends");
      },
      onError: (error) => {
        setMenu((prev) => {
          return { ...prev, trend: false, trendId: "" };
        });
        toast.error("Could not delete post", {
          autoClose: 2000,
          hideProgressBar: true,
          position: "top-center",
        });
      },
    });
  };
  return { onDeleteTrend,isDeletingTrend };
};

const useReportTrend = () => {
  return useMutation(reportTrend, { mutationKey: "report-trend" });
};

const useGetReportTrendReason = () => {
  return useQuery("report-trend-reason", getReportTrendReason);
};

export {
  useGetTrends,
  useSearchTrends,
  useFilterTrends,
  useGetTrendThemes,
  useGetTrendTags,
  useCreateTrend,
  useGetTrendLeaderBoard,
  useGetTrendStatistics,
  useDeleteTrend,
  useReportTrend,
  useGetReportTrendReason,
};
