import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove-icon.svg";
import Datepicker from "../hallpage/Datepicker";
import TimePicker from "rc-time-picker";
import { useState } from "react";
import { useContext } from "react";
import { createPostContext } from "../../contexts/createPostContext";
import moment from "moment";
import { generateRandomId } from "../../helpers/generateRandomId";
import { themeContext } from "../../contexts/themeContext";
import "../../styles/time-picker.css"

const CreatePoll = ({ onClose }) => {
  const {theme} = useContext(themeContext);
  const {post,setPost} = useContext(createPostContext);
  const minDate = new Date();
  const maxDate = new Date();
  maxDate.setDate(new Date().getDate() + 7)

  

  const onRemoveOption = (index) => {
    const data = [...post.options_text];
    data.splice(index,1);
    setPost(prevPost=>{
      return {...prevPost,options_text:data}
    })
  };

  const handleDateChange = (date) => {
    setPost(prevPost=>{
      return {...prevPost,end_date:date}
    })
  };

  const handleTimeChange = (time) => {
    setPost(prevPost=>{
      return {...prevPost,end_time:time ? time.format('HH:mm'):""}
    })
  };


  const handleChange = (event,index) => {
    setPost(prevPost=>{
      const option = post.options_text[index];
      option.value = event.target.value;
      prevPost.options_text.splice(index,1,option);
      return prevPost;
    })
  };
    
  const addOption = () => {
    if(post.options_text.length < 4){
    const option = generateRandomId();
    setPost(prevPost=>{
      return {...prevPost,options_text:[...prevPost.options_text,{name:option,value:""}]}
    })
  }
  };

  return (
    <section className="w-full pt-2">
      <div className="flex justify-between mb-1">
        <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark">Options</span>
        <div className="flex gap-2 items-center pr-2">
          {
            post.options_text.length < 4 && 
          <button className="text-white bg-filter-grad w-28 rounded-sm">
            +{" "}
            <span className="text-primaryTextLight" onClick={addOption}>
              Add Option
            </span>
          </button>
          }
          <CloseIcon
            height="20"
            width="20"
            onClick={onClose}
            className="cursor-pointer"
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {post.options_text?.map((option,index)=>{
          if(index === 0 || index === 1){
            return (
              <input
              placeholder={`Type Option ` + (index+1)}
              className="border border-1 dark:border-dark-gray focus:outline-none rounded-md h-12 px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
              name={option.name}
              key={option.name}
              onChange={(event)=>handleChange(event,index)}
            />
            )
          }else{
            return (
              <div
              className="flex border border-1 dark:border-dark-gray rounded-md h-12 pl-2 pr-4 items-center"
              key={option.name}
            >
              <input
                className="focus:outline-none w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
                placeholder={"Type Option " + (index + 1)}
                name={option.name}
                key={option.name}
                onChange={(event)=>handleChange(event,index)}
              />
              <RemoveIcon
                height="12"
                width="12"
                onClick={()=>onRemoveOption(index)}
                className="cursor-pointer"
              />
            </div>
            )
          }
        })}
      </div>
      <span className="mt-4 inline-block mb-2 font-semibold text-primaryTextLight dark:text-primaryTextDark">
        Poll ends on
      </span>
      <div className="flex justify-between gap-4 z-[100]">
        <Datepicker
          width={["50%"]}
          height={12}
          customstyling=" rounded-md"
          placeholder="Date"
          size={20}
          fill="rgba(54, 76, 102, 0.56)"
          position={"-10%"}
          date={post.end_date}
          setDate={handleDateChange}
          minDate={minDate}
          maxDate={maxDate}
          dateInputStyle={"text-base"}
          zIndex={50}
        />
        <div className="bg-cardBackgroundLight dark:bg-cardBackgroundDark">
        <TimePicker
          placeholder="Time"
          use12Hours
          showSecond={false}
          onChange={handleTimeChange}
          className={theme?.value === "light" ? "time-picker" : "time-picker-dark"}
          popupClassName={theme?.value === "light"? "pick-time":"pick-time-dark"}
          // value={post.end_time}
          allowEmpty={true}
          // className="!bg-cardBackgroundDark dark:!bg-cardBackgroundDark text-primaryTextLight "
          // popupClassName="!bg-cardBackgroundLight dark:!bg-cardBackgroundDark !text-primaryTextLight "
        />
        </div>
      </div>
    </section>
  );
};

export default CreatePoll;
