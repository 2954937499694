const TrendingCardWithTheme = ({tags,text,theme,likes,comments}) => {
  let bgColor;
  const colors = [];
  for(let color of theme.bg_colors){
    colors.push(color.color)
  }
  bgColor = colors.length === 1 ? colors[0] : `linear-gradient(45deg, ${colors.join(",")})`
  
  return (
    // Content with background
    <div className="w-52 h-52 rounded-md mr-4 flex flex-col px-4 pb-2 pt-4 shrink-0" style={{background:bgColor,color:theme?.text_color}}>
      <p className="mt-auto text-center text-xl flex line-clamp-5">
        {text}
      </p>
      <div className="flex flex-col gap-2 mt-auto">
        <div className="text-sm flex gap-2 text-[#0085FF]">
          {tags.length === 0 ? <span>General</span> :tags.map(tag=>{
            return (
              <span key={tag.id}>{tag.short_hand}</span>
            )
          })}
        </div>
        <div className="text-sm flex justify-between">
            <span>Likes: <span>{likes}</span></span>
            <span>Comments: <span>{comments}</span></span>
        </div>
      </div>
    </div>
  );
};

export default TrendingCardWithTheme;
