import { useContext, useEffect } from "react";
import { useState } from "react";
import { ReactComponent as DropdownIcon } from "../../assets/icons/dropdown-icon.svg";
import { ReactComponent as SelectHallIcon } from "../../assets/icons/select-hall-icon.svg";
import { ReactComponent as SelectPrivateIcon } from "../../assets/icons/select-private-icon.svg";
import { ReactComponent as SelectPublicIcon } from "../../assets/icons/select-public-icon.svg";
import { ReactComponent as SelectPublicIconDark} from "../../assets/icons/privacy-global-icon-dark.svg"
import { ReactComponent as SelectPrivateIconDark } from "../../assets/icons/select-private-icon-dark.svg";
import { ReactComponent as SelectHallIconDarkTheme } from "../../assets/icons/select-hall-icon-dark.svg";
import { themeContext } from "../../contexts/themeContext";

const SelectPrivacy = ({setMoment,privacy,height}) => {
  const {theme} = useContext(themeContext)
  const [rotate, setRotate] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  const [viewers, setViewers] = useState({
    hall: privacy === "Hall" ? true : false,
    public: privacy === "Public" ? true : false,
    private: privacy === "Private" ? true : false
  });
  const onDropDown = (event) => {
    setRotate((prev) => !prev);
    setShowHidden((prev) => !prev);
  };
  const onSelect = (target) => {
    if (target === "hall") {
      setMoment("Hall")
      setViewers({ public: false, private: false, hall: true });
    } else if (target === "public") {
      setMoment("Public")
      setViewers({ hall: false, private: false, public: true });
    } else if (target === "private") {
      setMoment("Private")
      setViewers({ hall: false, public: false, private: true });
    }
    setShowHidden((prev) => !prev);
    setRotate((prev) => !prev);
  };
  useEffect(()=>{
    setViewers({
      hall: privacy === "Hall" ? true : false,
      public: privacy === "Public" ? true : false,
      private: privacy === "Private" ? true : false
    })
  },[privacy])
  return (
    <div className="w-24 relative rounded-sm">
      <div className="h-7 w-full bg-filter-grad text-primaryTextLight dark:text-primaryTextDark text-sm flex items-center justify-between px-1 rounded-sm">
        <div className="w-2/3 flex">
          {viewers.public && (
            <div className="flex items-center gap-2 dark:text-primaryTextLight">
              <SelectPublicIcon height="15" width="15" />
              <span>Public</span>
            </div>
          )}
          {viewers.hall && (
            <div className="flex items-center gap-2 dark:text-primaryTextLight">
              <SelectHallIcon height="15" width="15" />
              <span>Hall</span>
            </div>
          )}
          {viewers.private && (
            <div className="flex items-center gap-2 dark:text-primaryTextLight">
              <SelectPrivateIcon height="15" width="15" />
              <span>Private</span>
            </div>
          )}
        </div>
        <div className="flex items-center gap-2">
          <DropdownIcon
            width="12"
            height="12"
            onClick={onDropDown}
            className={
              "cursor-pointer " +
              (rotate ? "rotate-180 delay-200" : "delay-200")
            }
          />
        </div>
      </div>
      {showHidden && (
        <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md mt-[1px] absolute top-7 w-full z-10 text-sm">
          <div className="flex items-center gap-2 py-1 pl-1 cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundDark" onClick={()=>onSelect("public")}>
            {theme?.value == "light" ?
              <SelectPublicIcon height="15" width="15" />
            : <SelectPublicIconDark height="15" width="15" />
            }
            <span className="dark:text-primaryTextDark text-primaryTextLight">Public</span>
          </div>
          <div className="flex items-center gap-2 py-1 pl-1 cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundDark" onClick={()=>onSelect("hall")}>
            {
              theme?.value == "light" ?
              <SelectHallIcon height="15" width="15" />
              :<SelectHallIconDarkTheme height="15" width="15" />

            }
            <span className="dark:text-primaryTextDark text-primaryTextLight">Hall</span>
          </div>
          <div className="flex items-center gap-2 py-1 pl-1 cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundDark" onClick={()=>onSelect("private")}>
{theme?.value === "light" ?             <SelectPrivateIcon height="15" width="15" />
:            <SelectPrivateIconDark height="15" width="15" />
}
            <span className="dark:text-primaryTextDark text-primaryTextLight">Private</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectPrivacy;
