import React, { useContext, useState } from "react";
import BackgroundImage from "../../components/BackgroundImage";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Input from "../../components/Input";
import PageContainer from "../../components/PageContainer";
import PasswordInput from "../../components/PasswordInput";
import Toggle from "../../components/Toggle";
import { LoginSchema } from "../../schema/authSchema";
import { Formik, ErrorMessage } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/authHooks";
import pick from "lodash.pick";
import { userContext } from "../../contexts/userContext";
import { toast } from "react-toastify";
import { hallActions, userActions } from "../../reducers/actions";
import {
  getAccessToken,
  storeAccessToken,
  storeAccessTokenWithExpiry,
  storeLocalStorageRefreshToken,
  storeRefreshTokeWithExpiry,
  storeRefreshToken,
  storeSessionStorageRefreshToken,
} from "../../helpers/manageToken";
import {
  storeLocalStorageUser,
  storeSessionStorageUser,
} from "../../helpers/manageUser";
import useIsAuthenticated from "../../hooks/isAuthenticated";
import { useEffect } from "react";
import { useGetHallInfo } from "../../hooks/hallHooks";
import { getHallInfo } from "../../api/hall";
import { storeSessionStorageHall } from "../../helpers/manageHall";
import { hallContext } from "../../contexts/hallContext";
import { getPostThemes } from "../../api/posts";
import { postBackgroundContext, trendBackgroundContext } from "../../contexts/backgroundContext";
import { getTrendThemes } from "../../api/trends";

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { setUser } = useContext(userContext);
  const { setHall } = useContext(hallContext);
  const {setPostBackgrounds} = useContext(postBackgroundContext);
  const {setTrendBackgrounds} = useContext(trendBackgroundContext);
  const [rememberMe, setRememberMe] = useState(false);
  const [invalid,setInvalid] = useState({invalid:false,message:""});
  const onToggle = () => {
    setRememberMe((prev) => !prev);
  };

  const nav = useNavigate();
  const { mutate: login, isLoading } = useLogin();
  const initialValues = {
    email_or_username: "",
    password: "",
  };

  const onSubmit = (values) => {
    if (values.email_or_username.includes("@")) {
      values.email = values.email_or_username;
      values = pick(values, ["email", "password"]);
    } else {
      values.username = values.email_or_username;
      values = pick(values, ["username", "password"]);
    }
    login(values, {
      onSuccess: async (data) => {
        if(!data.data.user.is_hall_admin && !data.data.user.is_superuser){
          setInvalid({invalid:true,message:"Invalid Credentials"});
        }else{
          const payload = {
            user: data?.data.user,
          };
          if (rememberMe) {
            storeAccessTokenWithExpiry({
              token: data?.data.access_token,
              expiry: data?.data.access_token_expiration,
            });
            storeRefreshTokeWithExpiry({
              token: data?.data.refresh_token,
              expiry: data?.data.refresh_token_expiration,
            })
          } else {
            storeAccessToken({
              token: data?.data.access_token,
              expiry: data?.data.access_token_expiration
            })
            storeRefreshToken({
              token: data?.data.refresh_token,
              expiry: data?.data.refresh_token_expiration,
            });
          }
          nav("/hallpage");
        }
      },
      onError: (error) => {
        if(error.response.data.errorCode === 101 || error.response.data.errorCode === 105 || error.response.data.errorCode === 106 || error.response.data.errorCode === 107)
          setInvalid({invalid:true,message:"Invalid credentials"});
        else if (error.response.data.errorCode === 103)
          setInvalid({invalid:true,message:"Account not activated"});
        else
          toast.error("Failed to login", {
            position: "top-center",
            hideProgressBar: true,
            autoClose: 2000,
          });
      },
    });
  };

  useEffect(()=>{
    document.title = "LinkLounge | Login"
  },[])

  if (isAuthenticated) {
    return <Navigate to="/hallpage" />;
  }

  return (
    <PageContainer customstyle="flex flex-col h-full">
      <main className="flex h-19/20 justify-between">
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={onSubmit}
        >
          {({ handleChange, handleSubmit, errors, touched, handleBlur }) => (
            <section className="flex flex-col w-full items-center justify-center md:w-3/5">
              <form
                className="w-80 p-4 flex flex-col gap-5"
                onSubmit={handleSubmit}
              >
                <h2 className="mb-2 font-bold text-2xl bg-linear-grad bg-clip-text text-transparent">
                  Welcome
                </h2>
                {invalid.invalid && <span className="text-[13px] text-[#F63E49]">{invalid.message}</span>}
                <div>
                  <Input
                    label="Username/ Email"
                    placeholder="Username"
                    name="email_or_username"
                    type="text"
                    customstyle="flex flex-col w-full gap-2 text-primaryTextLight dark:text-primaryTextDark text-sm"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email_or_username && touched.email_or_username ? (
                    <ErrorMessage
                      name="email_or_username"
                      component="span"
                      className="text-xs text-[#F63E49] leading-3"
                    />
                  ) : null}
                </div>
                <div className="dark:bg-backgroundDark">
                  <PasswordInput
                    customstyle="flex flex-col w-full gap-2 text-primaryTextLight dark:text-primaryTextDark text-sm"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <ErrorMessage
                      name="password"
                      component="span"
                      className="text-xs text-[#F63E49] leading-3"
                    />
                  ) : null}
                </div>
                <div className="flex gap-5 h-3 items-center mb-2">
                  <Toggle onClick={onToggle} state={rememberMe} />
                  <span className="text-primaryTextLight dark:text-primaryTextDark text-sm">Remember me</span>
                </div>
                <Button
                  text="SIGN IN"
                  type="submit"
                  customstyle="w-full h-8 drop-shadow-3xl"
                  isLoading={isLoading}
                  loadingText="SIGNING IN"
                />
                <span
                  className="bg-linear-grad bg-clip-text text-transparent text-sm hover:cursor-pointer"
                  onClick={() => nav("/forgot-password")}
                >
                  Forgotten password
                </span>
              </form>
            </section>
          )}
        </Formik>
        <section className="w-2/5 h-full">
          <BackgroundImage customstyle="h-full w-full" />
        </section>
      </main>
      <Footer />
    </PageContainer>
  );
};

export default LoginPage;
