import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import Banner from "../../components/hallpage/Banner";
import MomentsHeader from "../../components/moments/MomentsHeader";
import MomentInfo from "../../components/moments/MomentInfo";
import MomentImage from "../../components/moments/MomentImage";
import { useDeleteEntireMoment, useDeleteMomentPicture, useFilterMoments, useGetHallMoments, useGetSingleMoment } from "../../hooks/momentsHooks";
import React, { useContext, useEffect, useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import { userContext } from "../../contexts/userContext";
import { ClipLoader } from "react-spinners";
import { useLogout } from "../../hooks/authHooks";
import { logoutContext } from "../../contexts/logoutContext";
import ConfirmModal from "../../components/ConfirmModal";
import { momentMenuContext } from "../../contexts/momentMenuContext";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import SetAsProfilePictureModal from "../../components/moments/SetAsProfilePicture";
import SetAsCoverPhotoModal from "../../components/moments/SetAsCoverPhoto";
import Lightbox from "yet-another-react-lightbox";
import { createMomentContext } from "../../contexts/createMomentContext";
import EditMomentPicture from "../../components/moments/EditMomentPicture";
import EditMomentTitle from "../../components/moments/EditMomentTitle";
import EditMomentPictureTitle from "../../components/moments/EditMomentPictureTitle";
import AddMomentPictures from "../../components/moments/AddMomentPictures";
import { useParams } from "react-router-dom";
import MomentsGallery from "../../components/moments/MomentsGallery";
import ProgressiveImage from "react-progressive-graceful-image";
import moment from "moment";
import { set } from "idb-keyval";


const MomentsDetailPage = () => {
  const {momentId} = useParams();
  const [createMoments, setCreateMoments] = useState(false);
  const {momentMenu,setMomentMenu} = useContext(momentMenuContext);
  const {momentData,setMomentData} = useContext(createMomentContext)
  const {logout,setLogout} = useContext(logoutContext);
  const [viewImages,setViewImages] = useState(false);
  const [images,setImages] = useState([]);
  const [filterMoments,setFilterMoments] = useState(false);
  const [showFilteredMoments,setShowFilteredMoments] = useState(false);
  const [gallery,setGallery] = useState([]);
  const [selectedIndex,setSelectedindex] = useState(null);
  const [showGallery,setShowGallery] = useState(false);
  const [galleryIndexes,setGalleryIndexes] = useState({});

  const queryClient = useQueryClient();
  const {onLogout} = useLogout();
  
  const {mutate:deleteMoment,isLoading:deletingMoment} = useDeleteEntireMoment();
  const {mutate:deleteMomentPicture,isLoading:deletingMomentPicture} = useDeleteMomentPicture();
  const {data:singleMoment,isLoading:isFetchingMoment} = useGetSingleMoment(momentId);
  
    const onDeleteMoment = () => {
        deleteMoment(momentMenu.momentId,{
            onSuccess:(data)=>{
                queryClient.invalidateQueries("hall-moments");
                setMomentMenu(prev=>{return {...prev,deleteEntireMoment:false,momentId:""}});
                toast.success("Moment delete successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"})

            },
            onError:(error)=>{
                toast.error("Failed to delete moment",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
        })
    }

    const onDeleteMomentPicture = () => {
        deleteMomentPicture(momentMenu.pictureId,{
            onSuccess:(data)=>{
                queryClient.invalidateQueries("hall-moments");
                setMomentMenu(prev=>{ return {...prev,deleteMomentPicture:false,pictureId:""}});
                toast.success("Picture deleted from this moment successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            },
            onError:(error)=>{
                toast.error("Failed to delete picture from moment",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
        })
    }
    const fetchLighBoxImages = () => {
      if(singleMoment?.data !== undefined){
            const images = []
            const indexes = {}
              
            images.push({type:"title",title:singleMoment?.data?.title,published_at:singleMoment?.data?.published_at,background:momentMenu.background || JSON.parse(localStorage.getItem('momentBackground'))})
              const id = `moment-${singleMoment?.data?.id}`
              indexes[id] = images.length - 1
            images.push(...singleMoment?.data?.pictures.map((img,imgIndex)=>{
              const id = `picture-${img.id}`;
              indexes[id] = images.length + imgIndex
            return {...img,type:"image"}
          }))
            
            setGallery(images);
            setGalleryIndexes(indexes);
      }
    }
  
    useEffect(()=>{
      document.title = "LinkLounge | Hall Moments"
    },[])
    useEffect(()=>{
      fetchLighBoxImages()
    },[singleMoment?.data])

    useEffect(()=>{
      let timeout;
      if(showGallery && (gallery[selectedIndex].type == "title" || selectedIndex === 0)){
        timeout = setTimeout(()=>{
          setSelectedindex(prev=>prev+1)
        },4000)
      }
      return ()=>clearTimeout(timeout);
    },[selectedIndex]);

  return (
    <PageContainer customstyle={"flex h-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-y-auto" id="moments">
        <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
            <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-50`}
          >
            <Banner show={false} />
          </section>
          <section className="flex w-full mt-3 flex-col gap-1 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md p-4 pb-10">
            <MomentsHeader onCreateMoment={() => setCreateMoments(true)} onFilter={()=>setFilterMoments(true)} showFilteredMoments={showFilteredMoments} momentDetail={true}/>
            {
            <React.Fragment>
            { (isFetchingMoment) ? 
              <div className="w-full flex items-center justify-center mx-auto"><ClipLoader size={80} color="#F63E49"/></div> :
              
                <div className="flex flex-wrap gap-5 w-full">
                  <React.Fragment>
                    <MomentInfo
                      date={singleMoment?.data.published_at}
                      text={singleMoment?.data.title}
                      background={momentMenu.background || JSON.parse(localStorage.getItem('momentBackground'))}
                      momentId={singleMoment?.data.id}
                      hallMoment={singleMoment?.data}
                      onClickMoment={()=>setShowGallery(true)}
                      galleryIndex={galleryIndexes && galleryIndexes[`moment-${singleMoment?.data.id}`]}
                      setSelectedIndex={setSelectedindex}                    />
                    {
                    singleMoment?.data.pictures.map((picture) => {
                      return (
                        <MomentImage key={picture.id} image={picture.image} pictureId={picture.id} picture={picture}  onViewImage={()=>{setImages(singleMoment?.data?.pictures);setViewImages(true)}}
                        momentId={singleMoment?.data.id}
                        onClickImage={()=>setShowGallery(true)}
                        galleryIndex={galleryIndexes && galleryIndexes[`picture-${picture.id}`]}
                        setSelectedIndex={setSelectedindex}
                      />
                      );
                    })
                    }
                  </React.Fragment>
            </div>
              }
            </React.Fragment>
          }
          </section>
        </main>
      </div>
    {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        momentMenu.deleteEntireMoment &&
        <ConfirmModal question={"Do you want to delete all the pictures in this moment"} confirmAction={"Delete"} onCancel={()=>setMomentMenu(prev=>{return {...prev,deleteEntireMoment:false,momentId:""}})} onConfirm={onDeleteMoment} isLoading={deletingMoment}/>
      }
      {
        momentMenu.deleteMomentPicture &&
        <ConfirmModal question={"Do you want to delete this picture from the moments"} confirmAction={"Delete"} onCancel={()=>{setMomentMenu(prev=>{return {...prev,deleteMomentPicture:false,pictureId:""}})}} onConfirm={onDeleteMomentPicture} isLoading={deletingMomentPicture}/>
      }
      {
        momentMenu.setAsProfilePicture && 
        <SetAsProfilePictureModal/>
      }
      {
        momentMenu.setAsCoverPhoto &&
        <SetAsCoverPhotoModal/>
      }
      {
        momentMenu.editMomentTitle && 
        <EditMomentTitle/>
      }
      {
        momentMenu.editMomentPictureTitle && 
        <EditMomentPictureTitle/>
      }
      {
        momentMenu.addMomentPictures && 
        <div className="absolute h-full w-full z-50">
          <AddMomentPictures/>
        </div>
      }
      {
        momentData.editMomentPictures &&
        <EditMomentPicture onClose={()=>setMomentData(prev=>{return {...prev,editMomentPictures:false}})} showModal={momentData.editMomentPictures}/>
      }
      {
        <MomentsGallery onClose={()=>setShowGallery(false)} moments={gallery} show={showGallery} selectedIndex={selectedIndex} setSelectedIndex={setSelectedindex}/>
      }
    </PageContainer>
  );
};

export default MomentsDetailPage;
