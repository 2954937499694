import { createContext, useState } from "react"

export const createMomentContext = createContext();
const CreateMomentProvider = ({children}) => {
    const [momentData,setMomentData] = useState({editMomentPictures:false,momentPictures:[],momentPicturesPreview:[],preview:false});
    return (
        <createMomentContext.Provider value={{momentData,setMomentData}}>
            {children}
        </createMomentContext.Provider>
    )
}

export default CreateMomentProvider;