import { useState } from "react";
import { ReactComponent as SelectedIcon } from "../../assets/icons/correct-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { useGetTrendTags } from "../../hooks/trendHooks";
import Tag from "./Tag";
import { useContext } from "react";
import { createTrendContext } from "../../contexts/createTrendsContext";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import { useGetPostTags } from "../../hooks/postHooks";

const SelectTagsModal = ({setShowSelectTagsModal}) => {
  const [tags, setTags] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [searching, setSearching] = useState(false);
  const {setTrend,trend} = useContext(createTrendContext);
  const [selectedTags, setSelectedTags] = useState(trend.trendTags);
  const onSuccess = (data) => {
    const tagsArray = [];
    data?.pages.map(page=>{
      const tags = page?.data?.results;
      const selectedTags = trend.trendTags;
      for(const tag of selectedTags){
        const tagId = tag.id;
        const tagIndex = tags.findIndex(tag=> tag.id === tagId);
        tags.splice(tagIndex,1,tag);
      }
      tagsArray.push(...tags);
  })
  setTags(tagsArray);
}
  const {data,hasNextPage,fetchNextPage,isLoading} = useGetPostTags(onSuccess);

  const onChange = (event) => {
    if (event.target.value) {
      setSearching(true);
    } else {
      setSearching(false);
    }
    setSearchKey(event.target.value);
  };

  const onSelect = (id) => {
    const tagIndex = tags.findIndex((tag) => tag.id === id);
    const tag = tags[tagIndex];
    tag.selected = !tag.selected;
    if(selectedTags.findIndex(tag=>tag.id === id) === -1){
      setTrend(prevData=>{
        return {...prevData,tags:[...prevData.tags,tag.id]}
      })
      setSelectedTags(prevTags=>[...prevTags,tag])
    }else{
      setTrend(prevData=>{
        return {...prevData,tags:prevData.tags.filter(tagId=> tagId !== id )}
      })
      setSelectedTags(prevTags=>{
        return prevTags.filter(tag=> tag.id !== id)
      })
    }
    setTags(prevTags=>{
      prevTags.splice(tagIndex,1,tag);
      return prevTags
    })
  };

  const onClose = () => {
   setTrend(prevData=>{
    return {...prevData,tags:[],trendTags:[]}
   });
  data?.pages.map(page=>{
    const tags = page?.data?.results;
    tags.forEach(tag=>{
      tag.selected = false;
    })
    return tags;
})
setShowSelectTagsModal(false);
}

  const onAddSelectedTags = () => {
    setTrend(prevData=>{
      return {...prevData,trendTags:selectedTags};
    })
    setShowSelectTagsModal(false);
  }

  useEffect(()=>{
    onSuccess(data)
  },[data])

  return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center z-50 absolute bg-[rgba(0,0,0,0.3)]">
      <section className="w-[30%] bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md relative">
          <CloseIcon
            width="25"
            height="25"
            onClick={onClose}
            className="cursor-pointer absolute top-[-5px] right-[-5px]"
          />
        <div className="flex justify-between items-center px-4 py-3 text-primaryTextLight dark:text-primaryTextDark text-lg font-medium">
          <span>Select Tags</span>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="h-[24rem] overflow-y-auto" id="tags-scroll">
        <div className="w-full flex items-center justify-center py-4">
          <div className="w-[80%]">
            <div className="w-full">
              <input
                type="search"
                placeholder="Search for tags"
                className="font-light w-full h-10 rounded-md px-4 focus:outline-none border text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark"
                value={searchKey}
                onChange={onChange}
              />
              <div className="flex w-full gap-2 mt-2 h-full flex-wrap">
                {selectedTags.map((tag) => {
                  return <Tag tag={tag.short_hand} key={tag.id} />;
                })}
              </div>
            </div>
            <div className="w-full flex flex-col mt-4 h-52 mb-4 font-light text-primaryTextLight dark:text-primaryTextDark text-lg gap-1 overflow-y-scroll scrollbar-hide">
              {!searching ?
                isLoading ? <div className="flex w-full justify-center mt-5">
                <ClipLoader size={30} color="#F63E49"/>
            </div>:
                <InfiniteScroll
                dataLength={data?.pages?.length * 64 || 64}
                next={fetchNextPage}
                style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                hasMore={hasNextPage}
                loader={<div className="flex w-full justify-center mt-5">
                <ClipLoader size={30} color="#F63E49"/>
            </div>}
                scrollableTarget={"tags-scroll"}
                className="scrollbar-hide"
                >
                  {
                     tags?.map((tag) => {
                          return (
                            <div className="flex gap-1 items-center cursor-pointer" key={tag.id}>
                              <span className="w-8">
                                {tag.selected && (
                                  <SelectedIcon width="15" height="15" />
                                )}
                              </span>
                              <span
                                onClick={() => {
                                  onSelect(tag.id);
                                }}
                              >
                                {tag.name} {`(${tag.short_hand})`}
                              </span>
                            </div>
                          );
                        })
                  }
               </InfiniteScroll>
                : tags
                    .filter((tag) =>
                      tag.name.toLowerCase().includes(searchKey.toLowerCase())
                    )
                    .map((tag) => {
                      return (
                        <div className="flex gap-1 items-center" key={tag.id}>
                          <span className="w-8">
                            {tag.selected && (
                              <SelectedIcon width="15" height="15" />
                            )}
                          </span>
                          <span
                            onClick={() => {
                              onSelect(tag.id);
                            }}
                          >
                            {tag.name} {`(${tag.short_hand})`}
                          </span>
                        </div>
                      );
                    })}
            </div>
            <div className="w-full flex items-center justify-center mt-auto">
              <button className="bg-linear-grad text-white py-2 drop-shadow-3xl rounded-md w-full cursor-pointer" onClick={onAddSelectedTags}>
                Add Selected Tags
              </button>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>
  );
};

export default SelectTagsModal;
