import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ShowPassword } from '../assets/icons/openeye.svg';
import { ReactComponent as HidePassword } from '../assets/icons/closedeye.svg';



const PasswordInput = ({name,customstyle,onChange,onBlur}) => {
    const [showPassword,setShowPassword] = useState(false);
    const [passwordType,setPasswordType] = useState('password');
    const [passFocused,setPassFocused] = useState(false);
    const pass = useRef(null);
    const onShowPassword = () => {
        if(passwordType === 'text'){
            setPasswordType('password');
        }else if(passwordType === 'password'){
            setPasswordType('text');
        }
        setShowPassword(prev=>!prev);
    }

    const handleMouseDown = (event) => {
        if(pass.current?.contains(event.target)){
            setPassFocused(true);
        }else{
            setPassFocused(false);
        }
    }
    useEffect(()=>{
        window.addEventListener('mousedown',handleMouseDown)
    })
    return (
        <div className={customstyle + " dark:bg-backgroundDark bg-backgroundLight"} ref={pass}>
            <label htmlFor={name} className="font-medium text-base">Password</label>
            <div className={'flex h-auto items-center border dark:border-dark-gray rounded-md dark:bg-backgroundDark justify-between px-2 bg-white' + (passFocused ? " border-2 border-[#FE005B] dark:border-white" : '')}>
            <input id={name} name={name} placeholder="Password" type={passwordType} className="h-8 focus:outline-none w-64 placeholder:font-thin dark:bg-backgroundDark dark:text-primaryTextDark text-base" onChange={onChange} onBlur={onBlur}/>
            {
                showPassword ? 
                <ShowPassword onClick={onShowPassword} width="15px" height="15px"/>
:                <HidePassword onClick={onShowPassword} width='15px' height='15px'/>

            }
            </div>
        </div>
    )
}

export default PasswordInput;