import authClient from "./authAxiosInstance";
import client from "./axiosInstance";
import axios from "axios";

const getMoments = (pageParam) => {
    return authClient.get(pageParam)
}

const getSingleMoment = (id) => {
    return authClient.get(`/user/v1/moment/${id}/`);
}

const createMoment = (values) => {
    return authClient.post(`/user/v1/moment/`,values)
}

const createMomentPicture = (values,uploadProgress) => {
    return authClient.post(`/user/v1/moment-pics/`,values,{
        onUploadProgress:uploadProgress,
        headers:{
            "Content-Type":"multipart/form-data"
        }
    })
}

const editMoment = (id,values) => {
    return authClient.patch(`/user/v1/moment/${id}/`,values);
}

const editMomentPicture = (id,values) => {
    return authClient.patch(`/user/v1/moment-pics/${id}/`,values,{
        headers:{
            "Content-Type":"multipart/form-data"
        }
    });
}


const deleteEntireMoment = (id) => {
    return authClient.delete(`/user/v1/moment/${id}/`);
}

const deleteMomentPicture = (id) => {
    return authClient.delete(`/user/v1/moment-pics/${id}/`);
}

const filterMoments = ({pageParam}) => {
    return authClient.get(`${pageParam}`)
}
export {
    getMoments,
    getSingleMoment,
    createMoment,
    createMomentPicture,
    editMoment,
    editMomentPicture,
    deleteEntireMoment,
    deleteMomentPicture,
    filterMoments
}