import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { useFilterTrends, useGetTrendTags } from "../../hooks/trendHooks";
import FilterSelector from "../FilterSelector";
import { useContext, useEffect, useState } from "react";
import YearMonthDayPicker from "../YearMonthDayPicker";
import { BeatLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";

const FilterTrendsModal = ({onClose,setSearchedTrends,setFiltered,setSearchedTrendsDetails}) => {
    const [loading,setLoading] = useState(false);
    const [filter,setFilter] = useState({year:"",month:"",day:"",privacy:"",trend_type:"",tags:""});
    const [tags,setTags] = useState(["All Tags"]);
    const {hall} = useContext(hallContext);
    const onSuccess = (data) => {
      setFiltered(true);
      setSearchedTrends(data);
      setLoading(false);
      onClose()
    }
    const {data,hasNextPage:hasNextTagsPage,fetchNextPage:fetchNextTags,isLoading:isFetchingTags} = useGetTrendTags((data)=>{
      const trendTags = [];
      data?.pages.map(page=>{
        trendTags.push(...page?.data?.results);
      })
      setTags(trendTags);
      });
    const {refetch:onFilter,hasNextPage,fetchNextPage,isRefetching,isLoading} = useFilterTrends(filter,hall?.hallInfo.id,onSuccess)

    const onChange = (name,value) => {
        if(name === "tags"){
            setFilter(prev=>{
                return {...prev,[name]:tags.find(tag => tag.name === value).id}
            });
        }else{
            setFilter(prev=>{
                return {...prev,[name]:value === "PicturePost" ? "VisualPost" : value}
            });
        }
    }


    const onClear = () => {
        setFilter({year:"",month:"",day:"",privacy:"",type:"All Types",tags:"All"});
        setFiltered(false);
    }
    useEffect(()=>{
      setSearchedTrendsDetails({hasNextPage,fetchNextPage})
    },[hasNextPage])
    useEffect(()=>{
        const trendTags = [];
        data?.pages.map(page=>{
          trendTags.push(...page?.data?.results);
        })
        setTags(trendTags);
        },[data]);
    return (
    <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]" style={{zIndex:9999}}>
      <section className="w-[40%] h-[450px] bg-backgroundLight dark:bg-backgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark flex flex-col">
        <div className="flex justify-between items-center px-4 py-3">
          <span className="font-semibold text-lg">Filter Trends</span>
          <CloseIcon width="20" height="20" className="cursor-pointer" onClick={onClose}/>
        </div>
        <hr className="dark:border-dark-gray"/>
        <div className="w-[80%] px-10 mt-10">
          <div className="w-full flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Date</span>
            <div>
            <YearMonthDayPicker handleChange={onChange} year={filter.year} month={filter.month} day={filter.day}/>
            </div>
          </div>
          <div className="w-full text-center flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Type</span>
            <FilterSelector options={["All Types","TextPost", "TextOnThemePost", "PicturePost", "Poll"]} name="trend_type" onChange={onChange} />
          </div>
          <div className="w-full text-center flex py-4 gap-2 items-center">
            <span className="mr-5 font-medium w-10">Tags</span>
            <FilterSelector filter={"All Tags"} options={["All Tags",...tags.map(tag=>tag.name)]} name="tags" onChange={onChange} tagfilter={true} hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} isLoading={isFetchingTags} data={data}/>
          </div>
        </div>
        <div className="flex w-full justify-end gap-5 items-center pr-4 mt-auto mb-8">
          <div className="flex flex-col items-center cursor-pointer">
            <span
              className="font-medium text-2xl bg-linear-grad bg-clip-text text-transparent inline-block"
                onClick={onClear}
            >
              Clear
            </span>
            <span className="inline-block h-[2px] bg-linear-grad w-full"></span>
          </div>
          <button
            type="button"
            className="w-28 h-10 border text-white flex bg-linear-grad items-center rounded-md px-3 justify-center cursor-pointer"
            onClick={()=>{setLoading(true);onFilter()}}
         >
            {!loading && !isRefetching ? "FILTER" : <BeatLoader color="#fff" size={10}/>}
          </button>
        </div>
      </section>
    </div>
  );
};
export default FilterTrendsModal;
