import moment from "moment";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { unblockHallMember } from "../../api/members";
import defaultProfile from "../../assets/images/default-profile.png";
import { useQueryClient } from "react-query";
import { useState } from "react";

const BlockedRowMember = ({member}) => {
  const [loading,setLoading] = useState(false);
  const queryClient = useQueryClient();
  
  const unblockMember = async () => {
    setLoading(true)
    const response = await unblockHallMember(member?.user_account.id);
    if(!response.error){
      queryClient.invalidateQueries("blocked-hall-members")
      toast.success("Member unblocked successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
    }else{
      toast.error("Could not unblock user",{position:"top-center",hideProgressBar:true,autoClose:2000})
    }
    setLoading(false)
  }
  return (
    <div className="w-full flex items-center text-sm mt-2 mb-2 text-primaryTextLight dark:text-primaryTextDark">
      <div className="flex gap-2 items-center w-[25%]">
        <div className="h-10 w-10">
          <img
            src={
              member.user_account.user.image || defaultProfile
            }
            height="100%"
            width="100%"
            className="h-full w-full rounded-lg"
          />
        </div>
        <span>{member.user_account.user.fullname}</span>
      </div>
      <div className="w-[20%]">{moment(member.date).format("YY-MM-DD HH:SS A")}</div>
      <div className="">{member.reason.reason}</div>
      <div className="flex items-center w-32 px-2 rounded-md font-semibold bg-filter-grad text-primaryTextLight  h-8 justify-center cursor-pointer ml-auto mr-6" onClick={unblockMember}>
        {!loading ? "Unblock" : <BeatLoader color="white" size={10}/>}
      </div>
    </div>
  );
};

export default BlockedRowMember;
