import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BarLoader, BeatLoader, ClipLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as SearchIconDark } from "../../assets/icons/search-icon-dark.svg";
import { hallContext } from "../../contexts/hallContext";
import { leadershipContext } from "../../contexts/leadershipContext";
import { useGetHallAccounts, useSearchAccounts } from "../../hooks/membersHooks";
import Account from "./Account";
import { BehaviorSubject,debounceTime,map,distinctUntilChanged,filter,switchMap,merge,of } from 'rxjs';
import { useLinkAccount } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { themeContext } from "../../contexts/themeContext";

const LinkAccount = () => {
    const {hall} = useContext(hallContext);
    const {data:hallAccounts,isLoading,fetchNextPage,hasNextPage} = useGetHallAccounts(hall?.hallInfo.id);
    const [searchTerm,setSearchTerm] = useState("");
    const [loading,setLoading] = useState(false);
    const {data:searchedAccounts,fetchNextPage:fetchNextSearchedAccounts,hasNextPage:hasNextSearchedAccounts,refetch} = useSearchAccounts(hall?.hallInfo.id,searchTerm,setLoading);
    const [searching,setSearching] = useState(false);
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const [memberSubject,setMemberSubject] = useState(null);
    const {mutate:linkAccount,isLoading:isLinkingAccount} = useLinkAccount();
    const queryClient = useQueryClient()
    const {theme} = useContext(themeContext);
    
    const onChange = (event) => {
        setSearchTerm(event.target.value)
            if(memberSubject){
            setLoading(true);
            setSearching(true);
            return memberSubject.next(event.target.value)
            }
    }

    const memberSearch = () => {
        const observable = memberSubject.pipe(
            map(s => s.trim()),
            distinctUntilChanged(),
            debounceTime(200),
            // switchMap(()=>{
            //         return refetch();
            // })
            )
            .subscribe(()=>{
                refetch();
            })
            return () => {
                observable.unsubscribe()
                memberSubject.unsubscribe()}
    }
    useEffect(()=>{
      if(searchTerm === ""){
          setSearching(false);
          setLoading(false);
      }
  },[searchTerm])

  useEffect(()=>{
      if(memberSubject === null){
          const sub = new BehaviorSubject('')
          setMemberSubject(sub);
      }else{
          memberSearch()
      }
  },[memberSubject])

  const linkUserAccount = () => {
    const formdata = new FormData();
    formdata.append("user",leadershipStates.account.id);
    linkAccount({values:formdata,id:leadershipStates.leader.id},{
        onSuccess:(data)=>{
            toast.success("Account linked successfully",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            // if(leadershipStates.leaderCategory === "Executives"){
                queryClient.invalidateQueries(["leadership-portfolios",hall?.hallInfo.id]);
                queryClient.invalidateQueries("current-hall-executives");
                queryClient.invalidateQueries("past-hall-leaders")
            // }else if(leadershipContext.leaderCategory === "Management"){
                queryClient.invalidateQueries("current-hall-management");
                queryClient.invalidateQueries("past-management");
                // Selec
            // }
            setLeadershipStates(prev=>{ return {...prev,linkAccount:false,account:{},leader:{}}})
        },
        onError:(error)=>{
            toast.error("Could not link account",{autoClose:2000,hideProgressBar:true,position:"top-center"});
        }
    })
  }
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[20rem] max-h-[25rem] bg-backgroundLight dark:bg-backgroundDark rounded-md pb-2 relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setLeadershipStates(prev=>{ return {...prev,linkAccount:false,account:{},leader:{}}})}/>
                <div className="flex justify-between items-center px-4 h-10">
                    <span className="text-xl font-semibold text-primaryTextLight dark:text-primaryTextDark">Link Account</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                <div className="pl-4 flex flex-col w-full">
                    {
                        leadershipStates.account.user !== undefined ? 
                        <Account account={leadershipStates.account}/> :
                        <span className="text-primaryTextLight dark:text-primaryTextDark">No User Account Selected</span>

                    }
                    
                    <div className="w-[16rem] h-10 flex flex-col items-center gap-1  mt-5 mb-5 rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark" style={{border:theme?.value=="light"?"2px solid rgba(54, 76, 102, 0.56)":"2px solid #6c6c6c"}}>
                    <div className="flex rounded-full w-[15rem] gap-2 h-8 px-2 bg-cardBackgroundLight dark:bg-cardBackgroundDark items-center">
                        {
                            theme?.value == "light" ?
                            <SearchIcon width="15" height="15" />:
                            <SearchIconDark width="15" height="15" />
                        }
                        <input placeholder="Search for member" className="focus:outline-none text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" value={searchTerm} onChange={onChange}/>
                    </div>
                        {
                            loading && 
                            <BarLoader color="#F63E49" height={2} width="85%" className="w-full"/>
                        }
                </div>
                    <div className="h-[11rem] overflow-y-auto mb-3" id="hall-accounts">
                        {
                            isLoading && 
                            <div className="flex w-full h-36 justify-center mt-10">
                                <ClipLoader size={40} color="#F63E49"/>
                            </div>
                        }
                        <InfiniteScroll
                        dataLength={(!searching ? hallAccounts?.pages?.length * 64 : searchedAccounts?.pages?.length * 64) || 64}
                        next={!searching ? fetchNextPage : fetchNextSearchedAccounts}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={!searching ? hasNextPage : hasNextSearchedAccounts}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"hall-accounts"}
                        className="scrollbar-hide"
                        >
                            {!searching ? hallAccounts?.pages?.map((group,i)=>{
                                return group?.data?.results?.map(account=>{
                                return (
                                    <Account key={account.id} account={account}/>
                                    )
                                })
                        }) :
                        searchedAccounts?.pages?.map((group,i)=>{
                            return group?.data?.results?.map(account=>{
                            return (
                                <Account key={account.id} account={account}/>
                                )
                            })})
                    }
                        </InfiniteScroll>
                    </div>
                    {
                        leadershipStates.previous === "leadership" && 
                        <button className="bg-linear-grad text-white px-4 py-1 rounded-md mx-auto" onClick={linkUserAccount}>{!isLinkingAccount ? "Done" : <BeatLoader color="white" size={10}/>}</button>
                    }
                    {
                        leadershipStates.previous === "add-executive" && 
                        <button className="bg-linear-grad text-white px-4 py-1 rounded-md mx-auto" 
                        onClick={linkUserAccount}
                        >{!isLinkingAccount ? "Done" : <BeatLoader color="white" size={10}/>}</button>
                    }
                    {
                        (leadershipStates.previous === "register-change" || leadershipStates.previous === "add-management") && 
                        <div className="w-full flex justify-center gap-2">
                            <button className="text-[#F63E49] py-1 px-4 rounded-md" onClick={()=>setLeadershipStates(prev=>{return{...prev,addManagement:prev.previous === "add-management" ? true : false,registerChange:prev.previous === "register-change" ? true: false,linkAccount:false,linkedAccount:{}}})}>{"Skip"}</button>
                            <button className="bg-filter-grad text-white px-4 py-1 rounded-md dark:text-primaryTextLight" onClick={()=>setLeadershipStates(prev=>{return {...prev,linkAccount:false,addManagement:prev.previous === "add-management" ? true : false,registerChange:prev.previous === "register-change" ? true: false,linkedAccount:prev.account}})}>{"Continue"}</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LinkAccount;