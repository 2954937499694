import authClient from "./authAxiosInstance";

const getPosts = ({pageParam="/posts/v1/posts/"}) => {
  return authClient.get(pageParam);
};

const getTrendPosts = (pageParam) => {
  return authClient.get(pageParam)
}

const getPostThemes = () => {
  return authClient.get("/posts/v1/post-theme/");
};

const getPostTags = (pageParam) => {
  return authClient.get(pageParam);
};

const createPost = (post, contentType) => {
  return authClient.post("/posts/v1/posts/", post, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

const createPostPoll = (post) => {
  return authClient.post("/posts/v1/post-polls/", post);
};

const votePoll = async (id) => {
  const response = await authClient.get(
    `/posts/v1/post-poll-options/${id}/vote/`
  );
  if (response.status === 200) {
    const results = response.data;
    return results;
  }
  return { error: "Could not send vote" };
};

const cancelVote = async (id) => {
  const response = await authClient.get(
    `/posts/v1/post-poll-options/${id}/cancel-vote/`
  );
  if (response.status === 200) {
    const results = response.data;
    return results;
  }
  return { error: "Could not cancel vote" };
};

const filterPosts = async (pageParam) => {
  return authClient.get(pageParam);
};

const getPostStatistics = () => {
  return authClient.get("/posts/v1/post-stats/");
};

const deleteHallPost = (id) => {
  return authClient.delete(`/posts/v1/posts/${id}/`);
};

const searchPostsByText = (pageParam) => {
  return authClient.get(pageParam);
};

const turnOffPostNotification = async (id) => {
  const response = await authClient.get(
    `/posts/v1/posts/${id}/turn-of-notification/`
  );
  if (response.status === 200) {
    return response.data;
  }
  return { error: "Could not turn of notification" };
};

const turnOnPostNotification = async (id) => {
  const response = await authClient.get(
    `/posts/v1/posts/${id}/turn-of-notification/?turnon=true`
  );
  if (response.status === 200) {
    return response.data;
  }
  return { error: "Could not turn of notification" };
};

const hidePost = async (id) => {
  const response = await authClient.get(`/posts/v1/posts/${id}/hide-post/`);
  if (response.status === 200) {
    return response.data;
  }
  return {
    error: "Could not hide post",
  };
};

const reportPost = async (values) => {
  return authClient.post(`/posts/v1/report-post/`, values);
};

const getReportPostReasons = () => {
  return authClient(`/posts/v1/report-post-reason/`)
}

const changePostPrivacy = (id, values) => {
  return authClient.patch(`/posts/v1/posts/${id}/`, values);
};

const editPost = (id,values,contentType) => {
  return authClient.patch(`/posts/v1/posts/${id}/`,values,{
    headers:{
      "Content-Type":contentType
    }
  });
}

const deletePostImage = (id) => {
  return authClient.delete(`/posts/v1/posts-pics/${id}/`);
}

const createVideoPost = (values) => {
  return authClient.post(`/posts/v1/post-video/`,values,{
    headers:{
      "Content-Type":"multipart/form-data"
    }
  })
}

const deletePostVideo = (id) => {
  return authClient.delete(`/posts/v1/post-video/${id}/`);
}

export {
  getPosts,
  getPostThemes,
  createPost,
  createPostPoll,
  votePoll,
  cancelVote,
  filterPosts,
  getPostStatistics,
  searchPostsByText,
  turnOffPostNotification,
  turnOnPostNotification,
  deleteHallPost,
  reportPost,
  hidePost,
  changePostPrivacy,
  editPost,
  deletePostImage,
  getReportPostReasons,
  getPostTags,
  getTrendPosts,
  createVideoPost,
  deletePostVideo
};
