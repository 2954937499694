import { useNavigate } from "react-router";
import {ReactComponent as HallConnectLogo}  from "../../assets/icons/hall-connect-logo.svg"
import {ReactComponent as HallNewsIndicator} from "../../assets/icons/hall-news-indicator.svg";
import moment from "moment";

const RelatedNewsCard = ({news}) => {
  const nav = useNavigate();
  return (
    <div className="w-72 rounded-md shadow-3xl dark:bg-cardBackgroundDark h-32 p-4 cursor-pointer" onClick={()=>nav(`/news/${news.id}`)}>
      <h2 className="text-primaryTextLight dark:text-primaryTextDark text-base leading-5 font-medium line-clamp-3 opacity-80 h-16">
        {news.title}
      </h2>{" "}
      <div className="flex gap-2 items-center text-xs text-[#7A7D81] font-light mt-5">
      {
              !news.is_hall_buzz ? <HallConnectLogo width={"15"} height="15"/> : <HallNewsIndicator width={"15"} height="15"/>
            }               <span className="max-w-[50%] line-clamp-1">{!news.is_hall_buzz ? "LinkLounge" : news.created_by_useraccount.fullname}</span>
            <span className="inline-block w-[3px] h-[3px] rounded-full bg-dot-gray"></span>
            <span className="">{moment(news?.date_created).fromNow()}</span>
        </div>
    </div>
  );
};

export default RelatedNewsCard;
