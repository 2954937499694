import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useAddLeader, useGetCurrentTenure, useGetExecutiveLeadershipPortfolios, useGetLeadershipPortfolios, useGetLeadershipPortfoliosByType } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import compareDate from "../../helpers/compareDate";
import { memberContext } from "../../contexts/memberContext";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import validatePhoneNumber from "../../helpers/validatePhoneNumber";
import { themeContext } from "../../contexts/themeContext";
import pick from "lodash.pick";

const AddLeadershipPortfolio = () => {
  const {theme} = useContext(themeContext);
    const {memberOptions,setMemberOptions} = useContext(memberContext);
    const {hall} = useContext(hallContext);
    const [leader,setLeader] = useState({name:memberOptions.leadershipMember.user.fullname,user:memberOptions.leadershipMember.id,portofolio:"",hall:hall?.hallInfo.id,contact:"",start_date:"",end_date:"",in_service:false,tenure:""});
    const [showDateUnknown,setShowDateUnknown] = useState(false);
    const [showTenure,setShowTenure] = useState(false);
    const [dateUnknown,setDateUnknown] = useState(false);
    const [preview,setPreview] = useState("");
    const [profile,setProfile] = useState("");
    const [rotate, setRotate] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedPortfolio,setSelectedPortfolio] = useState(null);
    const {data:portfolios,isLoading:fetchingPortfolios,fetchNextPage,hasNextPage} = useGetExecutiveLeadershipPortfolios(hall?.hallInfo.id,"Executive")
    const {mutate:addLeader,isLoading} = useAddLeader();
    const { data: currentTenure, isLoading: isFetchingCurrentTenure } =
    useGetCurrentTenure(hall?.hallInfo.id);
    const [leaderType,setLeaderType] = useState("");
    const queryClient = useQueryClient();
    const onDropDown = () => {
    setShowOptions((prev) => !prev);
    setRotate((prev) => !prev);
  };
  const onSelect = (value,id,type) => {
    setSelectedOption(value);
    setLeader(prev=>{return {...prev,portofolio:id}})
    setRotate(false);
    setShowOptions(false);
    if(type === "Executive"){
      setLeaderType("Executive")
      setShowDateUnknown(false);
      setShowTenure(false)
    }else{
      setLeaderType("Management");
      setShowDateUnknown(true);
      setShowTenure(true);
    }
  };
    const onSelectFile = () => {
        document.getElementById("leader-image-input").click()
    }

    const addNewExecutive = () => {
      if(selectedPortfolio?.current_portfolio.length !== 0){
        toast.error("Leader with same portfolio in service",{autoClose:2000,hideProgressBar:true,position:"top-center"});
      }
      else if(leader.name === ""){
        toast.error("Name field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.contact === ""){
        toast.error("Contact field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(!validatePhoneNumber(leader.contact)){
        toast.error("Invalid contact",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leaderType === "Executive" && currentTenure?.data?.id === undefined){
        toast.error("Current Tenure for executives has not been created",{autoClose:2000,position:"top-center",hideProgressBar:true});
      }
      else if(leaderType === "Management" && leader.start_date === ""){
        toast.error("Start date cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leaderType === "Management" && leader.end_date === "" && dateUnknown === false){
        toast.error("End date cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }
      else{
        const formdata = new FormData();
        const newManagement = leader;
        const currentDate = new Date();
        if(dateUnknown){
          newManagement.end_date = null;
        }
        // check if management is in service
        if(newManagement.end_date !== null && newManagement.end_date !== ""){
          if(compareDate(currentDate,newManagement.start_date) === true && compareDate(currentDate,newManagement.end_date) === false){
            newManagement.in_service = true;
          }else{
            newManagement.in_service = false;
          }
        }else{
          newManagement.in_service = true;
        }
        const newLeader = leaderType === "Management" ? pick(newManagement,["start_date","end_date","name","user","portofolio","hall","contact"]) : pick(newManagement,["tenure","name","portofolio","hall","contact"])
        if(leaderType === "Executive"){
          newLeader.tenure = currentTenure?.data?.id
        }
        for(let data in newLeader){
          if((data === "start_date" || data === "end_date")){
            if(newLeader[data] === null){
              formdata.append(data,"");
            }else{
              formdata.append(data,moment(newLeader[data]).format("YYYY-MM-DD"))
            }
          }else {
            formdata.append(data,newLeader[data]);
          }
        }
        addLeader(formdata,{
          onSuccess:(data) => {
            toast.success("New leader added",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            queryClient.invalidateQueries("current-hall-executive");
            queryClient.invalidateQueries("current-hall-management");
            queryClient.invalidateQueries("past-management");
            setMemberOptions(prev=>{ return {...prev,addLeadershipPortfolio:false,leadershipMember:{}}})
          },
          onError:(error)=>{
            if(error.response.data.error && error.response.data.error[0] == 'Sorry, there is an existing portfolio alerady in service.'){
              toast.error("Leader with same portfolio in service",{autoClose:2000,hideProgressBar:true,position:"top-center"})
            }
            else if(error.response.data.contact[0] == "user portofolio with this contact already exists."){
              toast.error("Leader with this contact already exists",{autoClose:2000,hideProgressBar:true,position:"top-center",style:{width:"25rem"}});
            }else if(error.response.data.contact){
              toast.error("Invalid contact",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
            else{
              toast.error("Could not add new leader",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
          }
        })
      }
    }

    const handleChange = (event) => {
      setLeader(prev=> {return {...prev,[event.target.name]:event.target.value}})
    }

  const handleDateChange = (name,date) => {
      if(name === "end_date"){
        setDateUnknown(false);
      }
      setLeader(prev=> {return {...prev,[name]:date}})
  }


    useEffect(() => {
        if (!profile) {
          setPreview(null);
          return;
        }
    
        const objectUrl = URL.createObjectURL(profile);
        setPreview(objectUrl);
    
        return () => URL.revokeObjectURL(objectUrl);
      }, [profile]);
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[24rem] max-h-[36rem] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
                    <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setMemberOptions(prev=>{return {...prev,addLeadershipPortfolio:false,leadershipMember:{}}})}/>
                <div className="h-10 flex items-center justify-between px-4">
                    <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">New Leader</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                <div className="overflow-y-scroll pb-4 h-[90%]">
                <div className="w-[80%] h-40 flex justify-center mt-5">
                    <div className="flex w-[14.5rem] justify-end">
                    <div className="w-32 h-32 relative">
                        <img src={preview|| defaultProfile} alt="Leader Profile" width="100%" height="100%" className="w-36 h-full object-cover rounded-md"/>
                        <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight absolute bottom-[0px] right-[0px]">
                            <CameraIcon height="20" width="20" className="cursor-pointer" onClick={onSelectFile}/>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="w-full flex items-center flex-col gap-5 h-[50%]">
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Name <span className="text-[#F63E49]">*</span></label>
                            <input className="focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-backgroundDark" placeholder="Person Name" name="name" defaultValue={memberOptions.leadershipMember.user.fullname} onChange={handleChange}/>
                    </div>
                    <div className="flex justify-between w-[80%]">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">
              Portfolio <span className="text-[#F63E49]">*</span>
            </label>
            <div className="flex items-center gap-2 rounded-md h-8 border bg-cardBackgroundLight dark:bg-cardBackgroundDark relative w-[13rem]">
              <input
                placeholder="Select Position"
                id="title"
                className="focus:outline-none  text-primaryTextLight placeholder:font-thin dark:text-primaryTextDark dark:bg-cardBackgroundDark pl-2 w-[11rem]"
                value={selectedOption || ""}
                onClick={onDropDown}
                onChange={(event)=>setSelectedOption(event.target.value)}
              />
              <div className="flex items-center gap-2">
                {theme?.value == "light"?
                <SelectIcon
                width="12"
                height="12"
                onClick={onDropDown}
                className={
                  "cursor-pointer " +
                  (rotate ? "rotate-180 delay-200" : "delay-200")
                }
              />:<SelectIconDark
              width="12"
              height="12"
              onClick={onDropDown}
              className={
                "cursor-pointer " +
                (rotate ? "rotate-180 delay-200" : "delay-200")
              }
            />}
              </div>
              {showOptions && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 z-10 max-h-36 overflow-auto" id="management-portfolio">
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"management-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              if(portfolio.is_active){
                                return (
                                  <div
                                  className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0] dark:hover:text-primaryTextLight" key={portfolio.id}
                                  onClick={() => {onSelect(portfolio.title,portfolio.id,portfolio.leadership_type);setSelectedPortfolio(portfolio)}}>
                                  <span>{portfolio.title}</span>
                                  </div>                                  )
                              }else{
                                return null;
                              }
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact <span className="text-[#F63E49]">*</span></label>
              <input className="focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight placeholder:font-thin dark:text-primaryTextDark dark:bg-backgroundDark" placeholder="Person Contact" onChange={handleChange} name="contact"/>
            </div>
            {
              showTenure && 
              <div className="flex justify-between w-[80%]">
              <div className="flex">
                <span className="text-primaryTextLight dark:text-primaryTextDark">Tenure <span className="text-[#F63E49]">*</span></span>
              </div>
                <div className="flex flex-col gap-5 w-[13rem]">
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.start_date} placeholderText="Starting (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" name="start_date" onChange={(date)=>handleDateChange("start_date",date)} maxDate={new Date()}/>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.end_date} placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark" name="end_date" onChange={(date)=>handleDateChange("end_date",date)}/>
                  </div>
                </div>
            </div>
            }
            {
              showDateUnknown && 
              <div className="flex items-center w-[80%] justify-end">
                <div className="w-[12.6rem] flex items-center gap-4">
                <Toggle state={dateUnknown} onClick={()=>{setDateUnknown(prev=>!prev);setLeader(prev=>{return {...prev,end_date:null}})}}/>
            <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{"To date (unknown)"}</span>
                </div>
            </div>
            }
            <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={addNewExecutive}>{!isLoading ? "Done" : <BeatLoader color="white" size={10}/>}</button>
          </div>
          </div>
          </div>
          <input type="file" accept="image/*" className="hidden" id="leader-image-input" onChange={(event)=>{setProfile(event.target.files[0]);setLeader(prev=>{return {...prev,picture:event.target.files[0]}})}}/>
        </div>
    )
}

export default AddLeadershipPortfolio;