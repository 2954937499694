import { useContext, useEffect, useState } from "react";
import Banner from "../../components/hallpage/Banner";
import BlockedMembersList from "../../components/members/BlockedMembersList";
import Member from "../../components/members/Member";
import MemberListFooter from "../../components/members/MemberListFooter";
import MembersHeader from "../../components/members/MembersHeader";
import Memberslist from "../../components/members/MembersList";
import NewMembersList from "../../components/members/NewMembersList";
import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import { hallContext } from "../../contexts/hallContext";
import { useGetHallMembers } from "../../hooks/membersHooks";
import {ReactComponent as NoDataIcon} from "../../assets/icons/no-data.svg";
import { ClipLoader } from "react-spinners";
import ConfirmModal from "../../components/ConfirmModal";
import { useLogout } from "../../hooks/authHooks";
import { logoutContext } from "../../contexts/logoutContext";
import BlockMemberModal from "../../components/members/BlockMemberModal";
import { memberContext } from "../../contexts/memberContext";
import ReportMemberModal from "../../components/members/ReportMemberModal";
import NewMembersHeader from "../../components/members/NewMembersHeader";
import AddLeadershipPortfolio from "../../components/members/AddLeadershipPortfolio";
import ReactPaginate from "react-paginate";
import { useGetExecutiveLeadershipPortfolios } from "../../hooks/leadersHooks";

const MembersPage = () => {
  const [newMembers, setNewMembers] = useState(false);
  const [showMember,setShowMember] = useState(false);
  const [showNewMember,setShowNewMember] = useState(false);
  const [showBlockedMembers,setShowBlockedMembers] = useState(false);
  const [page,setPage] = useState(1);
  const [pageSize,setPageSize] = useState(10);
  const [member,setMember] = useState({});
  const [newMember,setNewMember] = useState({});
  const [count,setCount] = useState(10);

  const {memberOptions} = useContext(memberContext);
  const [searchedMembers,setSearchedMembers] = useState([]);
  const [searching,setSearching] = useState(false);

  const {hall} = useContext(hallContext);
    const {logout,setLogout} = useContext(logoutContext);
    const {onLogout} = useLogout();
    const onFetchHallMembers = (data) => {
        setCount(data?.data.count);
        // setPage()
    }
  const {isLoading,isFetching,data:members} =  useGetHallMembers(hall?.hallInfo.id,page,pageSize,onFetchHallMembers)

  useEffect(()=>{
    document.title = "LinkLounge | Hall Members"
  },[])

  return (
    <PageContainer customstyle={"flex h-full w-full relative"}>
      <div className="w-full h-full p-4 flex justify-between overflow-auto">
      <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] flex flex-col">
          <section
            className={`banner bg-cover bg-center rounded-md mb-5 p-2 flex flex-col w-full sticky top-0 z-20`}
          >
            <Banner show={false} />
          </section>
          <section className="w-full px-4 mt-3 bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md py-4">
            {!showBlockedMembers ? <>{
              !newMembers ? 
              <MembersHeader setNewMembers={()=>setNewMembers(prev=>!prev)} newMembers={newMembers} setShowBlockedMembers={setShowBlockedMembers}   setSearchedMembers={setSearchedMembers} setSearching={setSearching} page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize}/>
              : <NewMembersHeader setNewMembers={()=>setNewMembers(prev=>!prev)} newMembers={newMembers} setShowBlockedMembers={setShowBlockedMembers}   setSearchedMembers={setSearchedMembers} setSearching={setSearching} page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize}/>
            }

            {!newMembers && (
              <div className="flex flex-col mt-6 w-full">
                <div className="flex mb-2 w-full">
                  <span className="text-xs text-header-gray dark:text-primaryTextDark dark:text-primaryTextDarkd font-normal w-[30%]">
                    PROFILE
                  </span>
                  <span className="text-xs text-header-gray dark:text-primaryTextDark font-normal w-[10%] flex justify-start">
                    NICKNAME
                  </span>
                  {/* <span className="text-xs text-header-gray font-normal w-[8%] flex justify-start">
                    DOB
                  </span> */}
                  <span className="text-xs text-header-gray dark:text-primaryTextDark font-normal w-[10%] justify-center flex">
                    ONLINE
                  </span>
                  <span className="text-xs text-header-gray dark:text-primaryTextDark font-normal w-[15%] flex justify-start">
                    PERMISSION
                  </span>
                  <span className="text-xs text-header-gray dark:text-primaryTextDark font-normal w-[10%] flex justify-start">
                    JOINED
                  </span>
                  <span className="text-xs text-header-gray dark:text-primaryTextDark font-normal w-[10%] justify-start">
                    LAST ACTIVE
                  </span>
                  <span className="w-[10%]"></span>
                </div>
                {
                  (isLoading || isFetching) ? 
                  <div className="w-full h-36 flex items-center mt-10 justify-center">
                      <ClipLoader size={80} color="#F63E49"/>
                  </div> :
                  <>
                <Memberslist setShowMember={setShowMember} setMember={setMember} members={!searching ? members?.data?.results : searchedMembers?.results}/>
                <MemberListFooter page={page} pageSize={pageSize} setPage={setPage} setPageSize={setPageSize} count={!searching ? members?.data.count : searchedMembers?.count}/>
                </>
                }
              </div>
            )}
            </> :
            <BlockedMembersList setShowBlockedMembers={setShowBlockedMembers}/>
            }
          </section>
          {newMembers && !showBlockedMembers && (
              <NewMembersList setShowNewMember={()=>setShowNewMember(true)} setNewMember={setNewMember} searching={searching} searchedMembers={searchedMembers}/>
            )
            }
        </main>
      </div>
      {showMember &&
        <div className="w-full h-full z-20 absolute">
        <Member setShowMember={setShowMember} member={member}/>
        </div>
        }
        {
         showNewMember &&
         <div className="w-full h-full z-20 absolute">
         <Member setShowMember={setShowNewMember} member={newMember}/>
         </div> 
        }
        {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        memberOptions.block && 
        <BlockMemberModal/>
      }
      {
        memberOptions.report &&
        <ReportMemberModal/>
      }
      {
        memberOptions.addLeadershipPortfolio &&
        <AddLeadershipPortfolio/>
      }
    </PageContainer>
  );
};

export default MembersPage;
