const getAllYears = (startYear) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const years = [];
    for(let year = startYear; year <= Number(currentYear); year++){
        years.push(year);
    }
    return years.reverse();
}
export default getAllYears;
