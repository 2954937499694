import { toast } from "react-toastify";
import { useChangePostPrivacy } from "../../hooks/postHooks";
import { ReactComponent as SelectedIcon } from "../../assets/icons/correct-icon.svg";
import { useQueryClient } from "react-query";
import { useContext } from "react";
import { themeContext } from "../../contexts/themeContext";

const ChangeTheme = ({setShowChangeTheme,innerref}) => {
    const {toggleTheme,theme} = useContext(themeContext);
    const onChangeTheme = (value) => {
        toggleTheme(value);
        setShowChangeTheme(false);
    }
    return (
        <div className="flex flex-col rounded-md bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark text-xs absolute z-20 right-0 top-[100%] w-40" ref={innerref}>
        <span className="text-primaryTextLight dark:text-primaryTextDark pl-4 font-medium text-md mb-2 mt-2">Theme</span>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark pl-4 py-2 flex gap-10" onClick={()=>onChangeTheme("light")}>Light{"  "} {theme?.name === "light" && <SelectedIcon width="15" height="15" />}</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark pl-4 py-2 flex gap-4" onClick={()=>onChangeTheme("dark")}>Dark{"  "} {theme?.name === "dark" && <SelectedIcon width="15" height="15" />}</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="w-full flex flex-col text-primaryTextLight dark:text-primaryTextDark">
                <span className="cursor-pointer hover:bg-[#e0e0e0] dark:hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight dark:bg-cardBackgroundDark pl-4 py-2 flex gap-5" onClick={()=>onChangeTheme("system")}>System{"  "} {theme?.name === "system" && <SelectedIcon width="15" height="15" />}</span>
            </div>
        </div>
    )
}

export default ChangeTheme;