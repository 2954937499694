import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon-white.svg";
import { backgrounds } from "../../constants/backgrounds";
import ChangeMomentPrivacy from "./ChangeMomentPrivacy";
import MomentsInfoContext from "./MomentsInfoContext";

const MomentInfo = ({date,text,index,momentId,hallMoment,background,onClickMoment,galleryIndex,setSelectedIndex}) => {
    const [showContext,setShowContext] = useState(false);
    const ref = useRef();
    const [position,setPosition] = useState("");
    const [showChangePrivacy,setShowChangePrivacy] = useState(false);
    const handleMouseDown = (event) => {
        if (!ref.current?.contains(event.target)) {
        setShowContext(false);
        setShowChangePrivacy(false);
        }
    };

    const onMouseClick = (event) => {
        const mousePosition = event.pageX;
        if(mousePosition < window.innerWidth / 2){
            setPosition("left");
        }else{
            setPosition("right");
        }
    }
    useEffect(() => {
        window.addEventListener("mousedown", handleMouseDown);
      });
    return (
        <div className="h-40 w-40 rounded-md flex flex-col items-center justify-center relative shrink-0 cursor-pointer" style={{background:background || backgrounds[index % backgrounds.length]}}>
            <span className= "rounded-full w-5 h-5 flex items-center justify-center absolute top-1 right-1 cursor-pointer" style={{background:"rgba(0, 0, 0, 0.25)"}} onClick={(event)=>{onMouseClick(event);setShowContext(true);}}><MenuIcon fill="white" height="15" width="15"/></span>
            <div className="w-full h-full flex flex-col items-center justify-center" onClick={()=>{if(!showContext && ! showChangePrivacy){setSelectedIndex(galleryIndex);onClickMoment()}}}>
            <p className="text-xl text-center text-primaryTextLight px-2">{text}</p>
            <span className="text-primaryTextLight">{moment(date).format("MMM D YYYY")}</span>
            {
                showContext && 
                <div className={`absolute top-[10px] z-30`} style={position === "left" ? {left:"85%"} : {right:"0"}}>
                    <MomentsInfoContext innerRef={ref} momentId={momentId} moment={hallMoment} changePrivacy={(event)=>{onMouseClick(event);setShowChangePrivacy(true);setShowContext(false)}}/>
                </div>
            }
            {
              showChangePrivacy && 
            <div className={`absolute top-[10px] z-30`} style={position === "left" ? {left:"85%"} : {right:"0"}}>
              <ChangeMomentPrivacy innerRef={ref} privacy={hallMoment.privacy} setShowChangePrivacy={setShowChangePrivacy} momentId={momentId}/>
            </div>
            }
            </div>
        </div>
    )
}

export default MomentInfo;