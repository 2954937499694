// const { useEffect, useState } = require("react");
import moment from "moment";
import React, { useEffect,useState } from "react";
import { ReactComponent as LeftIcon } from "../../assets/icons/left-icon.svg";
import { ReactComponent as RightIcon } from "../../assets/icons/right-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import ProgressiveImage from "react-progressive-graceful-image";
import { ClipLoader } from "react-spinners";

const Image = React.memo(({item,index,currentSlide})=>{
 return(   <div
          className="h-full w-screen shrink-0 bg-center bg-cover transition-all duration-700 ease-in-out flex items-center justify-center bg-black"
          key={item.id}
          style={{
            // backgroundImage: `url(${image.image})`,
            marginLeft: index === 0 ? `-${currentSlide * 100}%` : undefined,
          }}
        >
            {
          item?.type === 'title' ?
          <div className="h-[40%] w-[40%] rounded-md flex flex-col items-center justify-center relative shrink-0" style={{background:item?.background}}>
            <p className="text-3xl text-center text-primaryTextLight px-2">{item?.title}</p>
            <span className="text-primaryTextLight text-xl">{moment(item?.published_at).format("MMM D YYYY")}</span>
        </div> :
          <div className="h-full w-full flex items-center justify-center">
          <ProgressiveImage src={item?.image} placeholder="">
              {(src, loading) => {
                if(loading){
                  return <div>
                    <ClipLoader size="30" color="#F63E49"/>
                  </div>
                }else{
                  return <img src={src} alt="Moment" height="100%" width="100%" className={`max-h-screen w-auto transition-opacity duration-500 ease-in-out ${(loading) ? 'opacity-0' : 'opacity-100'}`}/>
                }
          }}
              </ProgressiveImage>

          </div>
        }
        </div>)
})

const ImageSlider = ({
  images = [],
  autoPlay = true,
  autoPlayTime = 3000,
  onClose,
  show,
  children,
  currentSlide,
  setCurrentSlide,
  ...props
}) => {
  // const [currentSlide, setCurrentSlide] = useState(0);

  function nextSlide(slideIndex = currentSlide + 1) {
    if(slideIndex < images.length){
        setCurrentSlide(slideIndex);
    }
  }

  function prevSlide(slideIndex = currentSlide - 1){
    if(slideIndex >=0 ){
        setCurrentSlide(slideIndex);
    }
  }

  return (
    <div
      className={"h-screen w-screen flex-nowrap overflow-x-hidden absolute top-0 z-[150] " + (show ? "flex" : "hidden")}
      {...props}
    >
      <CloseIcon
        width="20"
        height="20"
        className="cursor-pointer absolute right-2 top-2 z-20"
        onClick={onClose}
      />
    <div className="absolute z-20 top-[50%] left-2 w-8 h-8 text-white flex items-center justify-center cursor-pointer" onClick={()=>prevSlide()}>
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <LeftIcon height={"20"} width={"20"} className="z-20"/>
    </div>
    <div className="absolute z-20 top-[50%] right-2 w-8 h-8 text-white flex items-center justify-center cursor-pointer" onClick={()=>nextSlide()}>
        <span className="bg-black rounded-full opacity-[25%] h-full w-full absolute"></span>
        <RightIcon height={"20"} width={"20"} className="z-20"/>
    </div>
      {images?.map((image, index) => (
        <Image item={image} key={image.id} index={index} currentSlide={currentSlide}/>
      ))}
      <div className="w-full h-full absolute top-0 left-0 bg-[rgba(0, 0, 0, 0.6)]"></div>
    </div>
  );
};

export default ImageSlider;
