import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useGetCurrentTenure, useGetManagementLeadershipPortfolios, useRegisterLeadershipChangeRequest } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import compareDate from "../../helpers/compareDate";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const RegisterLeadershipChange = () => {
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    const [leader,setLeader] = useState({name:leadershipStates.linkedAccount.user !== undefined ? leadershipStates.linkedAccount.user.fullname:"",user:leadershipStates.linkedAccount.user !== undefined ? leadershipStates.linkedAccount.id : "",picture:"",portofolio:leadershipStates.leader.portofolio.id,hall:hall?.hallInfo.id,contact:"",start_date:leadershipStates.leader?.start_date || null,end_date:leadershipStates.leader?.end_date || null,in_service:false,user_portfolio:leadershipStates.leader.id,tenure:""});
    const [dateUnknown,setDateUnknown] = useState(false);
    const [preview,setPreview] = useState("");
    const [profile,setProfile] = useState("");
    const [rotate, setRotate] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const {data:portfolios,isLoading:fetchingPortfolios,fetchNextPage,hasNextPage} = useGetManagementLeadershipPortfolios(hall?.hallInfo.id,"Management")
    const {mutate:registerLeadershipChange,isLoading} = useRegisterLeadershipChangeRequest();
    const { data: currentTenure, isLoading: isFetchingCurrentTenure } =
    useGetCurrentTenure(hall?.hallInfo.id);
  
    const queryClient = useQueryClient();
    const onDropDown = () => {
    setShowOptions((prev) => !prev);
    setRotate((prev) => !prev);
  };
  const onSelect = (value,id) => {
    setSelectedOption(value);
    setLeader(prev=>{return {...prev,portofolio:id}})
    setRotate(false);
    setShowOptions(false);
  };
    const onSelectFile = () => {
        document.getElementById("leader-image-input").click()
    }

    const registerLeadershipChangeRequest = () => {
      if(leader.name === ""){
        toast.error("Name field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.contact === ""){
        toast.error("Contact field cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.start_date === ""){
        toast.error("Start date cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }else if(leader.end_date === "" && dateUnknown === false){
        toast.error("End date cannot be empty",{autoClose:2000,hideProgressBar:true,position:"top-center"})
      }
      else{
        const formdata = new FormData();
        const changeRequest = leader;
        const currentDate = new Date();
        if(dateUnknown){
          changeRequest.end_date = null;
        }
        // check if management is in service
        if(changeRequest.end_date !== null && changeRequest.end_date !== ""){
          if(compareDate(currentDate,changeRequest.start_date) === true && compareDate(currentDate,changeRequest.end_date) === false){
            changeRequest.in_service = true;
          }else{
            changeRequest.in_service = false;
          }
        }else{
          changeRequest.in_service = true;
        }
        for(let data in changeRequest){
          if(data === "start_date" || data === "end_date"){
            if(changeRequest[data] === null || changeRequest[data] === ""){
              formdata.append(data,"");
            }else{
              formdata.append(data,moment(changeRequest[data]).format("YYYY-MM-DD"))
            }
          }else{
            formdata.append(data,changeRequest[data]);
          }
        }
        registerLeadershipChange(formdata,{
          onSuccess:(data) => {
            toast.success("Leadership Change Requested",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            queryClient.invalidateQueries("current-hall-management");
            queryClient.invalidateQueries(["leadership-portfolios",hall?.hallInfo.id]);
            queryClient.invalidateQueries("past-management")
            setLeadershipStates(prev=>{return {...prev,registerChange:false}})
          },
          onError:(error)=>{
            if(error.response.data.contact[0] == "user portofolio with this contact already exists."){
              toast.error("Leader with this contact already exists",{autoClose:2000,hideProgressBar:true,position:"top-center",style:{width:"25rem"}});
            }else if(error.response.data.contact){
              toast.error("Invalid contact",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }else{
              toast.error("Could not add new management",{autoClose:2000,hideProgressBar:true,position:"top-center"});
            }
          }
        })
      }
    }

    const handleChange = (event) => {
        setLeader(prev=> {return {...prev,[event.target.name]:event.target.value}})
      }

    const handleDateChange = (name,date) => {
        if(name === "end_date"){
          setDateUnknown(false);
        }
        setLeader(prev=> {return {...prev,[name]:date}})
    }


    useEffect(() => {
        if (!profile) {
          setPreview(null);
          return;
        }
    
        const objectUrl = URL.createObjectURL(profile);
        setPreview(objectUrl);
    
        return () => URL.revokeObjectURL(objectUrl);
      }, [profile]);

      useEffect(()=>{
        if(!isFetchingCurrentTenure && leadershipStates.leaderCategory === "Executive"){
          setLeader(prev=>{
            return {...prev,start_date:currentTenure?.data?.start_date,end_date:currentTenure?.data?.end_date,tenure:currentTenure?.data?.id}
          })
        }
      },[currentTenure?.data]);

    useEffect(()=>{
      console.log(leadershipStates)
    },[])
    return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[24rem] pb-5 max-h-[36rem] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setLeadershipStates(prev=>{return {...prev,registerChange:false,linkedAccount:{}}})}/>
                <div className="h-10 flex items-center justify-between px-4">
                    <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">Register Leadership Change</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                <div className="overflow-y-scroll pb-4 h-[90%]">
                <div className="w-[80%] h-40 flex justify-center mt-5">
                    <div className="flex w-[14.5rem] justify-end">
                    <div className="w-32 h-32 relative">
                        <img src={preview ||  defaultProfile} alt="Leader Profile" width="100%" height="100%" className="w-36 h-full object-cover rounded-md"/>
                          <div className="h-8 w-8 flex items-center justify-center rounded-full bg-cardBackgroundLight dark:bg-cardBackgroundDark absolute bottom-[0px] right-[0px]">
                            <CameraIcon height="20" width="20" className="cursor-pointer" onClick={onSelectFile}/>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="w-full flex items-center flex-col gap-5 h-[50%]">
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Name</label>
                        {
                            <input className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" placeholder="Person Name" name="name" onChange={handleChange} defaultValue={leader.name}/>
                        }
                    </div>
          <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact</label>
              <input className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" placeholder="Person Contact" onChange={handleChange} name="contact"/>
            </div>
            {
              leadershipStates.leaderCategory === "Management" && 
              <div className="flex justify-between w-[80%]">
              <div className="flex">
                <span className="text-primaryTextLight dark:text-primaryTextDark">Tenure</span>
              </div>
                <div className="flex flex-col gap-5 w-[13rem]">
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.start_date ? new Date(leader.start_date): null} placeholderText="Starting (MM/yyyy)" className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" name="start_date" onChange={(date)=>handleDateChange("start_date",date)} maxDate={new Date()}/>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker selected={leader.end_date ? new Date(leader.end_date): null} placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border dark:border-dark-gray h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark dark:bg-cardBackgroundDark" name="end_date" onChange={(date)=>handleDateChange("end_date",date)}/>
                  </div>
                </div>
            </div>
            }
            {
            leadershipStates.leaderCategory === "Management" &&
            <div className="flex items-center w-[80%] justify-end">
                <div className="w-[12.6rem] flex items-center gap-4">
                <Toggle state={dateUnknown} onClick={()=>{setDateUnknown(prev=>!prev);setLeader(prev=>{return {...prev,end_date:null}})}}/>
                <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{"To date (unknown)"}</span>
                </div>
            </div>
            }
            <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={registerLeadershipChangeRequest}>{!isLoading ? "Done" : <BeatLoader color="white" size={10}/>}</button>
          </div>
          </div>
          </div>
          <input type="file" accept="image/*" className="hidden" id="leader-image-input" onChange={(event)=>{setProfile(event.target.files[0]);setLeader(prev=>{return {...prev,picture:event.target.files[0]}})}}/>
        </div>
    )
}

export default RegisterLeadershipChange;