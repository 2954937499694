import PageContainer from "../../components/PageContainer";
import Sidebar from "../../components/Sidebar";
import Banner from "../../components/hallpage/Banner";
import CreatePost from "../../components/posts-trending/CreatePost";
import FilterPost from "../../components/posts-trending/FilterPost";
import TrendingPosts from "../../components/posts-trending/TrendingPosts";
import { useContext, useState } from "react";
import SelectTagsModal from "../../components/posts-trending/SelectTagsModal";
import PostStatistics from "../../components/posts-trending/PostStatistics";
import TrendStatistics from "../../components/posts-trending/TrendStatistics";
import AllPosts from "../../components/posts-trending/AllPosts";
import AllTrends from "../../components/posts-trending/AllTrends";
import FilterPostModal from "../../components/posts-trending/FilterPostModal";
import FilterTrendsModal from "../../components/posts-trending/FilterTrendsModal";
import SearchedPosts from "../../components/posts-trending/SearchedPosts";
import FilterTrend from "../../components/posts-trending/FilterTrend";
import Lightbox from "yet-another-react-lightbox";
import { useLogout } from "../../hooks/authHooks";
import { logoutContext } from "../../contexts/logoutContext";
import ConfirmModal from "../../components/ConfirmModal";
import { useDeletePost } from "../../hooks/postHooks";
import { useDeleteTrend } from "../../hooks/trendHooks";
import { postTrendMenuContext } from "../../contexts/postTrendMenuContext";
import { useEffect } from "react";
import ReportTrendModal from "../../components/posts-trending/ReportTrend";
import ReportPostModal from "../../components/posts-trending/ReportPostModal";

const PostsTrendingPage = () => {
  const [trending, setTrending] = useState(false);
  const [showSelectTagsModal, setShowSelectTagsModal] = useState(false);
  const [showFilterTrendsModal,setShowFilterTrendsModal] = useState(false);
  const [showFilterPostsModal,setShowFilterPostsModal] = useState(false);
  const [showTrendingPosts,setShowTrendingPosts] = useState(false);
  const [searching,setSearching] = useState(false);
  
  const [searchedPosts,setSearchedPosts] = useState([]);
  const [searchedPostsDetails,setSearchedPostsDetails] = useState({fetchNextPage:undefined,hasNextPage:false})

  
  const [searchedTrends,setSearchedTrends] = useState([]);
  const [searchedTrendsDetails,setSearchedTrendsDetails] = useState({fetchNextPage:undefined,hasNextPage:false});

  const [filtered,setFiltered] = useState(false);


  const [viewImages,setViewImages] = useState(false);
  const [images,setImages] = useState([]);

  const {logout,setLogout} = useContext(logoutContext);
  const {menu,setMenu} = useContext(postTrendMenuContext);

  const {onLogout} = useLogout();
  const {onDeletePost,isDeletingPost} = useDeletePost();
  const {onDeleteTrend,isDeletingTrend} = useDeleteTrend();

  const Posts = ({parentId}) => {
    useEffect(()=>{
      let timeout;
      if(filtered && searchedPosts.pages[0]?.data?.results.length === 0 && !trending){
        timeout = setTimeout(()=>{
          setFiltered(false);
        },8000)
      }
      else if(filtered && trending && searchedTrends.pages[0]?.data?.results.length === 0){
        timeout = setTimeout(()=>{
          setFiltered(false);
        },8000)
      }
      return ()=>clearTimeout(timeout)
    },[])
    if(searching || filtered){
      return (
        <SearchedPosts posts={searchedPosts} trends={searchedTrends} trending={trending} setViewImages={()=>setViewImages(true)} setImages={setImages} searchedPostsDetails={searchedPostsDetails} searchedTrendsDetails={searchedTrendsDetails} parentId={parentId}/>
        )
    }else if(!searching && !filtered && trending){
      return (
        <AllTrends setViewImages={()=>setViewImages(true)} setImages={setImages} parentId={parentId}/>
        )
    }else if(!searching && !filtered && !trending){
      return (
        <AllPosts setViewImages={()=>setViewImages(true)} setImages={setImages} parentId={parentId} showTrendingPosts={showTrendingPosts} setShowTrendingPosts={setShowTrendingPosts}/>
      )
    }
  }
  useEffect(()=>{
    document.title = "LinkLounge | Posts & Trends"
  })
  useEffect(()=>{
    let prevScrollPos = window.pageYOffset;

    window.onscroll = function() {
      const currentScrollPos = window.pageYOffset;

      if (prevScrollPos > currentScrollPos) {
        document.getElementById("navbar").style.top = "1rem";
      } else {
        document.getElementById("navbar").style.top = "-60px"; // Adjust the value to match the height of your navbar
      }

      prevScrollPos = currentScrollPos;
    };
  },[])
  return (
    <PageContainer customstyle={"flex h-full w-full relative"}>
      <div className="w-full h-full p-4 flex justify-between relative overflow-y-auto" id="posts-container">
        <section className="w-1/5  h-fit self-start">
          <div className="fixed top-[1rem] left-8">
          <Sidebar />
          </div>
        </section>
        <main className="w-[75%] self-start h-full">
          <section
            className={`banner bg-cover bg-center rounded-md mb-8 p-2 flex flex-col w-[73%] right-[1.5rem] fixed top-[1rem] z-50 duration-300`} id="navbar"
          >
            <Banner show={false} />
          </section>
          <section className="flex h-full w-full mt-20 flex-row px-4 justify-between">
            <div className="w-[65%]">
              <div className="">
              <CreatePost onSelectTag={() => setShowSelectTagsModal(true)} />
              </div>
              {
                // !trending ? 
                <FilterPost setTrending={setTrending} trending={trending} onFilterPosts={()=>setShowFilterPostsModal(true)} onFilterTrends={()=>setShowFilterTrendsModal(true)} setSearchedPosts={setSearchedPosts} setSearching={setSearching} filtered={filtered} setSearchedPostsDetails={setSearchedPostsDetails} showTrendingPosts={showTrendingPosts} setShowTrendingPosts={setShowTrendingPosts}/>
                // : <FilterTrend setTrending={setTrending} trending={trending} onFilterPosts={()=>setShowFilterPostsModal(true)} onFilterTrends={()=>setShowFilterTrendsModal(true)} setSearchedPosts={setSearchedTrends} setSearching={setSearching} filtered={filtered} setSearchedTrendsDetails={setSearchedTrendsDetails}/>

              }          
              <Posts parentId={"posts-container"}/>
            </div>
            <div className="w-60 fixed top-[6rem] right-[1rem]">
              <PostStatistics />
              <hr className="border-[1px] border-[#e0e0e0] dark:border-dark-gray" />
              {/* <TrendStatistics /> */}
              {/* <hr className="border-[1px] border-[#e0e0e0]" /> */}
            </div>
          </section>
        </main>
      </div>
      {showSelectTagsModal && (
        <SelectTagsModal setShowSelectTagsModal={setShowSelectTagsModal} />
      )}
      {
        showFilterPostsModal && (
          <FilterPostModal onClose={()=>setShowFilterPostsModal(false)} setSearchedPosts={setSearchedPosts} setFiltered={setFiltered} setSearchedPostsDetails={setSearchedPostsDetails}/>
        )
      }
      {
        showFilterTrendsModal && (
          <FilterTrendsModal onClose={()=>setShowFilterTrendsModal(false)} setSearchedTrends={setSearchedTrends} setFiltered={setFiltered} setSearchedTrendsDetails={setSearchedTrendsDetails}/>
        )
      }
      {
        logout && 
        <ConfirmModal question={"Do you want to logout"} confirmAction={"Logout"} onCancel={()=>setLogout(false)} onConfirm={onLogout}/>      
      }
      {
        menu.post && 
        <ConfirmModal question={"Are you sure about deleting this post?"} confirmAction={"Delete"} onCancel={()=>setMenu(prev=>{ return {...prev,post:false,postId:""}})} onConfirm={onDeletePost} isLoading={isDeletingPost}/> 
      }
      {
        menu.trend && 
        <ConfirmModal question={"Are you sure about deleting this trend?"} confirmAction={"Delete"} onCancel={()=>setMenu(prev=>{ return {...prev,trend:false,trendId:""}})} onConfirm={onDeleteTrend} isLoading={isDeletingTrend}/> 
      }
      {
        menu.reportPost &&
        <ReportPostModal/>
      }
      {
        menu.reportTrend &&
        <ReportTrendModal/>
      }
       <Lightbox
        open={viewImages}
        close={() => setViewImages(false)}
        slides={[...images.map(img=>{return {src:img.image}})]}
      />
    </PageContainer>
  );
};

export default PostsTrendingPage;
