import { useContext } from "react";
import { momentMenuContext } from "../../contexts/momentMenuContext";

const MomentsInfoContext = ({innerRef,momentId,moment,changePrivacy}) => {
    const {setMomentMenu} = useContext(momentMenuContext);
    const onDeleteMoment = () => {
        setMomentMenu(prev=>{return {...prev,deleteEntireMoment:true,momentId:momentId}})
    }
    const onEditMomentTitle = () => {
        setMomentMenu(prev=> {return {...prev,editMomentTitle:true,moment:moment}})
    }
    const onAddMomentPictures = () => {
        setMomentMenu(prev=> {return {...prev,addMomentPictures:true,momentId:momentId}})
    }
    return (
        <div className="w-56 shadow-3xl text-sm rounded-md bg-backgroundLight dark:bg-cardBackgroundDark" ref={innerRef}>
            <div className="w-full flex flex-col pl-4 rounded-t-md gap-1 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onEditMomentTitle}>
                <span>Edit moment title</span>
            </div>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={changePrivacy}>
                Change privacy of moment
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-0.5 text-primaryTextLight dark:text-primaryTextDark cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark dark:hover:text-primaryTextLight" onClick={onAddMomentPictures}>
                Add pictures to moment
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="pl-4 py-0.5 text-[#F63E49] rounded-b-md cursor-pointer hover:bg-cardBackgroundLight 
   dark:hover:bg-hoverDark " onClick={onDeleteMoment}>
                Delete entire moment
            </div>
        </div>
    )
}

export default MomentsInfoContext;