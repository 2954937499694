import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";

const LeadershipHeader = ({executives,setExecutives}) => {
    const {setLeadershipStates} = useContext(leadershipContext);
    return (
        <div className="flex flex-col bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md w-full px-1 mb-2">
            <div className="py-3 px-4 font-semibold flex w-full justify-between items-center">
                <span className="text-xl text-primaryTextLight dark:text-primaryTextDark">Hall Leadership</span>
                <span className="text-sm text-[#F63E49] cursor-pointer" onClick={()=>setLeadershipStates(prev=>{return {...prev,showPortofolios:true}})}>Leadership Portofolios</span>
            </div>
            <hr className="dark:border-dark-gray"/>
            <div className="flex text-sm pt-2 px-2">
                <span className={`px-4 cursor-pointer font-medium` + (executives ? " border-b-2 border-[#F63E49] text-[#F63E49]":" text-header-gray dark:text-primaryTextDark")} onClick={()=>setExecutives(true)}>Executives</span>
                <span className={`px-4 cursor-pointer font-medium` + (!executives ? " border-b-2 border-[#F63E49] text-[#F63E49]":" text-header-gray dark:text-primaryTextDark")} onClick={()=>setExecutives(false)}>Management</span>
            </div>
        </div>
    )
}

export default LeadershipHeader;