import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const NewsHeader = ({ category, setCategory,categories }) => {
  const nav = useNavigate()
  return (
    <div className="flex flex-col bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md w-full px-1 mb-2">
      <div className="py-3 px-4 font-semibold flex w-full justify-between items-center">
        <span className="text-xl text-primaryTextLight dark:text-primaryTextDark">
          News
        </span>
        <button
          type="button"
          className="h-8 border font-light text-base flex bg-linear-grad items-center rounded-md px-5 justify-between cursor-pointer border-none"
          onClick={()=>nav("/news/add")}
        >
          <span className="text-white">Add News</span>
        </button>{" "}
      </div>
      <hr className="dark:border-dark-gray"/>
      <div className="flex text-base pt-3 px-2">
        {categories?.map(type=>{
          return (
            <span
            key={type.id}
            onClick={()=>setCategory(type)}
          className={
            `px-4 pb-1 cursor-pointer font-medium` +
            (category?.type?.toLowerCase() === type.type.toLowerCase()
              ? " border-b-2 border-[#F63E49] text-[#F63E49]"
              : " text-header-gray dark:text-primaryTextDark")
          }
        >
          {type.type}
        </span>
          )
        })}
        {/* <span
          className={
            `px-4 pb-1 cursor-pointer font-medium` +
            (category.toLowerCase() === "general"
              ? " border-b-2 border-[#F63E49] text-[#F63E49]"
              : " text-header-gray dark:text-primaryTextDark")
          }
        >
          General
        </span>
        <span
          className={
            `px-4 pb-1 cursor-pointer font-medium` +
            (category.toLowerCase() === "sports"
              ? " border-b-2 border-[#F63E49] text-[#F63E49]"
              : " text-header-gray dark:text-primaryTextDark")
          }
        >
          Sports
        </span>
        <span
          className={
            `px-4 pb-1 cursor-pointer font-medium` +
            (category.toLowerCase() === "entertainment"
              ? " border-b-2 border-[#F63E49] text-[#F63E49]"
              : " text-header-gray dark:text-primaryTextDark")
          }
        >
          Entertainment
        </span> */}
      </div>
    </div>
  );
};

export default NewsHeader;
