import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import defaultProfile from "../../assets/images/default-profile.png";
import {ReactComponent as CameraIcon} from "../../assets/icons/camera-icon.svg";
import Toggle from "../Toggle";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { leadershipContext } from "../../contexts/leadershipContext";
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";
import { ReactComponent as SelectIconDark } from "../../assets/icons/select-icon-dark.svg";
import { useDeclineLeadershipChangeRequest, useGetLeadershipChangeRequest, useGetManagementLeadershipPortfolios, useRegisterLeadershipChangeRequest } from "../../hooks/leadersHooks";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { BeatLoader, ClipLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import InfiniteScroll from "react-infinite-scroll-component";
import compareDate from "../../helpers/compareDate";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { themeContext } from "../../contexts/themeContext";

const LeadershipChangeRequested = () => {
  const {theme} = useContext(themeContext);
    const {leadershipStates,setLeadershipStates} = useContext(leadershipContext);
    const {hall} = useContext(hallContext);
    const [leader,setLeader] = useState();
    const [dateUnknown,setDateUnknown] = useState(false);
    const queryClient = useQueryClient()
    const {data:changeRequest,isLoading} = useGetLeadershipChangeRequest(leadershipStates.leader.change_request_id);
    const onDeclineRequest = () => {
        toast.success("Leadership change request canceled",{autoClose:1000,hideProgressBar:true,position:"top-center"});
        if(leadershipStates.leaderCategory === "Executive"){
            queryClient.invalidateQueries("current-hall-executives");
            queryClient.invalidateQueries("past-hall-leaders");
        }else if(leadershipStates.leaderCategory === "Management"){
            queryClient.invalidateQueries("current-hall-management");
            queryClient.invalidateQueries("past-management");
        }
    }
    const onDeclineRequestError = (error) => {
        toast.error("Could not cancel request",{autoClose:1000,hideProgressBar:true,position:"top-center"});
    }
    const {refetch,isRefetching,isLoading:isDeclining} = useDeclineLeadershipChangeRequest(leadershipStates.leader.change_request_id,onDeclineRequest,onDeclineRequestError);
        return (
        <div className="h-full w-full overflow-y-scroll scrollbar-hide flex items-center justify-center absolute bg-[rgba(0,0,0,0.3)]"
        style={{ zIndex: 200 }}>
            <div className="w-[24rem] pb-5 max-h-[36rem] bg-backgroundLight dark:bg-backgroundDark rounded-md relative">
                <CloseIcon width="25" height="25" className="cursor-pointer absolute top-[-5px] right-[-5px]" onClick={()=>setLeadershipStates(prev=>{return {...prev,changeRequested:false}})}/>
                <div className="h-10 flex items-center justify-between px-4">
                    <span className="text-xl text-primaryTextLight dark:text-primaryTextDark font-semibold">Requested Leadership Change</span>
                </div>
                <hr className="dark:border-dark-gray"/>
                { isLoading ? 
                <div className="flex w-full h-36 justify-center mt-10">
                <ClipLoader size={80} color="#F63E49"/>
            </div> :
                <React.Fragment>
                <div className="overflow-y-scroll pb-4 h-[90%]">
                <div className="w-[80%] h-40 flex justify-center mt-5">
                    <div className="flex w-[14.5rem] justify-end">
                    <div className="w-32 h-32 relative">
                        <img src={changeRequest?.data.picture || defaultProfile} alt="Leader Profile" width="100%" height="100%" className="w-36 h-full object-cover rounded-md"/>
                    </div>
                    </div>
                </div>
                <div className="w-full flex items-center flex-col gap-5 h-[50%]">
                    <div className="flex justify-between w-[80%]">
                        <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Name</label>
                        {
                            <input className="placeholder:font-thin focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" placeholder="Person Name" name="name" value={changeRequest?.data.name} readOnly/>
                        }
                    </div>
                    {/* <div className="flex justify-between w-[80%]">
            <label htmlFor="type" className="text-primaryTextLight dark:text-primaryTextDark">
              Portfolio
            </label>
            <div className="flex items-center gap-2 rounded-md h-8 border bg-cardBackgroundLight dark:bg-cardBackgroundDark relative w-[13rem]">
              <input
                placeholder="Select Position"
                id="title"
                className="placeholder:font-thin focus:outline-none text-primaryTextLight dark:text-primaryTextDark pl-4 w-[11rem]"
                value={selectedOption || ""}
                onClick={onDropDown}
                onChange={(event)=>setSelectedOption(event.target.value)}
              />
              <div className="flex items-center gap-2">
                <SelectIcon
                  width="12"
                  height="12"
                  onClick={onDropDown}
                  className={
                    "cursor-pointer " +
                    (rotate ? "rotate-180 delay-200" : "delay-200")
                  }
                />
              </div>
              {showOptions && (
                <div className="shadow-5xl bg-cardBackgroundLight dark:bg-cardBackgroundDark rounded-md text-primaryTextLight dark:text-primaryTextDark mt-[1px] w-[13rem] absolute top-8 z-10 max-h-36 overflow-auto" id="management-portfolio">
                  <InfiniteScroll
                        dataLength={portfolios?.pages?.length * 64 || 64}
                        next={fetchNextPage}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        hasMore={hasNextPage}
                        loader={<div className="flex w-full justify-center mt-5">
                        <ClipLoader size={30} color="#F63E49"/>
                    </div>}                        scrollableTarget={"management-portfolio"}
                        className="scrollbar-hide"
                        >
                          {
                            fetchingPortfolios ? 
                            <ClipLoader size={20} color="#F63E49"/> :
                            portfolios?.pages?.map((group,i)=>{
                              return group?.data?.results?.map(portfolio=>{
                              return (
                            <div
                            className="flex items-center gap-2 py-2 px-2 cursor-pointer hover:bg-[#e0e0e0]" key={portfolio.id}
                            onClick={() => onSelect(portfolio.title,portfolio.id)}>
                            <span>{portfolio.title}</span>
                            </div>                                  )
                          })})
                          }
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div> */}
          <div className="flex justify-between w-[80%]">
              <label htmlFor="name" className="text-primaryTextLight dark:text-primaryTextDark">Contact</label>
              <input className="placeholder:font-thin focus:outline-none border h-8 w-[13rem] rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" placeholder="Person Contact" name="contact" value={changeRequest?.data.contact} readOnly/>
            </div>
            <div className="flex justify-between w-[80%]">
              <div className="flex">
                <span className="text-primaryTextLight dark:text-primaryTextDark">Tenure</span>
              </div>
                <div className="flex flex-col gap-5 w-[13rem]">
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker placeholderText="Starting (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" name="start_date" readOnly selected={new Date(changeRequest?.data.start_date)}/>
                  </div>
                  <div className="w-full">
                    <ReactDatePicker dateFormat="MM/yyyy" showMonthYearPicker placeholderText="Ending (MM/yyyy)" className="placeholder:font-thin focus:outline-none border h-8 rounded-md px-2 text-primaryTextLight dark:text-primaryTextDark" name="end_date" readOnly selected={changeRequest?.data.end_date !== null ? new Date(changeRequest?.data.end_date):null}/>
                  </div>
                </div>
            </div>
            {
            leadershipStates.leaderCategory === "Management" &&
            <div className="flex items-center w-[80%] justify-end">
                <div className="w-[12.6rem] flex items-center gap-4">
                <Toggle state={changeRequest?.data.end_date !== null ? false: true}/>
                <span className="text-sm text-primaryTextLight dark:text-primaryTextDark">{"To date (unknown)"}</span>
                </div>
            </div>
            }
            <button className="text-white bg-linear-grad mt-auto px-4 rounded-sm py-[0.2rem]" onClick={refetch}>{!isRefetching || isDeclining ?"Cancel Request" : <BeatLoader color="white" size={10}/>}</button>
          </div>
          </div>
          </React.Fragment>
            }
          </div>
        </div>
    )
}

export default LeadershipChangeRequested;