import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    email_or_username: Yup.string()
      .required('Username or Email is required')
      .test('email_or_phone', 'Email is invalid', (value) => {
            if(value !== undefined){ 
                if(value.includes('@')){
                    return validateEmail(value)
                }else{
                    return validateUsername(value)
            }}
      }),
    password: Yup.string().required("Password is required")
})

export const ForgotPasswordSchema = Yup.object().shape({
    email_or_username: Yup.string()
      .required('Username or Email is required')
      .test('email_or_phone', 'Email is invalid', (value) => {
            if(value !== undefined){ 
                if(value.includes('@')){
                    return validateEmail(value)
                }else{
                    return validateUsername(value)
            }}
      })
})

export const changePasswordSchema = Yup.object().shape({
    new_password1:Yup.string('Password is required')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol')})

const validateEmail = (email) => {
    return Yup.string().email().isValidSync(email)
 };
 
 const validateUsername = (username) => {
    return Yup.string().isValidSync(username);
 };

 export const otpSchema = Yup.object().shape({
    otp:Yup.string().required("OTP is required").length(6)
 })
