import { useEffect } from "react";
import React, { useRef } from "react";
import { useState } from "react";
import ImagesPostContent from "./ImagesPostContent";
import VideoPostContent from "./VideoPostContent";

const TextPostWithVideo = ({text,video}) => {
  const [showSeeMore,setShowSeeMore] = useState(false);
  const [seeMore,setSeeMore] = useState(false);
  const ref = useRef(null);

  useEffect(()=>{
    if ((ref.current && ref.current.clientHeight > 60)) {
      setSeeMore(true);
      setShowSeeMore(true);
    }else{
      setShowSeeMore(false);
    }
  },[ref])
  return (
    <div className="mt-4 w-full h-full">
      <div className="text-base w-full">
        <p ref={ref} className={"w-full break-words " + (seeMore && `line-clamp-4 inline`)}>
          {text}{" "}
        </p>
        {
          showSeeMore && 
          <React.Fragment>
            {!seeMore && 
                        <span className="text-xs text-[#F63E49] inline cursor-pointer" onClick={()=>setSeeMore(true)}>See less</span>
    
            }
            {seeMore && 
              <span className="text-xs text-[#F63E49] inline cursor-pointer" onClick={()=>setSeeMore(false)}>See more</span>
              }
          </React.Fragment>  
        }
        <VideoPostContent video={video}/>
      </div>
    </div>
  );
};

export default TextPostWithVideo;
