import {createContext, useEffect, useState} from "react";


export const postBackgroundContext = createContext();
export const trendBackgroundContext = createContext();

export const PostBackgroundProvider = ({children}) => {
    const [postBackgrounds,setPostBackgrounds] = useState([]);
    return (
        <postBackgroundContext.Provider value={{postBackgrounds,setPostBackgrounds}}>
            {children}
        </postBackgroundContext.Provider>
    )
}

export const TrendBackgroundProvider = ({children}) => {
    const [trendBackgrounds,setTrendBackgrounds] = useState([]);
    return (
        <trendBackgroundContext.Provider value={{trendBackgrounds,setTrendBackgrounds}}>
            {children}
        </trendBackgroundContext.Provider>
    )
}