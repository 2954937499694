import moment from "moment/moment";
import { useContext } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { ReactComponent as UploadDoneIcon } from "../../assets/icons/upload-done-icon.svg";
import {ReactComponent as ReUploadIcon} from "../../assets/icons/reupload-icon-white.svg"
import { createMomentContext } from "../../contexts/createMomentContext";
import CircularProgressTracker from "../CircularProgressTracker";
const MomentPreview = ({showLightBox,postMomentPicture}) => {
  const { momentData, setMomentData } = useContext(createMomentContext);
  const onRemovePicture = (id, name) => {
    if (momentData.momentPicturesPreview.length === 1) {
      setMomentData((prev) => {
        return {
          ...prev,
          momentPicturesPreview: prev.momentPicturesPreview.filter(
            (picture) => picture.id !== id
          ),
          momentPictures: prev.momentPictures.filter(
            (picture) => picture.id !== id
          ),
          preview:false
        };
      });
    } else {
      setMomentData((prev) => {
        return {
          ...prev,
          momentPicturesPreview: prev.momentPicturesPreview.filter(
            (picture) => picture.id !== id
          ),
          momentPictures: prev.momentPictures.filter(
            (picture) => picture.id !== id
          ),
        };
      });
    }
  };
  const onReUploadImage = (event,id,momentId) => {
    event.stopPropagation()
    const picture = momentData.momentPictures.find(picture=>picture.id === id);
    postMomentPicture(momentId,picture);
  }
  return (
    <div className="mt-4 mb-4 w-full max-h-80 relative">
      <div className=" w-full h-full gap-2">
        {momentData.momentPicturesPreview.length === 1 && (
          <div
            className={
              "max-w-full gap-2 relative" +
              (momentData.momentPicturesPreview[0].isUploaded ?
                " border-[3px] rounded-lg border-green-600" :"")
            }
            onClick={showLightBox}
          >
            <img
              src={momentData.momentPicturesPreview[0].image}
              alt="Post Images"
              width="100%"
              height="100%"
              className="rounded-md w-full max-h-80 object-cover"
            />
            <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-[999]" style={{zIndex:100}}>
              <CloseIcon
                width="20"
                height="20"
                onClick={(event) => {
                  event.stopPropagation()
                  onRemovePicture(
                    momentData.momentPicturesPreview[0].id,
                    momentData.momentPicturesPreview[0].name
                  )}
                }
                className="cursor-pointer z-50"
              />
            </div>
            {/* Complete Moment Upload function and reupload. Adjust zIndex to solve problem */}
            {(momentData.momentPicturesPreview[0].isLoading || momentData.momentPicturesPreview[0].isUploaded || momentData.momentPicturesPreview[0].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[0].isLoading && momentData.momentPicturesPreview[0]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[0]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[0].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[0].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[0]?.id,momentData.momentPicturesPreview[0]?.momentId)}/>
              )}
            </div>
              </>
            }
          </div>
        )}
        {momentData.momentPicturesPreview.length === 2 && (
          <div className="w-full h-80 flex gap-1">
            {momentData.momentPicturesPreview.map((picture) => {
              return (
                <div
                  className={
                    "w-1/2 h-full relative" +
                    (picture.isUploaded ?
                      " border-[3px] rounded-lg border-green-600" :"")
                  }
                  key={picture.id}
                  onClick={showLightBox}
                >
                  <img
                    src={picture.image}
                    alt="Post Images"
                    width="100%"
                    height="100%"
                    className="w-full h-full object-cover rounded-md"
                  />
                  <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                    <CloseIcon
                      width="20"
                      height="20"
                      onClick={(event) => {event.stopPropagation();onRemovePicture(picture.id, picture.name)}}
                      className="cursor-pointer z-50"
                    />
                  </div>
                  {(picture?.isLoading || picture?.isUploaded || picture?.reUpload) && <>
                  <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
                  <div className="m-auto absolute top-0 w-full h-full flex items-center justify-center" style={{zIndex:100}}>
                    {(picture.isLoading && picture?.uploadProgress?.progress !== 1) && (
                      <CircularProgressTracker percentage={Math.round(picture?.uploadProgress?.progress*100)} width="60px" height="60px"/>
                    )}
                    {picture.isUploaded && (
                      <UploadDoneIcon width="40" height="40" />
                    )}
                    {picture?.reUpload && (
                      <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,picture?.id,picture?.momentId)}/>

                    )}
                  </div>

                  </>}
                </div>
              );
            })}
          </div>
        )}
        {momentData.momentPicturesPreview.length === 3 && (
          <div className="h-80 w-full flex gap-1 relative">
            <div
              className={
                "w-1/2 h-full relative" +
                (momentData.momentPicturesPreview[0].isUploaded ?
                  " border-[3px] rounded-lg border-green-600" : "")
              }
              onClick={showLightBox}
            >
              <img
                src={momentData.momentPicturesPreview[0].image}
                alt="Post Images"
                width="100%"
                height="100%"
                className="w-full h-full object-cover rounded-md"
              />
              <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                <CloseIcon
                  width="20"
                  height="20"
                  onClick={(event) =>{
                    event.stopPropagation();
                    onRemovePicture(
                      momentData.momentPicturesPreview[0].id,
                      momentData.momentPicturesPreview[0].name
                    )
                  }}
                  className="cursor-pointer z-50"
                />
              </div>
              {(momentData.momentPicturesPreview[0].isLoading || momentData.momentPicturesPreview[0].isUploaded || momentData.momentPicturesPreview[0].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[0].isLoading && momentData.momentPicturesPreview[0]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[0]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[0].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[0].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[0]?.id,momentData.momentPicturesPreview[0]?.momentId)}/>
              )}
            </div>
              </>
            }
            </div>
            <div className="w-1/2 h-80 flex flex-col justify-between">
              {momentData.momentPicturesPreview.slice(1).map((picture) => {
                return (
                  <div
                    className={
                      "h-[49%] w-full relative" +
                      (picture.isUploaded ?
                        " border-[3px] rounded-lg border-green-600": "")
                    }
                    key={picture.id}
                    onClick={showLightBox}
                  >
                    <img
                      src={picture.image}
                      alt="Post Images"
                      width="100%"
                      height="100%"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                      <CloseIcon
                        width="20"
                        height="20"
                        onClick={(event) =>{
                          event.stopPropagation()
                          onRemovePicture(picture.id, picture.name)
                        }}
                        className="cursor-pointer z-50"
                      />
                    </div>
                    {(picture?.isLoading || picture?.isUploaded || picture?.reUpload) && <>
                  <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
                  <div className="m-auto absolute top-0 w-full h-full flex items-center justify-center" style={{zIndex:100}}>
                    {(picture.isLoading && picture?.uploadProgress?.progress !== 1) && (
                      <CircularProgressTracker percentage={Math.round(picture?.uploadProgress?.progress*100)} width="60px" height="60px"/>
                    )}
                    {picture.isUploaded && (
                      <UploadDoneIcon width="40" height="40" />
                    )}
                    {picture?.reUpload && (
                      <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,picture?.id,picture?.momentId)}/>

                    )}
                  </div>

                  </>}                  </div>
                );
              })}
            </div>
          </div>
        )}
        {momentData.momentPicturesPreview.length === 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[0].isUploaded ?
                    " border-[3px] rounded-lg border-green-600" :"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[0].id,
                        momentData.momentPicturesPreview[0].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[0].isLoading || momentData.momentPicturesPreview[0].isUploaded || momentData.momentPicturesPreview[0].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[0].isLoading && momentData.momentPicturesPreview[0]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[0]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[0].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[0].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[0]?.id,momentData.momentPicturesPreview[0]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[2].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[2].id,
                        momentData.momentPicturesPreview[2].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[2].isLoading || momentData.momentPicturesPreview[2].isUploaded || momentData.momentPicturesPreview[2].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[2].isLoading && momentData.momentPicturesPreview[2]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[2]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[2].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[2].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[2]?.id,momentData.momentPicturesPreview[2]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[1].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation()
                      onRemovePicture(
                        momentData.momentPicturesPreview[1].id,
                        momentData.momentPicturesPreview[1].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[1].isLoading || momentData.momentPicturesPreview[1].isUploaded || momentData.momentPicturesPreview[1].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[1].isLoading && momentData.momentPicturesPreview[1]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[1]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[1].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[1].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[1]?.id,momentData.momentPicturesPreview[1]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[3].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[3].id,
                        momentData.momentPicturesPreview[3].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[3].isLoading || momentData.momentPicturesPreview[3].isUploaded || momentData.momentPicturesPreview[3].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[3].isLoading && momentData.momentPicturesPreview[0]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[0]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[3].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[3].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[3]?.id,momentData.momentPicturesPreview[3]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
            </div>
          </div>
        )}
        {momentData.momentPicturesPreview.length > 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[0].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[0].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[0].id,
                        momentData.momentPicturesPreview[0].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[0].isLoading || momentData.momentPicturesPreview[0].isUploaded || momentData.momentPicturesPreview[0].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[0].isLoading && momentData.momentPicturesPreview[0]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[0]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[0].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[0].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[0]?.id,momentData.momentPicturesPreview[0]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[2].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[2].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation()
                      onRemovePicture(
                        momentData.momentPicturesPreview[2].id,
                        momentData.momentPicturesPreview[2].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[2].isLoading || momentData.momentPicturesPreview[2].isUploaded || momentData.momentPicturesPreview[2].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[2].isLoading && momentData.momentPicturesPreview[2]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[2]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[2].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[2].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[2]?.id,momentData.momentPicturesPreview[2]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[1].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[1].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) =>{
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[1].id,
                        momentData.momentPicturesPreview[1].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {(momentData.momentPicturesPreview[1].isLoading || momentData.momentPicturesPreview[1].isUploaded || momentData.momentPicturesPreview[1].reUpload) &&
              <>
              <div className="w-full h-full absolute top-0 bg-black opacity-[20%] rounded-md" style={{zIndex:50}}></div>
            <div className="m-auto absolute top-0 flex items-center justify-center h-full w-full" style={{zIndex:100}}>
              {(momentData.momentPicturesPreview[1].isLoading && momentData.momentPicturesPreview[1]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[1]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
              )}
              {momentData.momentPicturesPreview[1].isUploaded && (
                <UploadDoneIcon width="40" height="40" />
              )}
              {momentData.momentPicturesPreview[1].reUpload && (
                <ReUploadIcon width="40" height="40" className="cursor-pointer" onClick={(event)=>onReUploadImage(event,momentData.momentPicturesPreview[1]?.id,momentData.momentPicturesPreview[1]?.momentId)}/>
              )}
            </div>
              </>
            }
              </div>
              <div
                className={
                  "w-full h-[49%] relative" +
                  (momentData.momentPicturesPreview[3].isUploaded ?
                    " border-[3px] rounded-lg border-green-600":"")
                }
                onClick={showLightBox}
              >
                <img
                  src={momentData.momentPicturesPreview[3].image}
                  alt="Post Images"
                  width="100%"
                  height="100%"
                  className="w-full h-full object-cover rounded-md absolute"
                />
                <div className="w-full flex items-center justify-end px-2 py-2 absolute top-0 cursor-pointer z-50" style={{zIndex:100}}>
                  <CloseIcon
                    width="20"
                    height="20"
                    onClick={(event) => {
                      event.stopPropagation();
                      onRemovePicture(
                        momentData.momentPicturesPreview[3].id,
                        momentData.momentPicturesPreview[3].name
                      )
                    }}
                    className="cursor-pointer z-50"
                  />
                </div>
                {/* <div className="w-1/2 h-[49%] absolute top-0 bg-black opacity-[20%]" style={{zIndex:50}}></div>
                <div className="m-auto w-full h-full absolute top-0 flex items-center justify-center" style={{zIndex:100}}>
                  {(momentData.momentPicturesPreview[3].isLoading && momentData.momentPicturesPreview[3]?.uploadProgress?.progress !== 1) && (
                <CircularProgressTracker percentage={Math.round(momentData.momentPicturesPreview[3]?.uploadProgress?.progress*100)} width="60px" height="60px"/>
                  )}
                  {momentData.momentPicturesPreview[3].isUploaded && (
                    <UploadDoneIcon width="40" height="40" />
                  )}
                </div> */}
                <div className="absolute flex items-center justify-center w-full h-full bg-[#000000] opacity-70 text-white font-2xl rounded-md">{`+${
                  momentData.momentPicturesPreview.length - 4
                }`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MomentPreview;
