import { useMutation } from "react-query";
import { changePassword, confirmResetOTP, forgotPassword, login, logout } from "../api/auth";
import { removeAccessToken, removeLocalStorageRefreshToken, removeSessionStorageRefreshToken } from "../helpers/manageToken";
import { removeLocalStorageUser, removeSessionStorageUser } from "../helpers/manageUser";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { logoutContext } from "../contexts/logoutContext";
import { removeHall } from "../helpers/manageHall";


const useLogin = () => {
    return useMutation(login,{
        mutationKey:"login"
    });
}

const useForgotPassword = () => {
    return useMutation(forgotPassword,{
        mutationKey:"forgot-password"
    });
}

const useConfirmPasswordResetOTP = () => {
    return useMutation(confirmResetOTP,{
        mutationKey:"confirm-password-reset-otp"
    })
}

const useChangePassword = () => {
    return useMutation(changePassword,{
        mutationKey:"change-password"
    })
}

const useLogout = () => {
    const {setLogout} = useContext(logoutContext);
    const {mutate} = useMutation(logout,{mutationKey:"logout"});
    const nav = useNavigate();
    const onLogout = () => {
        removeAccessToken();
        removeLocalStorageRefreshToken();
        removeSessionStorageRefreshToken();
        removeLocalStorageUser();
        removeSessionStorageUser();
        removeHall()
        mutate();
        setLogout(false);
        nav('/')
    }
    return {onLogout};
}



export {useLogin,useForgotPassword,useLogout,useConfirmPasswordResetOTP,useChangePassword};