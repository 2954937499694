import { useState } from "react";
import { createContext } from "react";

export const momentMenuContext = createContext();
const MomentMenuContextProvider = ({children}) => {
    const [momentMenu,setMomentMenu] = useState({changePrivacy:false,editTitle:false,deleteEntireMoment:false,momentId:"",deleteMomentPicture:false,pictureId:"",setAsProfilePicture:false,profilePicture:"",setAsCoverPhoto:false,coverPhoto:"",moment:{},editMomentPictureTitle:false,momentPicture:{},addMomentPictures:false,background:""});
    return (
        <momentMenuContext.Provider value={{momentMenu,setMomentMenu}}>
            {children}
        </momentMenuContext.Provider>
    )
}

export default MomentMenuContextProvider;