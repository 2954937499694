import { ReactComponent as SearchIcon } from "../../assets/icons/search-icon.svg";
import { ReactComponent as SearchIconDark } from "../../assets/icons/search-icon-dark.svg";
import { BehaviorSubject,debounceTime,map,distinctUntilChanged,filter,switchMap,merge,of } from 'rxjs';
import { searchMembers } from "../../api/members";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { BarLoader } from "react-spinners";
import { hallContext } from "../../contexts/hallContext";
import { themeContext } from "../../contexts/themeContext";

const MembersHeader = ({setNewMembers,newMembers,setShowBlockedMembers,setSearchedMembers,setSearching,page,pageSize,setPage,setPageSize}) => {
  const {theme} = useContext(themeContext);
  const {hall} = useContext(hallContext);  
  const [searchTerm,setSearchTerm] = useState("");
    const [loading,setLoading] = useState(false);
    const [memberSubject,setMemberSubject] = useState(null);
    
    const onChange = (event) => {
      setPage(1);
      setPageSize(10);
        setSearchTerm(event.target.value)
            if(memberSubject){
            setLoading(true);
            setSearching(true);
            return memberSubject.next(event.target.value)
            }
    }

    const memberSearch = () => {
        const observable = memberSubject.pipe(
            map(s => s.trim()),
            distinctUntilChanged(),
            debounceTime(200),
            switchMap((term)=>{
                    return searchMembers(term,hall?.hallInfo?.id,page,pageSize).then(data=>{
                        setLoading(false);
                        return data;
                    })
            }))
            .subscribe(data=>{
                setSearchedMembers(data);
            })
            return () => {
                observable.unsubscribe()
                memberSubject.unsubscribe()}
    }
    useEffect(()=>{
      if(searchTerm === ""){
          setSearching(false);
          setLoading(false);
      }
  },[searchTerm])

  useEffect(()=>{
      if(memberSubject === null){
          const sub = new BehaviorSubject('')
          setMemberSubject(sub);
      }else{
          memberSearch()
      }
  },[memberSubject])
  return (
    <div className="flex justify-between w-full">
      <div className="flex items-center gap-2">
        <span className="font-semibold text-primaryTextLight dark:text-primaryTextDark text-lg">{!newMembers ? "Members" : "New Members"}</span>
        <span className="inline-block w-[2px] h-[2px] rounded-full bg-dot-gray dark:bg-primaryTextDark"></span>
        <span className="text-sm font-medium text-[#F63E49] cursor-pointer" onClick={setNewMembers}>{!newMembers ? "New Members" : "Members"}</span>
      </div>
      <div className="flex gap-4">
        <div className="flex flex-col border dark:border-dark-gray rounded-md items-center gap-1 w-52 h-full px-2 mr-4">
          <div className="flex w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark items-center gap-2 rounded-md h-full z-10">
            {
              theme?.value == "light" ?
              <SearchIcon width="15" height="15" />
:            <SearchIconDark width="15" height="15" />

            }
            <input
              type={"search"}
              placeholder="Search for Members"
              className="text-sm font-light h-full focus:outline-none w-full bg-cardBackgroundLight dark:bg-cardBackgroundDark text-primaryTextLight dark:text-primaryTextDark"
              value={searchTerm} 
              onChange={onChange}
            />
          </div>
          {
            loading && 
            <BarLoader color="#F63E49" height={2} width="95%" className="w-full"/>
          }
        </div>
        <button type="button" className="w-40 h-8 border text-white flex bg-linear-grad items-center rounded-md px-3 justify-center cursor-pointer" onClick={()=>setShowBlockedMembers(true)}>
            Blocklist
        </button>
      </div>
    </div>
  );
};

export default MembersHeader;
