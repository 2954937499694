import { ReactComponent as PrivacyGlobalIcon } from "../../assets/icons/public-post-icon-shaded.svg";
import { ReactComponent as PrivacyGlobalIconDark } from "../../assets/icons/privacy-global-icon-dark.svg";
import { ReactComponent as PrivacyPrivateIcon } from "../../assets/icons/private-post-icon-shaded.svg";
import { ReactComponent as PrivacyPrivateIconDark } from "../../assets/icons/private-post-icon-shaded-dark.svg";
import { ReactComponent as PrivacyHallIcon } from "../../assets/icons/hall-post-icon-shaded.svg";
import { ReactComponent as PrivacyHallIconDark } from "../../assets/icons/hall-post-icon-shaded-dark.svg";

import PrivacyCircleIcon from "../../assets/icons/circle-post-icon.png";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as MenuIconDark } from "../../assets/icons/menu-icon-dark.svg";
import SmallProfile from "../hallpage/SmallProfile";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { hallContext } from "../../contexts/hallContext";
import PostContextMenu from "./PostContextMenu";
import millify from "millify";
import ChangePrivacy from "./ChangePrivacy";
import { createPostContext } from "../../contexts/createPostContext";
import { backgroundContext } from "../../contexts/postBackgroundContext";
import { ReactComponent as EditedIcon } from "../../assets/icons/edited-post-icon.svg";
import { ReactComponent as EditedIconDark } from "../../assets/icons/edited-post-icon-dark.svg";
import { ReactComponent as ScheduledIcon } from "../../assets/icons/scheduled-icon.svg";
import { ReactComponent as ScheduledIconDark } from "../../assets/icons/scheduled-icon-dark.svg";
import { themeContext } from "../../contexts/themeContext";

const Post = ({ children, post,shared,sharerName,sharerProfileUrl }) => {
  const { hall } = useContext(hallContext);
  const { setPost } = useContext(createPostContext);
  const { setBackground } = useContext(backgroundContext);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showChangePrivacy, setShowChangePrivacy] = useState(false);
  const { theme } = useContext(themeContext);

  const ref = useRef();
  const handleMouseDown = (event) => {
    if (!ref.current?.contains(event.target)) {
      setShowContextMenu(false);
      setShowChangePrivacy(false);
    }
  };

  const onEdit = () => {
    if (post.pictures.length === 0 && post.videos.length === 0) {
      setPost((prev) => {
        return {
          ...prev,
          text: post.text,
          theme: post.theme,
          pictures: post.pictures,
          privacy: post.privacy,
          published_at: post.published_at,
          editPost: true,
          postId: post.id,
          postWithBg: post.theme ? true : false,
        };
      });
      if (post.theme) {
        setBackground(post.theme);
      }
    } else if (post.pictures.length !== 0) {
      setPost((prev) => {
        return {
          ...prev,
          text: post.text,
          theme: post.theme,
          pictures: post.pictures,
          preview: true,
          previewImages: post.pictures,
          privacy: post.privacy,
          published_at: post.published_at,
          editPost: true,
          postId: post.id,
          postWithBg: false,
        };
      });
    }else if(post.videos.length !== 0){
      setPost((prev) => {
        return {
          ...prev,
          text: post.text,
          theme: post.theme,
          preview: true,
          privacy: post.privacy,
          published_at: post.published_at,
          editPost: true,
          postId: post.id,
          postWithBg: false,
          previewVideos:post.videos,
        };
      });
    }
    setShowContextMenu(false);
    if (window.location.pathname === "/hallpage") {
      document
        .getElementById("scroll")
        .scrollTo({ top: 0, behavior: "smooth" });
    } else if (window.location.pathname === "/posts") {
      document
        .getElementById("posts-container")
        .scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
  });

  return (
    <div className="w-full p-4 rounded-md flex flex-col mb-5 text-primaryTextLight dark:text-primaryTextDark bg-cardBackgroundLight dark:bg-cardBackgroundDark">
      {
        shared && 
        <div className="w-full mb-5">
        <div className="flex w-full mb-2 items-center relative">
          <div className="w-10 h-10 mr-4 shrink-0">
            <img
              src={sharerProfileUrl}
              alt="Profile"
              height="100%"
              width="100%"
              className="rounded-full h-10 w-10 mr-4"
            />
          </div>
          <span className="inline-block">
            {sharerName} <span className="font-thin text-sm">shared this post</span>
          </span>
          <div className="ml-auto">
          {theme.value == "light" ? (
            <div className="w-12 h-12 flex items-center justify-center cursor-pointer" onClick={() => {
              setShowContextMenu(true);
            }}>
              <MenuIcon
                width="20"
                height="20"
                id="menu"
                className=""
                
              />
              </div>
            ) : (
              <div className="w-12 h-12 flex items-center justify-center cursor-pointer" onClick={() => {
                setShowContextMenu(true);
              }}>
              <MenuIconDark
                width="20"
                height="20"
                id="menu"
                className=""
                
              />
              </div>
            )}
            {showContextMenu && (
              <div className="absolute z-10 top-[0%] right-[0%]" ref={ref}>
                <PostContextMenu
                  postId={post.id}
                  postNotificationOff={post.notification_off}
                  onClose={() => setShowContextMenu(false)}
                  isHallPost={post.is_hall_post && (post?.hall === hall?.hallInfo?.id)}
                  postReported={post.post_reported}
                  setShowChangePrivacy={setShowChangePrivacy}
                  onEditPost={onEdit}
                  isPostPoll={post.polls.length !== 0 ? true : false}
                />
              </div>
            )}
            {showChangePrivacy && (
              <div className="absolute z-10 top-[80%] right-[0%]" ref={ref}>
                <ChangePrivacy
                  privacy={post.privacy}
                  setShowChangePrivacy={setShowChangePrivacy}
                  postId={post.id}
                />
              </div>
            )}
          </div>
        </div>
        <hr className="dark:border-dark-gray mt-2"/>
        </div>
      }
      <div className="flex w-full">
        <SmallProfile
          url={
            post?.is_hall_post || post?.polls[0]?.is_hall_poll
              ? post?.hall_details?.picture
              : post?.user_account.image
          }
        />
        <div className="flex flex-col ml-auto w-[100%]">
          <div className="flex h-14 items-center w-full justify-between relative">
            <div className="flex flex-col text-xl">
              <span>
                {post?.is_hall_post || post?.polls[0]?.is_hall_poll
                  ? post?.hall_details?.name
                  : post?.user_account.username}
              </span>
              <span className="text-sm inline-flex flex-wrap gap-1 items-center text-[rgba(54,76,102,0.84)] dark:text-primaryTextDark">
                {(post?.is_hall_post || post?.polls[0]?.is_hall_poll) && (
                  <>
                    {post?.user_account.fullname}{" "}
                    <span
                      className={
                        "inline-block w-[3px] h-[3px] rounded-full " +
                        (theme.value == "light"
                          ? "bg-dot-gray"
                          : "bg-[#e4e4e4]")
                      }
                    ></span>
                  </>
                )}
                {moment(post?.published_at).fromNow(false)}{" "}
                <span
                  className={
                    "inline-block w-[3px] h-[3px] rounded-full " +
                    (theme.value == "light" ? "bg-dot-gray" : "bg-[#e4e4e4]")
                  }
                ></span>
                {post?.privacy === "Public" && (
                  <>
                    {theme.value == "light" ? (
                      <PrivacyGlobalIcon
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    ) : (
                      <PrivacyGlobalIconDark
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    )}
                  </>
                )}
                {post?.privacy === "Private" && (
                  <>
                    {theme?.value == "light" ? (
                      <PrivacyPrivateIcon
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    ) : (
                      <PrivacyPrivateIconDark
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    )}
                  </>
                )}
                {post?.privacy === "Hall" && (
                  <>
                    {theme?.value == "light" ? (
                      <PrivacyHallIcon
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    ) : (
                      <PrivacyHallIconDark
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    )}
                  </>
                )}
                {post?.privacy === "Circle" && (
                  <img
                    src={PrivacyCircleIcon}
                    height="100%"
                    width="100%"
                    className="w-4 h-4"
                  />
                )}
                
                {!post?.published && (
                  <>
                    <span
                      className={
                        "inline-block w-[3px] h-[3px] rounded-full " +
                        (theme.value == "light"
                          ? "bg-dot-gray"
                          : "bg-[#e4e4e4]")
                      }
                    ></span>
                    {theme?.value == "light" ? (
                      <ScheduledIcon
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    ) : (
                      <ScheduledIconDark
                        height="12"
                        width="12"
                        className="inline-block"
                      />
                    )}
                  </>
                )}
                  <span
                    className={
                      "inline-block w-[3px] h-[3px] rounded-full " +
                      (theme.value == "light" ? "bg-dot-gray" : "bg-[#e4e4e4]")
                    }
                  ></span>
                <div className="flex gap-1">
                  {post?.tags?.length !== 0 ? post?.tags?.map((tag) => {
                    return (
                      <span className="text-[#0085FF]" key={tag.id}>
                        {tag.short_hand}
                      </span>
                    );
                  }):
                  <span className="text-[#0085FF]">
                  {"General"}
                </span>}
                </div>
              </span>
            </div>
            <>
            {!shared && 
            <>
            {theme.value == "light" ? (
              <div className="w-12 h-12 flex items-center justify-center cursor-pointer" onClick={() => {
                setShowContextMenu(true);
              }}>
              <MenuIcon
                width="20"
                height="20"
                id="menu"
                className=""
                
              />
              </div>
            ) : (
              <div className="w-12 h-12 flex items-center justify-center cursor-pointer" onClick={() => {
                setShowContextMenu(true);
              }}>
              <MenuIconDark
                width="20"
                height="20"
                id="menu"
                className=""
              />
              </div>
            )}
            {showContextMenu && (
              <div className="absolute z-10 top-[0%] right-[0%]" ref={ref}>
                <PostContextMenu
                  postId={post.id}
                  postNotificationOff={post.notification_off}
                  onClose={() => setShowContextMenu(false)}
                  isHallPost={post.is_hall_post && (post?.hall === hall?.hallInfo?.id)}
                  postReported={post.post_reported}
                  setShowChangePrivacy={setShowChangePrivacy}
                  onEditPost={onEdit}
                  isPostPoll={post.polls.length !== 0 ? true : false}
                />
              </div>
            )}
            {showChangePrivacy && (
              <div className="absolute z-10 top-[80%] right-[0%]" ref={ref}>
                <ChangePrivacy
                  privacy={post.privacy}
                  setShowChangePrivacy={setShowChangePrivacy}
                  postId={post.id}
                />
              </div>
            )}
            </>
            }
            </>
          </div>
          {children}
        {post?.is_edited && (
          <span className="mb-2 text-sm italic text-primaryTextLight dark:text-primaryTextDark opacity-[80%]">{"(Edited)"}</span>
                  )}
        </div>
      </div>
      <div className="mt-6">
        <hr className="dark:border-dark-gray"/>
        <div className="w-full flex justify-evenly text-sm items-center mt-2">
          <span>
            Likes:{" "}
            <span className="font-bold">
              {millify(post?.likes_count)}
            </span>
          </span>
          <span>
            Comments:{" "}
            <span className="font-bold">
              {millify(post?.comment_count)}
            </span>
          </span>
          <span>
            Shares:{" "}
            <span className="font-bold">
              {millify(post?.shares_count)}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Post;
