import ProgressiveImage from "react-progressive-graceful-image";

const dominantImageColor = "#777777";

const ImagesPostContent = ({ pictures, setViewImages, setImages }) => {
  return (
    <div className="mt-4 w-full">
      <div className=" w-full h-full gap-2">
        {pictures.length === 1 && (
          <ProgressiveImage src={pictures[0].image} placeholder="">
            {(src, loading) => {
              return loading ? (
                <div
                  className="h-96 w-full loader rounded-md"
                  style={{ backgroundColor: dominantImageColor }}
                />
              ) : (
                <img
                  src={src}
                  alt="Post Image"
                  className="rounded-md max-h-96 object-cover cursor-pointer"
                  onClick={() => {
                    setImages(pictures);
                    setViewImages(true);
                  }}
                  width="100%"
                  height="100%"
                />
              );
            }}
          </ProgressiveImage>
          // </div>
        )}
        {pictures.length === 2 && (
          <div className="w-full h-80 flex gap-1">
            {pictures.map((picture) => {
              return (
                <div className="w-1/2 h-full" key={picture.id}>
                  <ProgressiveImage src={picture.image} placeholder="">
                    {(src, loading) => {
                      return loading ? (
                        <div
                          className="h-full w-full loader rounded-md"
                          style={{ backgroundColor: dominantImageColor }}
                        />
                      ) : (
                        <img
                          src={src}
                          alt="Post Images"
                          width="100%"
                          height="100%"
                          className="w-full h-full object-cover rounded-md cursor-pointer"
                          onClick={() => {
                            setImages(pictures);
                            setViewImages(true);
                          }}
                        />
                      );
                    }}
                  </ProgressiveImage>
                </div>
              );
            })}
          </div>
        )}
        {pictures.length === 3 && (
          <div className="h-80 w-full flex gap-1">
            <div className="w-1/2 h-full">
              <ProgressiveImage src={pictures[0].image} placeholder="">
                {(src, loading) => {
                  return loading ? (
                    <div
                      className="h-full w-full loader rounded-md"
                      style={{ backgroundColor: dominantImageColor }}
                    />
                  ) : (
                    <img
                      src={src}
                      alt="Post Images"
                      width="100%"
                      height="100%"
                      className="w-full h-full object-cover rounded-md cursor-pointer"
                      onClick={() => {
                        setImages(pictures);
                        setViewImages(true);
                      }}
                    />
                  );
                }}
              </ProgressiveImage>
            </div>
            <div className="w-1/2 h-full flex flex-col justify-between">
              {pictures.slice(1).map((picture) => {
                return (
                  <div className="h-[49%] w-full" key={picture.id}>
                    <ProgressiveImage src={picture.image} placeholder="">
                      {(src, loading) => {
                        return loading ? (
                          <div
                            className="h-full w-full loader rounded-md"
                            style={{ backgroundColor: dominantImageColor }}
                          />
                        ) : (
                          <img
                            src={src}
                            alt="Post Images"
                            width="100%"
                            height="100%"
                            className="w-full h-full object-cover rounded-md cursor-pointer"
                            onClick={() => {
                              setImages(pictures);
                              setViewImages(true);
                            }}
                          />
                        );
                      }}
                    </ProgressiveImage>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {pictures.length === 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[0].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[2].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-96 w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[1].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[3].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
            </div>
          </div>
        )}
        {pictures.length > 4 && (
          <div className="h-80 w-full flex gap-2">
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[0].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[2].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between">
              <div className="w-full h-[49%]">
                <ProgressiveImage src={pictures[1].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
              </div>
              <div className="w-full h-[49%] relative">
                <ProgressiveImage src={pictures[3].image} placeholder="">
                  {(src, loading) => {
                    return loading ? (
                      <div
                        className="h-full w-full loader rounded-md"
                        style={{ backgroundColor: dominantImageColor }}
                      />
                    ) : (
                      <img
                        src={src}
                        alt="Post Images"
                        width="100%"
                        height="100%"
                        className="w-full h-full object-cover rounded-md absolute cursor-pointer"
                        onClick={() => {
                          setImages(pictures);
                          setViewImages(true);
                        }}
                      />
                    );
                  }}
                </ProgressiveImage>
                <div
                  className="absolute flex items-center justify-center w-full h-full bg-[#000000] opacity-70 text-white font-2xl rounded-md cursor-pointer"
                  onClick={() => {
                    setImages(pictures);
                    setViewImages(true);
                  }}
                >{`+${pictures.length - 4}`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesPostContent;
